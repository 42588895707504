import { SET_MOBILE_VIEW, SET_ENLIGHTEN_MANAGER_TOKEN, SET_USER_DETAILS } from './constants';

export function setMobileView(isMobileView) {
  return { type: SET_MOBILE_VIEW, isMobileView };
}

export function setEnlightenManagerToken(enlightenManagerToken) {
  return { type: SET_ENLIGHTEN_MANAGER_TOKEN, enlightenManagerToken };
}

export function setUserDetails(userDetails) {
  return { type: SET_USER_DETAILS, userDetails };
}
