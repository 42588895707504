import { makeStyles } from '@material-ui/core/styles';

export const styles = theme => ({
  titleWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  closeButton: {
    padding: 0
  },
  button: {
    textTransform: 'none'
  },
  saveButton: {
    color: theme.palette.secondary.main,
    textTransform: 'none'
  },
  buttonWrapper: {
    padding: theme.spacing(2, 3)
  },
  mapArea: {
    width: '100%',
    height: theme.spacing(50),
    backgroundColor: '#0001',
    textAlign: 'center',
    '& img': {
      width: '100%',
      height: '100%'
    },
    '&&': {
      marginTop: 0
    }
  },
  mapWrapper: {
    position: 'relative',
    padding: '0 !important'
  },
  address: {
    width: 'calc(100% - 32px)',
    position: 'absolute',
    zIndex: 1,
    backgroundColor: theme.palette.secondary.main,
    margin: 'auto',
    left: 0,
    right: 0,
    top: theme.spacing(1),
    padding: theme.spacing(1),
    borderRadius: theme.spacing(0.5),
    '& .MuiOutlinedInput-root': {
      borderRadius: 0,
      borderColor: '#707070'
    },
    '& input': {
      padding: theme.spacing(1.5)
    }
  },
  hide: {
    display: 'none'
  }
});

const useStyles = makeStyles(theme => ({
  ...styles(theme)
}));

export default useStyles;
