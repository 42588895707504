import * as React from 'react';
import { SvgIcon } from '@material-ui/core';

const Interconnect = props => {
  return (
    <SvgIcon {...props} width="20" height="20" viewBox="0 0 20 20">
      <g transform="translate(-56 -870)">
        <g transform="translate(-38 74)">
          <rect fill="#f37320" width="20" height="20" rx="4" transform="translate(94 796)" />
          <path
            fill="#fff"
            d="M6087.016,2092.984V2095h1.969v-2.016Zm-5.031,0V2095H6084v-2.016Zm10.016,0V2095h2.016v-2.016Zm-10.016,5.031v1.969H6084v-1.969Zm5.031,0v1.969h1.969v-1.969Zm4.984,0v1.969h2.016v-1.969Z"
            transform="translate(-5984 -1292.999)"
          />
        </g>
      </g>
    </SvgIcon>
  );
};

export default Interconnect;
