import React from 'react';

const MapIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24">
      <defs />
      <g transform="translate(0.13 0)">
        <rect fill="none" width="24" height="24" transform="translate(-0.13 0)" />
        <path
          fill="#1a85e2"
          d="M21.108,12h-1.47v1.47h1.47V23.759H3.47V13.47H4.94V12H3.47A1.471,1.471,0,0,0,2,13.47V23.759a1.471,1.471,0,0,0,1.47,1.47H21.108a1.471,1.471,0,0,0,1.47-1.47V13.47A1.471,1.471,0,0,0,21.108,12Z"
          transform="translate(-0.53 -2.698)"
        />
        <path
          fill="#1a85e2"
          d="M13.879,18.174,9.4,11.859a5.986,5.986,0,0,1,4.476-9.836A5.934,5.934,0,0,1,19.759,8a6.049,6.049,0,0,1-1.319,3.774Zm0-14.681A4.463,4.463,0,0,0,9.47,8a4.556,4.556,0,0,0,1.092,2.959l3.317,4.68,3.4-4.771A4.572,4.572,0,0,0,18.289,8,4.463,4.463,0,0,0,13.879,3.493Z"
          transform="translate(-2.121 -0.053)"
        />
        <path
          fill="#1a85e2"
          d="M1.47,0A1.47,1.47,0,1,1,0,1.47,1.47,1.47,0,0,1,1.47,0Z"
          transform="translate(10.289 5.644)"
        />
      </g>
    </svg>
  );
};

export default MapIcon;
