import React from 'react';
import {
  withStyles,
  Dialog,
  Button,
  DialogActions,
  DialogTitle,
  DialogContent,
  DialogContentText
} from '@material-ui/core';

const styles = theme => ({
  buttonWrapper: {
    padding: theme.spacing(2, 3),
    borderTop: '1px solid #E1E1E1'
  },
  acceptButton: {
    color: theme.palette.secondary.main,
    textTransform: 'none'
  },
  rejectButton: {
    color: '#919191',
    textTransform: 'none'
  },
  titleWrapper: {
    borderBottom: '1px solid #E1E1E1'
  }
});

function BOMSavePopup({ showExitIntentPopup, handleClose, classes, handleSave }) {
  return (
    <div>
      <Dialog open={showExitIntentPopup} onClose={handleClose}>
        <DialogTitle className={classes.titleWrapper}>{'Save BOM'}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            You have some unsaved changes in your BOM application, do you wish to save BOM before proceed.
          </DialogContentText>
        </DialogContent>
        <DialogActions className={classes.buttonWrapper}>
          <Button
            onClick={() => {
              handleClose();
              handleSave();
            }}
            color="primary"
            variant="contained"
            className={classes.acceptButton}
          >
            Yes
          </Button>
          <Button onClick={handleClose} className={classes.rejectButton}>
            No
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default withStyles(styles)(BOMSavePopup);
