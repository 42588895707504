import React, { useState } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Link, Typography, Avatar } from '@material-ui/core';
import FooterImage from './Icons/footer_logo.png';
import DisclaimerDialog from './DisclaimerDialog';
import I18n from '../../config/locales';
import { localeMoment as moment } from '../common/moment';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    padding: theme.spacing(5, 0, 5, 3),
    marginTop: 'auto',
    marginBottom: theme.spacing(13)
  },
  privacyTerms: {
    display: 'flex',
    alignItems: 'center',
    fontSize: 12
  },
  link: {
    color: theme.palette.secondary.link,
    cursor: 'pointer',
    padding: theme.spacing(0, 1)
  },
  copyRights: {
    fontSize: 12
  },
  footerLogo: {
    height: theme.spacing(3.5),
    width: theme.spacing(15.5),
    minHeight: theme.spacing(3.5),
    marginRight: theme.spacing(2.5)
  }
}));

const Footer = props => {
  const classes = useStyles();
  const { className = '' } = props;
  const [ showDisclaimerDialog, setShowDisclaimerDialog ] = useState(false);

  return (
    <footer className={clsx(classes.root, className && className)}>
      <Box>
        <Link title={I18n.t('footer.imageTitle')} href="http://enphase.com/" target="_blank">
          <Avatar variant="square" className={classes.footerLogo} alt="enphase footer logo" src={FooterImage} />
        </Link>
      </Box>
      <Box className={classes.privacyTerms}>
        <Typography className={classes.copyRights}>
          {I18n.t('footer.enphaseText', { currentYear: moment().year() })}
        </Typography>
        <Link className={classes.link} href="https://enphase.com/en-us/legal/privacy-policy" target="_blank">
          {I18n.t('footer.privacy')}
        </Link>
        <Typography>|</Typography>
        <Link className={classes.link} href="https://enphase.com/en-us/legal/terms-of-service" target="_blank">
          {I18n.t('footer.terms')}
        </Link>
        <Typography>|</Typography>
        <Link className={classes.link} onClick={() => setShowDisclaimerDialog(true)}>
          Disclaimer
        </Link>
      </Box>
      <DisclaimerDialog open={showDisclaimerDialog} onClose={() => setShowDisclaimerDialog(false)} />
    </footer>
  );
};

export default Footer;
