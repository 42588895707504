import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  titleWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderBottom: '1px solid #E1E1E1'
  },
  closeButton: {
    padding: 0
  },
  button: {
    textTransform: 'none'
  },
  saveButton: {
    color: theme.palette.secondary.main,
    textTransform: 'none'
  },
  buttonWrapper: {
    borderTop: '1px solid #E1E1E1',
    padding: theme.spacing(2, 3)
  },
  label: {
    fontSize: 14,
    color: '#4A4A4A',
    marginTop: theme.spacing(1.5)
  },
  note: {
    fontSize: 16,
    color: '#484848DE',
    marginBottom: theme.spacing(2)
  },
  inputField: {
    width: '100%',
    margin: theme.spacing(3, 0, 3.5)
  }
}));

export default useStyles;
