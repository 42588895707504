export const getSubCategoryLabels = () => {
  return {
    INVERTERS: 'Inverters',
    COMMUNICATIONS: 'Communications',
    ACCESSORIES: 'Accessories',
    PV_PANEL: 'PV Panel',
    AC_PANEL: 'AC Panel',
    ELECTRICAL: 'Electrical',
    RACKING: 'Racking',
    OTHER_EBOS: 'Other EBOS',
    LABOR_PROJECT_MANAGEMENT: 'Labour and Project Management',
    CONTINGENCY: 'Contingency',
    STORAGE: 'Storage'
  };
};

export const getTableHeading = () => {
  return {
    ENPHASE_EQUIPMENT: 'Enphase Equipment',
    NON_ENPHASE_EQUIPMENT_ELECTRICAL: 'Non-Enphase Equipment (Electrical)',
    NON_ENPHASE_EQUIPMENT_STRUCTURAL: 'Non-Enphase Equipment (Structural)',
    SOFT_COST: 'Other Soft Costs'
  };
};
