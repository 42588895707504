import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  projectTypePaper: {
    padding: theme.spacing(0.75),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
    boxShadow: '0px 0px 8px #00000029'
  },
  chooseProjectTypeText: {
    color: theme.palette.primary.main,
    padding: theme.spacing(3),
    fontWeight: 'bold'
  },
  mainTypeWrapper: {
    display: 'flex',
    [theme.breakpoints.down('xs')]: {
      display: 'block'
    },
    padding: theme.spacing(0, 3, 3, 0)
  },
  styleTypeWrapper: {
    width: '208px',
    height: '144px',
    position: 'relative',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginLeft: theme.spacing(3),
    borderRadius: theme.spacing(1),
    cursor: 'pointer',
    [theme.breakpoints.down('xs')]: {
      width: '93%',
      height: '64px',
      flexDirection: 'row',
      justifyContent: 'start',
      marginTop: theme.spacing(1)
    }
  },
  typeWrapper: {
    border: '1px solid #E0E3E6'
  },
  typeWrapperOutlined: {
    border: '1px solid #F3731F'
  },
  styleCheckboxButton: {
    position: 'absolute',
    top: '3px',
    color: '#4b4b4ba3',
    [theme.breakpoints.down('xs')]: {
      top: '20%'
    },
    right: '3px'
  },
  iconSize: {
    fontSize: 40
  },
  typeText: {
    color: '#4b4b4ba3',
    [theme.breakpoints.down('xs')]: {
      marginTop: theme.spacing(1.25),
      marginLeft: theme.spacing(2),
      fontSize: 17
    }
  },
  fontColorMain: {
    color: theme.palette.primary.main
  },
  styleTypeSubWrapper: {
    textAlign: 'center',
    color: '#535353',
    [theme.breakpoints.down('xs')]: {
      display: 'flex',
      justifyContent: 'center',
      marginLeft: theme.spacing(2)
    }
  },
  disabledField: {
    pointerEvents: 'none',
    opacity: 0.5
  }
}));

export default useStyles;
