import { TRIGGER_DOWNLOAD_CSV_REQUEST, GET_NPS_SHOWN_REQUEST, SET_NPS_FEEDBACK_REQUEST } from './constants';

export function triggerDownloadCSV(payload) {
  return { type: TRIGGER_DOWNLOAD_CSV_REQUEST, payload };
}

export function getNpsShown(payload) {
  return { type: GET_NPS_SHOWN_REQUEST, payload };
}

export function setNpsFeedback(payload) {
  return { type: SET_NPS_FEEDBACK_REQUEST, payload };
}
