import React from 'react';
import { Dialog, DialogContent, DialogTitle, Typography, DialogContentText } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import clsx from 'clsx';

const styles = makeStyles(theme => ({
  titleWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderBottom: '1px solid #E1E1E1',
    padding: theme.spacing(2.75, 3)
  },
  dialogTitle: {
    fontSize: 20,
    color: '#4A4A4A',
    fontWeight: 1000
  },
  paddingDialogContent: {
    padding: theme.spacing(3)
  },
  close: {
    opacity: 0.5,
    cursor: 'pointer',
    fontSize: theme.spacing(3),
    marginRight: theme.spacing(-0.75)
  },
  paper: {
    borderRadius: 0
  },
  textBox: {
    fontSize: 14,
    color: '#4A4A4A'
  },
  marginTop8: {
    marginTop: theme.spacing(1)
  }
}));

const DisclaimerDialog = props => {
  const { open, onClose } = props;
  const classes = styles();
  return (
    <Dialog open={open} onClose={onClose} classes={{ paper: classes.paper }}>
      <DialogTitle id="BOM-tool-disclaimer" disableTypography className={classes.titleWrapper}>
        <Typography className={classes.dialogTitle}>Tool Disclaimer</Typography>
        <CloseIcon className={classes.close} onClick={onClose} />
      </DialogTitle>
      <DialogContent className={classes.paddingDialogContent}>
        <DialogContentText className={classes.dialogContentWrapper}>
          <Typography className={classes.textBox}>
            The Enphase Bill of Materials (BOM) calculator was developed to assist users in generating a comprehensive
            BOM for system designs (commercial and residential). The calculator has built-in certain design assumptions
            and formulas which accurately recommend BOM quantities given the design inputs.
          </Typography>
          <Typography className={clsx(classes.textBox, classes.marginTop8)}>
            By using the BOM calculator, the user claims all responsibility for the accuracy and validity of the design
            inputs, and, thereby, resulting calculations. Enphase advises users to validate and make necessary
            modifications to the BOM calculator results.
          </Typography>
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
};

export default DisclaimerDialog;
