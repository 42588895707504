import React, { useState, useEffect, useRef } from 'react';
import clsx from 'clsx';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Button,
  Typography,
  IconButton,
  FormControl,
  InputLabel,
  Select,
  MenuItem
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import useStyles from './styles';
import { Label } from '../../common/Label';
import { connect } from 'react-redux';
import { get } from 'lodash';
import {
  commercial,
  fullEnergyIndependence,
  homeEssentialBackup,
  sunlightBackup
} from '../../../containers/BOMCalculator/constants';

const TemplatePopup = props => {
  const { open, handleClose, handleContinue, templatesList, stateValues } = props;
  const classes = useStyles();
  const [ defaultTemplateList, setDefaultTemplateList ] = useState([]);
  const [ selectedTemplate, setSelectedTemplate ] = useState('');
  const [ companyIdTemplate, setCompanyIdTemplate ] = useState('');
  const getMatchingTemplate = useRef(() => {});
  const { projectType, systemRequirements, systemDetails } = stateValues;
  getMatchingTemplate.current = (tempDefaultTemplatesList, templateName) => {
    let result = '';
    tempDefaultTemplatesList.forEach(template => {
      if (template.template_name === templateName) {
        result = template.id;
      }
    });
    return result;
  };

  useEffect(
    () => {
      let tempDefaultTemplatesList = templatesList;
      tempDefaultTemplatesList.filter(template => {
        return template.is_default;
      });
      if (projectType === commercial) {
        let sumToDecideCommercial = 0;
        const interconnects = get(systemDetails, projectType, []);
        interconnects.forEach(interconnect => {
          sumToDecideCommercial +=
            Number(get(interconnect, 'pvPanelWattage')) * Number(get(interconnect, 'pvPanelsCount'));
        });
        if (sumToDecideCommercial > 125000) {
          setSelectedTemplate(getMatchingTemplate.current(templatesList, 'Large Commercial'));
        } else {
          setSelectedTemplate(getMatchingTemplate.current(templatesList, 'Small Commercial'));
        }
      } else {
        const { requirement = '' } = systemRequirements;
        if (requirement === 'Solar only' || requirement === sunlightBackup) {
          setSelectedTemplate(getMatchingTemplate.current(templatesList, 'Residential (Solar)'));
        } else if (requirement === 'Storage only' || requirement === 'Storage Upgrade (Existing customer)') {
          setSelectedTemplate(getMatchingTemplate.current(templatesList, 'Residential (Storage)'));
        } else if (
          requirement === 'Solar + Storage' ||
          requirement === homeEssentialBackup ||
          requirement === fullEnergyIndependence
        ) {
          setSelectedTemplate(getMatchingTemplate.current(templatesList, 'Residential (Solar + Storage)'));
        }
      }
      setDefaultTemplateList(tempDefaultTemplatesList);
      setCompanyIdTemplate(get(tempDefaultTemplatesList[0], 'company_id'));
    },
    [ projectType, systemRequirements, systemDetails, templatesList ]
  );

  return (
    <Dialog
      open={open}
      aria-labelledby="template"
      aria-describedby="template-description"
      onClose={handleClose}
      fullWidth
    >
      <DialogTitle id="template" disableTypography className={classes.titleWrapper}>
        <Typography variant="h6">Template</Typography>
        <IconButton aria-label="close" onClick={handleClose} className={classes.closeButton}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Typography className={classes.label}>Please select pricing template to calculate the BOM</Typography>
        <FormControl id={'requirementFormControl'} className={clsx(classes.inputField, classes.customLabel)}>
          <InputLabel id={'requirementInputLabel'}>
            <Label text="Select Template" />
          </InputLabel>
          <Select
            IconComponent={props => <ExpandMoreIcon className="MuiSelect-icon" />}
            onChange={event => setSelectedTemplate(event.target.value)}
            value={selectedTemplate}
          >
            {defaultTemplateList.map((list, index) => (
              <MenuItem key={list.id + index} value={list.id}>
                {list.template_name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Typography className={classes.note}>
          Note - Templates allow you to automatically populate material prices.
        </Typography>
      </DialogContent>
      <DialogActions className={classes.buttonWrapper}>
        <Button className={classes.button} onClick={handleClose} variant="outlined">
          Cancel
        </Button>
        <Button
          className={classes.saveButton}
          onClick={() => handleContinue(selectedTemplate, companyIdTemplate)}
          color="primary"
          variant="contained"
          disableElevation
        >
          Continue
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const mapStateToProps = state => ({
  templatesList: state.bomInputReducer.templatesList
});

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(TemplatePopup);
