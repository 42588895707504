import React from 'react';
import { Dialog, DialogContent, DialogTitle, Typography, DialogContentText, Link } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';

const styles = makeStyles(theme => ({
  titleWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderBottom: '1px solid #E1E1E1',
    padding: theme.spacing(2.75, 3)
  },
  dialogTitle: {
    fontSize: 20,
    color: '#4A4A4A',
    fontWeight: 1000
  },
  paddingDialogContent: {
    padding: theme.spacing(3)
  },
  close: {
    opacity: 0.5,
    cursor: 'pointer',
    fontSize: theme.spacing(3),
    marginRight: theme.spacing(-0.75)
  },
  paper: {
    borderRadius: 0
  },
  textBox: {
    fontSize: 14,
    color: '#4A4A4A'
  },
  marginTop8: {
    marginTop: theme.spacing(1)
  }
}));

const PvSheddingPopup = props => {
  const { open, onClose } = props;
  const classes = styles();
  return (
    <Dialog open={open} onClose={onClose} classes={{ paper: classes.paper }}>
      <DialogTitle id="PV-Shedding-Popup" disableTypography className={classes.titleWrapper}>
        <Typography className={classes.dialogTitle}>Note</Typography>
        <CloseIcon className={classes.close} onClick={onClose} />
      </DialogTitle>
      <DialogContent className={classes.paddingDialogContent}>
        <DialogContentText className={classes.dialogContentWrapper}>
          <Typography className={classes.textBox}>
            The IQ7 PV installations with an IQ Battery require to maintain a Solar-To-Storage ratio when off-grid. By
            using an IQ Load Controller, you can install an additional 2 PV branches on a site, that function normally
            in an on-grid scenario but are shed when the system goes off-grid, thus preserving the Solar-To-Storage
            ratio.
            <Link
              href="https://enphase.com/sites/default/files/2021-10/Auxiliary_Contacts_IQ_Load_Controller-NA.pdf"
              target="_blank"
            >
              Learn more
            </Link>
          </Typography>
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
};

export default PvSheddingPopup;
