import React, { useState } from 'react';
import { withStyles, IconButton, Tooltip, ClickAwayListener } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

export const LightTooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: `${theme.palette.secondary.main} !important`,
    color: '#0F0F0F !important',
    borderRadius: '0px !important',
    boxShadow: theme.shadows[1],
    fontSize: '12px !important',
    top: theme.spacing(-1),
    pointerEvents: 'auto',
    cursor: 'pointer'
  },
  arrow: {
    color: `${theme.palette.secondary.main} !important`
  },
  popper: {}
}))(Tooltip);

const useStyles = makeStyles(theme => ({
  infoIconWrapper: {
    padding: '0 !important',
    marginLeft: theme.spacing(0.25)
  },
  infoIconDimension: {
    fontSize: 20,
    marginLeft: theme.spacing(0.25)
  },
  systemDetailsInfo: {
    marginBottom: theme.spacing(0.25)
  }
}));

const InfoTooltip = props => {
  const classes = useStyles();
  const [ open, setOpen ] = useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };
  const { text, applyClassName = 'systemDetailsInfo' } = props;
  return (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <LightTooltip
        title={text}
        open={open}
        onClose={handleTooltipClose}
        disableFocusListener
        disableHoverListener
        disableTouchListener
        arrow
      >
        <IconButton className={classes.infoIconWrapper} onClick={handleTooltipOpen}>
          <InfoIcon className={clsx(classes.infoIconDimension, classes[applyClassName])} />
        </IconButton>
      </LightTooltip>
    </ClickAwayListener>
  );
};

export default InfoTooltip;
