import React, { Component, Fragment } from 'react';
import {
  Box,
  InputBase,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  withStyles
} from '@material-ui/core';
import clsx from 'clsx';
import { isEmpty, isEqual } from 'lodash';
import styles from './styles';
import {
  getHelperArrays,
  getNetPriceIncludingDiscountPerBOMItem,
  getNetPriceIncludingDiscountSubcategoryWise
} from './helper';

class OtherSoftCosts extends Component {
  state = {
    currentEnphaseBOMItems: []
  };
  isInterconnectUpdated = false;

  componentDidUpdate(prevProps) {
    if (!this.isInterconnectUpdated && !isEmpty(this.props.currentProjectBomItems)) {
      this.isInterconnectUpdated = true;
      this.setState({
        currentEnphaseBOMItems: this.props.currentProjectBomItems
      });
    }
    if (!isEqual(prevProps.currentProjectBomItems, this.props.currentProjectBomItems)) {
      this.setState({ currentEnphaseBOMItems: this.props.currentProjectBomItems });
    }
  }

  render() {
    const { classes, handleProjectBomItemsInputChange } = this.props;
    const helperArray = getHelperArrays(this.state.currentEnphaseBOMItems, {
      category: 'SOFT_COST'
    });

    return (
      <Fragment>
        <Paper square className={classes.mainWrapper}>
          <Box className={classes.mainTableWrapper}>
            <Box className={classes.headingBox}>Soft costs</Box>
          </Box>
          <TableContainer component={Paper} className={classes.tableWrapper}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead className={classes.tableHeader}>
                <TableRow>
                  <TableCell
                    className={clsx(classes.borderRight, classes.headerContainer)}
                    classes={{ root: clsx(classes.styleTableHeaderContent, classes.widthItems) }}
                  >
                    ITEMS
                  </TableCell>
                  <TableCell
                    className={classes.headerContainer}
                    classes={{ root: clsx(classes.styleTableHeaderContent, classes.widthQuantity) }}
                  />
                  <TableCell
                    className={classes.headerContainer}
                    classes={{ root: clsx(classes.styleTableHeaderContent, classes.widthPrice) }}
                  >
                    COST ($)
                  </TableCell>
                  <TableCell
                    className={classes.headerContainer}
                    classes={{ root: clsx(classes.styleTableHeaderContent, classes.widthNetPrice) }}
                  >
                    NET COST
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {helperArray.map((row, index) => {
                  const { subCategory, key } = row;
                  const netPrice = Math.round(
                    getNetPriceIncludingDiscountPerBOMItem(this.state.currentEnphaseBOMItems, {
                      category: 'SOFT_COST',
                      subCategory: 'SOFT_COST',
                      name: subCategory
                    })
                  );
                  return (
                    <Fragment key={'other-soft-cost-' + index}>
                      <TableRow>
                        <TableCell className={classes.borderRight} component="th" scope="row">
                          <Typography className={classes.firstColumnHeadingOtherSoftCosts}>{subCategory}</Typography>
                        </TableCell>
                        <TableCell />
                        <TableCell>
                          <Box className={classes.priceInputWrapper}>
                            <Typography>$</Typography>
                            <InputBase
                              value={netPrice}
                              className={classes.inputBaseMarginLeftRight}
                              onChange={e => {
                                handleProjectBomItemsInputChange(e, {
                                  category: 'SOFT_COST',
                                  subCategory: key,
                                  subCategoryName: subCategory,
                                  fieldName: 'distributor_price'
                                });
                              }}
                            />
                          </Box>
                        </TableCell>
                        <TableCell>
                          <Typography className={clsx(classes.font14, classes.stylePriceText)}>
                            ${netPrice.toLocaleString()}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    </Fragment>
                  );
                })}

                <TableRow>
                  <TableCell
                    className={clsx(classes.borderRight, classes.styleTotalRowText)}
                    component="th"
                    scope="row"
                  >
                    Total
                  </TableCell>
                  <TableCell />
                  <TableCell />
                  <TableCell className={classes.styleTotalRowText}>
                    ${Math.round(
                      getNetPriceIncludingDiscountSubcategoryWise(this.state.currentEnphaseBOMItems, {
                        subCategory: 'SOFT_COST',
                        category: 'SOFT_COST'
                      })
                    ).toLocaleString()}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Fragment>
    );
  }
}

export default withStyles(styles)(OtherSoftCosts);
