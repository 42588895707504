import { isEmpty, get, set, cloneDeep } from 'lodash';
import { getCountryCallingCode } from 'react-phone-number-input';

export const validateBOMInputs = (errorData, data, helperParams) => {
  const {
    key = '',
    checkZipCodeEmpty = false,
    zipCodeError = false,
    interconnectIndex,
    from,
    projectType,
    interconnectFieldName,
    checkAllFieldsSystemRequirements = false
  } = helperParams;
  const errors = cloneDeep(errorData);
  const noErrorObj = {
    error: false,
    errorMessage: ''
  };
  const fieldRequired = {
    error: true,
    errorMessage: 'This field is required'
  };
  const characterLimitName = {
    error: true,
    errorMessage: 'Character limit exceeded.'
  };
  const validEmail = {
    error: true,
    errorMessage: 'Please enter a valid email address.'
  };
  const validZip = {
    error: true,
    errorMessage: 'Please enter a valid zip code.'
  };
  const completeName = {
    error: true,
    errorMessage: 'Please enter full name'
  };
  const commercialPVPanelWattage = {
    error: true,
    errorMessage: 'Please enter value between 300W and 600W.'
  };
  const commercialPVPanelCount = {
    error: true,
    errorMessage: 'Please enter value between 0 and 2000.'
  };
  const commercialUnusedCable = {
    error: true,
    errorMessage: 'Please enter value between 0% and 15%.'
  };
  const residentialPVPanelWattage = {
    error: true,
    errorMessage: 'Please enter value between 100W and 600W.'
  };
  const residentialPVPanelCount = {
    error: true,
    errorMessage: 'Please enter value between 0 and 100.'
  };
  const residentialExistingMicroinverterCount = {
    error: true,
    errorMessage: 'Please enter value between 0 and 200.'
  };

  const setErrorMessage = (key, checkEmpty = false) => {
    let field = '';
    if (from === 'systemRequirements') {
      field = (get(data, `${key}`, '') && get(data, `${key}`, '').toString()) || '';
    } else {
      const fieldObject = get(data, `systemDetails.${projectType}`, [])[interconnectIndex];
      field =
        (get(fieldObject, `${interconnectFieldName}`, '') &&
          get(fieldObject, `${interconnectFieldName}`, '').toString()) ||
        '';
    }
    if (isEmpty(field) && key !== 'systemRequirements.customerDetails.zipCode') {
      const insertErrorObject = fieldRequired;
      if (from === 'systemRequirements') {
        set(errors, key, insertErrorObject);
      } else {
        errors['systemDetails'][projectType][interconnectIndex][interconnectFieldName] = insertErrorObject;
      }
    } else if (key === 'systemRequirements.customerDetails.name') {
      if (field.length > 30) {
        set(errors, key, characterLimitName);
      } else if (!field.includes(' ')) {
        set(errors, key, completeName);
      } else {
        set(errors, key, noErrorObj);
      }
    } else if (key === 'systemRequirements.customerDetails.email') {
      // eslint-disable-next-line no-useless-escape
      const emailFormat = /^(([^<>()\[\]\\.,;:\u00C0-\u00FF\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,10}))$/;
      const isValidEmail = field.match(emailFormat);
      const insertErrorObject = isValidEmail ? noErrorObj : validEmail;
      set(errors, key, insertErrorObject);
    } else if (key === 'systemRequirements.customerDetails.mobile') {
      const isValidPhone = field.replace('+', '') !== getCountryCallingCode('US');
      const insertErrorObject = isValidPhone ? noErrorObj : fieldRequired;
      set(errors, key, insertErrorObject);
    } else if (key === 'systemRequirements.customerDetails.zipCode') {
      if (zipCodeError) {
        set(errors, key, validZip);
      } else if (checkZipCodeEmpty && isEmpty(field)) {
        set(errors, key, fieldRequired);
      } else {
        set(errors, key, noErrorObj);
      }
    } else if (key === 'systemDetails.commercial.pvPanelWattage') {
      const insertErrorObject = Number(field) < 300 || Number(field) > 600 ? commercialPVPanelWattage : noErrorObj;
      errors['systemDetails'][projectType][interconnectIndex][interconnectFieldName] = insertErrorObject;
    } else if (key === 'systemDetails.commercial.pvPanelsCount') {
      const insertErrorObject = Number(field) < 0 || Number(field) > 2000 ? commercialPVPanelCount : noErrorObj;
      errors['systemDetails'][projectType][interconnectIndex][interconnectFieldName] = insertErrorObject;
    } else if (key === 'systemDetails.commercial.unusedCableDrops') {
      const insertErrorObject = Number(field) < 0 || Number(field) > 15 ? commercialUnusedCable : noErrorObj;
      errors['systemDetails'][projectType][interconnectIndex][interconnectFieldName] = insertErrorObject;
    } else if (key === 'systemDetails.residential.pvPanelWattage') {
      const insertErrorObject = Number(field) < 100 || Number(field) > 600 ? residentialPVPanelWattage : noErrorObj;
      errors['systemDetails'][projectType][interconnectIndex][interconnectFieldName] = insertErrorObject;
    } else if (key === 'systemDetails.residential.pvPanelsCount') {
      const insertErrorObject = Number(field) < 0 || Number(field) > 100 ? residentialPVPanelCount : noErrorObj;
      errors['systemDetails'][projectType][interconnectIndex][interconnectFieldName] = insertErrorObject;
    } else if (key === 'systemDetails.residential.existingMicroinverterCount') {
      const insertErrorObject =
        Number(field) < 0 || Number(field) > 200 ? residentialExistingMicroinverterCount : noErrorObj;
      errors['systemDetails'][projectType][interconnectIndex][interconnectFieldName] = insertErrorObject;
    } else {
      if (from === 'systemRequirements') {
        set(errors, key, noErrorObj);
      } else {
        errors['systemDetails'][projectType][interconnectIndex][interconnectFieldName] = noErrorObj;
      }
    }
  };
  if (checkAllFieldsSystemRequirements) {
    const keysArray = [
      'systemRequirements.customerDetails.name',
      'systemRequirements.customerDetails.email',
      'systemRequirements.customerDetails.mobile',
      'systemRequirements.customerDetails.address',
      'systemRequirements.customerDetails.zipCode',
      'systemRequirements.requirement'
    ];
    keysArray.forEach(key => {
      setErrorMessage(key);
    });
  } else {
    setErrorMessage(key);
  }
  return errors;
};
