const styles = theme => ({
  projectTypePaper: {
    padding: theme.spacing(0.75),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
    boxShadow: '0px 0px 8px #00000029'
  },
  chooseProjectTypeText: {
    color: theme.palette.primary.main,
    padding: theme.spacing(3),
    fontWeight: 'bold'
  },
  customLabel: {
    '& .Mui-focused': {
      '& label': {
        alignItems: 'center'
      }
    },
    '& .MuiInputLabel-shrink': {
      '& label': {
        fontSize: theme.spacing(2.125),
        color: '#999999',
        alignItems: 'center'
      }
    },
    '& .MuiInput-underline.Mui-disabled:before': {
      borderBottomStyle: 'solid'
    }
  },
  inputField: {
    width: '27%',
    marginLeft: theme.spacing(3),
    marginTop: theme.spacing(-1),
    [theme.breakpoints.down('xs')]: {
      width: '86%'
    }
  },
  styleLabel: {
    color: '#999999'
  },
  addMoreDetailsWrapper: {
    display: 'flex',
    padding: theme.spacing(2, 3, 0)
  },
  optionalText: {
    color: '#4A4A4A',
    opacity: '0.6'
  },
  styleSwitch: {
    marginLeft: theme.spacing(0.5),
    [theme.breakpoints.down('xs')]: {
      marginLeft: 'auto',
      marginRight: 0
    },
    marginTop: theme.spacing(-0.875)
  },
  noteText: {
    color: '#919191',
    fontSize: theme.spacing(1.5),
    padding: theme.spacing(0, 3, 3)
  },
  additionalDetails: {
    width: '100%',
    padding: theme.spacing(0, 3, 4),
    display: 'grid',
    gridRowGap: theme.spacing(2.5),
    gridColumnGap: theme.spacing(5),
    gridTemplateColumns: 'repeat(auto-fill, minmax(320px, 1fr))',
    [theme.breakpoints.down('xs')]: {
      gridTemplateColumns: 'repeat(auto-fill, minmax(250px, 1fr))'
    }
  },
  additionalmapWrapper: {
    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: 'repeat(auto-fill, minmax(100%, 1fr))'
    }
  },
  textFieldAdditionalDetails: {
    width: '100%'
  },
  mapText: {
    marginTop: theme.spacing(0.375),
    marginRight: theme.spacing(0.75),
    color: '#1A85E2',
    cursor: 'pointer',
    fontSize: 14
  },
  mapWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(0.5)
  },
  buttonClass: {
    marginLeft: theme.spacing(0.25),
    height: '25px !important',
    width: '24px !important',
    border: '0px !important',
    background: 'white !important',
    '& .selected-flag': {
      width: '24px !important',
      cursor: 'default'
    },
    '& div': {
      padding: '0px !important'
    },
    '&:hover': {
      border: '0px !important'
    },
    '&:focus': {
      border: '0px !important'
    }
  },
  phoneField: {
    width: theme.spacing(2.5),
    background: 'white !important',
    border: '0px  !important',
    borderRadius: '0 !important',
    borderBottom: '1px solid rgba(0, 0, 0, 0.42) !important',
    '& div': {
      padding: '0px !important',
      pointerEvents: 'none',
      '&:hover': {
        backgroundColor: 'transparent !important'
      }
    },
    '&:hover': {
      borderBottom: '2px solid rgba(0, 0, 0, 0.87) !important'
    },
    '&:focus': {
      borderBottom: '2px solid #F3731F !important'
    }
  },
  errorClass: {
    borderBottom: '2px solid #f44336 !important',
    '&:hover': {
      borderBottom: '2px solid #f44336 !important'
    },
    '&:focus': {
      borderBottom: '2px solid #f44336 !important'
    }
  },
  styleMobileHeading: {
    color: '#696969',
    alignItems: 'center',
    fontWeight: 400,
    transform: 'translate(0, 1.5px) scale(0.75)',
    transformOrigin: 'top left',
    fontSize: 17,
    marginBottom: theme.spacing(-0.5)
  },
  colorRed: {
    color: '#f44336'
  },
  phoneInputField: {
    width: '100% !important',
    height: '27px !important',
    paddingLeft: `${theme.spacing(3.25)}px !important`
  },
  additionalPhoneInputField: {
    width: 'auto',
    height: theme.spacing(3),
    paddingLeft: theme.spacing(3.25)
  },
  disabledField: {
    pointerEvents: 'none',
    opacity: 0.5
  },
  hideInput: {
    display: 'none'
  },
  customerDetailsWrapper: {
    display: 'flex',
    alignItems: 'flex-start',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column'
    }
  },
  notes: {
    color: '#484848DE',
    fontSize: 14,
    padding: theme.spacing(3)
  },
  errorMessage: {
    fontSize: 13,
    color: theme.palette.primary.required,
    marginTop: theme.spacing(0.5)
  },
  backupWrapper: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    paddingTop: theme.spacing(0.75),
    marginLeft: theme.spacing(1.5)
  },
  requirementRowWrapper: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  styleLearnMore: {
    marginLeft: theme.spacing(1),
    cursor: 'pointer'
  }
});

export default styles;
