import React from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Button,
  Typography,
  IconButton,
  Divider
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import useStyles from '../MapDialog/styles';

const CustomerDetailsPopup = props => {
  const { open, onPopupClose, children, handleSave } = props;
  const classes = useStyles();
  return (
    <Dialog open={open} aria-labelledby="map" aria-describedby="map-description" maxWidth="md" fullWidth>
      <DialogTitle id="map" disableTypography className={classes.titleWrapper}>
        <Typography variant="h6">Connect BOM with lead</Typography>
        <IconButton aria-label="close" onClick={onPopupClose} className={classes.closeButton}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <Divider />
      <DialogContent className={classes.mapWrapper}>{children}</DialogContent>
      <Divider />
      <DialogActions className={classes.buttonWrapper}>
        <Button className={classes.button} onClick={onPopupClose} variant="outlined">
          Cancel
        </Button>
        <Button
          className={classes.saveButton}
          onClick={handleSave}
          color="primary"
          variant="contained"
          disableElevation
        >
          Proceed
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CustomerDetailsPopup;
