import { call, take } from 'redux-saga/effects';
import { GET_NPS_SHOWN_REQUEST, SET_NPS_FEEDBACK_REQUEST, TRIGGER_DOWNLOAD_CSV_REQUEST } from './constants';
import { post, get } from '../../utils/api';
import settings from '../../utils/settings';

let sendingTriggerDownloadCSV = false;
export function* triggerDownloadCSVFlow() {
  while (true) {
    const request = yield take(TRIGGER_DOWNLOAD_CSV_REQUEST);
    const { csvObject } = request.payload;
    if (!sendingTriggerDownloadCSV) {
      sendingTriggerDownloadCSV = true;
      try {
        const url = settings.digiCoreUrl(`bom_download_email`);
        yield call(post, {
          url: url,
          body: csvObject,
          withBearer: true
        });
        sendingTriggerDownloadCSV = false;
      } catch (error) {
        sendingTriggerDownloadCSV = false;
      }
    }
  }
}

let sendingGetNpsFeedback = false;
export function* getNpsShownFlow() {
  while (true) {
    const request = yield take(GET_NPS_SHOWN_REQUEST);
    const { leadId = '', successCbk = () => {} } = request.payload;
    if (!sendingGetNpsFeedback) {
      sendingGetNpsFeedback = true;
      try {
        let response;
        const url = settings.bomApiUrl(`nps/${leadId}`);
        response = yield call(get, {
          url: url,
          withBearer: true
        });
        successCbk(response.result.isNpsShown);
        sendingGetNpsFeedback = false;
      } catch (error) {
        sendingGetNpsFeedback = false;
      }
    }
  }
}

let sendingSetNpsFeedback = false;
export function* setNpsFeedbackFlow() {
  while (true) {
    const request = yield take(SET_NPS_FEEDBACK_REQUEST);
    const { feedbackBody, successCbk = () => {} } = request.payload;
    if (!sendingSetNpsFeedback) {
      sendingSetNpsFeedback = true;
      try {
        const url = settings.bomApiUrl(`nps/create`);
        yield call(post, {
          url: url,
          body: feedbackBody,
          withBearer: true
        });
        successCbk();
        sendingSetNpsFeedback = false;
      } catch (error) {
        sendingSetNpsFeedback = false;
      }
    }
  }
}
