import { REQUEST_FAILURE, SET_MOBILE_VIEW, SET_ENLIGHTEN_MANAGER_TOKEN, SET_USER_DETAILS } from './constants';

let initialState = {
  isMobileView: false,
  loading: false,
  enlightenManagerToken: '',
  message: '',
  messageType: '',
  userDetails: {}
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_FAILURE:
      return { ...state, message: action.error, messageType: 'error', loading: false };
    case SET_MOBILE_VIEW:
      return { ...state, isMobileView: action.isMobileView };
    case SET_ENLIGHTEN_MANAGER_TOKEN:
      return { ...state, enlightenManagerToken: action.enlightenManagerToken };
    case SET_USER_DETAILS:
      return { ...state, userDetails: action.userDetails };
    default:
      return state;
  }
};

export default reducer;
