const styles = theme => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2),
    paddingBottom: theme.spacing(15),
    [theme.breakpoints.up('sm')]: {
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3)
    }
  },
  rootMobile: {
    backgroundColor: theme.palette.background.paper,
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(15)
  },
  importDesign: {
    display: 'flex',
    margin: theme.spacing(2.5),
    textTransform: 'none'
  },
  headerWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    [theme.breakpoints.down('xs')]: {
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(3.25)
    }
  },
  fontSizeHeader: {
    fontSize: 35,
    [theme.breakpoints.down('xs')]: {
      fontSize: 20
    }
  },
  projectTypePaper: {
    padding: theme.spacing(0.75),
    marginTop: theme.spacing(2),
    boxShadow: '0px 0px 8px #00000029'
  },
  chooseProjectTypeText: {
    color: theme.palette.primary.main,
    padding: theme.spacing(3),
    fontWeight: 'bold'
  },
  customLabel: {
    '& .Mui-focused': {
      '& label': {
        alignItems: 'center'
      }
    },
    '& .MuiInputLabel-shrink': {
      '& label': {
        fontSize: theme.spacing(2.125),
        color: '#999999',
        alignItems: 'center'
      }
    },
    '& .MuiInput-underline.Mui-disabled:before': {
      borderBottomStyle: 'solid'
    }
  },
  inputField: {
    width: '27%',
    marginLeft: theme.spacing(3),
    marginTop: theme.spacing(-1),
    [theme.breakpoints.down('xs')]: {
      width: '100%'
    }
  },
  styleLabel: {
    color: '#999999'
  },
  addMoreDetailsWrapper: {
    display: 'flex',
    padding: theme.spacing(4, 3, 0)
  },
  optionalText: {
    color: '#4A4A4A',
    opacity: '0.6'
  },
  styleSwitch: {
    marginLeft: theme.spacing(0.5),
    marginTop: theme.spacing(-0.875)
  },
  noteText: {
    color: '#919191',
    fontSize: theme.spacing(1.5),
    padding: theme.spacing(0, 3, 3),
    marginTop: theme.spacing(-0.75)
  },
  additionalDetails: {
    width: '100%',
    padding: theme.spacing(0, 2.5, 4),
    display: 'grid',
    gridRowGap: theme.spacing(2.5),
    gridColumnGap: theme.spacing(2.5),
    gridTemplateColumns: 'repeat(auto-fill, minmax(400px, 1fr))'
  },
  additionalDetailsMobile: {
    gridTemplateColumns: 'repeat(auto-fill, minmax(160px, 1fr))'
  },
  textFieldAdditionalDetails: {
    width: '85%',
    [theme.breakpoints.down('xs')]: {
      width: '100%'
    }
  },
  backdrop: {
    zIndex: 11,
    color: theme.palette.primary.main
  },
  downloadIcon: {
    width: theme.spacing(1.5),
    height: theme.spacing(1.5)
  }
});

export default styles;
