import JsonWebToken from 'jsonwebtoken';
import { JWT_HMAC_SECRET, JWT_ALGORITHM } from '../config/constants';
import { getValue, setValue } from './localStorage';
import history from '../routes/history';
import { isEmpty } from 'lodash';

export const decodeToken = token => {
  let decoded = { error: null };
  try {
    decoded = JsonWebToken.verify(token, JWT_HMAC_SECRET, { algorithms: [ JWT_ALGORITHM ] })['data'];
    decoded['error'] = null;
  } catch (error) {
    console.log('JWT token verify error:', error);
    decoded.error = error.message;
  }
  return decoded;
};

export const getToken = () => getValue('_enlighten_manager_token');

export const setToken = value => setValue('_enlighten_manager_token', value);

export const forwardTo = location => history.push(location);

export const constructQueryString = (queryObj, ignoreEmptyParams = false) => {
  let queryString = '';
  Object.keys(queryObj).forEach((queryKey, index) => {
    const queryConnector = queryString === '' ? '?' : '&';
    if (!ignoreEmptyParams || !isEmpty(queryObj[queryKey])) {
      queryString = queryString + `${queryConnector}${queryKey}=${queryObj[queryKey]}`;
    }
  });
  return queryString;
};

export const getEnlightenOrigin = () => {
  const hostname = window.location.hostname;
  let origin;
  if (hostname.search('bom.enphaseenergy') >= 0) {
    origin = 'https://enlighten.enphaseenergy.com';
  } else if (hostname.search('bomstg.qa-enphaseenergy') >= 0) {
    origin = 'https://qa2.enphaseenergy.com';
  } else if (hostname.search('bomdev.qa-enphaseenergy') >= 0) {
    origin = 'https://enlighten-qa4.enphaseenergy.com';
  } else if (window.location.port === '8888') {
    origin = 'http://localhost:7000';
  } else {
    origin = 'http://localhost:7000';
  }
  return origin;
};

export const trackEvents = (eventname, datalayer = {}) => {
  if (window.parent.location !== window.location) {
    window.parent.postMessage({ message: 'event', eventName: eventname, dataLayer: datalayer }, getEnlightenOrigin());
  }
};

export const currencyFormater = number => {
  return new Intl.NumberFormat('en-US').format(number);
};
