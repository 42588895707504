import React from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Button,
  Typography,
  Divider,
  Box,
  Checkbox
} from '@material-ui/core';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import { getCSVCheckboxLabels } from '../../../containers/BOMCalculator/constants';
import { trackEvents } from '../../../utils/helper';

const styles = makeStyles(theme => ({
  titleWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderBottom: '1px solid #E1E1E1',
    padding: theme.spacing(2.75, 3)
  },
  dialogTitle: {
    fontSize: 20,
    color: '#4A4A4A',
    fontWeight: 1000
  },
  paddingDialogContent: {
    padding: theme.spacing(2, 3, 2, 1.875)
  },
  close: {
    opacity: 0.5,
    cursor: 'pointer',
    fontSize: theme.spacing(3),
    marginRight: theme.spacing(-0.75)
  },
  buttonsWrapper: {
    padding: theme.spacing(2, 2.5)
  },
  acceptButton: {
    color: theme.palette.primary.white,
    textTransform: 'none'
  },
  rejectButton: {
    color: '#919191',
    border: '0.5px solid #E3E3E3',
    textTransform: 'none',
    width: theme.spacing(10)
  },
  checkboxRowWrapper: {
    display: 'flex',
    alignItems: 'center'
  },
  styleCheckboxLabel: {
    color: '#4b4b4ba3'
  }
}));

const ExportBOMPopup = props => {
  const { open, handleClose, itemList, handleCheckboxChangeCSV, triggerCSVDownload } = props;
  const classes = styles();
  return (
    <Dialog open={open} aria-labelledby="template" aria-describedby="template-description" onClose={handleClose}>
      <DialogTitle id="template" disableTypography className={classes.titleWrapper}>
        <Typography className={classes.dialogTitle}>Export BOM</Typography>
        <CloseIcon className={classes.close} onClick={handleClose} />
      </DialogTitle>
      <DialogContent className={classes.paddingDialogContent}>
        {Object.entries(itemList).map(([ key, value ], index) => {
          return (
            <Box key={key + index} className={classes.checkboxRowWrapper}>
              <Checkbox checked={value} color="primary" onChange={e => handleCheckboxChangeCSV(e, key)} />
              <Typography className={classes.styleCheckboxLabel}>{getCSVCheckboxLabels()[key]}</Typography>
            </Box>
          );
        })}
      </DialogContent>
      <Divider />
      <DialogActions className={classes.buttonsWrapper}>
        <Button onClick={handleClose} className={classes.rejectButton}>
          Cancel
        </Button>
        <Button
          color="primary"
          variant="contained"
          className={classes.acceptButton}
          onClick={() => {
            trackEvents('triggerBOM', { menuValue: 'Download_CSV' });
            triggerCSVDownload();
          }}
        >
          Download CSV
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const mapStateToProps = state => ({
  leadJourneyObject: state.bomInputReducer.leadJourneyObject,
  userDetails: state.appReducer.userDetails
});

export default connect(mapStateToProps)(ExportBOMPopup);
