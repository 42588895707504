import React from 'react';
import clsx from 'clsx';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  header: {
    fontSize: 35,
    fontWeight: 700,
    color: '#335977',
    margin: theme.spacing(0)
  }
}));

const PageHeader = props => {
  const classes = useStyles();
  const { text, className = '' } = props;
  const classname = className ? clsx(classes.header, className) : classes.header;
  return (
    <Typography className={classname} variant="h5" component="h5">
      {text}
    </Typography>
  );
};

export default PageHeader;
