import React, { Component, Fragment } from 'react';
import queryString from 'query-string';
import PageHeader from '../../components/common/PageHeader';
import { withStyles, Box, Backdrop, CircularProgress, Button } from '@material-ui/core';
import { cloneDeep, get, isEmpty, set, every, startsWith } from 'lodash';
import {
  ProjectType,
  SystemRequirements,
  LinearProgress,
  SystemDetails,
  ActionButtons
} from '../../components/BOMCalculatorInput';
import Snackbar from '../../components/SnakBar';
import { connect } from 'react-redux';
import clsx from 'clsx';
import { validateBOMInputs } from './validate';
import styles from './styles';
import {
  setBOMInputDetails,
  getAllTemplates,
  connectBOMToLead,
  getLeadBOMDetails,
  getPVManufacturers,
  getPVModels,
  calculateResidentialBOMOutput,
  checkExistingEmail,
  getExistingSystemDetails,
  getRecentlyUsedPVManufacturersModules
} from './actions';
import { getCountryCallingCode } from 'react-phone-number-input';
import { getInputDataFromLead, getInterconnectsToSend, getStorageSizeDropDown } from './helper';
import BOMCalculatorOutput from '../BOMCalculatorOutput';
import TemplatePopupAllTemplates from '../../components/BOMCalculatorInput/TemplatePopupAllTemplates';
import {
  IQ7,
  cell60,
  portrait,
  lessThanOrEqual200,
  servicePanelVoltage120_208,
  IQ7Plus,
  M175,
  yes,
  no,
  commercial,
  residential,
  solarOnly,
  existingMicroinverterArrayList,
  existingStorageUpgrade,
  acModule,
  microInverterArrayList,
  servicePanelVoltage120_240,
  sunlightBackup,
  homeEssentialBackup,
  fullEnergyIndependence,
  storageSizeArrayList,
  iq10BatteryId,
  iq3BatteryId,
  storageOnly
} from './constants';
import { getInputData } from '../BOMCalculatorOutput/helper';
import { forwardTo, constructQueryString, trackEvents, getEnlightenOrigin } from '../../utils/helper';
import { getNpsShown, setNpsFeedback } from '../BOMCalculatorOutput/actions';
import BOMImportDesignButton from '../../components/BOMCalculatorInput/BOMImportDesignButton';

export class BOMCalculatorInput extends Component {
  initialErrorData = () => ({
    systemRequirements: {
      requirement: {},
      customerDetails: {
        name: {},
        email: {},
        mobile: {},
        address: {},
        zipCode: {}
      }
    },
    systemDetails: {
      residential: [
        {
          pvManufacturer: {},
          pvModel: {},
          pvPanelType: {},
          acModule: {},
          pvPanelWattage: {},
          pvPanelsCount: {},
          panelOrientation: {},
          servicePanelVoltage: {},
          microinverter: {},
          storageSize: {},
          existingMicroinverter: {},
          existingMicroinverterCount: {},
          existingCellModem: {}
        }
      ],
      commercial: [
        {
          pvManufacturer: {},
          pvModel: {},
          pvPanelType: {},
          pvPanelWattage: {},
          pvPanelsCount: {},
          panelOrientation: {},
          servicePanelAmperage: {},
          servicePanelVoltage: {},
          microinverter: {},
          centerTapping: {},
          unusedCableDrops: {},
          highElectricNoiseEnvironment: {},
          nprRequired: {},
          advGridFunctionRequired: {}
        }
      ]
    }
  });

  constructor(props) {
    super(props);
    this.state = {
      navigateToOutput: false,
      projectType: '',
      progressValue: 10,
      showTemplatePopup: false,
      showCustomerDetailsPopup: false,
      systemRequirements: {
        addMoreToggle: false,
        requirement: '',
        customerDetails: {
          name: '',
          email: '',
          mobile: '',
          address: '',
          zipCode: '',
          existingSystem: {},
          addressObject: {}
        },
        sunlightBackupRequired: false,
        loadControllersRequired: false
      },
      systemDetails: {
        residential: [
          {
            id: 0,
            name: 'System',
            pvManufacturer: '',
            pvModel: '',
            pvModelsList: [],
            recentPVModelsList: [],
            pvPanelType: '',
            acModule: '',
            pvPanelWattage: '',
            pvPanelsCount: '',
            panelOrientation: '',
            portraitPanelCount: 0,
            landscapePanelCount: 0,
            servicePanelVoltage: servicePanelVoltage120_240,
            microinverter: IQ7,
            storageSize: '',
            existingMicroinverter: M175,
            existingMicroinverterCount: '0',
            openFindModulePopup: false,
            isProceedClickedPopup: false,
            existingCellModem: no,
            openCustomOrientationPanelsPopup: false,
            showPvSheddingLink: false
          }
        ],
        commercial: [
          {
            id: 0,
            name: `Interconnect 1`,
            pvManufacturer: '',
            pvModel: '',
            pvModelsList: [],
            recentPVModelsList: [],
            pvPanelWattage: '',
            pvPanelsCount: '',
            openFindModulePopup: false,
            isProceedClickedPopup: false,
            pvPanelType: '',
            panelOrientation: portrait,
            servicePanelAmperage: lessThanOrEqual200,
            servicePanelVoltage: servicePanelVoltage120_208,
            microinverter: IQ7Plus,
            centerTapping: no,
            unusedCableDrops: '5',
            highElectricNoiseEnvironment: no,
            nprRequired: no,
            advGridFunctionRequired: no
          }
        ]
      },
      isCalculateEnabled: true,
      errorData: this.initialErrorData(),
      residentialLabelsToCheck: [],
      selectedTemplate: '',
      openBomSavePopup: false,
      companyId: '',
      from: '',
      leadAssociated: false,
      existingEmailCheckError: false,
      existingEmailCheckErrorMessage: '',
      isBOMChanged: false,
      isNPSPopupShown: false,
      openNPSPopup: false,
      npsFeedbackSource: '',
      isSolargrafAvailable: false
    };
  }

  bomDetailsToSave = {};

  componentDidMount() {
    this.props.getAllTemplates();
    this.props.getPVManufacturers();
    this.props.getRecentlyUsedPVManufacturersModules({});
    const { page = '', ...remainingParams } = queryString.parse(this.props.history.location.search);
    // open output page directly
    if (page === 'output') {
      this.setState({ navigateToOutput: true });
    }
    // open bom input page with section 1 and 2 filled but hidden
    const { from = '', leadId = '', journeyType = '', ...otherRemainingParams } = remainingParams;
    if (!isEmpty(leadId)) {
      this.props.getNpsShown({
        leadId,
        successCbk: isNpsShown => {
          this.setState({ isNPSPopupShown: isNpsShown });
        }
      });
    }
    if (from === 'lead' && page === '') {
      forwardTo(this.props.history.location.pathname + constructQueryString(otherRemainingParams));
      this.setState({ from });
      const companyId = get(this.props.userDetails, 'company_id', '');
      this.props.getLeadBOMDetails({
        companyId,
        leadId,
        journeyType,
        successCbk: response => {
          let inputData = {};
          if (!isEmpty(get(response, 'projectBOM', {}))) {
            inputData = getInputData(response.projectBOM);
            this.setState({
              systemDetails: inputData.systemDetails,
              systemRequirements: inputData.systemRequirements,
              projectType: inputData.projectType,
              companyId: inputData.companyId,
              selectedTemplate: inputData.selectedTemplate,
              progressValue: 95,
              navigateToOutput: true,
              leadAssociated: true
            });
            if (inputData.projectType === commercial) {
              const errorData = cloneDeep(this.state.errorData);
              set(
                errorData,
                `systemDetails.${commercial}`,
                this.getEmptyErrorObjects(inputData.systemDetails.commercial.length)
              );
              this.setState({ errorData });
            }
          } else {
            inputData = getInputDataFromLead(response);
            this.setState({
              systemRequirements: inputData.systemRequirements,
              projectType: inputData.projectType,
              progressValue: 66
            });
            // Data sync between bom and created quote
            if (
              !isEmpty(response.quotes) &&
              isEmpty(get(response, 'sg_project_id', '')) &&
              isEmpty(get(response, 'sg_project_public_id', ''))
            ) {
              let systemDetails = this.dataSyncQuoteAndBOM(response);
              const { projectType } = this.state;
              const pvManufacturerTemp = systemDetails[projectType][0]['pvManufacturer'];
              // setting pv model details if manufacturer not custom
              if (pvManufacturerTemp !== 'Custom') {
                const quotes = get(response, 'quotes', [])[0];
                const { panel_model } = quotes;
                this.props.getRecentlyUsedPVManufacturersModules({
                  makerId: pvManufacturerTemp,
                  successCbk: recentPVModulesList => {
                    this.props.getPVModels({
                      id: pvManufacturerTemp,
                      successCbk: pvModelsList => {
                        systemDetails[projectType][0]['pvModelsList'] = pvModelsList;
                        systemDetails[projectType][0]['recentPVModelsList'] = recentPVModulesList;
                        pvModelsList.forEach(pvModel => {
                          if (pvModel.name === panel_model) {
                            systemDetails[projectType][0]['pvModel'] = pvModel['id'];
                            systemDetails[projectType][0]['pvPanelType'] =
                              pvModel.cell_count === 120 || pvModel.cell_count === 144
                                ? `${pvModel.cell_count} half - cell`
                                : `${pvModel.cell_count} - cell`;
                            systemDetails[projectType][0]['pvPanelWattage'] = pvModel.stc_rating;
                          }
                        });
                        this.setState({ systemDetails });
                      }
                    });
                  }
                });
              } else {
                this.setState({ systemDetails });
              }
            } else if (
              !isEmpty(get(response, 'sg_project_id', '')) &&
              !isEmpty(get(response, 'sg_project_public_id', ''))
            ) {
              this.setState({
                isSolargrafAvailable: true
              });
              window.parent.postMessage('loadComplete', getEnlightenOrigin());
              window.addEventListener('message', this.receiveMessage);
            }
          }
        }
      });
    } else {
      window.parent.postMessage('loadComplete', getEnlightenOrigin());
      window.addEventListener('message', this.receiveMessage);
    }
  }

  componentWillUnmount() {
    window.removeEventListener('message', this.receiveMessage);
  }

  receiveMessage = event => {
    if (
      event.data.message === 'solargraf' &&
      event.origin === getEnlightenOrigin() &&
      this.state.systemDetails &&
      event.data.value !== null
    ) {
      const sgProjectDetailsData = event.data.value;
      const { Materials = {}, RoofPlanes = [] } = sgProjectDetailsData;
      const { storageConfiguration = [], inverter = [] } = Materials;
      const proposalId = event.data.proposalId;
      if (isEmpty(sgProjectDetailsData)) return;
      let systemDetails = cloneDeep(this.state.systemDetails);
      let projectType = this.state.projectType;
      if (!projectType || this.state.from !== 'lead') {
        projectType = sgProjectDetailsData.Settings.project_type;
        let { systemRequirements } = this.state;
        systemRequirements.requirement = solarOnly;
        this.setState({ systemRequirements, projectType }, () => this.handleProgressBarChange());
      }

      // Getting Storage Details
      let iqBattery3 = 0;
      let iqBattery10 = 0;
      storageConfiguration.forEach(storage => {
        if (storage.proposal_ids.includes(proposalId)) {
          storage.batteryBackups.forEach(battery => {
            if (battery.id === iq10BatteryId) {
              iqBattery10 = battery.quantity;
            } else if (battery.id === iq3BatteryId) {
              iqBattery3 = battery.quantity;
            }
          });
        }
      });

      if ((iqBattery10 > 0 || iqBattery3 > 0) && projectType === 'residential') {
        let { systemRequirements } = this.state;
        systemRequirements.requirement = fullEnergyIndependence;
        this.setState({ systemRequirements: systemRequirements }, () => this.handleProgressBarChange());
        storageSizeArrayList().forEach(storageSize => {
          if (storageSize.e3Count === iqBattery3 && storageSize.e10Count === iqBattery10) {
            systemDetails[projectType][0]['storageSize'] = storageSize.value;
          }
        });
      }

      // Getting Inverter Details
      inverter.forEach(micro => {
        if (micro.proposal_ids.includes(proposalId)) {
          systemDetails[projectType][0]['microinverter'] = this.getInverterFromSG(micro.name);
        }
      });

      // Getting RoofPlane Details (Panel Details)
      const pvManufacturersList = this.props.pvManufacturersList || [];
      RoofPlanes.forEach(plane => {
        if (plane.proposal_id === proposalId) {
          const pvManufacturer = pvManufacturersList.find(
            manufacturer => manufacturer.name === plane.Panel.Manufacturer.name
          );
          if (pvManufacturer !== undefined) {
            this.props.getPVModels({
              id: pvManufacturer.id,
              successCbk: pvModelsList => {
                systemDetails[projectType][0]['pvModelsList'] = pvModelsList;
                pvModelsList.forEach(pvModel => {
                  if (pvModel.name === plane.Panel.name) {
                    systemDetails[projectType][0]['pvModel'] = pvModel['id'];
                    systemDetails[projectType][0]['pvPanelType'] =
                      pvModel.cell_count === 120 || pvModel.cell_count === 144
                        ? `${pvModel.cell_count} half - cell`
                        : `${pvModel.cell_count} - cell`;
                    systemDetails[projectType][0]['pvPanelWattage'] = pvModel.stc_rating;
                    systemDetails[projectType][0]['pvManufacturer'] = pvManufacturer['id'];
                  }
                });
              }
            });
          }
          // If matching panel manufacturer not found in pvManufacturersList
          if (systemDetails[projectType][0]['pvModel'] === '') {
            const cellCount = plane.Panel.cell_count || 60;
            systemDetails[projectType][0]['pvManufacturer'] = 'Custom';
            systemDetails[projectType][0]['pvModel'] = 'Custom';
            systemDetails[projectType][0]['pvPanelType'] =
              cellCount === 120 || cellCount === 144 ? `${cellCount} half - cell` : `${cellCount} - cell`;
            systemDetails[projectType][0]['pvPanelWattage'] = plane.Panel.size_in_watts;
          }
          systemDetails[projectType][0]['pvPanelsCount'] = plane.count;
          systemDetails[projectType][0]['panelOrientation'] =
            plane.panel_orientation === 'portrait' ? 'Portrait' : 'Landscape';
        }
      });
      this.setState({ systemDetails });
    } else if (
      event.data.message === 'solargraf-available' &&
      event.data.value === true &&
      event.origin === getEnlightenOrigin()
    ) {
      this.setState({
        isSolargrafAvailable: true
      });
    }
    return;
  };

  getInverterFromSG = name => {
    const inverter = name.split('-')[0];
    if (inverter === 'IQ7PLUS') {
      return 'IQ7+';
    } else if (inverter === 'IQ8PLUS') {
      return 'IQ8+';
    } else if (startsWith(inverter, 'IQ6')) {
      return '';
    }
    return inverter;
  };

  dataSyncQuoteAndBOM = leadDetails => {
    const quotes = get(leadDetails, 'quotes', [])[0];
    const requirement = get(leadDetails, 'requirement', []);
    const systemDetails = cloneDeep(this.state.systemDetails);
    const { pvManufacturersList } = this.props;
    const { projectType } = this.state;
    const { panel_type, panel_mfg, panel_qty, storage_capacity, inverter_type } = quotes;

    const getManufacturerAndModelDataSync = () => {
      let panelMfg = '',
        panelModel = '';
      if (panel_type === 'acm') {
        panelMfg = 'Custom';
        panelModel = 'Custom';
      } else {
        if (!isEmpty(pvManufacturersList)) {
          pvManufacturersList.forEach(pvManufacturer => {
            if (pvManufacturer.name === panel_mfg) {
              panelMfg = pvManufacturer.id;
            }
          });
        }
      }
      return { manufacturer: panelMfg, model: panelModel };
    };

    const getACModule = () => {
      switch (panel_mfg) {
        case 'Hanwha':
          return 'Hanwa Q cells';
        case 'Solaria':
          return 'Solaria';
        case 'LONGi':
          return 'LONGi';
        default:
          return '';
      }
    };

    const getInverterType = systemDetailsNew => {
      let result = '';
      switch (inverter_type) {
        case 'Enphase IQ7+':
          result = 'IQ7+';
          break;
        case 'Enphase IQ 7A':
          result = 'IQ7A';
          break;
        case 'Enphase IQ 7X':
          result = 'IQ7X';
          break;
        case 'Enphase IQ 7':
          result = 'IQ7';
          break;
        default:
          result = '';
      }
      let isResultValid = false;
      const isCommercial = projectType === commercial;
      microInverterArrayList(isCommercial, systemDetailsNew[projectType][0]).forEach(inverterObject => {
        isResultValid = isResultValid || (inverterObject.value === result && !inverterObject.hide);
      });
      return isResultValid ? result : isCommercial ? IQ7Plus : IQ7;
    };

    const { manufacturer = '', model = '' } = getManufacturerAndModelDataSync();

    if (projectType === residential) {
      if (requirement !== 'Solar Only' && requirement !== sunlightBackup) {
        systemDetails[projectType][0]['storageSize'] = getStorageSizeDropDown(storage_capacity);
      }
      if (
        requirement === 'Solar Only' ||
        requirement === 'Solar and Storage' ||
        requirement === sunlightBackup ||
        requirement === homeEssentialBackup ||
        requirement === fullEnergyIndependence
      ) {
        systemDetails[projectType][0]['pvManufacturer'] = manufacturer;
        systemDetails[projectType][0]['pvModel'] = model;
        systemDetails[projectType][0]['isProceedClickedPopup'] =
          systemDetails[projectType][0]['pvManufacturer'] === 'Custom' ? true : false;
        systemDetails[projectType][0]['pvPanelsCount'] = panel_qty;
        if (panel_type === 'normal') {
          systemDetails[projectType][0]['microinverter'] = getInverterType(systemDetails);
        } else {
          systemDetails[projectType][0]['pvPanelType'] = 'AC Module';
          systemDetails[projectType][0]['acModule'] = getACModule();
        }
      }
    } else {
      systemDetails[projectType][0]['pvManufacturer'] = manufacturer;
      systemDetails[projectType][0]['pvPanelsCount'] = panel_qty;
      systemDetails[projectType][0]['microinverter'] = getInverterType(systemDetails);
    }

    return systemDetails;
  };

  handleNpsPopupOpenClose = (value, source = '') => {
    this.setState({
      openNPSPopup: value,
      isNPSPopupShown: value ? value : this.state.isNPSPopupShown,
      npsFeedbackSource: source
    });
  };

  onSubmitNpsFeedback = (feedbackBody = {}) => {
    const { setNpsFeedback, leadId } = this.props;
    if (!isEmpty(leadId)) {
      feedbackBody['lead_id'] = leadId;
    }
    feedbackBody['source'] = this.state.npsFeedbackSource;
    setNpsFeedback({
      feedbackBody,
      successCbk: () => {
        this.setState({ openNPSPopup: false }, () => {
          if (this.state.npsFeedbackSource === 'BOM_SAVE') this.handlePopupOpenClose('openBomSavePopup', true);
        });
      }
    });
  };

  // this will add empty error objects = no of interconnects
  // in case of commercial project type
  getEmptyErrorObjects = interconnects => {
    let errors = [];
    for (let i = 0; i < interconnects; i++) {
      errors.push(this.addErrorObjectCommercialInterconnect());
    }
    return errors;
  };

  getPVModelsForEachIC = interconnect => {
    const makerId = get(interconnect, 'pvManufacturer', '');
    if (makerId === 'Custom') {
      interconnect.isProceedClickedPopup = true;
    } else {
      this.props.getRecentlyUsedPVManufacturersModules({
        makerId: makerId,
        successCbk: recentPVModulesList => {
          this.props.getPVModels({
            id: makerId,
            successCbk: result => {
              interconnect.pvModelsList = result;
              interconnect.recentPVModelsList = recentPVModulesList;
            }
          });
        }
      });
    }
  };

  updateInputState = inputData => {
    if (inputData.projectType === commercial) {
      const errorData = cloneDeep(this.state.errorData);
      set(
        errorData,
        `systemDetails.${commercial}`,
        this.getEmptyErrorObjects(inputData.systemDetails.commercial.length)
      );
      this.setState({ errorData });
      inputData.systemDetails.commercial.map(interconnect => this.getPVModelsForEachIC(interconnect));
    } else {
      inputData.systemDetails.residential.map(interconnect => this.getPVModelsForEachIC(interconnect));
    }
    this.setState({ ...this.state, ...inputData });
  };

  addErrorObjectCommercialInterconnect = () => {
    return {
      pvManufacturer: {},
      pvModel: {},
      pvPanelType: {},
      pvPanelWattage: {},
      pvPanelsCount: {},
      panelOrientation: {},
      servicePanelAmperage: {},
      servicePanelVoltage: {},
      microinverter: {},
      centerTapping: {},
      unusedCableDrops: {},
      highElectricNoiseEnvironment: {},
      nprRequired: {},
      advGridFunctionRequired: {}
    };
  };

  addCommercialInterconnect = id => {
    const { systemDetails, projectType } = this.state;
    const interconnectArray = cloneDeep(get(systemDetails, `${projectType}`, []));
    return {
      id,
      name: `Interconnect ${interconnectArray.length + 1}`,
      pvManufacturer: '',
      pvModel: '',
      pvModelsList: [],
      recentPVModelsList: [],
      pvPanelWattage: '',
      pvPanelsCount: '',
      openFindModulePopup: false,
      isProceedClickedPopup: false,
      pvPanelType: '',
      panelOrientation: portrait,
      servicePanelAmperage: lessThanOrEqual200,
      servicePanelVoltage: servicePanelVoltage120_208,
      microinverter: IQ7Plus,
      centerTapping: no,
      unusedCableDrops: '5',
      highElectricNoiseEnvironment: no,
      nprRequired: no,
      advGridFunctionRequired: no
    };
  };

  validateSystemRequirementsInput = (helperParams = {}) => {
    let errors = {};
    if (get(helperParams, 'key', '') === 'systemRequirements.requirement') {
      errors = this.initialErrorData();
    } else {
      errors = validateBOMInputs(this.state.errorData, this.state, helperParams);
    }
    this.setState({ errorData: errors });
  };

  validateSystemDetailsInput = (helperParams = {}) => {
    const { interconnectFieldName } = helperParams;
    if (isEmpty(interconnectFieldName)) return;
    const errors = validateBOMInputs(this.state.errorData, this.state, helperParams);
    this.setState({ errorData: errors });
  };

  handleAddRemoveInterConnect = (projectType, interconnectArray, interconnectErrorArray) => {
    let tempState = cloneDeep(this.state);
    set(tempState, `systemDetails.${projectType}`, interconnectArray);
    set(tempState, `errorData.systemDetails.${projectType}`, interconnectErrorArray);
    this.setState(tempState);
  };

  setStateOnChange = (tempState, key, helperParams) => {
    const { projectType } = this.state;
    const { from = '', checkZipCodeEmpty = false, interconnectFieldName = '', interconnectIndex } = helperParams;
    this.setState(tempState, () => {
      this.handleProgressBarChange();
      from === 'systemRequirements'
        ? this.validateSystemRequirementsInput({ key, checkZipCodeEmpty, from })
        : this.validateSystemDetailsInput({
            key: key + '.' + interconnectFieldName,
            interconnectIndex,
            projectType,
            from,
            interconnectFieldName
          });
    });
  };

  handleInterconnectNameChange = (event, interconnectIndex) => {
    const value = event.target.value;
    const interconnectFormat = /^[\w\d\s]*$/g;
    const isValidValue = value.match(interconnectFormat);
    if (!isEmpty(value) && (value.length > 32 || !isValidValue)) return;
    const { projectType } = this.state;
    let tempState = cloneDeep(this.state.systemDetails);
    tempState[projectType][interconnectIndex]['name'] = event.target.value;
    this.setState({ systemDetails: tempState });
  };

  checkIQ8toIQ7Switching = (projectType, systemDetails) => {
    if (projectType === commercial) return false;
    const currentMicroinverter = get(this.state, 'systemDetails.residential')[0]['microinverter'];
    return currentMicroinverter.includes('IQ8') && systemDetails[0]['microinverter'].includes('IQ7');
  };

  resetValuesOnHandleChange = tempState => {
    const valuesToReset = [ 'servicePanelVoltage', 'microinverter', 'acModule', 'storageSize' ];
    tempState['systemDetails'][residential].forEach(interconnect => {
      valuesToReset.forEach(field => {
        interconnect[field] = '';
      });
    });
    return tempState;
  };

  handleOnChange = (event, key, helperParams = {}) => {
    const { value = '', interconnectFieldName = '', interconnectIndex } = helperParams;
    const { projectType } = this.state;
    let tempState = cloneDeep(this.state);

    if (key === 'systemRequirements.sunlightBackupRequired' || key === 'systemRequirements.loadControllersRequired') {
      tempState['systemDetails'][projectType].forEach(interconnect => {
        interconnect['microinverter'] = '';
        interconnect['servicePanelVoltage'] = '';
        interconnect['storageSize'] = '';
      });
    }
    tempState = set(tempState, `${key}`, value || event.target.value);
    if (projectType === residential) {
      if (interconnectFieldName === 'microinverter' && this.checkIQ8toIQ7Switching(projectType, value)) {
        tempState['systemDetails']['residential'][0]['storageSize'] = '';
      } else if (interconnectFieldName === 'servicePanelVoltage') {
        tempState['systemDetails']['residential'][0]['microinverter'] = '';
      } else if (interconnectFieldName === 'pvPanelsCount') {
        tempState['systemDetails']['residential'][0]['storageSize'] = '';
      }
    }
    if (key === 'systemRequirements.requirement') {
      if (tempState['systemRequirements']['requirement'] === sunlightBackup) {
        tempState['systemRequirements']['sunlightBackupRequired'] = true;
      } else {
        tempState['systemRequirements']['sunlightBackupRequired'] = false;
      }
      if (tempState['systemRequirements']['requirement'] === homeEssentialBackup) {
        tempState['systemRequirements']['loadControllersRequired'] = true;
      } else {
        tempState['systemRequirements']['loadControllersRequired'] = false;
      }
      tempState = this.resetValuesOnHandleChange(tempState);
    }

    const pvManufacturerValue = get(value[interconnectIndex], 'pvManufacturer');
    if (interconnectFieldName === 'pvManufacturer') {
      if (pvManufacturerValue) {
        this.props.getRecentlyUsedPVManufacturersModules({
          makerId: pvManufacturerValue,
          successCbk: recentPVModulesList => {
            this.props.getPVModels({
              id: pvManufacturerValue,
              successCbk: result => {
                tempState['systemDetails'][projectType][interconnectIndex]['pvModelsList'] = result;
                tempState['systemDetails'][projectType][interconnectIndex]['recentPVModelsList'] = recentPVModulesList;
                tempState['systemDetails'][projectType][interconnectIndex]['isProceedClickedPopup'] = false;
                this.setStateOnChange(tempState, key, helperParams);
              }
            });
          }
        });
      } else {
        tempState['systemDetails'][projectType][interconnectIndex]['pvModelsList'] = [];
        tempState['systemDetails'][projectType][interconnectIndex]['recentPVModelsList'] = [];
        tempState['systemDetails'][projectType][interconnectIndex]['isProceedClickedPopup'] = false;
        this.setStateOnChange(tempState, key, helperParams);
      }
    } else {
      this.setStateOnChange(tempState, key, helperParams);
    }
  };

  handleCustomPanelConfigurations = (interconnectId, value = false, helperParams) => {
    const { fieldName, source = 'moduleDetails', resetValuesPanelOrientation = false } = helperParams;
    let tempState = cloneDeep(this.state.systemDetails);
    const { projectType } = this.state;
    let projectTypeArray = get(tempState, `${projectType}`, []);
    projectTypeArray.forEach(array => {
      if (array.id === interconnectId) {
        array[fieldName] = value;
        if (source === 'panelOrientation' && value === false && resetValuesPanelOrientation) {
          array['panelOrientation'] = '';
          array['portraitPanelCount'] = 0;
          array['landscapePanelCount'] = 0;
        }
      }
    });
    tempState[projectType] = projectTypeArray;
    const { pvPanelType = '' } = get(tempState, `${projectType}`, [])[0];
    if (source === 'moduleDetails' && isEmpty(pvPanelType)) {
      tempState[projectType][0]['pvPanelType'] = cell60;
    }
    this.setState({ systemDetails: tempState });
  };

  checkNoEmptyField = interconnect => {
    const excludeKeys = [ 'id', 'pvManufacturer', 'pvModel', 'pvModelsList', 'recentPVModelsList' ];
    return Object.keys(interconnect).every(keyName => {
      return excludeKeys.includes(keyName) || (interconnect[keyName] && !isEmpty(interconnect[keyName].toString()));
    });
  };

  isOneInterconnectFilledCompletely = () => {
    const { projectType, residentialLabelsToCheck, systemDetails } = this.state;
    let result = [];
    if (isEmpty(projectType)) return false;
    const isCommercial = projectType === 'commercial';
    const interconnectArray = cloneDeep(get(systemDetails, `${projectType}`, []));
    interconnectArray.forEach(interconnect => {
      let modifiedInterConnect = {};
      if (!isCommercial && residentialLabelsToCheck && residentialLabelsToCheck.length) {
        this.state.residentialLabelsToCheck.forEach(key => {
          modifiedInterConnect[key] = interconnect[key];
        });
      } else {
        modifiedInterConnect = cloneDeep(interconnect);
      }
      result.push(Boolean(this.checkNoEmptyField(modifiedInterConnect)));
    });
    return every(result);
  };

  handleProgressBarChange = () => {
    let progressValue = 10;
    if (!isEmpty(this.state.projectType)) {
      progressValue = 33;
    }
    if (!isEmpty(this.state.systemRequirements.requirement)) {
      progressValue = 66;
    }
    if (this.isOneInterconnectFilledCompletely()) {
      progressValue = 95;
    }
    this.setState({ progressValue, isCalculateEnabled: true });
  };

  handleRadioChange = projectType => {
    if (this.state.projectType === projectType) return;
    const systemRequirements = cloneDeep(this.state.systemRequirements);
    const requirement = projectType === commercial ? solarOnly : '';
    this.setState(
      {
        projectType,
        systemRequirements: {
          ...systemRequirements,
          requirement
        }
      },
      () => {
        this.handleProgressBarChange();
      }
    );
  };

  handleMoreDetailsToggle = () => {
    this.setState({
      systemRequirements: {
        ...this.state.systemRequirements,
        addMoreToggle: !this.state.systemRequirements.addMoreToggle
      }
    });
    if (!this.state.systemRequirements.addMoreToggle) {
      trackEvents('triggerBOM', { menuValue: 'Add_more_details' });
    }
  };

  setResidentialLabelsToCheck = labels => this.setState({ residentialLabelsToCheck: labels });

  handleCustomerDetails = customerDetails => {
    let tempState = cloneDeep(this.state);
    tempState = set(
      tempState,
      'systemRequirements.customerDetails',
      customerDetails || get(this.state, 'systemRequirements.customerDetails')
    );
    this.setState(tempState);
  };

  getRequirement = () => {
    const requirement = get(this.state, 'systemRequirements.requirement');
    if (requirement === sunlightBackup) {
      return solarOnly;
    } else if (requirement === homeEssentialBackup || requirement === fullEnergyIndependence) {
      return 'Solar + Storage';
    } else {
      return requirement;
    }
  };

  getBomObjectToSend = (templateId, companyId, helperParams = {}) => {
    const { isCreateLead = false, sliceCodeFromMobile = false } = helperParams;
    let result = {};
    const splitNameIndex = get(this.state, 'systemRequirements.customerDetails.name').lastIndexOf(' ');
    const code = getCountryCallingCode('US');
    let mobile = get(this.state, 'systemRequirements.customerDetails.mobile').replace('+', '');
    if (sliceCodeFromMobile) {
      mobile = mobile.slice(code.toString().length);
    }

    result = {
      lead_details: {
        customer_type:
          get(this.state, 'systemRequirements.requirement') === 'Storage Upgrade (Existing customer)'
            ? 'existing'
            : 'new',
        address: get(this.state, 'systemRequirements.customerDetails.addressObject'),
        first_name: get(this.state, 'systemRequirements.customerDetails.name').substring(0, splitNameIndex),
        last_name: get(this.state, 'systemRequirements.customerDetails.name').substring(splitNameIndex + 1),
        email: get(this.state, 'systemRequirements.customerDetails.email'),
        mobile,
        project_type: get(this.state, 'projectType').toUpperCase(),
        requirement: this.getRequirement(),
        save_bom_calculation: true
      },
      pricing_template_id: templateId,
      company_id: companyId
    };
    if (!isCreateLead) {
      const key = this.state.projectType === 'commercial' ? 'interconnects' : 'residential_interconnects';
      result[key] = getInterconnectsToSend(
        get(this.state, `systemDetails.${this.state.projectType}`, []),
        this.state.projectType,
        get(this.state, 'systemRequirements', ''),
        this.props.pvManufacturersList,
        this.props.existingSystemDetails
      );
    }
    return result;
  };

  connectBOMwithLead = bomObject => {
    const { connectBOMToLead } = this.props;
    connectBOMToLead({
      bomObject,
      successCbk: () => {
        this.setState({ navigateToOutput: true, showTemplatePopup: false });
      }
    });
  };

  handleContinue = (templateId, companyId) => {
    this.setState({ selectedTemplate: templateId, companyId });
    const { setBOMInputDetails, leadJourneyObject, leadId, calculateResidentialBOMOutput } = this.props;
    const { systemRequirements, projectType } = this.state;
    const { addMoreToggle } = systemRequirements;
    if (
      addMoreToggle &&
      isEmpty(leadJourneyObject) &&
      ((this.state.from === 'lead' && !isEmpty(leadId) && !this.state.leadAssociated) ||
        (this.state.from !== 'lead' && isEmpty(leadId)))
    ) {
      if (projectType === 'commercial') {
        setBOMInputDetails({
          bomObject: this.getBomObjectToSend(templateId, companyId, {
            sliceCodeFromMobile: this.state.from !== 'lead'
          }),
          successCbk: bomObject => {
            this.connectBOMwithLead(bomObject);
          }
        });
      } else {
        calculateResidentialBOMOutput({
          bomObject: this.getBomObjectToSend(templateId, companyId, {
            sliceCodeFromMobile: this.state.from !== 'lead'
          }),
          successCbk: bomObject => {
            this.connectBOMwithLead(bomObject);
          }
        });
      }
    } else {
      if (projectType === 'commercial') {
        setBOMInputDetails({
          bomObject: this.getBomObjectToSend(templateId, companyId),
          successCbk: () => {
            this.setState({ navigateToOutput: true, showTemplatePopup: false });
          }
        });
      } else {
        calculateResidentialBOMOutput({
          bomObject: this.getBomObjectToSend(templateId, companyId),
          successCbk: bomObject => {
            this.setState({ navigateToOutput: true, showTemplatePopup: false });
          }
        });
      }
    }
  };

  handleBack = () => {
    this.setState({ navigateToOutput: false });
  };

  handleSave = (interconnects, projectBomItems, projectSummary, forceSave = false, buttonClicked = 'none') => {
    const { selectedTemplate, companyId, isNPSPopupShown } = this.state;
    const { leadId, connectBOMToLead } = this.props;
    if (buttonClicked === 'save') {
      trackEvents('triggerBOM', { menuValue: 'Save_BOM' });
    } else if (buttonClicked === 'proceed') {
      trackEvents('triggerBOM', { menuValue: 'Lead_details_entered_successfully' });
    }

    if ((this.state.systemRequirements.addMoreToggle && !isEmpty(leadId)) || forceSave) {
      let result = {};
      result = this.getBomObjectToSend(selectedTemplate, companyId, { isCreateLead: true });
      result = this.getBomObjectToSend(selectedTemplate, companyId, { isCreateLead: true, sliceCodeFromMobile: true });
      result['interconnects'] = interconnects;
      result['project_bomitems'] = projectBomItems;
      result['project_summary'] = projectSummary;
      connectBOMToLead({
        bomObject: result,
        successCbk: () => {
          if (!isNPSPopupShown) {
            this.handleNpsPopupOpenClose(true, 'BOM_SAVE');
          } else {
            this.handlePopupOpenClose('openBomSavePopup', true);
          }
          const systemRequirements = cloneDeep(this.state.systemRequirements);
          set(systemRequirements, 'addMoreToggle', true);
          this.setState({ systemRequirements });
          this.forceUpdate();
          forceSave && this.handleCustomerDetailsPopup(false);
        }
      });
    } else {
      this.bomDetailsToSave = { interconnects, projectBomItems, projectSummary };
      this.handleCustomerDetailsPopup(true);
    }
    this.setState({ isBOMChanged: false });
  };

  getValue = (value, key) => (!isEmpty(value) ? get(value, key, '') : '');

  getMicroinverterInfo = inverters => {
    let result = {};
    Object.entries(inverters).forEach(([ inverterName, inverterCount ]) => {
      existingMicroinverterArrayList().forEach(obj => {
        if (obj.key === inverterName && isEmpty(result)) {
          result = { [inverterName]: inverterCount };
        }
      });
    });
    return result;
  };

  handleExistingSystemSelect = async value => {
    const { getExistingSystemDetails } = this.props;
    const ownerInfo = get(value, 'owner_info', {});
    const systemId = this.getValue(value, 'system_id');
    const email = this.getValue(ownerInfo, 'email');
    const zipCode = this.getValue(value, 'zip');
    await getExistingSystemDetails({
      systemId,
      email,
      zipCode,
      successCbk: existingSiteDetails => {
        const { active_cell_modems_present, inverters } = existingSiteDetails[0];
        const microinverterInfo = this.getMicroinverterInfo(inverters);
        const tempState = cloneDeep(this.state);
        tempState['systemDetails']['residential'][0]['existingCellModem'] = active_cell_modems_present ? yes : no;
        if (!isEmpty(microinverterInfo)) {
          tempState['systemDetails']['residential'][0]['existingMicroinverter'] = Object.keys(microinverterInfo)[0];
          tempState['systemDetails']['residential'][0]['existingMicroinverterCount'] = Object.values(
            microinverterInfo
          )[0];
        }

        this.setState(tempState);
      }
    });

    const errors = validateBOMInputs(this.state.errorData, this.state, {
      checkAllFieldsSystemRequirements: true,
      from: 'systemRequirements'
    });
    this.setState({ errorData: errors });
  };

  handleErrorCheck = canProceed => {
    const errors = validateBOMInputs(this.state.errorData, this.state, {
      checkAllFieldsSystemRequirements: true,
      from: 'systemRequirements'
    });
    this.setState({ errorData: errors });
    const customerDetails = get(errors['systemRequirements'], 'customerDetails');
    Object.entries(customerDetails).forEach(([ key, value ]) => {
      const { error } = value;
      canProceed = canProceed && !error;
    });
    return canProceed;
  };

  getKeysToValidateSystemDetails = () => {
    const { projectType } = this.state;
    let keys = [];
    if (projectType === 'commercial') {
      keys = [
        'systemDetails.commercial.pvManufacturer',
        'systemDetails.commercial.pvModel',
        'systemDetails.commercial.pvPanelsCount',
        'systemDetails.commercial.panelOrientation',
        'systemDetails.commercial.servicePanelAmperage',
        'systemDetails.commercial.servicePanelVoltage',
        'systemDetails.commercial.microinverter',
        'systemDetails.commercial.centerTapping',
        'systemDetails.commercial.unusedCableDrops',
        'systemDetails.commercial.highElectricNoiseEnvironment',
        'systemDetails.commercial.nprRequired',
        'systemDetails.commercial.advGridFunctionRequired'
      ];
    } else if (projectType === 'residential') {
      const pvPanelType = get(get(this.state.systemDetails, 'residential')[0], 'pvPanelType');
      if (
        this.state.systemRequirements.requirement === 'Solar only' ||
        this.state.systemRequirements.requirement === 'Solar + Storage' ||
        this.state.systemRequirements.requirement === sunlightBackup ||
        this.state.systemRequirements.requirement === homeEssentialBackup ||
        this.state.systemRequirements.requirement === fullEnergyIndependence
      ) {
        keys = [
          'systemDetails.residential.pvManufacturer',
          'systemDetails.residential.pvModel',
          'systemDetails.residential.pvPanelsCount',
          'systemDetails.residential.panelOrientation',
          'systemDetails.residential.servicePanelVoltage'
        ];
        if (pvPanelType === acModule) {
          keys.push('systemDetails.residential.acModule');
        } else {
          keys.push('systemDetails.residential.microinverter');
        }
        if (
          this.state.systemRequirements.requirement === 'Solar + Storage' ||
          this.state.systemRequirements.requirement === homeEssentialBackup ||
          this.state.systemRequirements.requirement === fullEnergyIndependence
        ) {
          keys.push('systemDetails.residential.storageSize');
        }
      } else if (this.state.systemRequirements.requirement === 'Storage only') {
        keys = [ 'systemDetails.residential.storageSize' ];
      } else {
        keys = [
          'systemDetails.residential.storageSize',
          'systemDetails.residential.existingMicroinverter',
          'systemDetails.residential.existingMicroinverterCount',
          'systemDetails.residential.existingCellModem'
        ];
      }
    }
    keys.push('systemRequirements.requirement');
    return keys;
  };

  handleErrorCheckSystem = async canProceed => {
    const projectTypeInterconnect = get(this.state, `systemDetails.${this.state.projectType}`, []);
    let errors = this.state.errorData;
    const keys = this.getKeysToValidateSystemDetails();
    projectTypeInterconnect.forEach((interconnect, index) => {
      keys.forEach(field => {
        errors = validateBOMInputs(errors, this.state, {
          projectType: this.state.projectType,
          from: field === 'systemRequirements.requirement' ? 'systemRequirements' : 'systemDetails',
          interconnectIndex: index,
          interconnectFieldName: field.lastIndexOf('.') > -1 ? field.substring(field.lastIndexOf('.') + 1) : field,
          key: field
        });
      });
    });
    await this.setState({ errorData: errors }, () => {
      const projectTypeErrorData = get(this.state, `errorData.systemDetails.${this.state.projectType}`, []);
      projectTypeErrorData.forEach((error, index) => {
        Object.entries(error).forEach(([ key, value ]) => {
          const error = get(value, 'error') || false;
          canProceed = canProceed && !error;
        });
      });
    });
    return canProceed;
  };

  handleCalculate = async () => {
    const { checkExistingEmail, leadId } = this.props;
    const { systemRequirements, projectType, systemDetails } = this.state;
    const { requirement } = systemRequirements;
    let canProceed = !isEmpty(projectType);

    const menuValueObj = { menuValue: 'Calculate_BOM' };
    const invterSelectionObj = { inverterSelection: systemDetails[projectType][0].microinverter };
    const pvPanelCountObj = { pvPanelCount: systemDetails[projectType][0].pvPanelsCount };
    const storageConfigurationObj = { storageConfiguration: systemDetails[projectType][0].storageSize };
    const projectTypeObj = { project_type: this.state.projectType };
    const requirementTypeObj = {
      requirement_type: this.state.systemRequirements.requirement
    };
    let bomDLValues = {};
    let bomInputChangesDLValues = {};

    if (get(systemRequirements, 'addMoreToggle')) {
      canProceed = this.handleErrorCheck(canProceed);
    }
    canProceed = canProceed && (await this.handleErrorCheckSystem(canProceed));
    if (canProceed) {
      if (get(systemRequirements, 'addMoreToggle')) {
        if (this.state.from !== 'lead' && isEmpty(leadId)) {
          checkExistingEmail({
            emailObject: { email: get(systemRequirements.customerDetails, 'email') },
            successCbk: result => {
              const { lead_in_progress, same_installer } = result;
              if (
                lead_in_progress &&
                same_installer &&
                get(systemRequirements, 'requirement') !== existingStorageUpgrade
              ) {
                this.setState({
                  existingEmailCheckError: true,
                  existingEmailCheckErrorMessage: `The email ID entered by you is already associated with an existing lead in your account`
                });
              } else {
                this.setState({
                  showTemplatePopup: true,
                  existingEmailCheckError: false,
                  existingEmailCheckErrorMessage: ``
                });
                bomDLValues = { ...bomDLValues, ...menuValueObj };
                bomInputChangesDLValues = { ...bomInputChangesDLValues, ...projectTypeObj, ...requirementTypeObj };
              }
            }
          });
        } else {
          this.setState({
            showTemplatePopup: true
          });
          bomDLValues = { ...bomDLValues, ...menuValueObj };
          bomInputChangesDLValues = { ...bomInputChangesDLValues, ...projectTypeObj, ...requirementTypeObj };
        }
      } else {
        this.setState({
          showTemplatePopup: true
        });
        bomDLValues = { ...bomDLValues, ...menuValueObj };
        bomInputChangesDLValues = { ...bomInputChangesDLValues, ...projectTypeObj, ...requirementTypeObj };
      }
      if (requirement !== storageOnly && requirement !== existingStorageUpgrade) {
        bomDLValues = {
          ...bomDLValues,
          ...invterSelectionObj,
          ...pvPanelCountObj
        };
      }
      if (requirement !== solarOnly && requirement !== sunlightBackup) {
        bomDLValues = {
          ...bomDLValues,
          ...storageConfigurationObj
        };
      }
      trackEvents('triggerBOM', bomDLValues);
      trackEvents('triggerBOMInputChanges', bomInputChangesDLValues);
    }
  };

  handlePopupOpenClose = (keyName, value = false) => {
    this.setState({ [keyName]: value });
  };

  handleCustomerDetailsPopup = showPopup => this.setState({ showCustomerDetailsPopup: showPopup });

  handleSaveCustomerDetailsPopup = () => {
    const canProceed = this.handleErrorCheck(true);
    if (canProceed) {
      const { interconnects, projectBomItems, projectSummary } = this.bomDetailsToSave;
      const { checkExistingEmail } = this.props;
      checkExistingEmail({
        emailObject: { email: get(this.state.systemRequirements.customerDetails, 'email') },
        successCbk: result => {
          const { lead_in_progress, same_installer } = result;
          if (lead_in_progress && same_installer) {
            this.setState({
              existingEmailCheckError: true,
              existingEmailCheckErrorMessage: `The email ID entered by you is already associated with an existing lead in your account`
            });
          } else {
            this.setState({
              existingEmailCheckError: false,
              existingEmailCheckErrorMessage: ``
            });
            this.handleSave(interconnects, projectBomItems, projectSummary, true, 'proceed');
          }
        }
      });
    }
  };

  render() {
    const {
      classes,
      isMobile,
      currentlySending,
      currentlySendingEmailValidation,
      currentlySendingRecentPVDetails,
      history,
      message,
      messageType,
      bomDetailsArray,
      leadId,
      pvManufacturersList,
      recentlyUsedPVManufacturersList
    } = this.props;
    const {
      projectType,
      systemRequirements,
      progressValue,
      systemDetails,
      isCalculateEnabled,
      errorData,
      showTemplatePopup,
      navigateToOutput,
      showCustomerDetailsPopup,
      existingEmailCheckError,
      existingEmailCheckErrorMessage,
      isNPSPopupShown,
      openNPSPopup,
      isSolargrafAvailable
    } = this.state;
    if (navigateToOutput) {
      return (
        <Fragment>
          <BOMCalculatorOutput
            handleSave={this.handleSave}
            inputState={this.state}
            history={history}
            handleBack={this.handleBack}
            openBomSavePopup={this.state.openBomSavePopup}
            handlePopupOpenClose={this.handlePopupOpenClose}
            bomDetailsArray={bomDetailsArray}
            leadId={leadId}
            updateInputState={this.updateInputState}
            onBOMChange={() => {
              this.setState({ isBOMChanged: true });
            }}
            isBOMChanged={this.state.isBOMChanged}
            existingEmailCheckError={existingEmailCheckError}
            existingEmailCheckErrorMessage={existingEmailCheckErrorMessage}
            isNpsShownLead={isNPSPopupShown}
            handleNpsPopupOpenClose={this.handleNpsPopupOpenClose}
            openNPSPopup={openNPSPopup}
            onSubmitNpsFeedback={this.onSubmitNpsFeedback}
          />
          <SystemRequirements
            handleOnChange={this.handleOnChange}
            handleMoreDetailsToggle={this.handleMoreDetailsToggle}
            handleCustomerDetails={this.handleCustomerDetails}
            handleExistingSystemSelect={this.handleExistingSystemSelect}
            addMoreToggle={systemRequirements.addMoreToggle}
            projectType={this.state.projectType}
            systemRequirements={systemRequirements}
            validateSystemRequirementsInput={this.validateSystemRequirementsInput}
            errorData={errorData}
            disabled={leadId !== ''}
            isBOMCalculatorOutput
            showCustomerDetailsPopup={showCustomerDetailsPopup}
            handleCloseCustomerDetailsPopup={() => this.handleCustomerDetailsPopup(false)}
            handleSaveCustomerDetailsPopup={this.handleSaveCustomerDetailsPopup}
            from={this.state.from}
          />
        </Fragment>
      );
    }
    return (
      <Box className={clsx(isMobile ? classes.rootMobile : classes.root)}>
        <Box className={classes.headerWrapper}>
          <PageHeader className={classes.fontSizeHeader} text="BOM Calculator" />
          {this.state.from !== 'lead' &&
          isSolargrafAvailable && <BOMImportDesignButton msgToSend="showImportDesignPopup" />}
        </Box>
        {this.state.from !== 'lead' && <LinearProgress progressValue={this.state.progressValue} />}
        {this.state.from !== 'lead' && (
          <ProjectType projectType={projectType} handleRadioChange={this.handleRadioChange} disabled={leadId !== ''} />
        )}
        {progressValue >= 33 &&
        this.state.from !== 'lead' && (
          <SystemRequirements
            handleOnChange={this.handleOnChange}
            handleMoreDetailsToggle={this.handleMoreDetailsToggle}
            handleCustomerDetails={this.handleCustomerDetails}
            handleExistingSystemSelect={this.handleExistingSystemSelect}
            addMoreToggle={systemRequirements.addMoreToggle}
            projectType={this.state.projectType}
            systemRequirements={systemRequirements}
            validateSystemRequirementsInput={this.validateSystemRequirementsInput}
            errorData={errorData}
            disabled={leadId !== ''}
            from={this.state.from}
            isBOMCalculatorOutput={false}
          />
        )}
        {progressValue >= 66 && (
          <SystemDetails
            handleOnChange={this.handleOnChange}
            handleInterconnectNameChange={this.handleInterconnectNameChange}
            projectType={projectType}
            systemDetails={systemDetails}
            addCommercialInterconnect={this.addCommercialInterconnect}
            systemRequirements={systemRequirements}
            errorData={errorData}
            from={this.state.from}
            isSolargrafAvailable={isSolargrafAvailable}
            handleAddRemoveInterConnect={this.handleAddRemoveInterConnect}
            setResidentialLabelsToCheck={this.setResidentialLabelsToCheck}
            addErrorObjectCommercialInterconnect={this.addErrorObjectCommercialInterconnect}
            pvManufacturersList={pvManufacturersList}
            recentlyUsedPVManufacturersList={recentlyUsedPVManufacturersList}
            openDidntFindModulePopup={this.state.openDidntFindModulePopup}
            handleCustomPanelConfigurations={this.handleCustomPanelConfigurations}
          />
        )}
        <ActionButtons isCalculateEnabled={isCalculateEnabled} handleClick={() => this.handleCalculate()} />
        <TemplatePopupAllTemplates
          open={showTemplatePopup}
          handleClose={() => this.setState({ showTemplatePopup: false })}
          handleContinue={this.handleContinue}
          stateValues={this.state}
          source="input"
        />
        {(currentlySending || currentlySendingEmailValidation || currentlySendingRecentPVDetails) && (
          <Backdrop className={classes.backdrop} open>
            <CircularProgress color="inherit" />
          </Backdrop>
        )}
        {message && messageType && <Snackbar severity={messageType} message={message} />}
        {existingEmailCheckError &&
        existingEmailCheckErrorMessage && <Snackbar severity={'error'} message={existingEmailCheckErrorMessage} />}
      </Box>
    );
  }
}

const mapStateToProps = state => ({
  isMobile: state.appReducer.isMobileView,
  currentlySending: state.bomInputReducer.currentlySending,
  currentlySendingEmailValidation: state.bomInputReducer.currentlySendingEmailValidation,
  currentlySendingRecentPVDetails: state.bomInputReducer.currentlySendingRecentPVDetails,
  message: state.bomInputReducer.message,
  messageType: state.bomInputReducer.messageType,
  bomDetailsArray: state.bomInputReducer.bomDetailsArray,
  leadJourneyObject: state.bomInputReducer.leadJourneyObject,
  leadId: state.bomInputReducer.leadId,
  userDetails: state.appReducer.userDetails,
  pvManufacturersList: state.bomInputReducer.pvManufacturersList,
  recentlyUsedPVManufacturersList: state.bomInputReducer.recentlyUsedPVManufacturersList,
  pvModelsList: state.bomInputReducer.pvModelsList,
  existingSystemDetails: state.bomInputReducer.existingSystemDetails
});

const mapDispatchToProps = dispatch => ({
  setBOMInputDetails: payload => dispatch(setBOMInputDetails(payload)),
  getAllTemplates: payload => dispatch(getAllTemplates(payload)),
  connectBOMToLead: payload => dispatch(connectBOMToLead(payload)),
  getLeadBOMDetails: payload => dispatch(getLeadBOMDetails(payload)),
  getPVManufacturers: () => dispatch(getPVManufacturers()),
  getRecentlyUsedPVManufacturersModules: payload => dispatch(getRecentlyUsedPVManufacturersModules(payload)),
  getPVModels: payload => dispatch(getPVModels(payload)),
  calculateResidentialBOMOutput: payload => dispatch(calculateResidentialBOMOutput(payload)),
  checkExistingEmail: payload => dispatch(checkExistingEmail(payload)),
  getExistingSystemDetails: payload => dispatch(getExistingSystemDetails(payload)),
  getNpsShown: payload => dispatch(getNpsShown(payload)),
  setNpsFeedback: payload => dispatch(setNpsFeedback(payload))
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(BOMCalculatorInput));
