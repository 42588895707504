import React from 'react';
import { connect } from 'react-redux';
import I18n from 'e_i18n';
import { setToken, getToken } from '../../utils/helper';

import { setMobileView, setEnlightenManagerToken } from './actions';
import getMoment from '../../components/common/moment';

class LibraryLayout extends React.Component {
  UNSAFE_componentWillMount() {
    window.addEventListener('resize', this.handleWindowSizeChange);
  }

  handleWindowSizeChange = () => {
    const isMobileView = window.innerWidth <= 1200;
    this.props.setMobileView(isMobileView);
  };

  componentDidMount = () => {
    this.handleWindowSizeChange();
    this.updateEnlightenManagerToken();
  };

  componentDidUpdate() {
    I18n.defaultLocale = 'en';
    I18n.locale = this.props.locale || '';
    I18n.fallbacks = true;
    getMoment(this.props.locale);
    this.updateEnlightenManagerToken();
  }

  updateEnlightenManagerToken = () => {
    const { enlightenToken, enlightenManagerToken } = this.props;
    const token = getToken() || enlightenToken;
    if (!enlightenManagerToken && token) {
      this.props.setEnlightenManagerToken(token);
      setToken(token);
    }
  };

  render() {
    const { component: Component, enlightenManagerToken, ...remainingProps } = this.props;
    return <Component {...remainingProps} />;
  }
}

const mapDispatchToProps = dispatch => ({
  setMobileView: isMobileView => dispatch(setMobileView(isMobileView)),
  setEnlightenManagerToken: tokenVal => dispatch(setEnlightenManagerToken(tokenVal))
});

const mapStateToProps = state => ({
  isMobileView: state.defaultReducer.isMobileView,
  enlightenManagerToken: state.defaultReducer.enlightenManagerToken
});

export default connect(mapStateToProps, mapDispatchToProps)(LibraryLayout);
