import React from 'react';
import { Dialog, DialogContent, DialogTitle, DialogActions, Button, Typography, Link } from '@material-ui/core';
import { connect } from 'react-redux';
import { get } from 'lodash';

import { makeStyles } from '@material-ui/core/styles';
import settings from '../../../utils/settings';
import { trackEvents } from '../../../utils/helper';

const styles = makeStyles(theme => ({
  titleWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderBottom: '1px solid #E1E1E1'
  },
  button: {
    textTransform: 'none',
    width: theme.spacing(11),
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.white,
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.white
    }
  },
  buttonWrapper: {
    padding: theme.spacing(0, 3, 2),
    justifyContent: 'flex-start'
  },
  paddingDialogContent: {
    padding: theme.spacing(2, 3)
  },
  label: {
    fontSize: 16,
    color: '#4A4A4A'
  },
  colorLeadId: {
    color: '#4F89F4'
  }
}));

const BomSavePopup = props => {
  const { open, handleClose, leadJourneyObject, handlePopupOpenClose, userDetails } = props;
  const classes = styles();
  const leadID = get(leadJourneyObject, 'lead_id');
  const journeyType = get(leadJourneyObject, 'journey_type');
  const { company_id } = userDetails;

  return (
    <Dialog
      open={open}
      aria-labelledby="template"
      aria-describedby="template-description"
      onClose={handleClose}
      fullWidth
    >
      <DialogTitle id="template" disableTypography className={classes.titleWrapper}>
        <Typography variant="h6">BOM Saved Successfully</Typography>
      </DialogTitle>
      <DialogContent className={classes.paddingDialogContent}>
        <Typography className={classes.label}>
          Your BOM calculation has been successfully saved with the Lead :{' '}
          <span>
            <Link
              className={classes.colorLeadId}
              target="_parent"
              href={settings.enlightenUrl(
                `manager/dashboard/${company_id}/leads/${leadID}?journey_type=${journeyType}`
              )}
            >
              {leadID}
            </Link>
          </span>
        </Typography>
      </DialogContent>
      <DialogActions className={classes.buttonWrapper}>
        <Button
          className={classes.button}
          onClick={() => {
            trackEvents('triggerBOM', { menuValue: 'BOM_saved_successfully' });
            handlePopupOpenClose('openBomSavePopup', false);
          }}
          variant="outlined"
        >
          Okay
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const mapStateToProps = state => ({
  leadJourneyObject: state.bomInputReducer.leadJourneyObject,
  userDetails: state.appReducer.userDetails
});

export default connect(mapStateToProps)(BomSavePopup);
