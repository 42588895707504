import React, { Component, Fragment } from 'react';
import {
  Box,
  IconButton,
  InputBase,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  withStyles
} from '@material-ui/core';
import clsx from 'clsx';
import { get } from 'lodash';
import styles from '../../../BOMCalculatorOutput/BillOfMaterial/styles';
import theme from '../../../../theme';
import AddBoxIcon from '@material-ui/icons/AddBox';
import IndeterminateCheckBoxIcon from '@material-ui/icons/IndeterminateCheckBox';
import { getTableHeading } from './helper';
class CommonTemplatesTables extends Component {
  state = {};

  getNonSoftCosts = (row, index) => {
    const {
      collapseItems,
      classes,
      handleExpandCollapseTableRow,
      category,
      handleInputPriceChanges,
      isEditClicked
    } = this.props;
    const { key, label, subCategoryList } = row;
    const isCollapsed = get(collapseItems, key);
    return (
      <Fragment key={key + index}>
        <TableRow>
          <TableCell className={classes.borderRightAndWidth} component="th" scope="row">
            <Box className={classes.firstColumnWrapper}>
              <IconButton
                onClick={() => {
                  handleExpandCollapseTableRow(key);
                }}
                className={classes.styleCollapseIcon}
              >
                {!isCollapsed ? <AddBoxIcon color="primary" /> : <IndeterminateCheckBoxIcon color="primary" />}
              </IconButton>
              <Typography
                className={clsx(classes.firstColumnHeading, isCollapsed && classes.firstColumnHeadingSelected)}
              >
                {label}
              </Typography>
            </Box>
          </TableCell>
          <TableCell className={classes.templatesSecondColumnPaddingLeft}>-</TableCell>
        </TableRow>
        {isCollapsed &&
          subCategoryList.map((value, index) => {
            const { name, sku, price } = value;
            return (
              <TableRow className={classes.tableRowBackground} key={'NonSoftCost-Price-' + index}>
                <TableCell className={classes.borderRightAndWidth} component="th" scope="row">
                  <Box className={classes.subCategoryMainWrapper}>
                    <Typography className={classes.font13}>{name}</Typography>
                    {sku && <Typography className={classes.subCategoryStyleSKU}>SKU : {sku}</Typography>}
                  </Box>
                </TableCell>
                <TableCell className={classes.templatesSecondColumnPaddingLeft}>
                  {!isEditClicked ? (
                    <Typography>${price}</Typography>
                  ) : (
                    <Fragment>
                      <Box className={classes.priceInputWrapper}>
                        {category !== 'NON_ENPHASE_EQUIPMENT_STRUCTURAL' && <Typography>$</Typography>}
                        <InputBase
                          className={classes.inputBaseMarginLeftRight}
                          value={price}
                          onChange={event =>
                            handleInputPriceChanges(event, {
                              category,
                              subCategory: key,
                              name
                            })}
                        />

                        {category === 'NON_ENPHASE_EQUIPMENT_STRUCTURAL' && <Typography>/Wdc</Typography>}
                      </Box>
                    </Fragment>
                  )}
                </TableCell>
              </TableRow>
            );
          })}
      </Fragment>
    );
  };

  getSoftCosts = (row, index) => {
    const { classes, category, handleInputPriceChanges, isEditClicked } = this.props;
    const { key, subCategoryList } = row;
    return (
      <Fragment key={key + index}>
        {subCategoryList.map((value, index) => {
          const { name, price } = value;
          return (
            <TableRow key={'SoftCost-Price-' + index}>
              <TableCell className={classes.borderRightAndWidth} component="th" scope="row">
                <Typography className={classes.firstColumnHeadingOtherSoftCosts}>{name}</Typography>
              </TableCell>
              <TableCell className={classes.templatesSecondColumnPaddingLeft}>
                {!isEditClicked ? (
                  <Typography>${price}</Typography>
                ) : (
                  <Fragment>
                    <Box className={classes.priceInputWrapper}>
                      <Typography>$</Typography>
                      <InputBase
                        className={classes.inputBaseMarginLeftRight}
                        value={price}
                        onChange={event =>
                          handleInputPriceChanges(event, {
                            category,
                            subCategory: key,
                            name
                          })}
                      />
                    </Box>
                  </Fragment>
                )}
              </TableCell>
            </TableRow>
          );
        })}
      </Fragment>
    );
  };

  getPriceTextTableHeading = category => {
    switch (category) {
      case 'NON_ENPHASE_EQUIPMENT_STRUCTURAL':
        return 'COST ($/WDC)';
      case 'SOFT_COST':
        return 'COST ($)';
      default:
        return 'COST ($/UNIT)';
    }
  };

  render() {
    const { classes, bomItemsArray, category } = this.props;
    return (
      <Fragment>
        <Paper square className={classes.mainWrapperTemplates}>
          <Box className={classes.mainTableWrapper}>
            <Box className={classes.headingBox}>{get(getTableHeading(), category)}</Box>
          </Box>
          <TableContainer component={Paper} className={classes.tableWrapperTemplates}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead className={classes.tableHeader}>
                <TableRow>
                  <TableCell
                    className={clsx(classes.borderRightAndWidth, classes.headerContainer)}
                    classes={{ root: classes.styleTableHeaderContentTemplate }}
                  >
                    ITEMS
                  </TableCell>
                  <TableCell
                    className={classes.headerContainer}
                    classes={{ root: classes.styleTableHeaderContentTemplate }}
                  >
                    {this.getPriceTextTableHeading(category)}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {bomItemsArray.map((row, index) => {
                  return category !== 'SOFT_COST' ? this.getNonSoftCosts(row, index) : this.getSoftCosts(row, index);
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Fragment>
    );
  }
}

export default withStyles(styles(theme, true))(CommonTemplatesTables);
