const styles = theme => ({
  projectTypePaper: {
    padding: 0,
    marginTop: theme.spacing(2),
    boxShadow: '0px 0px 8px #00000029'
  },
  chooseProjectTypeText: {
    color: theme.palette.primary.main,
    padding: theme.spacing(3),
    fontWeight: 'bold'
  },
  interconnectIconWrapper: {
    display: 'flex',
    padding: theme.spacing(3),
    alignItems: 'center',
    borderTop: '1px solid #b2c7d5b3'
  },
  interconnectTextField: {
    color: '#121212',
    fontWeight: 'bold',
    fontSize: 20
  },
  interconnectText: {
    marginLeft: theme.spacing(1.5)
  },
  styleDragIcon: {
    fontSize: 20
  },
  systemDetailsGridWrapper: {
    width: '100%',
    padding: theme.spacing(0, 3, 4),
    display: 'grid',
    gridRowGap: theme.spacing(2.5),
    gridColumnGap: theme.spacing(2.5),
    gridTemplateColumns: 'repeat(auto-fill, minmax(440px, 1fr))',
    [theme.breakpoints.down('xs')]: {
      gridTemplateColumns: 'repeat(auto-fill, minmax(100%, 1fr))'
    }
  },
  minHeight64: {
    minHeight: theme.spacing(8)
  },
  addInterconnectPointer: {
    cursor: 'pointer',
    borderTop: '1px solid #b2c7d5b3'
  },
  closeIconDimension: {
    fontSize: 24
  },
  closeIconWrapper: {
    marginRight: theme.spacing(1),
    marginLeft: 'auto',
    paddingTop: 0,
    paddingBottom: 0,
    '&:hover': {
      backgroundColor: theme.palette.secondary.main
    }
  },
  importDesign: {
    display: 'flex',
    margin: theme.spacing(2.5),
    textTransform: 'none'
  },
  headerBox: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  downloadIcon: {
    width: theme.spacing(1.5),
    height: theme.spacing(1.5)
  }
});

export default styles;
