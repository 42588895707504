import { assign } from 'lodash';
import { getValue } from '../utils/localStorage';

const development = {
  api: 'https://enlighten-qa4.enphaseenergy.com',
  enlighten_url: 'https://enlighten-qa4.enphaseenergy.com',
  bom_api_url: 'https://bom-api-dev.qa-enphaseenergy.com',
  digital_core_url: 'https://dc.qa-enphaseenergy.com'
};

const test = {
  api: 'https://enlighten-qa4.enphaseenergy.com',
  enlighten_url: 'https://enlighten-qa4.enphaseenergy.com',
  bom_api_url: 'https://bom-api-dev.qa-enphaseenergy.com',
  digital_core_url: 'https://dc.qa-enphaseenergy.com'
};

const qa2 = {
  api: 'https://qa2.enphaseenergy.com',
  enlighten_url: 'https://qa2.enphaseenergy.com',
  bom_api_url: 'https://bom-api-stg.qa-enphaseenergy.com',
  digital_core_url: 'https://stgdc.qa-enphaseenergy.com',
  drs_api_url: 'https://drs-api-stg.qa-enphaseenergy.com',
};

const qa4 = {
  api: 'https://enlighten-qa4.enphaseenergy.com',
  enlighten_url: 'https://enlighten-qa4.enphaseenergy.com',
  bom_api_url: 'https://bom-api-dev.qa-enphaseenergy.com',
  digital_core_url: 'https://dc.qa-enphaseenergy.com',
  drs_api_url: 'https://drs-api-dev.qa-enphaseenergy.com',

};

const production = {
  api: 'https://enlighten.enphaseenergy.com',
  enlighten_url: 'https://enlighten.enphaseenergy.com',
  digital_core_url: 'https://dc.enphaseenergy.com',
  bom_api_url: 'https://bom-api.enphaseenergy.com',
  drs_api_url: 'https://drs-api.enphaseenergy.com',
};

const DEFAULT = {};

const CONFIG = {
  development,
  production,
  test,
  qa2,
  qa4
};

const getConfig = env => {
  let hostname;
  if (typeof window !== 'undefined') {
    hostname = window.location.hostname;
    if (hostname.search('enlighten.enphaseenergy') >= 0 || hostname.search('bom.enphaseenergy.com') >= 0) {
      env = 'production';
    } else if (hostname.search('qa2.enphaseenergy') >= 0 || hostname.search('bomstg.qa-enphaseenergy') >= 0) {
      env = 'qa2';
    } else if (hostname.search('enlighten-qa4') >= 0 || hostname.search('bomdev.qa-enphaseenergy') >= 0) {
      env = 'qa4';
    } else if (window.location.port === '7000') {
      env = getValue('development_env') || 'development';
    } else if (window.location.port === '8888') {
      env = 'development';
    } else {
      env = 'development';
    }
  } else if (env === 'test') {
    env = test;
  }
  console.log('App running environment is:', env);
  const envConfig = CONFIG[env];
  if (envConfig) {
    return assign({}, DEFAULT, envConfig);
  }
  throw new Error('Unknown environment : ' + env);
};

export default getConfig;
