import { findIndex, get, isEmpty } from 'lodash';
import { commercial, IQ6Plus, panelMakers } from '../BOMCalculator/constants';
import { getStorageSizeDropDown } from '../BOMCalculator/helper';
import { categories, inputFields, csvOutputFields, priceKeys, sysSizeAC, sysSizeDC } from './constants';

const isBomExistingAlready = (array, findObject) => {
  const matchingIndex = findIndex(array, value => value.name === findObject.name);
  return matchingIndex !== -1;
};

export const getNetPriceAndDiscount = (state, systemSizeDc) => {
  const { currentInterconnects, currentProjectBOMItems } = state;
  let netPrice = 0;
  let totalDiscountArray = [];
  let totalDiscountSum = 0;
  currentInterconnects.forEach(currentInterconnect => {
    const currentBomItems = get(currentInterconnect, 'bom_items', []);
    currentBomItems.forEach(bomItem => {
      const { net_price } = bomItem;
      if (!isBomExistingAlready(totalDiscountArray, bomItem)) {
        totalDiscountArray.push(bomItem);
      }
      netPrice += net_price;
    });
  });
  currentProjectBOMItems.forEach(currentBomItem => {
    const { net_price } = currentBomItem;
    if (!isBomExistingAlready(totalDiscountArray, currentBomItem)) {
      totalDiscountArray.push(currentBomItem);
    }
    netPrice += net_price;
  });
  netPrice = Math.round(netPrice);
  totalDiscountArray.forEach(bomItem => {
    const { discount_amount } = bomItem;
    totalDiscountSum += discount_amount ? Number(discount_amount) : 0;
  });
  return { netPrice, totalDiscountSum };
};

const getFieldFromAPIKey = (fields, apiKey) => {
  if (fields.length === 0) {
    return {};
  }
  return fields.find(field => field.apiKey === apiKey);
};

// if a lead has BOM associated then this function will be
// called and it will map the lead details api response
// to the input state object
export const getInputData = (response, companyId, from) => {
  const inputData = {};
  const projectType = (get(response, 'lead_details.project_type', 'RESIDENTIAL') || 'RESIDENTIAL').toLowerCase();
  if (!isEmpty(projectType)) {
    // variables
    const fields = inputFields[projectType];
    const interconnects = get(response, 'interconnects', []);
    const leadDetails = get(response, 'lead_details', {});
    const inputInterconnects = [];

    // initialize
    inputData['systemDetails'] = {};
    inputData['systemRequirements'] = {};
    inputData['projectType'] = projectType;
    inputData['companyId'] = companyId;
    inputData['selectedTemplate'] = get(response, 'pricing_template_id', '');
    inputData['from'] = from;
    inputData['progressValue'] = 95;
    inputData['leadAssociated'] = true;

    for (const i in interconnects) {
      const interconnect = interconnects[i];
      const inputInterconnect = {};
      for (const key in interconnect) {
        const field = getFieldFromAPIKey(fields, key);
        if (field) {
          let value = '';
          if (field.boolean) {
            value = interconnect[key] === 'true' || interconnect[key] === true ? 'Yes' : 'No';
          } else {
            value = interconnect[key] ? interconnect[key].toString() : interconnect[key];
          }
          if (field.key === 'pvManufacturer' || field.key === 'pvModel') {
            inputInterconnect[field.key] = value === null ? 'Custom' : value;
          } else if (field.key === 'pvPanelType' && value === 'ACM') {
            inputInterconnect[field.key] = 'AC Module';
          } else if (field.key === 'acModule') {
            let val = '';
            for (let key in panelMakers) {
              if (panelMakers[key] === value) {
                val = key;
              }
            }
            inputInterconnect[field.key] = val;
          } else if (key === 'storage_size') {
            inputInterconnect[field.key] = interconnect[key] === null ? '' : getStorageSizeDropDown(interconnect[key]);
          } else if (key === 'existing_micros' && !isEmpty(interconnect[key])) {
            inputInterconnect['existingMicroinverter'] =
              Object.keys(interconnect[key])[0] === 'IQ6P' ? IQ6Plus : Object.keys(interconnect[key])[0];
            inputInterconnect['existingMicroinverterCount'] =
              Object.values(interconnect[key])[0] === 0 ? '0' : Object.values(interconnect[key])[0];
          } else if (key === 'cell_modem_required') {
            inputInterconnect[field.key] = interconnect[key] === 'true' || interconnect[key] === true ? 'No' : 'Yes';
          } else if (key === 'percentage_of_unused_cable_drops') {
            inputInterconnect[field.key] = interconnect[key] === null ? '' : String(interconnect[key]);
          } else {
            inputInterconnect[field.key] = value || '';
          }
        }
      }
      inputInterconnect['id'] = Number(i);
      inputInterconnects.push(inputInterconnect);
    }
    inputData['systemDetails'][projectType] = inputInterconnects;

    const customerEmail = get(leadDetails, 'email', '');
    if (!isEmpty(customerEmail)) {
      inputData['systemRequirements'] = {
        addMoreToggle: true,
        requirement: get(leadDetails, 'requirement', ''),
        customerDetails: {
          name: `${get(leadDetails, 'first_name', '')} ${get(leadDetails, 'last_name', '')}`,
          email: get(leadDetails, 'email', ''),
          mobile: get(leadDetails, 'mobile', ''),
          address: [
            get(leadDetails, 'address.address1', ''),
            get(leadDetails, 'address.address2', ''),
            get(leadDetails, 'address.city', ''),
            get(leadDetails, 'address.state', ''),
            get(leadDetails, 'address.zip', ''),
            get(leadDetails, 'address.country', '')
          ].join(', '),
          zipCode: get(leadDetails, 'address.zip', ''),
          existingSystem: {},
          addressObject: get(leadDetails, 'address', {}),
          sunlightBackupRequired: get(interconnects[0], 'sunlight_backup_required', false),
          loadControllersRequired: get(interconnects[0], 'load_controllers_required', false) || false
        }
      };
    } else {
      inputData['systemRequirements'] = {
        addMoreToggle: false,
        requirement: get(leadDetails, 'requirement', ''),
        customerDetails: {
          name: '',
          email: '',
          mobile: '',
          address: '',
          zipCode: '',
          existingSystem: {},
          addressObject: {}
        },
        sunlightBackupRequired: get(interconnects[0], 'sunlight_backup_required', false),
        loadControllersRequired: get(interconnects[0], 'load_controllers_required', false) || false
      };
    }
    return inputData;
  }
  return {};
};

/***** CSV  *****/

// these values will be added in the last line of CSV
const overallProjectFields = {};

const resetOverallProjectFields = includePrices => {
  overallProjectFields['quantity'] = '';
  if (includePrices) {
    overallProjectFields['distributor_price'] = '';
    overallProjectFields['net_price'] = 0;
    overallProjectFields['discount_amount'] = 0;
    overallProjectFields['total_price'] = 0;
    overallProjectFields['price_per_wdc'] = 0;
  }
};

// convert string to number
// if NAN return 0
const stoI = value => {
  return !isNaN(Number(value)) ? Number(value) : 0;
};

const removeComma = val => {
  if (typeof val === 'string') {
    return val.replace(',', '');
  }
  return val;
};

const getValue = (val, key) => {
  if (priceKeys.includes(key)) {
    return stoI(val).toFixed(2);
  }
  return val;
};

// sum of array values by key
const getArraySumByKey = (key, array) => {
  if (array.length === 0) {
    return 0;
  }
  let sum = 0;
  for (let i in array) {
    sum += stoI(array[i][key]);
  }
  return key === 'price_per_wdc' ? stoI(sum.toFixed(4)) : stoI(sum.toFixed(2));
};

const numberFormatter = (val, key) => {
  if (key.toString() === sysSizeAC || key.toString() === sysSizeDC) {
    return val.toFixed(2);
  } else {
    return removeComma(val);
  }
};

// array should be array of objects
// input [{a: 2, b: 1, c: 3}, {a: 4, b: 0, c: 4}]
// output
// a, 2, 4
// b, 1, 0
// it will skip those properties which are not in fields
// like c is skipped in above e.g.
// add: boolean, if you want to sum all the column values
// for a row and add a another column for the sum
// THIS FUNCTION IS USED FOR TOP 2 SECTION IN CSV
// i.e. INPUTS AND OUTPUTS SECTION
const convertArrayToCSV = (array, fields, add = false) => {
  let CSV = '';
  if (array.length === 0) {
    return CSV;
  }
  for (let ind in fields) {
    const field = fields[ind];
    CSV += removeComma(field.name);
    let sum = 0;
    for (let i = 0; i < array.length; i++) {
      CSV += ', ';
      CSV +=
        array[i][field.key] !== null && array[i][field.key] !== undefined
          ? numberFormatter(array[i][field.key], field.key)
          : '';
      if (field.unit && array[i][field.key]) {
        CSV += field.unit;
      }
      sum += !isNaN(Number(array[i][field.key])) ? Number(array[i][field.key]) : 0;
    }
    if (add) {
      CSV += ', ';
      if (field.canAdd) {
        if (field.key.toString() === sysSizeAC || field.key.toString() === sysSizeDC) {
          CSV += sum.toFixed(2);
        } else {
          CSV += sum;
        }
        if (field.unit) {
          CSV += field.unit;
        }
      } else {
        CSV += '-';
      }
    }
    CSV += '\r\n';
  }
  return CSV;
};

// array should be array of objects
// input [{a: 2, b: 1, name: 's'}, {a: 4, b: 0, name: 'r'}]
// output
// section, 3, 4
// s, 2, 4
// r, 1, 0
// it can skip some property based on some validations
// like c is skipped in above e.g.
// THIS FUNCTION IS USED FOR SECTION 3,4,5 and 6 IN CSV
const convertArrayToCSV2 = (
  section,
  array,
  fields,
  add = false,
  interconnectsCount = 0,
  interconnectKey,
  storeDistributorToggle = false
) => {
  let CSV = '';
  if (array.length === 0) {
    return CSV;
  }

  // Adding sub category and sum of the values of few properties
  // like INVERTES, , , , TOTAL_PRICE_SUM, DISCOUNT_SUM, NET_PRICE_SUM, ,
  CSV = section;
  // skip few columns like interconnect 1, 2, etc
  for (let j = 1; j <= interconnectsCount; j++) {
    CSV += ', ';
  }
  for (let ind in fields) {
    const field = fields[ind];
    if (!field.categorySum) {
      CSV += ', ';
    } else {
      CSV += ', ' + getArraySumByKey(field.key, array);
    }
  }
  CSV += '\r\n';

  for (let i = 0; i < array.length; i++) {
    CSV += removeComma(array[i].name);

    // Adding interconnect values first like interconnect 1 quantity,interconnect 2 quantity etc.
    for (let j = 1; j <= interconnectsCount; j++) {
      CSV += ', ';
      CSV +=
        array[i][interconnectKey + 'i' + j] !== null && array[i][interconnectKey + 'i' + j] !== undefined
          ? array[i][interconnectKey + 'i' + j]
          : '';
    }
    for (let ind in fields) {
      const field = fields[ind];
      const key = storeDistributorToggle ? (field.alternateKey ? field.alternateKey : field.key) : field.key;
      CSV += ', ';
      CSV += array[i][key] !== null && array[i][key] !== undefined ? getValue(array[i][key], key) : '';
    }
    CSV += '\r\n';
  }
  return CSV;
};

const filterBySubCategory = (items, subCategory) => {
  if (items.length === 0) {
    return null;
  }
  return items.filter(item => item.sub_category === subCategory);
};

// THIS IS USED FOR ADDING COLUMN NAMES IN TOP 2 SECTIONS
const addIOFieldsInCSV = (section, array, helperParams = {}) => {
  const { add = false, interconnectNames } = helperParams;
  let CSV = section;
  for (let i = 1; i <= array.length; i++) {
    CSV += `, ${interconnectNames[i - 1]}`;
  }
  if (add) {
    CSV += ', Project';
  }
  CSV += '\r\n';
  return CSV;
};

// THIS IS USED FOR ADDING COLUMN NAMES IN SECTION 3,4,5 and 6
const addBOMFieldsinCSV = (fields, interconnectsCount, fieldName, interconnectNames) => {
  let CSV = '';

  // Currently Interconnect columns like Interconnect 1 quantity, Interconnect 2 quantity
  // is required in all 4 tables
  for (let i = 1; i <= interconnectsCount; i++) {
    CSV += `, ${interconnectNames[i - 1]} ` + fieldName;
  }
  for (let ind in fields) {
    CSV += ', ' + fields[ind].name;
  }
  CSV += '\r\n';
  return CSV;
};

// THIS IS USED FOR ADDING CATEGORY NAME AND SUM OF THE VALUES OF FEW PROPERTIES
// like Enphase Equipment, , , , TOTAL_PRICE_SUM, DISCOUNT_SUM, NET_PRICE_SUM, ,
const addSectionNameWithSumInCSV = (categoryName, key, array, fields, interconnectsCount) => {
  let CSV = '';

  // for SOFT_COST , we have only one sub category which is SOFT_COST only
  // so it will be added to CSV in convertArrayToCSV2 function
  if (key !== 'SOFT_COST') {
    CSV += categoryName;

    // skip few columns like Interconnect 1, 2, etc.
    for (let j = 1; j <= interconnectsCount; j++) {
      CSV += ', ';
    }
    for (let ind in fields) {
      const field = fields[ind];

      // skip those columns for which category sum is not required
      if (!field.categorySum) {
        CSV += ', ';
      } else {
        const sum = getArraySumByKey(field.key, array);
        CSV += ', ' + sum;
        overallProjectFields[field.key] += sum;
      }
    }
    CSV += '\r\n\r\n';
  } else if (key === 'SOFT_COST') {
    for (let ind in fields) {
      if (fields[ind].categorySum) {
        overallProjectFields[fields[ind].key] += getArraySumByKey(fields[ind].key, array);
      }
    }
  }
  return CSV;
};

const getSystemSizeDC = interconnects => {
  if (interconnects.length === 0) {
    return 0;
  }
  let systemSizeDC = 0;
  for (let i in interconnects) {
    const interconnect = interconnects[i];
    systemSizeDC += interconnect.summary_params.system_size_dc ? interconnect.summary_params.system_size_dc : 0;
  }
  // convert to W from KW
  return systemSizeDC * 1000;
};

// THIS IS USED TO ADD COMPLETE SECTIONS LIKE ENPHASE EQUIPMENT
const addSection = (
  includePrices,
  category,
  items,
  projectType,
  interconnectsCount,
  fieldName,
  interconnectKey,
  storeDistributorToggle = false,
  interconnectNames
) => {
  const fields = getFilteredFields(categories[category].fields[projectType], includePrices);
  const subCategories = categories[category].subCategories;
  let CSV = '';
  CSV += addBOMFieldsinCSV(fields, interconnectsCount, fieldName, interconnectNames);
  CSV += addSectionNameWithSumInCSV(categories[category].name, category, items, fields, interconnectsCount);
  if (items.length > 0) {
    for (let key in subCategories) {
      CSV += convertArrayToCSV2(
        subCategories[key],
        filterBySubCategory(items, key),
        fields,
        true,
        interconnectsCount,
        interconnectKey,
        storeDistributorToggle
      );
      CSV += '\r\n';
    }
  }
  return CSV;
};

const getFilteredFields = (list = [], includePrices = false) => {
  const includePricesList = [
    'store_price',
    'distributor_price',
    'net_price',
    'discount_amount',
    'total_price',
    'price_per_wdc'
  ];
  return list.filter(item => {
    if (!includePrices && includePricesList.includes(item.key)) return false;
    return true;
  });
};

// THIS FUNCTION IS USED AS A PREPROCESSING FUNCTION FOR SECTION 3 and 4
// IT WILL RETURN THE DATA IN THE REQUIRED FORMAT FROM STATE
const getEquipments = (
  includePrices,
  interconnects,
  projectType,
  sectionKey,
  systemSizeDC,
  interconnectKey,
  storeDistributorToggle = false
) => {
  if (interconnects.length === 0) {
    return [];
  }
  let itemsMap = {};
  const fields = getFilteredFields(categories[sectionKey].fields[projectType], includePrices);
  // Combining the data of different interconnects into a single array
  for (let i in interconnects) {
    const bomItems = interconnects[i].bom_items;
    for (let j in bomItems) {
      const item = bomItems[j];
      if (item.category !== sectionKey) {
        continue;
      }
      if (!itemsMap[item.name]) {
        itemsMap[item.name] = {};
      }
      itemsMap[item.name][interconnectKey + 'i' + (stoI(i) + 1)] = item[interconnectKey];
      for (let ind in fields) {
        const field = fields[ind];
        const key = storeDistributorToggle ? (field.alternateKey ? field.alternateKey : field.key) : field.key;
        if (field.canAdd) {
          if (!itemsMap[item.name][key]) {
            itemsMap[item.name][key] = 0;
          }
          itemsMap[item.name][key] += stoI(item[key]);
        } else if (!field.calculatedByOtherField) {
          itemsMap[item.name][key] = item[key];
        }
      }
      itemsMap[item.name]['total_price'] =
        stoI(itemsMap[item.name]['net_price']) - stoI(itemsMap[item.name]['discount_amount']);
      itemsMap[item.name]['price_per_wdc'] =
        stoI(systemSizeDC) === 0 ? '-' : (stoI(itemsMap[item.name]['total_price']) / stoI(systemSizeDC)).toFixed(4);
      itemsMap[item.name]['category'] = item['category'];
      itemsMap[item.name]['sub_category'] = item['sub_category'];
      itemsMap[item.name]['name'] = item['name'];
    }
  }
  let result = [];
  for (let key in itemsMap) {
    result.push(itemsMap[key]);
  }
  return result;
};

// THIS FUNCTION IS USED AS A PREPROCESSING FUNCTION FOR SECTION 5 and 6
// IT WILL RETURN THE DATA IN THE REQUIRED FORMAT FROM STATE
const getEquipments2 = (
  includePrices,
  bomItems,
  projectType,
  sectionKey,
  systemSizeDC,
  interconnectsCount,
  interconnectKey
) => {
  if (bomItems.length === 0) {
    return [];
  }
  let result = [];
  const fields = getFilteredFields(categories[sectionKey].fields[projectType], includePrices);
  for (let i in bomItems) {
    const item = bomItems[i];
    if (item.category !== sectionKey) {
      continue;
    }
    let newItem = {};

    // Adding the data for different interconnects
    for (let j = 1; j <= interconnectsCount; j++) {
      newItem[interconnectKey + 'i' + stoI(j)] = (stoI(item[interconnectKey]) / interconnectsCount).toFixed(2);
    }
    for (let ind in fields) {
      const field = fields[ind];
      const key = field.key;
      if (!field.calculatedByOtherField) {
        newItem[key] = item[key];
      }
    }
    newItem['quantity'] = null;
    newItem['total_price'] = stoI(item['net_price']) - stoI(item['discount_amount']);
    newItem['price_per_wdc'] =
      stoI(systemSizeDC) === 0 ? '-' : (stoI(newItem['total_price']) / stoI(systemSizeDC)).toFixed(4);
    newItem['category'] = item['category'];
    newItem['sub_category'] = item['sub_category'];
    newItem['name'] = item['name'];
    result.push(newItem);
  }
  return result;
};

export const addInstallerInfoToCSV = (state, projectType, helperParams) => {
  const { installer = '', user = '', generatedOn = '', leadId = '' } = helperParams;
  let CSV = '';
  CSV += 'Installer, ' + installer + '\r\n';
  CSV += 'User, ' + user + '\r\n';
  CSV += 'Generated On, ' + generatedOn + '\r\n';
  CSV += 'Project type, ' + projectType + '\r\n';
  CSV += 'Requirement, ' + get(state, 'systemRequirements.requirement', '') + '\r\n';
  if (!isEmpty(leadId)) {
    CSV += 'Lead ID, ' + leadId;
  }
  CSV += '\r\n';
  return CSV;
};

export const addInputDataToCSV = (state, projectType) => {
  let CSV = '';
  CSV += 'Inputs\r\n';
  let inputs = [],
    interconnectNames = [],
    fields = [];
  interconnectNames = get(state, `systemDetails.${projectType}`).map(interconnect => {
    return interconnect.name;
  });
  inputs = get(state, `systemDetails.${projectType}`, []);
  fields = inputFields[projectType].filter(field => !field.nonCsvColumn);
  CSV += addIOFieldsInCSV('Input parameters', inputs, { interconnectNames });
  if (inputs.length > 0) {
    CSV += convertArrayToCSV(inputs, fields);
  }
  return CSV;
};

export const addOutputDataToCSV = (state, projectType) => {
  let CSV = 'Outputs\r\n';
  let outputs = [],
    interconnectNames = [],
    fields = [];
  interconnectNames = state.map(interconnect => {
    return interconnect.name;
  });
  outputs = state.map(interconnect => {
    return interconnect.summary_params;
  });
  fields = csvOutputFields[projectType];
  CSV += addIOFieldsInCSV('Project parameters', outputs, { add: true, interconnectNames });
  if (outputs.length > 0) {
    CSV += convertArrayToCSV(outputs, fields, true);
  }
  return CSV;
};

export const addProjectBOMDataToCSV = (state, projectType, storeDistributorToggle = false) => {
  let CSV = 'Project Bill of Material\r\n';
  const interconnectsCount = state.currentInterconnects.length;
  const systemSizeDC = getSystemSizeDC(state.currentInterconnects);
  const { includePrices } = get(state, 'exportBOMPopup', {});
  resetOverallProjectFields(includePrices);
  const interconnectNames = get(state, 'currentInterconnects').map(interconnect => {
    return interconnect.name;
  });
  const equipments = getEquipments(
    includePrices,
    state.currentInterconnects,
    projectType,
    'ENPHASE_EQUIPMENT',
    systemSizeDC,
    'quantity',
    storeDistributorToggle
  );
  CSV += addSection(
    includePrices,
    'ENPHASE_EQUIPMENT',
    equipments,
    projectType,
    interconnectsCount,
    'Quantity',
    'quantity',
    storeDistributorToggle,
    interconnectNames
  );
  CSV += '\r\n';

  const equipmentsElec = getEquipments(
    includePrices,
    state.currentInterconnects,
    projectType,
    'NON_ENPHASE_EQUIPMENT_ELECTRICAL',
    systemSizeDC,
    'quantity'
  );
  CSV += addSection(
    includePrices,
    'NON_ENPHASE_EQUIPMENT_ELECTRICAL',
    equipmentsElec,
    projectType,
    interconnectsCount,
    'Quantity',
    'quantity',
    false,
    interconnectNames
  );
  CSV += '\r\n';

  const equipmentsStruc = getEquipments2(
    includePrices,
    state.currentProjectBOMItems,
    projectType,
    'NON_ENPHASE_EQUIPMENT_STRUCTURAL',
    systemSizeDC,
    interconnectsCount,
    'distributor_price'
  );
  CSV += addSection(
    includePrices,
    'NON_ENPHASE_EQUIPMENT_STRUCTURAL',
    equipmentsStruc,
    projectType,
    interconnectsCount,
    'unit price ($/Wdc)',
    'distributor_price',
    false,
    interconnectNames
  );
  CSV += '\r\n';

  const softCosts = getEquipments2(
    includePrices,
    state.currentProjectBOMItems,
    projectType,
    'SOFT_COST',
    systemSizeDC,
    interconnectsCount,
    'distributor_price'
  );
  CSV += addSection(
    includePrices,
    'SOFT_COST',
    softCosts,
    projectType,
    interconnectsCount,
    'unit price ($)',
    'distributor_price',
    false,
    interconnectNames
  );
  if (includePrices) {
    CSV += '\r\n';
    CSV += 'Total Project cost';
    for (let j = 1; j <= interconnectsCount; j++) {
      CSV += ', ';
    }
    for (let key in overallProjectFields) {
      CSV += ', ' + getValue(overallProjectFields[key], key);
    }
    CSV += '\r\n';
  }

  return CSV;
};
