import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withStyles, Box, Typography, Paper, Grid, IconButton, TextField, Button } from '@material-ui/core';
import clsx from 'clsx';
import CloseIcon from '@material-ui/icons/Close';
import { cloneDeep, findIndex, get, isEmpty, isEqual } from 'lodash';
import styles from './styles';
import { Interconnect } from '../Icons';
import { getConstantValues } from '../../../containers/BOMCalculator/helper';
import {
  landscape,
  landscapeBallastEW,
  microCompatibilityList,
  no,
  portrait,
  storageSizeArrayList,
  yes
} from '../../../containers/BOMCalculator/constants';
import SystemDetailInputField from '../CommonFields/systemDetailInputField';
import CustomValuePopup from '../CustomValuePopup';
import { getEnlightenOrigin, trackEvents } from '../../../utils/helper';
import BOMImportDesignButton from '../BOMImportDesignButton';

class index extends Component {
  prevRenderFieldsKeys = [];
  renderFieldsKeys = [];

  componentDidUpdate() {
    const { setResidentialLabelsToCheck } = this.props;
    if (!isEqual(this.prevRenderFieldsKeys, this.renderFieldsKeys)) {
      this.prevRenderFieldsKeys = this.renderFieldsKeys;
      setResidentialLabelsToCheck(this.renderFieldsKeys);
    }
  }

  handleAddCloseInterconnect = async (key = 'add', id) => {
    const {
      systemDetails,
      projectType,
      addCommercialInterconnect,
      errorData,
      addErrorObjectCommercialInterconnect,
      handleAddRemoveInterConnect
    } = this.props;
    let interconnectArray = cloneDeep(get(systemDetails, `${projectType}`, []));
    let interconnectErrorArray = cloneDeep(get(errorData, `systemDetails.${projectType}`, []));

    if (key === 'add') {
      const nextInterconnectID = interconnectArray[interconnectArray.length - 1]['id'];
      const addObject = addCommercialInterconnect(nextInterconnectID + 1);
      const addErrorObject = addErrorObjectCommercialInterconnect();
      interconnectArray = [ ...interconnectArray, addObject ];
      interconnectErrorArray = [ ...interconnectErrorArray, addErrorObject ];
      trackEvents('triggerBOM', { menuValue: 'Add_Interconnect' });
    } else {
      const matchingInterconnectIndex = findIndex(interconnectArray, interconnect => interconnect.id === id);
      interconnectArray.splice(matchingInterconnectIndex, 1);
    }
    handleAddRemoveInterConnect(projectType, interconnectArray, interconnectErrorArray);
  };

  handleChange = (event, key, interconnect, helperParams = {}) => {
    const { handleOnChange, projectType, systemDetails, systemRequirements } = this.props;
    const pvModelsList = get(interconnect, 'pvModelsList', []);
    const { isProceedClicked = false } = helperParams;
    const isCommercial = projectType === 'commercial';
    let interconnectArray = cloneDeep(get(systemDetails, `${projectType}`, []));
    const matchingInterconnectIndex = findIndex(interconnectArray, object => object.id === interconnect['id']);
    const value = event.target.value;

    if (!isEmpty(key)) {
      interconnectArray[matchingInterconnectIndex][key] = value;
    }
    if (isCommercial) {
      if (key === 'servicePanelVoltage' && value === '120/240 (1 Phase)') {
        const microInverterValue = interconnectArray[matchingInterconnectIndex]['microinverter'];
        if (!isEmpty(microInverterValue) && microInverterValue === 'IQ8D') {
          interconnectArray[matchingInterconnectIndex]['microinverter'] = '';
        }
      }
      if (key === 'microinverter' || key === 'panelOrientation') {
        const panelOrientationValue = interconnectArray[matchingInterconnectIndex]['panelOrientation'];
        const microInverterValue = interconnectArray[matchingInterconnectIndex]['microinverter'];
        const isIQ8D = microInverterValue === 'IQ8D';
        if (isIQ8D && panelOrientationValue === landscapeBallastEW) {
          interconnectArray[matchingInterconnectIndex]['centerTapping'] = yes;
        } else if (isIQ8D && (panelOrientationValue === landscape || panelOrientationValue === portrait)) {
          interconnectArray[matchingInterconnectIndex]['centerTapping'] = no;
        }
      }

      if (key === 'microinverter') {
        const microInverterValue = interconnectArray[matchingInterconnectIndex]['microinverter'];
        const unusedCableValue = interconnectArray[matchingInterconnectIndex]['unusedCableDrops'];
        const newUnusedCableValue =
          microInverterValue === 'IQ8D' ? '0' : microInverterValue === 'IQ7+' ? '5' : unusedCableValue;
        interconnectArray[matchingInterconnectIndex]['unusedCableDrops'] = newUnusedCableValue;
      }
    } else {
      if (key === 'storageSize' || key === 'microinverter') {
        const microInverterValue = interconnectArray[matchingInterconnectIndex]['microinverter'];
        const isIQ7 = microInverterValue.startsWith('IQ7');
        storageSizeArrayList(isCommercial, interconnect, systemRequirements).forEach(storageSize => {
          if (storageSize.value === interconnectArray[matchingInterconnectIndex]['storageSize']) {
            interconnectArray[matchingInterconnectIndex]['showPvSheddingLink'] = isIQ7
              ? storageSize.pvSheddingRequired
              : false;
          }
        });
      }
      if (key === 'pvPanelType') {
        const microInverterValue = interconnectArray[matchingInterconnectIndex]['microinverter'];
        if (!isEmpty(microInverterValue) && !microCompatibilityList[microInverterValue].includes(value)) {
          interconnectArray[matchingInterconnectIndex]['microinverter'] = '';
        }
      }
      if (key === 'panelOrientation') {
        if (value.toLowerCase() === 'portrait') {
          interconnectArray[matchingInterconnectIndex]['portraitPanelCount'] =
            interconnectArray[matchingInterconnectIndex]['pvPanelsCount'];
          interconnectArray[matchingInterconnectIndex]['landscapePanelCount'] = 0;
        } else if (value.toLowerCase() === 'landscape') {
          interconnectArray[matchingInterconnectIndex]['landscapePanelCount'] =
            interconnectArray[matchingInterconnectIndex]['pvPanelsCount'];
          interconnectArray[matchingInterconnectIndex]['portraitPanelCount'] = 0;
        } else {
          interconnectArray[matchingInterconnectIndex]['openCustomOrientationPanelsPopup'] = true;
        }
      }
    }

    if (key === 'pvPanelsCount') {
      const { panelOrientation } = interconnectArray[matchingInterconnectIndex];
      if (panelOrientation === 'portrait') {
        interconnectArray[matchingInterconnectIndex]['portraitPanelCount'] = value;
      } else if (panelOrientation === 'landscape') {
        interconnectArray[matchingInterconnectIndex]['landscapePanelCount'] = value;
      } else {
        interconnectArray[matchingInterconnectIndex]['portraitPanelCount'] = 0;
        interconnectArray[matchingInterconnectIndex]['landscapePanelCount'] = 0;
        interconnectArray[matchingInterconnectIndex]['panelOrientation'] = '';
      }
    }

    if (isProceedClicked) {
      interconnectArray[matchingInterconnectIndex]['pvManufacturer'] = 'Custom';
      interconnectArray[matchingInterconnectIndex]['pvModel'] = 'Custom';
    }
    if (key === 'pvModel') {
      pvModelsList.forEach(item => {
        if (item.id === value) {
          interconnectArray[matchingInterconnectIndex]['isProceedClickedPopup'] = false;
          interconnectArray[matchingInterconnectIndex]['pvPanelType'] =
            item.cell_count === 120 || item.cell_count === 144
              ? `${item.cell_count} half - cell`
              : `${item.cell_count} - cell`;
          interconnectArray[matchingInterconnectIndex]['pvPanelWattage'] = item.stc_rating;
        }
      });
    }
    handleOnChange('', `systemDetails.${projectType}`, {
      value: interconnectArray,
      from: 'systemDetails',
      interconnectFieldName: key,
      interconnectIndex: matchingInterconnectIndex
    });
  };

  getFieldProperties = (inputField, interconnect) => {
    const keyName = inputField[0];
    const inputFieldProperties = inputField[1];
    const { array = [], tooltip = '', unit = '', label = '', disabled = false } = inputFieldProperties;
    const showUnit = !isEmpty(unit);
    const value = get(interconnect, `${keyName}`) || '';
    return {
      keyName,
      array,
      tooltip,
      unit,
      label,
      disabled,
      showUnit,
      value,
      handleChange: this.handleChange
    };
  };

  getErrorDetails = (interconnect, keyName, value) => {
    const { errorData, projectType, systemDetails } = this.props;
    let interconnectArray = cloneDeep(get(systemDetails, `${projectType}`, []));
    const matchingInterconnectIndex = findIndex(interconnectArray, object => object.id === interconnect['id']);
    const errorObject = errorData && get(errorData, `systemDetails.${projectType}`, [])[matchingInterconnectIndex];
    const isError = get(errorObject, `${keyName}.error`, false);
    const helperObject = errorData && get(errorData, `systemDetails.${projectType}`, [])[matchingInterconnectIndex];
    const helperText = get(helperObject, `${keyName}.errorMessage`, []);
    return { isError, helperText };
  };

  renderInputFields = (inputField, interconnect, type, index) => {
    const {
      pvManufacturersList,
      openDidntFindModulePopup,
      handleCustomPanelConfigurations,
      recentlyUsedPVManufacturersList
    } = this.props;
    const fieldProperties = this.getFieldProperties(inputField, interconnect);
    const { keyName, value } = fieldProperties;
    const errorDetails = this.getErrorDetails(interconnect, keyName, value);
    const pvModelsList = get(interconnect, 'pvModelsList', []);
    const recentPVModelsList = get(interconnect, 'recentPVModelsList', []);
    const recentPVManufacturersList =
      keyName === 'pvManufacturer' ? recentlyUsedPVManufacturersList : recentPVModelsList;
    const manufacturersOrModelsList = keyName === 'pvManufacturer' ? pvManufacturersList : pvModelsList;
    return (
      <SystemDetailInputField
        type={type}
        index={index}
        manufacturersOrModelsList={manufacturersOrModelsList}
        recentlyUsedPVList={recentPVManufacturersList}
        pvModelsList={pvModelsList}
        interconnect={interconnect}
        openDidntFindModulePopup={openDidntFindModulePopup}
        handleCustomPanelConfigurations={handleCustomPanelConfigurations}
        {...fieldProperties}
        {...errorDetails}
      />
    );
  };

  renderInterconnectSection = (interconnect, index) => {
    const {
      classes,
      projectType,
      systemDetails,
      systemRequirements,
      handleCustomPanelConfigurations,
      errorData,
      handleInterconnectNameChange
    } = this.props;
    const isCommercial = projectType === 'commercial';
    const renderFields = cloneDeep(getConstantValues(projectType, interconnect, systemRequirements));
    delete renderFields['requirementList'];
    if (!isCommercial) {
      this.renderFieldsKeys = Object.keys(renderFields);
    }
    const interconnectId = get(interconnect, 'id');
    const arrayCount = get(systemDetails, `${projectType}`, []).length;
    const customConfigurationProps = {
      handleCustomPanelConfigurations: handleCustomPanelConfigurations,
      renderFields: renderFields,
      interconnect: interconnect,
      handleChange: this.handleChange,
      errorData: errorData,
      projectType: projectType,
      systemDetails: systemDetails,
      interconnectId: interconnectId
    };

    return (
      <Fragment key={interconnectId + projectType}>
        <Box className={classes.interconnectIconWrapper}>
          <Interconnect className={classes.styleDragIcon} />
          <Box>
            <TextField
              value={get(interconnect, 'name')}
              className={classes.interconnectText}
              onChange={e => handleInterconnectNameChange(e, index)}
              InputProps={{ className: classes.interconnectTextField, readOnly: !isCommercial }}
            />
          </Box>
          {arrayCount > 1 &&
          isCommercial && (
            <IconButton
              className={classes.closeIconWrapper}
              onClick={() => this.handleAddCloseInterconnect('close', interconnectId)}
            >
              <CloseIcon className={classes.closeIconDimension} />
            </IconButton>
          )}
        </Box>
        <Grid container direction="row" className={classes.systemDetailsGridWrapper}>
          {Object.entries(renderFields).map((inputField, index) => {
            const inputFieldProperties = inputField[1];
            const { type = '' } = inputFieldProperties;
            const keyName = inputField[0];
            if (
              keyName === 'pvPanelType' ||
              keyName === 'pvPanelWattage' ||
              keyName === 'portraitPanelCount' ||
              keyName === 'landscapePanelCount'
            ) {
              return <Fragment key={inputField + index} />;
            }
            return (
              <Box key={inputField + index} className={classes.minHeight64}>
                {this.renderInputFields(inputField, interconnect, type, index)}
              </Box>
            );
          })}
        </Grid>
        <CustomValuePopup
          open={get(interconnect, 'openDidntFindModulePopup')}
          {...customConfigurationProps}
          source="moduleDetails"
        />
        <CustomValuePopup
          open={get(interconnect, 'openCustomOrientationPanelsPopup')}
          {...customConfigurationProps}
          source="panelOrientation"
        />
      </Fragment>
    );
  };

  render() {
    const { classes, projectType = '', systemDetails, from, isSolargrafAvailable } = this.props;
    const isCommercial = projectType === 'commercial';
    const interconnectArray = get(systemDetails, `${projectType}`, []);
    return (
      <Paper square className={classes.projectTypePaper}>
        <Box className={classes.headerBox}>
          <Typography className={classes.chooseProjectTypeText}>Enter your system details</Typography>
          {from === 'lead' &&
          isSolargrafAvailable && <BOMImportDesignButton msgToSend="showImportDesignPopupForLead" />}
        </Box>
        {interconnectArray.map((interconnect, index) => {
          return this.renderInterconnectSection(interconnect, index);
        })}
        {isCommercial && (
          <Typography
            className={clsx(classes.chooseProjectTypeText, classes.addInterconnectPointer)}
            onClick={() => this.handleAddCloseInterconnect('add')}
          >
            + Add Another Interconnect
          </Typography>
        )}
      </Paper>
    );
  }
}

const mapStateToProps = state => ({
  isMobile: state.appReducer.isMobileView
});

export default connect(mapStateToProps)(withStyles(styles)(index));
