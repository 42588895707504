import React, { Fragment, useEffect, useState } from 'react';
import clsx from 'clsx';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Button,
  Typography,
  IconButton,
  Divider,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TableHead,
  Popper,
  Fade,
  Paper
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import AddOutlinedIcon from '@material-ui/icons/AddOutlined';
import RemoveOutlinedIcon from '@material-ui/icons/RemoveOutlined';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import { get, cloneDeep, isEmpty } from 'lodash';
import ReactHtmlParser from 'react-html-parser';

import { skuQuantityInfo } from '../../../containers/BOMCalculator/constants';
import settings from '../../../utils/settings';
import { currencyFormater, trackEvents } from '../../../utils/helper';
import { LightTooltip } from '../../BOMCalculatorInput/CommonFields/infoTooltip';
import useStyles from './styles';
import { excludeSKUsFromStoreOrder } from '../../../containers/BOMCalculatorOutput/constants';

const OrderFromStorePopup = props => {
  const classes = useStyles();
  const {
    open,
    handleOrderfromStorePopup,
    currentInterconnects,
    leadId,
    journeyType,
    journeyId,
    getCartUrl,
    userDetails
  } = props;
  const [ mergedInterConnects, setMergedInterConnects ] = useState([]);
  const [ showBOMInfo, setShowBOMInfo ] = useState(false);
  const [ bomToShowInfo, setBomToShowInfo ] = useState({});
  const [ anchorElForBOM, setAnchorElForBOM ] = useState(null);

  useEffect(
    () => {
      let mergedInterConnects = [];
      currentInterconnects.length &&
        currentInterconnects.forEach((interconnects, index) => {
          const bomItems = get(interconnects, 'bom_items', []);
          if (index === 0) {
            mergedInterConnects = bomItems.map(item => {
              if (isEmpty(item.sku) || excludeSKUsFromStoreOrder.includes(item.sku)) {
                return null;
              }
              const {
                id,
                sku,
                quantity,
                net_price,
                store_price,
                distributor_price,
                name,
                description,
                quantity_per_sku,
                estore_url
              } = item;
              const skuQuantity = Math.ceil(quantity / quantity_per_sku);
              const totalPrice = skuQuantity * quantity_per_sku * store_price;
              return {
                id,
                sku,
                quantity,
                net_price,
                store_price,
                distributor_price,
                name,
                description,
                quantity_per_sku,
                skuQuantity,
                totalPrice,
                estore_url
              };
            });
          } else {
            bomItems.forEach(item => {
              if (isEmpty(item.sku) || excludeSKUsFromStoreOrder.includes(item.sku)) {
                return;
              }
              const {
                id,
                sku,
                quantity,
                net_price,
                store_price,
                distributor_price,
                name,
                description,
                quantity_per_sku,
                estore_url
              } = item;
              const mergedInterConnectIndex = mergedInterConnects.findIndex(
                mergedInterConnect => get(mergedInterConnect, 'id') && get(mergedInterConnect, 'id') === id
              );
              if (mergedInterConnectIndex >= 0) {
                const newQuantity = quantity + mergedInterConnects[mergedInterConnectIndex].quantity;
                const skuQuantity = Math.ceil(newQuantity / quantity_per_sku);
                const totalPrice = skuQuantity * quantity_per_sku * store_price;
                mergedInterConnects[mergedInterConnectIndex] = {
                  id,
                  sku,
                  quantity: newQuantity,
                  net_price: net_price + mergedInterConnects[mergedInterConnectIndex].net_price,
                  store_price,
                  distributor_price,
                  name,
                  description,
                  quantity_per_sku,
                  skuQuantity,
                  totalPrice,
                  estore_url
                };
              } else {
                const skuQuantity = Math.ceil(quantity / quantity_per_sku);
                const totalPrice = skuQuantity * quantity_per_sku * store_price;
                mergedInterConnects.push({
                  id,
                  sku,
                  quantity,
                  net_price,
                  store_price,
                  distributor_price,
                  name,
                  description,
                  quantity_per_sku,
                  skuQuantity,
                  totalPrice,
                  estore_url
                });
              }
            });
          }
        });
      mergedInterConnects = mergedInterConnects.filter(interconnect => !isEmpty(interconnect));
      setMergedInterConnects(mergedInterConnects);
    },
    [ currentInterconnects ]
  );

  const updateBomQuantity = (index, increase = false) => {
    let newMergedInterConnects = cloneDeep(mergedInterConnects);
    const { skuQuantity, store_price, quantity_per_sku } = newMergedInterConnects[index];
    const newQuantity = skuQuantity + (increase ? 1 : -1);
    newMergedInterConnects[index] = {
      ...newMergedInterConnects[index],
      skuQuantity: newQuantity,
      totalPrice: parseFloat(
        (newQuantity *
          quantity_per_sku *
          (store_price !== null && store_price !== undefined ? store_price : 0)).toFixed(2)
      )
    };
    setMergedInterConnects(newMergedInterConnects);
  };

  const showInfoDetailsOfBOM = (e, bom) => {
    const showDetails = () => {
      setShowBOMInfo(true);
      setAnchorElForBOM(e.currentTarget);
      setBomToShowInfo(bom);
    };
    if (showBOMInfo) {
      if (anchorElForBOM.id === e.currentTarget.id) {
        closeBOMInfoDetails();
      } else {
        showDetails();
      }
    } else {
      showDetails();
    }
  };

  const closeBOMInfoDetails = () => {
    setShowBOMInfo(false);
    setAnchorElForBOM(null);
    setBomToShowInfo({});
  };

  const getUrlToStoreWithRedirectOptions = (cartUrl, journeyId, leadId, currentUrl) => {
    let redirectAPI = settings.digiCoreUrl(
      'installers/order_placed?order_id%3D%26payment_id%3D%26sgip_promo_code%3D%26permit_promo_code%3D%26journey_id=' +
        journeyId
    );
    return cartUrl + '&redirect_api=' + redirectAPI + '&redirect_url=' + currentUrl + '%26req_source=enstore';
  };

  const handlePlaceOrder = () => {
    let skuDetails = [];
    const companyId = get(userDetails, 'company_id', '');
    trackEvents('triggerBOM', { menuValue: 'Proceed_to_store_button' });
    mergedInterConnects.forEach(interConnect => {
      const { sku, skuQuantity, estore_url } = interConnect;
      if (sku && skuQuantity && estore_url) {
        skuDetails.push({
          sku,
          qty: skuQuantity
        });
      }
    });
    getCartUrl({
      skuDetails: skuDetails,
      successCb: result => {
        let currentUrl = settings.enlightenUrl(
          `manager/dashboard/${companyId}/leads/${leadId}?journey_type=${journeyType}&leadView=bom`
        );
        let urlForStore = getUrlToStoreWithRedirectOptions(result.cartUrl, journeyId, leadId, currentUrl);
        window.open(urlForStore, '_parent');
      },
      failureCb: () => {}
    });
  };

  const totalPrice = mergedInterConnects.length
    ? mergedInterConnects.reduce((prevValue, currentValue) => {
        return parseFloat((prevValue + currentValue.totalPrice).toFixed(2));
      }, 0)
    : 0;

  return (
    <Fragment>
      <Dialog open={open} aria-labelledby="map" aria-describedby="map-description" maxWidth="md" fullWidth>
        <DialogTitle id="map" disableTypography className={classes.titleWrapper}>
          <Typography variant="h6">Bill of Material (BOM) Review</Typography>
          <IconButton
            aria-label="close"
            onClick={() => handleOrderfromStorePopup(false)}
            className={classes.closeButton}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <Divider />
        <DialogContent className={classes.mapWrapper}>
          <Typography className={classes.notes}>
            Note: Please note that only the items that are available on Enphase store will be ordered and the item
            quantities have been adjusted based on the SKU requirements. The final price, including tax, will be
            displayed on the Enphase store checkout page
          </Typography>
          <Box>
            <TableContainer className={classes.tableContainer}>
              <Table className={classes.bomPricingTable}>
                <TableHead>
                  <TableRow className={classes.highlightedRow}>
                    <TableCell>
                      <Typography className={classes.tableHeader}>Items</Typography>
                    </TableCell>
                    <TableCell align="center" className={classes.skuQuantityWrapper}>
                      <Typography className={classes.tableHeader}>SKU Qty</Typography>
                      <LightTooltip title={skuQuantityInfo}>
                        <HelpOutlineIcon className={classes.helpIcon} />
                      </LightTooltip>
                    </TableCell>
                    <TableCell align="right">
                      <Typography className={classes.tableHeader}>Price</Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {mergedInterConnects.map((interConnect, idx) => {
                    const { sku, name, skuQuantity, totalPrice, estore_url } = interConnect;
                    if (!sku || !estore_url) {
                      return null;
                    }
                    return (
                      <TableRow key={idx}>
                        <TableCell component="th" scope="row" className={classes.bomItemTableCell}>
                          <IconButton
                            id={idx + '_info'}
                            onClick={e => {
                              e.stopPropagation();
                              showInfoDetailsOfBOM(e, interConnect);
                            }}
                            onMouseEnter={e => {
                              showInfoDetailsOfBOM(e, interConnect);
                            }}
                            onMouseLeave={e => closeBOMInfoDetails()}
                            className={classes.noPadding}
                          >
                            <HelpOutlineIcon className={classes.helpIcon} />
                          </IconButton>
                          &nbsp;&nbsp;
                          <Typography component="div">
                            <Typography className={classes.bomItemName}>{name}</Typography>
                          </Typography>
                        </TableCell>
                        <TableCell align="center" className={clsx(classes.bomItemName, classes.bomQuantity)}>
                          <IconButton
                            className={clsx(classes.removeIcon, classes.addremoveIcon)}
                            aria-label="delete"
                            onClick={() => updateBomQuantity(idx, false)}
                            disabled={!skuQuantity}
                          >
                            <RemoveOutlinedIcon className={classes.addRemoveButton} />
                          </IconButton>
                          {skuQuantity}
                          <IconButton
                            className={clsx(classes.addIcon, classes.addremoveIcon)}
                            aria-label="add"
                            onClick={() => updateBomQuantity(idx, true)}
                          >
                            <AddOutlinedIcon className={classes.addRemoveButton} />
                          </IconButton>
                        </TableCell>
                        <TableCell align="right" className={classes.bomItemName}>
                          {totalPrice !== null && totalPrice !== undefined && totalPrice >= 0 ? (
                            `$${currencyFormater(interConnect.totalPrice.toFixed(2))}`
                          ) : (
                            `$${currencyFormater(0)}`
                          )}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  <TableRow>
                    <TableCell component="th" scope="row" className={classes.bomItemName}>
                      Other items like racking, wiring, etc.
                    </TableCell>
                    <TableCell align="center" className={classes.bomItemName}>
                      as required
                    </TableCell>
                    <TableCell align="right">--</TableCell>
                  </TableRow>
                  <TableRow className={classes.highlightedRow}>
                    <TableCell colSpan={2}>
                      <Typography className={classes.tableHeader}>
                        <span className={classes.boldFont}>Total</span>
                      </Typography>
                    </TableCell>
                    <TableCell align="right">
                      <Typography className={classes.systemPrice}>{`$${currencyFormater(totalPrice)}`}</Typography>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
            <Popper
              id={'bomInfoPopper'}
              open={showBOMInfo}
              anchorEl={anchorElForBOM}
              transition
              className={classes.bomInfoPopper}
            >
              {({ TransitionProps }) => (
                <Fade {...TransitionProps}>
                  <Paper className={classes.descPaper}>
                    <Typography className={classes.fontBold}>
                      Product Description
                      <IconButton size="small" className={classes.descIcon} onClick={() => closeBOMInfoDetails()}>
                        <CloseIcon className={classes.infoPrice} />
                      </IconButton>
                    </Typography>
                    <br />
                    {bomToShowInfo.description === '' || !bomToShowInfo.description ? (
                      <Typography>{'** No description available **'}</Typography>
                    ) : (
                      <Fragment>{ReactHtmlParser(bomToShowInfo.description)}</Fragment>
                    )}
                  </Paper>
                </Fade>
              )}
            </Popper>
          </Box>
        </DialogContent>
        <Divider />
        <DialogActions className={classes.buttonWrapper}>
          <Button
            className={classes.saveButton}
            onClick={handlePlaceOrder}
            color="primary"
            variant="contained"
            disableElevation
          >
            Place Order on Enphase Store
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};

export default OrderFromStorePopup;
