import React, { Component, Fragment } from 'react';
import { Box, Paper, Typography, withStyles } from '@material-ui/core';
import EnphaseEquipmentsTable from './EnphaseEquipmentsTable';
import NonEnphaseElectricalTable from './NonEnphaseElectricalTable';
import NonEnphaseStructuralTable from './NonEnphaseStructuralTable';
import OtherSoftCosts from './OtherSoftCosts';
import { cloneDeep } from 'lodash';
import { connect } from 'react-redux';
import { trackEvents } from '../../../utils/helper';

const styles = theme => ({
  billOfMaterialMainPaper: {
    padding: theme.spacing(4, 2.75, 2.5),
    boxShadow: '0px 0px 8px #00000029',
    backgroundColor: '#FBFBFB'
  },
  bomHeaderWrapper: {
    display: 'flex',
    alignItems: 'center'
  },
  projectBOMText: {
    fontWeight: '1000',
    fontSize: 20,
    marginLeft: theme.spacing(1.75),
    [theme.breakpoints.down('xs')]: {
      fontSize: 16,
      marginTop: theme.spacing(0.5)
    }
  },
  manageTemplateWrapper: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: 'auto'
  },
  styleCollapseAll: {
    color: '#4F89F4',
    marginTop: theme.spacing(0.5),
    cursor: 'pointer',
    fontWeight: 600,
    marginRight: theme.spacing(0.5)
  },
  styleManageTemplate: {
    color: '#4F89F4',
    marginTop: theme.spacing(0.5),
    cursor: 'pointer',
    fontWeight: 600,
    marginRight: theme.spacing(1.5)
  }
});

class BillOfMaterial extends Component {
  state = {
    expandCollapseItems: {
      collapseEnphaseInverters: false,
      collapseEnphaseCommunications: false,
      collapseEnphaseAccessories: false,
      collapseEnphaseStorage: false,
      collapsePVPanels: false,
      collapseACPanels: false,
      collapseElectrical: false,
      collaseOtherEBOS: false,
      collapseContigency: false,
      collapseLabor: false,
      collapseRacking: false
    },
    isCollapseAll: false
  };

  handleCollapseExpandAll = () => {
    const isCollapseAll = cloneDeep(this.state.isCollapseAll);
    let expandCollapseItems = cloneDeep(this.state.expandCollapseItems);
    if (!this.state.isCollapseAll) {
      trackEvents('triggerBOM', { menuValue: 'Collapse_all' });
    }

    Object.entries(expandCollapseItems).forEach(([ key, value ]) => {
      expandCollapseItems[key] = !isCollapseAll;
    });
    this.setState({ isCollapseAll: !isCollapseAll, expandCollapseItems });
  };

  handleExpandCollapseTables = itemToExpandCollapse => {
    let expandCollapseItems = cloneDeep(this.state.expandCollapseItems);
    expandCollapseItems[itemToExpandCollapse] = !expandCollapseItems[itemToExpandCollapse];
    this.setState({ expandCollapseItems });
  };

  render() {
    const {
      classes,
      handleInterconnectsInputChange,
      handleProjectBomItemsInputChange,
      currentProjectBOMItems,
      systemSizeDc,
      updateStoreDistributerToggle,
      storeDistributorToggle,
      handleOpenTemplate
    } = this.props;

    return (
      <Fragment>
        <Paper square className={classes.billOfMaterialMainPaper}>
          <Box className={classes.bomHeaderWrapper}>
            <img alt="downloadCsvButton" src={require(`../Icons/projectBOM.svg`)} />
            <Typography className={classes.projectBOMText}>Project Bill of Material</Typography>
            <Box className={classes.manageTemplateWrapper}>
              <Typography
                onClick={() => {
                  trackEvents('triggerBOM', { menuValue: 'Manage_Template_Outputpage' });
                  handleOpenTemplate();
                }}
                className={classes.styleManageTemplate}
              >
                {'Choose Template'}
              </Typography>
              <Typography onClick={() => this.handleCollapseExpandAll()} className={classes.styleCollapseAll}>
                {this.state.isCollapseAll ? 'Expand All' : 'Collapse All'}
              </Typography>
            </Box>
          </Box>
          <EnphaseEquipmentsTable
            currentInterconnects={this.props.currentInterconnects}
            handleInterconnectsInputChange={handleInterconnectsInputChange}
            handleExpandCollapseTables={this.handleExpandCollapseTables}
            expandCollapseItems={this.state.expandCollapseItems}
            updateStoreDistributerToggle={updateStoreDistributerToggle}
            storeDistributorToggle={storeDistributorToggle}
          />
          <NonEnphaseElectricalTable
            currentInterconnects={this.props.currentInterconnects}
            handleInterconnectsInputChange={handleInterconnectsInputChange}
            handleExpandCollapseTables={this.handleExpandCollapseTables}
            expandCollapseItems={this.state.expandCollapseItems}
          />
          <NonEnphaseStructuralTable
            currentProjectBomItems={currentProjectBOMItems}
            handleProjectBomItemsInputChange={handleProjectBomItemsInputChange}
            handleExpandCollapseTables={this.handleExpandCollapseTables}
            expandCollapseItems={this.state.expandCollapseItems}
            systemSizeDc={systemSizeDc}
          />
          <OtherSoftCosts
            currentProjectBomItems={currentProjectBOMItems}
            handleProjectBomItemsInputChange={handleProjectBomItemsInputChange}
          />
        </Paper>
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  bomDetailsArray: state.bomInputReducer.bomDetailsArray
});

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(BillOfMaterial));
