import {
  CONNECT_BOM_TO_LEAD_REQUEST,
  GET_PV_MANUFACTURERS_REQUEST,
  GET_PV_MODELS_REQUEST,
  GET_TEMPLATES_REQUEST,
  SAVE_TEMPLATE_REQUEST,
  SET_BOM_INPUT_DETAILS_REQUEST,
  SITE_SEARCH_LEAD_REQUEST,
  GET_EXISTING_SYSTEM_DETAILS_REQUEST,
  GET_LEAD_BOM_DETAILS_REQUEST,
  CALCULATE_RESIDENTIAL_BOM_OUTPUT_REQUEST,
  GET_CART_URL_REQUEST,
  CREATE_TEMPLATE_REQUEST,
  DELETE_TEMPLATE_REQUEST,
  CHECK_EXISTING_EMAIL_REQUEST,
  GET_RECENTLY_USED_PV_REQUEST
} from './constants';

export function getSiteSearchLeads(payload) {
  return { type: SITE_SEARCH_LEAD_REQUEST, payload };
}

export function setBOMInputDetails(payload) {
  return { type: SET_BOM_INPUT_DETAILS_REQUEST, payload };
}

export function calculateResidentialBOMOutput(payload) {
  return { type: CALCULATE_RESIDENTIAL_BOM_OUTPUT_REQUEST, payload };
}

export function getAllTemplates() {
  return { type: GET_TEMPLATES_REQUEST };
}

export function saveTemplate(payload) {
  return { type: SAVE_TEMPLATE_REQUEST, payload };
}

export function createTemplate(payload) {
  return { type: CREATE_TEMPLATE_REQUEST, payload };
}

export function deleteTemplate(payload) {
  return { type: DELETE_TEMPLATE_REQUEST, payload };
}

export function connectBOMToLead(payload) {
  return { type: CONNECT_BOM_TO_LEAD_REQUEST, payload };
}

export function getLeadBOMDetails(payload) {
  return { type: GET_LEAD_BOM_DETAILS_REQUEST, payload };
}

export function getPVManufacturers() {
  return { type: GET_PV_MANUFACTURERS_REQUEST };
}

export function getPVModels(payload) {
  return { type: GET_PV_MODELS_REQUEST, payload };
}

export function getExistingSystemDetails(payload) {
  return { type: GET_EXISTING_SYSTEM_DETAILS_REQUEST, payload };
}

export function getCartUrl(payload) {
  return { type: GET_CART_URL_REQUEST, payload };
}

export function checkExistingEmail(payload) {
  return { type: CHECK_EXISTING_EMAIL_REQUEST, payload };
}

export function getRecentlyUsedPVManufacturersModules(payload) {
  return { type: GET_RECENTLY_USED_PV_REQUEST, payload };
}
