import React, { Component } from 'react';
import {
  Dialog,
  DialogTitle,
  Typography,
  DialogContent,
  Divider,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  MenuItem,
  Select,
  InputBase,
  TableBody,
  withStyles
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import clsx from 'clsx';
import { connect } from 'react-redux';
import { get, isEmpty } from 'lodash';
import { storageSizeArrayList } from '../../../containers/BOMCalculator/constants';

const styles = theme => ({
  dialogTitleWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  paper: {
    borderRadius: theme.spacing(0),
    width: '450px'
  },
  dialogTitle: {
    fontSize: 20,
    color: '#4A4A4A',
    fontWeight: 1000
  },
  dialogContentWrapper: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(3, 2)
  },
  close: {
    opacity: 0.5,
    cursor: 'pointer',
    fontSize: theme.spacing(3),
    marginRight: theme.spacing(-0.75)
  },
  tableHeader: {
    border: 'none',
    padding: theme.spacing(2)
  },
  tableWrapper: {
    borderRadius: 0,
    boxShadow: 'none',
    border: '0.5px solid #E3E3E3'
  },
  headerContainer: {
    color: '#FFF',
    backgroundColor: theme.palette.primary.dark
  },
  borderRight: {
    borderRight: '2px solid #E5E5E5'
  },
  styleTableHeaderContent: {
    padding: theme.spacing(0.5, 0.5, 0.5, 2),
    fontSize: 14,
    width: '40%',
    fontWeight: 600
  },
  selectWrapper: {
    width: '90%'
  },
  underline: {
    color: '#FFF',
    borderBottom: '1px solid #FFF',
    marginBottom: theme.spacing(0.5),
    width: '90%'
  },
  dropdownIcon: {
    fill: '#FFF'
  },
  firstColumnBodyColor: {
    color: '#4A4A4A',
    fontWeight: 500
  },
  secondColumnBodyColor: {
    color: theme.palette.primary.main,
    fontWeight: 600
  }
});

class ProjectSummaryPopup extends Component {
  state = {
    interconnectName: ''
  };
  isInterconnectUpdate = false;

  componentDidUpdate() {
    if (!this.isInterconnectUpdate && !isEmpty(this.props.bomDetailsArray)) {
      this.isInterconnectUpdate = true;
      this.setState({ interconnectName: get(this.props.bomDetailsArray, 'interconnects')[0]['name'] });
    }
  }

  handleInterconnectChange = event => {
    const newValue = event.target.value;
    this.setState({ interconnectName: newValue });
  };

  getInterconnectBasedProjectSummary = (getStorageSize = false) => {
    if (isEmpty(this.props.bomDetailsArray)) return [];
    const interconnectSpecificParams = get(this.props.bomDetailsArray, 'interconnects').filter(bomItem => {
      return bomItem.name === this.state.interconnectName;
    });
    let storageSizeDetails = '';
    storageSizeArrayList().forEach(item => {
      if (Number(item.key) === get(interconnectSpecificParams[0], 'storage_size')) {
        storageSizeDetails = item.value;
      }
    });
    return getStorageSize ? storageSizeDetails : get(interconnectSpecificParams[0], 'summary_params');
  };

  render() {
    const { open, handleClose = () => {}, classes } = this.props;
    const helperArray = [
      { key: 'system_size_dc', label: 'kWdc' },
      { key: 'system_size_ac', label: 'kWac' },
      { key: 'dc_ac_ratio', label: 'DC/AC' },
      { key: 'service_panel_voltage', label: 'Service Voltage' },
      { key: 'inverter_type', label: 'Inverter Model' },
      { key: 'inverters_count', label: 'No. of Inverters' },
      { key: 'inverters_per_phase', label: 'No. of Inverters per phase' },
      { key: 'branch_circuits_count', label: 'No. of Branch Circuits' },
      { key: 'branch_circuits_per_phase', label: 'No. of BC per phase' },
      { key: 'storage_size', label: 'Storage Size' }
    ];
    const interconnectSpecificSummaryParams = this.getInterconnectBasedProjectSummary();
    const interconnectsArrayLength = get(this.props.bomDetailsArray, 'interconnects', []).length;
    const getFieldValue = (key, value) => {
      const stringValuesCheck = [ 'service_panel_voltage', 'inverter_type' ];
      if (stringValuesCheck.includes(key)) {
        value = isEmpty(value) ? '-' : value;
      } else {
        value = value === null || value === 0 ? '-' : value;
      }
      return value;
    };

    return (
      <Dialog
        open={open}
        maxWidth={'sm'}
        aria-labelledby="self-lead-title-confirmation"
        fullWidth
        id="selfLeadPopup"
        classes={{ paper: classes.paper }}
      >
        <DialogTitle disableTypography className={classes.dialogTitleWrapper}>
          <Typography className={classes.dialogTitle}>{'View All Project Summary'}</Typography>
          <CloseIcon className={classes.close} onClick={() => handleClose(false)} />
        </DialogTitle>
        <Divider />
        <DialogContent className={classes.dialogContentWrapper}>
          <TableContainer component={Paper} className={classes.tableWrapper}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead className={classes.tableHeader}>
                <TableRow>
                  <TableCell
                    className={clsx(classes.borderRight, classes.headerContainer)}
                    classes={{ root: classes.styleTableHeaderContent }}
                  >
                    ITEMS
                  </TableCell>
                  <TableCell className={classes.headerContainer} classes={{ root: classes.styleTableHeaderContent }}>
                    {interconnectsArrayLength > 1 ? (
                      <Select
                        value={this.state.interconnectName}
                        input={<InputBase className={classes.underline} />}
                        classes={{ icon: classes.dropdownIcon }}
                        onChange={e => this.handleInterconnectChange(e)}
                      >
                        {get(this.props.bomDetailsArray, 'interconnects', []).map((interconnect, index) => {
                          return (
                            <MenuItem key={interconnect.name + index} value={interconnect.name}>
                              {interconnect.name}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    ) : (
                      'PROJECT'
                    )}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {helperArray.map((interconnectFields, index) => {
                  const { key, label } = interconnectFields;
                  if (key === 'storage_size' && isEmpty(this.getInterconnectBasedProjectSummary(true))) return null;
                  return (
                    <TableRow key={interconnectFields.label + index}>
                      <TableCell className={clsx(classes.borderRight, classes.firstColumnBodyColor)}>{label}</TableCell>
                      <TableCell className={clsx(classes.styleValues, classes.secondColumnBodyColor)}>
                        {key === 'storage_size' ? (
                          this.getInterconnectBasedProjectSummary(true)
                        ) : (
                          getFieldValue(key, get(interconnectSpecificSummaryParams, key, ''))
                        )}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
      </Dialog>
    );
  }
}

const mapStateToProps = state => ({
  isMobile: state.appReducer.isMobileView,
  bomDetailsArray: state.bomInputReducer.bomDetailsArray
});

export default connect(mapStateToProps)(withStyles(styles)(ProjectSummaryPopup));
