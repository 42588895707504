import { get, isEmpty } from 'lodash';
import {
  yesNoArrayList,
  panelOrientationArrayList,
  servicePanelAmperageArrayList,
  acModuleArrayList,
  storageSizeArrayList,
  existingMicroinverterArrayList,
  requirementArrayList,
  servicePanelVoltageArrayList,
  pvPanelTypeArrayList,
  microInverterArrayList,
  solarOnly,
  existingStorageUpgrade,
  acModule,
  storageOnly,
  requirementMap,
  panelMakers,
  servicePanelVoltage120_240,
  IQ6Plus,
  no,
  yes,
  panelOrientationList,
  IQ7,
  sunlightBackup,
  homeEssentialBackup,
  fullEnergyIndependence
} from './constants';

const getPVPanelWattage = () => ({ unit: 'Watt', label: 'PV Panel Wattage - DC', type: 'textfield' });

const getPVPanelsCount = () => ({ label: 'PV Panels Count', type: 'textfield' });

const getPortraitPanelCount = () => ({ label: 'Portrait Panel Count', type: 'textfield' });

const getLanscapePanelCount = () => ({ label: 'Landscape Panel Count', type: 'textfield' });

const getPanelOrientation = () => ({ array: panelOrientationList(), type: 'dropdown', label: 'Panel Orientation' });

const getRequirementList = isCommercial => ({
  array: requirementArrayList(isCommercial),
  type: 'dropdown'
});

const getPVManufacturer = () => ({
  array: [],
  type: 'dropdown',
  label: 'PV Manufacturer'
});

const getPVModel = () => ({
  array: [],
  type: 'dropdown',
  label: 'PV Model'
});

const getPVPanelType = isCommercial => ({
  array: pvPanelTypeArrayList(isCommercial),
  label: 'PV Panel Type',
  type: 'dropdown'
});

const getServicePanelVoltage = systemRequirements => ({
  array: servicePanelVoltageArrayList(systemRequirements),
  unit: 'V',
  tooltip: 'Please enter the Voltage value of the main service panel to which the interconnect will be connected',
  label: 'Service Panel Voltage',
  type: 'dropdown'
});

const getMicroInverter = (isCommercial, interconnect, systemRequirements) => ({
  array: microInverterArrayList(isCommercial, interconnect, systemRequirements),
  label: 'Inverter',
  type: 'dropdown'
});

const getStorageSize = (isCommercial, interconnect, systemRequirements) => ({
  array: storageSizeArrayList(isCommercial, interconnect, systemRequirements),
  unit: 'kWh',
  label: 'Storage Size',
  type: 'dropdown',
  ...(!isCommercial &&
  get(interconnect, 'microinverter', '').includes('IQ7') && {
    tooltip: 'Battery configurations which are not compatible with the IQ7 PV system require PV shedding'
  })
});

export const getConstantValues = (projectType = '', interconnect, systemRequirements) => {
  if (isEmpty(projectType)) return {};
  const isCommercial = projectType === 'commercial';
  const requirement = get(systemRequirements, 'requirement', '') || '';
  const getValue = key => get(interconnect, key, '') || '';
  if (isCommercial) {
    return {
      requirementList: getRequirementList(isCommercial),
      pvPanelType: getPVPanelType(isCommercial),
      pvManufacturer: getPVManufacturer(),
      pvModel: getPVModel(),
      pvPanelWattage: getPVPanelWattage(),
      pvPanelsCount: getPVPanelsCount(),
      panelOrientation: {
        array: panelOrientationArrayList(),
        label: 'Panel Orientation',
        type: 'dropdown'
      },
      servicePanelAmperage: {
        array: servicePanelAmperageArrayList(),
        unit: 'Amp',
        tooltip:
          'Please enter the Amperage rating of the main service panel to which the interconnect will be connected',
        label: 'Service panel amperage',
        type: 'dropdown'
      },
      servicePanelVoltage: getServicePanelVoltage(systemRequirements),
      microinverter: getMicroInverter(isCommercial, interconnect, systemRequirements),
      centerTapping: {
        array: yesNoArrayList(),
        tooltip:
          'Select yes if you are center-feeding the branch circuits to reduce the Voltage rise. Enphase recommends Center feeding the branch-circuits to minimize the voltage rise in the fully populated branch. To center-feed a branch, divide the circuit into two sub-branch circuits protected by a single overcurrent protection device (OCPD) <a href="https://www4.enphase.com/sites/default/files/downloads/support/TechBrief-CalculatingACVoltageRise-IQ.pdf" target="_blank">Learn more</a>',
        label: 'Center Feeding',
        type: 'dropdown',
        disabled: getValue('microinverter') === 'IQ8D' && !isEmpty(getValue('panelOrientation'))
      },
      unusedCableDrops: {
        tooltip:
          'Please ensure phases are properly balanced in final design if not using all cable drops. Input percentage will be used to calculate extra Q cables (unused) and added to the BOM.',
        label: '% of Unused Cable Drops',
        type: 'textfield'
      },
      highElectricNoiseEnvironment: {
        array: yesNoArrayList(),
        tooltip:
          'Select Yes if significant power line electrical noise anticipated from customer loads and/or grid. Enphase recommends adding power line filters with Envoy for high noise environment',
        label: 'High Electric Noise Environment',
        type: 'dropdown'
      },
      nprRequired: {
        array: yesNoArrayList(),
        tooltip:
          'Select Yes if Network Protection Relay (NPR) is required for this project to meet local utility and/or AHJ compliance. An NPR will be added to the BOM <a href="https://www4.enphase.com/sites/default/files/downloads/support/3P-NPR-DS-EN-US.pdf" target="_blank">Learn more</a>',
        label: 'NPR Required?',
        type: 'dropdown',
        disabled: getValue('microinverter') === 'IQ8D' || getValue('servicePanelVoltage') === servicePanelVoltage120_240
      },
      advGridFunctionRequired: {
        array: yesNoArrayList(),
        label: 'Advanced Grid Function Required?',
        tooltip: `Select Yes if the project has specific advanced grid function requirements (eg. Power Export Limiting). Enphase team can discuss your specific project requirements with you and help determine the best solution`,
        type: 'dropdown'
      }
    };
  }
  if (requirement === storageOnly || requirement === existingStorageUpgrade) {
    return {
      storageSize: getStorageSize(isCommercial, interconnect, systemRequirements),
      ...(requirement === existingStorageUpgrade && {
        existingMicroinverter: {
          array: existingMicroinverterArrayList(),
          tooltip: `Please enter the microinverter used in Homeowner's existing PV system. We will recommend an upgrade if required`,
          label: 'Existing Microinverter',
          type: 'dropdown'
        },
        existingMicroinverterCount: {
          label: 'Existing Microinverter Count',
          type: 'textfield'
        },
        ...(getValue('existingMicroinverter').includes('IQ') && {
          existingCellModem: {
            array: yesNoArrayList(),
            tooltip: `Please tell us if  Homeowner's existing PV system has a cell modem. We will recommend an upgrade if required`,
            label: 'Existing Cell modem',
            type: 'dropdown'
          }
        })
      })
    };
  }
  return {
    pvManufacturer: getPVManufacturer(),
    pvModel: getPVModel(),
    requirementList: getRequirementList(isCommercial),
    pvPanelType: getPVPanelType(isCommercial),
    ...(getValue('pvPanelType') === acModule && {
      acModule: {
        array: acModuleArrayList(),
        label: 'AC Module',
        type: 'dropdown'
      }
    }),
    pvPanelWattage: getPVPanelWattage(),
    pvPanelsCount: getPVPanelsCount(),
    portraitPanelCount: getPortraitPanelCount(),
    landscapePanelCount: getLanscapePanelCount(),
    panelOrientation: getPanelOrientation(),
    servicePanelVoltage: getServicePanelVoltage(systemRequirements),
    ...(!isEmpty(getValue('pvPanelType')) &&
    getValue('pvPanelType') !== acModule && {
      microinverter: getMicroInverter(isCommercial, interconnect, systemRequirements)
    }),
    ...(requirement !== solarOnly &&
    requirement !== sunlightBackup && {
      storageSize: getStorageSize(isCommercial, interconnect, systemRequirements)
    })
  };
};

const getMicroType = name => {
  if (name === IQ6Plus) return 'IQ6P';
  else if (name === 'IQ8+') return 'IQ8PLUS';
  else if (name === 'IQ7+' || name === 'IQ7P') return 'IQ7PLUS';
  return name;
};

const getPanelType = name => {
  return name && name.toLowerCase() === 'ac module' ? 'ACM' : name;
};

const getStorageValue = storageConfig => {
  let result = '';
  storageSizeArrayList().forEach(storageSize => {
    if (storageSize.value === storageConfig) result = storageSize.key;
  });
  return result;
};

export const getStorageSizeDropDown = storageConfig => {
  let result = '';
  storageSizeArrayList().forEach(storageSize => {
    if (storageSize.key === storageConfig.toString()) result = storageSize.value;
  });
  return result;
};

export const getInterconnectsToSend = (
  interconnects,
  projectType,
  systemRequirements,
  pvManufacturersList,
  existingSystemDetails
) => {
  let modifiedInterconnects = [];
  const { requirement, sunlightBackupRequired, loadControllersRequired } = systemRequirements;

  if (projectType.toLowerCase() === 'commercial') {
    interconnects.forEach(interconnect => {
      const newInterconnect = {};
      newInterconnect['name'] = interconnect['name'];
      newInterconnect['maker_id'] = interconnect['pvManufacturer'] === 'Custom' ? null : interconnect['pvManufacturer'];
      newInterconnect['module_id'] = interconnect['pvModel'] === 'Custom' ? null : interconnect['pvModel'];
      newInterconnect['panel_type'] = interconnect['pvPanelType'];
      newInterconnect['panel_wdc'] = Number(interconnect['pvPanelWattage']);
      newInterconnect['panel_count'] = Number(interconnect['pvPanelsCount']);
      newInterconnect['panel_orientation'] = interconnect['panelOrientation'];
      newInterconnect['service_panel_amperage'] = interconnect['servicePanelAmperage'];
      newInterconnect['service_panel_voltage'] = interconnect['servicePanelVoltage'];
      newInterconnect['inverter_type'] = interconnect['microinverter'];
      newInterconnect['is_center_tapping'] = interconnect['centerTapping'].toLowerCase() === 'yes';
      newInterconnect['percentage_of_unused_cable_drops'] = Number(interconnect['unusedCableDrops']);
      newInterconnect['is_high_electric_noise'] = interconnect['highElectricNoiseEnvironment'].toLowerCase() === 'yes';
      newInterconnect['is_npr_required'] = interconnect['nprRequired'].toLowerCase() === 'yes';
      newInterconnect['is_adv_grid_function_required'] =
        interconnect['advGridFunctionRequired'].toLowerCase() === 'yes';
      newInterconnect['sunlight_backup_required'] = sunlightBackupRequired;
      modifiedInterconnects.push(newInterconnect);
    });
  } else {
    interconnects.forEach(interconnect => {
      const newInterconnect = {};
      let storageSize = null,
        e3_count = null,
        e10_count = null;
      if (!isEmpty(interconnect['storageSize'])) {
        storageSize = parseFloat(getStorageValue(interconnect['storageSize']));
        let x = Math.floor(Math.max(0, storageSize / 3.3));
        let y = Math.floor(Math.max(0, storageSize / 10));
        e3_count = Math.floor(Math.max(0, x - 3 * y));
        e10_count = y;
      }
      newInterconnect['name'] = interconnect['name'];
      newInterconnect['sunlight_backup_required'] = sunlightBackupRequired;
      newInterconnect['load_controllers_required'] = loadControllersRequired;

      if (
        requirement === solarOnly ||
        requirement === 'Solar + Storage' ||
        requirement === sunlightBackup ||
        requirement === homeEssentialBackup ||
        requirement === fullEnergyIndependence
      ) {
        newInterconnect['panel_orientation'] = interconnect['panelOrientation'];
        newInterconnect['portrait_panel_count'] = interconnect['portraitPanelCount'];
        newInterconnect['landscape_panel_count'] = interconnect['landscapePanelCount'];
      }
      if (requirement === solarOnly || requirement === sunlightBackup) {
        newInterconnect['maker_id'] =
          interconnect['pvManufacturer'] === 'Custom' ? null : interconnect['pvManufacturer'];
        newInterconnect['module_id'] = interconnect['pvModel'] === 'Custom' ? null : interconnect['pvModel'];
      }
      if (requirement !== storageOnly) {
        newInterconnect['panel_type'] = getPanelType(interconnect['pvPanelType']);
        newInterconnect['panel_wdc'] = interconnect['pvPanelWattage'];
        newInterconnect['panel_count'] = requirement === existingStorageUpgrade ? '' : interconnect['pvPanelsCount'];
        newInterconnect['service_panel_voltage'] = interconnect['servicePanelVoltage'];
        if (newInterconnect['panel_type'] !== 'ACM') {
          newInterconnect['micro_type'] = getMicroType(interconnect['microinverter']);
        }
        newInterconnect['panel_maker'] =
          newInterconnect['panel_type'] === 'ACM' ? panelMakers[interconnect['acModule']] : 'DISCRETE_MODULES';
        newInterconnect['module_name'] =
          newInterconnect['panel_type'] === 'ACM' ? panelMakers[interconnect['acModule']] : 'DISCRETE_MODULES';
      }
      if (requirement !== solarOnly && requirement !== sunlightBackup) {
        newInterconnect['storage_size'] = storageSize;
        newInterconnect['e3_count'] = e3_count;
        newInterconnect['e10_count'] = e10_count;
      }
      if (requirement === existingStorageUpgrade) {
        if (
          interconnect['existingMicroinverter'] === 'M175' ||
          interconnect['existingMicroinverter'] === 'M190' ||
          interconnect['existingMicroinverter'] === 'M210' ||
          interconnect['existingMicroinverter'] === 'D380'
        ) {
          interconnect['existingCellModem'] = yes;
        } else if (
          interconnect['existingMicroinverter'] === 'M215' ||
          interconnect['existingMicroinverter'] === 'M250'
        ) {
          interconnect['existingCellModem'] = no;
        }
        newInterconnect['cell_modem_required'] =
          interconnect['existingCellModem'].toLowerCase() === 'yes' ? false : true;
        newInterconnect['existing_micros'] = {
          [getMicroType(interconnect['existingMicroinverter'])]: Number(interconnect['existingMicroinverterCount'])
        };
        // Defaulting to this
        newInterconnect['service_panel_voltage'] = servicePanelVoltage120_240;
        newInterconnect['micro_type'] = IQ7;
      }
      newInterconnect['purchase_type'] = requirement === existingStorageUpgrade ? 'EXISTING' : 'NEW';
      modifiedInterconnects.push(newInterconnect);
    });
  }
  return modifiedInterconnects;
};

export const getInputDataFromLead = response => {
  const inputData = {};
  const projectType = (get(response, 'project_type', 'residential') || 'residential').toLowerCase();
  const requirement = requirementMap[get(response, 'product_type', '')];
  inputData['projectType'] = projectType;
  inputData['systemRequirements'] = {
    requirement: requirement,
    addMoreToggle: true,
    customerDetails: {
      name: `${get(response, 'first_name', '')} ${get(response, 'last_name', '')}`,
      email: get(response, 'email', ''),
      mobile: get(response, 'phone', ''),
      address: [
        get(response, 'address.address1', ''),
        get(response, 'address.address2', ''),
        get(response, 'address.city', ''),
        get(response, 'address.state', ''),
        get(response, 'address.zip', ''),
        get(response, 'address.country', '')
      ].join(', '),
      zipCode: get(response, 'address.zip', ''),
      addressObject: get(response, 'address', {}),
      sunlightBackupRequired: false,
      loadControllersRequired: false
    }
  };
  return inputData;
};
