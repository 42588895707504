import moment from 'moment';
// import 'moment/locale/de';
// import 'moment/locale/es';
// import 'moment/locale/fr';
// import 'moment/locale/it';
// import 'moment/locale/nl';

export let localeMoment = moment;

export default function getMoment(locale) {
  var momentVariable = moment;
  momentVariable.locale(locale);
  localeMoment = momentVariable;
}
