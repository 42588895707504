import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  autocomplete: {
    marginTop: theme.spacing(2)
  },
  systemDetailsWrapper: {
    justifyContent: 'space-between'
  },
  leftHeading: {
    display: 'flex',
    alignItems: 'flex-end',
    width: '55%',
    paddingRight: theme.spacing(3.5),
    [theme.breakpoints.down('xs')]: {
      paddingRight: theme.spacing(2.25)
    }
  },
  rightSection: {
    width: '45%'
  },
  styleModuleCompatibilty: {
    fontSize: 12,
    color: theme.palette.primary.main,
    cursor: 'pointer'
  },
  systemDetailsField: {
    width: '90%',
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    }
  },
  customLabel: {
    '& .Mui-focused': {
      '& label': {
        display: 'none'
      }
    },
    '& .MuiInputLabel-shrink': {
      '& label': {
        display: 'none'
      }
    },
    '& .MuiInput-underline.Mui-disabled:before': {
      borderBottomStyle: 'solid'
    }
  },
  styleLabel: {
    color: '#4A4A4A'
  },
  styleRecentDivider: {
    width: '100%',
    backgroundColor: '#4b4b4ba3'
  },
  colorGrey: {
    color: 'grey'
  }
}));

export default useStyles;
