import React, { Component } from 'react';
import { withTheme, withStyles } from '@material-ui/core/styles';
import { Typography, Box, Dialog } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import clsx from 'clsx';
import { cloneDeep } from 'lodash';

const styles = theme => ({
  card: {
    padding: theme.spacing(3)
  },
  ratingButton: {
    border: '1px solid #233643 ',
    borderRadius: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: theme.spacing(4.25),
    width: theme.spacing(4.25),
    marginRight: theme.spacing(1.5),
    outlineWidth: 0,
    backgroundColor: 'white',
    '&:hover': {
      cursor: 'pointer'
    },
    [theme.breakpoints.down('sm')]: {
      height: theme.spacing(3.25),
      width: theme.spacing(3.25)
    },
    [theme.breakpoints.down('xs')]: {
      height: theme.spacing(2.9),
      width: theme.spacing(2.9)
    }
  },
  displayFlex: {
    display: 'flex',
    marginBottom: theme.spacing(2),
    flexWrap: 'wrap'
  },
  displayButtonFlex: {
    display: 'flex',
    flexDirection: 'column'
  },
  ratingText: {
    paddingLeft: theme.spacing(0.5),
    fontSize: theme.spacing(1.5)
  },
  backgroundRed: {
    backgroundColor: '#E43E3D',
    borderColor: '#E43E3D !important'
  },
  backgroundOrange: {
    backgroundColor: '#EF874C',
    borderColor: '#EF874C !important'
  },
  backgroundYellow: {
    backgroundColor: '#F8C43C',
    borderColor: '#F8C43C !important'
  },
  backgroundGreen: {
    backgroundColor: '#5BAF2A',
    borderColor: '#5BAF2A !important'
  },
  borderRed: {
    border: '1px solid #E43E3D'
  },
  borderOrange: {
    border: '1px solid #EF874C'
  },
  borderYellow: {
    border: '1px solid #F8C43C'
  },
  borderGreen: {
    border: '1px solid #5BAF2A'
  },
  poorText: {
    paddingLeft: theme.spacing(0.5),
    marginLeft: theme.spacing(-0.5),
    color: '#EF212C',
    fontSize: theme.spacing(1.5),
    [theme.breakpoints.down('sm')]: {
      fontSize: theme.spacing(1.2),
      marginLeft: theme.spacing(0)
    },
    [theme.breakpoints.down('xs')]: {
      paddingLeft: 0,
      marginLeft: theme.spacing(0)
    }
  },
  greatText: {
    paddingLeft: theme.spacing(0.5),
    color: '#3C9C46',
    fontSize: theme.spacing(1.5),
    [theme.breakpoints.down('sm')]: {
      fontSize: theme.spacing(1.2)
    },
    [theme.breakpoints.down('xs')]: {
      paddingLeft: 0
    }
  },
  buttonWrapper: {
    justifyContent: 'start',
    marginTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    marginLeft: theme.spacing(3)
  },
  button: {
    color: theme.palette.primary.white,
    height: theme.spacing(5),
    borderRadius: 0,
    width: theme.spacing(14.5)
  },
  fontSize: {
    fontSize: theme.spacing(3)
  },
  contentColor: {
    color: '#4B4B4B',
    fontSize: theme.spacing(2),
    margin: theme.spacing(2, 0)
  },
  cancelButton: {
    color: '#919191',
    marginLeft: theme.spacing(2)
  }
});
class NPSFeedback extends Component {
  constructor(props) {
    super(props);
    this.search = window.location.search;
    this.params = new URLSearchParams(this.search);
    this.ratingValue = this.params.get('rating') !== null ? this.params.get('rating') : -1;
    this.state = {
      clicked: false,
      rating: 0,
      feedback: '',
      feedbackSubmitted: false,
      ratingObject: [
        {
          colourClass: 'backgroundRed',
          borderClass: 'borderRed',
          text: 'Poor',
          className: ''
        },
        {
          colourClass: 'backgroundRed',
          borderClass: 'borderRed',
          text: '',
          className: ''
        },
        {
          colourClass: 'backgroundRed',
          borderClass: 'borderRed',
          text: '',
          className: ''
        },
        {
          colourClass: 'backgroundOrange',
          borderClass: 'borderOrange',
          text: '',
          className: ''
        },
        {
          colourClass: 'backgroundOrange',
          borderClass: 'borderOrange',
          text: '',
          className: ''
        },
        {
          colourClass: 'backgroundOrange',
          borderClass: 'borderOrange',
          text: '',
          className: ''
        },
        {
          colourClass: 'backgroundOrange',
          borderClass: 'borderOrange',
          text: '',
          className: ''
        },
        {
          colourClass: 'backgroundYellow',
          borderClass: 'borderYellow',
          text: '',
          className: ''
        },
        {
          colourClass: 'backgroundYellow',
          borderClass: 'borderYellow',
          text: '',
          className: ''
        },
        {
          colourClass: 'backgroundGreen',
          borderClass: 'borderGreen',
          text: '',
          className: ''
        },
        {
          colourClass: 'backgroundGreen',
          borderClass: 'borderGreen',
          text: 'Great',
          className: ''
        }
      ]
    };
  }

  handleMouseHover = index => {
    if (!this.state.clicked) {
      const { classes } = this.props;
      const tempRatingObject = cloneDeep(this.state.ratingObject);
      tempRatingObject[index].className = classes[this.state.ratingObject[index].colourClass];
      this.setState({ ratingObject: [ ...tempRatingObject ] });
    }
  };

  handleMouseLeave = index => {
    if (!this.state.clicked) {
      const tempRatingObject = cloneDeep(this.state.ratingObject);
      tempRatingObject[index].className = '';
      this.setState({ ratingObject: [ ...tempRatingObject ] });
    }
  };

  feedbackChange = e => {
    this.setState({ feedback: e.target.value });
  };

  buttonClick = (e, ind) => {
    this.setState({ clicked: true, rating: e.target.value });
    this.changeButtonColours(ind);
  };

  changeButtonColours = ratingValue => {
    const { classes } = this.props;
    const tempRatingObject = cloneDeep(this.state.ratingObject);
    for (let ind in this.state.ratingObject) {
      tempRatingObject[ind].className = '';
    }
    for (let i = 0; i <= ratingValue; i++) {
      tempRatingObject[i].className = classes[this.state.ratingObject[ratingValue].colourClass];
    }
    this.setState({ ratingObject: [ ...tempRatingObject ] });
  };

  handleFeedbackSubmit = () => {
    const { onSubmit } = this.props;
    onSubmit({ comment: this.state.feedback, rating: this.state.rating });
  };

  render() {
    const { classes, openNPSPopup } = this.props;
    return (
      <div>
        <Dialog open={openNPSPopup} onClose={() => {}}>
          <Box className={classes.container}>
            <Box className={classes.card}>
              <Box className={classes.contentColor}>{`Thank you for rating your experience with BOM Calculator`} </Box>
              <div className={classes.displayFlex}>
                {this.state.ratingObject.map((ele, idx) => {
                  return (
                    <div key={idx} className={classes.displayButtonFlex}>
                      <button
                        id={idx}
                        onMouseOver={e => this.handleMouseHover(idx)}
                        onMouseLeave={e => this.handleMouseLeave(idx)}
                        className={clsx(classes.ratingButton, classes[ele.borderClass], ele.className)}
                        value={idx}
                        onClick={e => this.buttonClick(e, idx)}
                      >
                        {idx}
                      </button>
                      <Typography
                        className={clsx(classes.ratingText, ele.text === 'Poor' ? classes.poorText : classes.greatText)}
                      >
                        {ele.text}
                      </Typography>
                    </div>
                  );
                })}
              </div>

              <Box className={classes.contentColor}>
                If you would like to share specific comments please enter them below
              </Box>

              <TextField
                value={this.state.feedback}
                onChange={e => this.feedbackChange(e)}
                multiline
                rows={4}
                rowsMax={16}
                fullWidth
                variant="outlined"
              />
            </Box>
            <Box className={classes.buttonWrapper}>
              <Button
                color="primary"
                variant="contained"
                disableElevation
                className={classes.button}
                onClick={e => this.handleFeedbackSubmit()}
              >
                Submit
              </Button>
            </Box>
          </Box>
        </Dialog>
      </div>
    );
  }
}

export default withTheme(withStyles(styles)(NPSFeedback));
