import * as React from 'react';
import { SvgIcon } from '@material-ui/core';

const Commercial = props => {
  return (
    <SvgIcon {...props} width="50.5" height="50.5" viewBox="0 0 50.5 50.5">
      <path
        fill={props.isselected === 'true' ? '#f37320' : '#4b4b4b'}
        opacity={props.isselected === 'true' ? 1 : 0.64}
        stroke="#fff"
        strokeWidth={2.5}
        d="M30.8,16.4h2.4a2.4,2.4,0,1,0,0-4.8H30.8a2.4,2.4,0,1,0,0,4.8Zm0,9.6h2.4a2.4,2.4,0,1,0,0-4.8H30.8a2.4,2.4,0,1,0,0,4.8Zm-12-9.6h2.4a2.4,2.4,0,1,0,0-4.8H18.8a2.4,2.4,0,0,0,0,4.8Zm0,9.6h2.4a2.4,2.4,0,1,0,0-4.8H18.8a2.4,2.4,0,0,0,0,4.8ZM47.6,45.2H45.2V4.4A2.4,2.4,0,0,0,42.8,2H9.2A2.4,2.4,0,0,0,6.8,4.4V45.2H4.4a2.4,2.4,0,0,0,0,4.8H47.6a2.4,2.4,0,1,0,0-4.8Zm-19.2,0H23.6V35.6h4.8Zm12,0H33.2v-12a2.4,2.4,0,0,0-2.4-2.4H21.2a2.4,2.4,0,0,0-2.4,2.4v12H11.6V6.8H40.4Z"
        transform="translate(-0.75 -0.75)"
      />
    </SvgIcon>
  );
};

export default Commercial;
