import { call, take, put, takeEvery } from 'redux-saga/effects';

import {
  SITE_SEARCH_LEAD_REQUEST,
  SITE_SEARCH_LEAD_SUCCESS,
  SITE_SEARCH_LEAD_FAILURE,
  SET_BOM_INPUT_DETAILS_REQUEST,
  SET_BOM_INPUT_DETAILS_SUCCESS,
  SET_BOM_INPUT_DETAILS_FAILURE,
  GET_TEMPLATES_REQUEST,
  GET_TEMPLATES_SUCCESS,
  GET_TEMPLATES_FAILURE,
  SAVE_TEMPLATE_REQUEST,
  SAVE_TEMPLATE_SUCCESS,
  SAVE_TEMPLATE_FAILURE,
  CONNECT_BOM_TO_LEAD_REQUEST,
  CONNECT_BOM_TO_LEAD_SUCCESS,
  CONNECT_BOM_TO_LEAD_FAILURE,
  GET_LEAD_BOM_DETAILS_REQUEST,
  GET_LEAD_BOM_DETAILS_SUCCESS,
  GET_LEAD_BOM_DETAILS_FAILURE,
  GET_PV_MANUFACTURERS_SUCCESS,
  GET_PV_MANUFACTURERS_FAILURE,
  GET_PV_MANUFACTURERS_REQUEST,
  GET_PV_MODELS_REQUEST,
  GET_PV_MODELS_SUCCESS,
  GET_PV_MODELS_FAILURE,
  GET_EXISTING_SYSTEM_DETAILS_REQUEST,
  GET_EXISTING_SYSTEM_DETAILS_SUCCESS,
  GET_EXISTING_SYSTEM_DETAILS_FAILURE,
  CALCULATE_RESIDENTIAL_BOM_OUTPUT_REQUEST,
  CALCULATE_RESIDENTIAL_BOM_OUTPUT_SUCCESS,
  CALCULATE_RESIDENTIAL_BOM_OUTPUT_FAILURE,
  GET_CART_URL_REQUEST,
  GET_CART_URL_SUCCESS,
  GET_CART_URL_FAILURE,
  SET_CURRENTLY_SENDING_FALSE,
  CREATE_TEMPLATE_REQUEST,
  CREATE_TEMPLATE_SUCCESS,
  CREATE_TEMPLATE_FAILURE,
  DELETE_TEMPLATE_REQUEST,
  DELETE_TEMPLATE_SUCCESS,
  DELETE_TEMPLATE_FAILURE,
  CHECK_EXISTING_EMAIL_REQUEST,
  CHECK_EXISTING_EMAIL_SUCCESS,
  CHECK_EXISTING_EMAIL_FAILURE,
  GET_RECENTLY_USED_PV_REQUEST,
  GET_RECENTLY_USED_PV_SUCCESS,
  GET_RECENTLY_USED_PV_FAILURE
} from './constants';
import { deleteMethod, get, post, putMethod } from '../../utils/api';
import settings from '../../utils/settings';

let siteSearchLeadRequest = false;
export function* getSiteSearchLeadsFlow() {
  while (true) {
    const request = yield take(SITE_SEARCH_LEAD_REQUEST);
    if (!siteSearchLeadRequest) {
      siteSearchLeadRequest = true;
      try {
        let response;
        const { searchValue = '' } = request.payload;
        response = yield call(get, {
          url: settings.getEnlightenProxyApiUrl({
            url: settings.getApiUrl('api_internal/sites'),
            method: 'GET',
            columns: 'system_id,name,city,state,system_id,zip,owner_info,address,country',
            limit: 10,
            page: 1,
            'site[stage][text]': 'all',
            'site[term][text]': searchValue
          })
        });
        siteSearchLeadRequest = false;
        if (response.success) {
          yield put({ type: SITE_SEARCH_LEAD_SUCCESS, siteSearchList: response.result.sites });
        } else {
          yield put({ type: SITE_SEARCH_LEAD_FAILURE, message: response.result.message });
        }
      } catch (error) {
        siteSearchLeadRequest = false;
        yield put({ type: SITE_SEARCH_LEAD_FAILURE, message: error });
      }
    }
  }
}

let sendingSetBomDetailsRequest = false;
export function* setBOMInputDetailsFlow() {
  while (true) {
    const request = yield take(SET_BOM_INPUT_DETAILS_REQUEST);
    if (!sendingSetBomDetailsRequest) {
      sendingSetBomDetailsRequest = true;
      try {
        let response;
        const url = settings.bomApiUrl(`bom/bom_for_interconnects`);
        const { bomObject = {}, successCbk = () => {} } = request.payload;
        response = yield call(post, {
          url: url,
          body: bomObject,
          withBearer: true
        });
        sendingSetBomDetailsRequest = false;
        if (response.success) {
          yield put({ type: SET_BOM_INPUT_DETAILS_SUCCESS, bomDetailsArray: response.result });
          successCbk(response.result);
        } else {
          yield put({
            type: SET_BOM_INPUT_DETAILS_FAILURE,
            message: response.result.message || response.result.messages
          });
        }
      } catch (error) {
        sendingSetBomDetailsRequest = false;
        yield put({ type: SET_BOM_INPUT_DETAILS_FAILURE, message: error });
      }
    }
  }
}

let sendingGetTemplateRequest = false;
export function* getAllTemplatesFlow() {
  while (true) {
    yield take(GET_TEMPLATES_REQUEST);
    if (!sendingGetTemplateRequest) {
      sendingGetTemplateRequest = true;
      try {
        let response;
        response = yield call(get, {
          url: settings.bomApiUrl(`template/all`),
          withBearer: true
        });
        sendingGetTemplateRequest = false;
        if (response.success) {
          yield put({ type: GET_TEMPLATES_SUCCESS, templatesList: response.result });
        } else {
          yield put({ type: GET_TEMPLATES_FAILURE, message: response.result.message });
        }
      } catch (error) {
        sendingGetTemplateRequest = false;
        yield put({ type: GET_TEMPLATES_FAILURE, message: error });
      }
    }
  }
}

let sendingSaveTemplateRequest = false;
export function* saveTemplateFlow() {
  while (true) {
    const request = yield take(SAVE_TEMPLATE_REQUEST);
    const { id, sendTemplateBody, successCbk = () => {} } = request.payload;
    if (!sendingSaveTemplateRequest) {
      sendingSaveTemplateRequest = true;
      try {
        let response;
        response = yield call(putMethod, {
          url: settings.bomApiUrl(`template/${id}`),
          body: sendTemplateBody,
          withBearer: true
        });
        sendingSaveTemplateRequest = false;
        if (response.success) {
          yield put({ type: SAVE_TEMPLATE_SUCCESS });
          successCbk();
        } else {
          yield put({ type: SAVE_TEMPLATE_FAILURE, message: response.result.message });
        }
      } catch (error) {
        sendingSaveTemplateRequest = false;
        yield put({ type: SAVE_TEMPLATE_FAILURE, message: error });
      }
    }
  }
}

let sendingCreateTemplateRequest = false;
export function* createTemplateFlow() {
  while (true) {
    const request = yield take(CREATE_TEMPLATE_REQUEST);
    const { createTemplateBody, successCbk = () => {} } = request.payload;
    if (!sendingCreateTemplateRequest) {
      sendingCreateTemplateRequest = true;
      try {
        let response;
        response = yield call(post, {
          url: settings.bomApiUrl(`template/create`),
          body: createTemplateBody,
          withBearer: true
        });
        sendingCreateTemplateRequest = false;
        if (response.success) {
          yield put({ type: CREATE_TEMPLATE_SUCCESS });
          successCbk();
        } else {
          yield put({ type: CREATE_TEMPLATE_FAILURE, message: response.result.message });
        }
      } catch (error) {
        sendingCreateTemplateRequest = false;
        yield put({ type: CREATE_TEMPLATE_FAILURE, message: error });
      }
    }
  }
}

let sendingDeleteTemplateRequest = false;
export function* deleteTemplateFlow() {
  while (true) {
    const request = yield take(DELETE_TEMPLATE_REQUEST);
    const { templateID, successCbk = () => {} } = request.payload;
    if (!sendingDeleteTemplateRequest) {
      sendingDeleteTemplateRequest = true;
      try {
        let response;
        response = yield call(deleteMethod, {
          url: settings.bomApiUrl(`template/${templateID}`),
          withBearer: true
        });
        sendingDeleteTemplateRequest = false;
        if (response.success) {
          yield put({ type: DELETE_TEMPLATE_SUCCESS });
          successCbk();
        } else {
          yield put({ type: DELETE_TEMPLATE_FAILURE, message: response.result.message });
        }
      } catch (error) {
        sendingDeleteTemplateRequest = false;
        yield put({ type: DELETE_TEMPLATE_FAILURE, message: error });
      }
    }
  }
}

let sendingConnectBOMToLeadRequest = false;
export function* connectBOMToLeadFlow() {
  while (true) {
    const request = yield take(CONNECT_BOM_TO_LEAD_REQUEST);
    const { bomObject, successCbk = () => {}, failureCbk = () => {} } = request.payload;
    if (!sendingConnectBOMToLeadRequest) {
      sendingConnectBOMToLeadRequest = true;
      try {
        let response;
        response = yield call(post, {
          url: settings.digiCoreUrl(`lead_with_bom`),
          body: bomObject,
          withBearer: true
        });
        sendingConnectBOMToLeadRequest = false;
        if (response.success) {
          yield put({ type: CONNECT_BOM_TO_LEAD_SUCCESS, leadJourneyObject: response.result.lead_journey });
          successCbk();
        } else {
          yield put({ type: CONNECT_BOM_TO_LEAD_FAILURE, message: response.result.message });
          failureCbk();
        }
      } catch (error) {
        sendingConnectBOMToLeadRequest = false;
        yield put({ type: CONNECT_BOM_TO_LEAD_FAILURE, message: error });
      }
    }
  }
}

let getLeadBOMDetailsRequest = false;
export function* getLeadBOMDetailsFlow() {
  while (true) {
    const request = yield take(GET_LEAD_BOM_DETAILS_REQUEST);
    if (!getLeadBOMDetailsRequest) {
      getLeadBOMDetailsRequest = true;
      try {
        let response;
        const { leadId = '', journeyType = '', companyId = '', successCbk = () => {} } = request.payload;
        const url = settings.digiCoreUrl(
          `installers/leads/${leadId}?installer_id=${companyId}&journey_type=${journeyType}`
        );
        response = yield call(get, {
          url: url,
          withBearer: true
        });
        getLeadBOMDetailsRequest = false;
        if (response.success) {
          successCbk(response.result);
          yield put({
            type: GET_LEAD_BOM_DETAILS_SUCCESS,
            leadDetails: response.result,
            bomDetailsArray: response.result.projectBOM,
            leadId: response.result.lead_id
          });
        } else {
          yield put({
            type: GET_LEAD_BOM_DETAILS_FAILURE,
            message: response.result.message || response.result.messages
          });
        }
      } catch (error) {
        getLeadBOMDetailsRequest = false;
        yield put({ type: GET_LEAD_BOM_DETAILS_FAILURE, message: error });
      }
    }
  }
}

let gettingPVManufacturersRequest = false;
export function* getPVManufacturersFlow() {
  while (true) {
    yield take(GET_PV_MANUFACTURERS_REQUEST);
    if (!gettingPVManufacturersRequest) {
      gettingPVManufacturersRequest = true;
      try {
        let response;
        response = yield call(get, {
          url: settings.bomApiUrl(`panels/makers`),
          withBearer: true
        });
        gettingPVManufacturersRequest = false;
        if (response.success) {
          yield put({ type: GET_PV_MANUFACTURERS_SUCCESS, pvManufacturersList: response.result });
        } else {
          yield put({ type: GET_PV_MANUFACTURERS_FAILURE, message: response.result.message });
        }
      } catch (error) {
        gettingPVManufacturersRequest = false;
        yield put({ type: GET_PV_MANUFACTURERS_FAILURE, message: error });
      }
    }
  }
}

export function* getPVModelsHelper(request) {
  const { id, successCbk = () => {} } = request.payload;
  try {
    let response;
    response = yield call(get, {
      url: settings.bomApiUrl(`panels/makers/${id}/modules`),
      withBearer: true
    });
    if (response.success) {
      successCbk(response.result);
      yield put({ type: GET_PV_MODELS_SUCCESS });
      yield put({ type: SET_CURRENTLY_SENDING_FALSE, keyName: 'currentlySending' });
    } else {
      yield put({ type: GET_PV_MODELS_FAILURE });
      yield put({ type: SET_CURRENTLY_SENDING_FALSE, keyName: 'currentlySending' });
    }
  } catch (error) {
    yield put({ type: GET_PV_MODELS_FAILURE });
    yield put({ type: SET_CURRENTLY_SENDING_FALSE, keyName: 'currentlySending' });
  }
}

export function* getPVModelsFlow() {
  yield takeEvery(GET_PV_MODELS_REQUEST, getPVModelsHelper);
}

let sendingGetSystemDetailsRequest = false;
export function* getExistingSystemDetailsFlow() {
  while (true) {
    const request = yield take(GET_EXISTING_SYSTEM_DETAILS_REQUEST);
    const { systemId, email, zipCode, successCbk = () => {} } = request.payload;
    if (!sendingGetSystemDetailsRequest) {
      sendingGetSystemDetailsRequest = true;
      try {
        let response;
        response = yield call(get, {
          url: settings.digiCoreUrl(`systems/system_meta_data?site_id=${systemId}&email=${email}&zipcode=${zipCode}`),
          withBearer: true
        });
        sendingGetSystemDetailsRequest = false;
        if (response.success) {
          yield put({ type: GET_EXISTING_SYSTEM_DETAILS_SUCCESS, existingSystemDetails: response.result });
          successCbk(response.result);
        } else {
          yield put({ type: GET_EXISTING_SYSTEM_DETAILS_FAILURE, message: response.result.message });
        }
      } catch (error) {
        sendingGetSystemDetailsRequest = false;
        yield put({ type: GET_EXISTING_SYSTEM_DETAILS_FAILURE, message: error });
      }
    }
  }
}

let calculateResidentialBOMOutput = false;
export function* calculateResidentialBOMOutputFlow() {
  while (true) {
    const request = yield take(CALCULATE_RESIDENTIAL_BOM_OUTPUT_REQUEST);
    if (!calculateResidentialBOMOutput) {
      calculateResidentialBOMOutput = true;
      try {
        let response;
        const url = settings.bomApiUrl(`bom/residential_bom`);
        const { bomObject = {}, successCbk = () => {} } = request.payload;
        response = yield call(post, {
          url: url,
          body: bomObject,
          withBearer: true
        });
        calculateResidentialBOMOutput = false;
        if (response.success) {
          yield put({ type: CALCULATE_RESIDENTIAL_BOM_OUTPUT_SUCCESS, bomDetailsArray: response.result });
          successCbk(response.result);
        } else {
          yield put({
            type: CALCULATE_RESIDENTIAL_BOM_OUTPUT_FAILURE,
            message: response.result.message || response.result.messages
          });
        }
      } catch (error) {
        calculateResidentialBOMOutput = false;
        yield put({ type: CALCULATE_RESIDENTIAL_BOM_OUTPUT_FAILURE, message: error });
      }
    }
  }
}

let sendingGetCartURL = false;
export function* getCartUrlFlow(payload) {
  while (true) {
    const request = yield take(GET_CART_URL_REQUEST);
    const { skuDetails, successCb, failureCb } = request.payload;
    if (!sendingGetCartURL) {
      sendingGetCartURL = true;
      try {
        let response;
        let url = 'store/addToCart';
        response = yield call(post, {
          url: settings.digiCoreUrl(url),
          withBearer: true,
          body: { skuDetails: skuDetails }
        });
        const { success, status, result } = response;
        sendingGetCartURL = false;
        if (success && status === 200) {
          yield put({
            type: GET_CART_URL_SUCCESS
          });
          successCb(result);
        } else {
          yield put({ type: GET_CART_URL_FAILURE, message: result.message || result.messages });
          failureCb();
        }
      } catch (error) {
        sendingGetCartURL = false;
        yield put({ type: GET_CART_URL_FAILURE, message: error });
        failureCb();
      }
    }
  }
}

let sendingCheckingExistingEmail = false;
export function* checkExistingEmailFlow() {
  while (true) {
    const request = yield take(CHECK_EXISTING_EMAIL_REQUEST);
    const { emailObject, successCbk = () => {} } = request.payload;
    if (!sendingCheckingExistingEmail) {
      sendingCheckingExistingEmail = true;
      try {
        let response;
        response = yield call(post, {
          url: settings.digiCoreUrl(`users/validate_lead`),
          body: emailObject,
          withBearer: true
        });
        sendingCheckingExistingEmail = false;
        const { status, result } = response;
        if (status === 200) {
          successCbk(result);
          yield put({ type: CHECK_EXISTING_EMAIL_SUCCESS });
        } else {
          yield put({ type: CHECK_EXISTING_EMAIL_FAILURE });
        }
      } catch (error) {
        sendingCheckingExistingEmail = false;
        yield put({ type: CHECK_EXISTING_EMAIL_FAILURE });
      }
    }
  }
}

export function* getRecentlyUsedPVManufacturersModulesHelper(request) {
  const { makerId = '', successCbk = () => {} } = request.payload;
  const isForModule = makerId !== '';
  try {
    let response;
    response = yield call(get, {
      url: isForModule
        ? settings.digiCoreUrl(`recently_used_material?maker_id=${makerId}`)
        : settings.digiCoreUrl(`recently_used_material`),
      withBearer: true
    });
    if (response.success) {
      if (isForModule) {
        yield put({ type: SET_CURRENTLY_SENDING_FALSE, keyName: 'currentlySendingRecentPVDetails' });
        successCbk(response.result);
      } else {
        yield put({
          type: GET_RECENTLY_USED_PV_SUCCESS,
          listName: 'recentlyUsedPVManufacturersList',
          recentlyUsedList: response.result
        });
      }
    } else {
      yield put({ type: GET_RECENTLY_USED_PV_FAILURE, message: response.result.message });
    }
  } catch (error) {
    yield put({ type: GET_RECENTLY_USED_PV_FAILURE, message: error });
  }
}

export function* getRecentlyUsedPVManufacturersModulesFlow() {
  yield takeEvery(GET_RECENTLY_USED_PV_REQUEST, getRecentlyUsedPVManufacturersModulesHelper);
}
