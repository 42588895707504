import React, { Fragment, useState } from 'react';
import clsx from 'clsx';
import { Box, Button, Divider, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { get, isEmpty } from 'lodash';
import { connect } from 'react-redux';

import { LightTooltip } from '../CommonFields/infoTooltip';
import { mandatoryInfo, saveBomInfo } from '../../../containers/BOMCalculator/constants';
import { trackEvents } from '../../../utils/helper';

const useStyles = makeStyles(theme => ({
  buttonRowInput: {
    position: 'fixed',
    bottom: 0,
    right: 11,
    width: '100%',
    background: '#FFFFFF',
    display: 'flex',
    padding: theme.spacing(2.5, 0),
    justifyContent: 'flex-end',
    paddingRight: theme.spacing(4),
    borderTop: '1px solid #D1D1D1',
    borderBottom: '1px solid #D1D1D1',
    zIndex: 5
  },
  buttonRowOutput: {
    position: 'fixed',
    bottom: 0,
    width: '100%',
    background: '#FFFFFF',
    paddingRight: theme.spacing(1.375),
    borderTop: '1px solid #D1D1D1',
    borderBottom: '1px solid #D1D1D1',
    zIndex: 5
  },
  displayFlex: {
    display: 'flex'
  },
  justifyContentNormal: {
    justifyContent: 'normal'
  },
  calculateButton: {
    color: theme.palette.primary.white,
    width: theme.spacing(25),
    cursor: 'pointer',
    backgroundColor: theme.palette.primary.main,
    display: 'flex',
    textAlign: 'center',
    justifyContent: 'center',
    alignItems: 'center'
  },
  backButtonWrapper: {
    padding: theme.spacing(2.5, 0)
  },
  backButtonWrapperWidth: {
    width: '33%',
    [theme.breakpoints.down('sm')]: {
      width: '61%'
    },
    [theme.breakpoints.down('md')]: {
      width: '73%'
    },
    [theme.breakpoints.down('xs')]: {
      width: '33%'
    }
  },
  backButton: {
    marginLeft: theme.spacing(2.75),
    width: theme.spacing(20),
    backgroundColor: '#FFF',
    color: theme.palette.primary.main,
    border: '1px solid #F3731F',
    [theme.breakpoints.down('xs')]: {
      width: theme.spacing(10)
    },
    '&:hover': {
      backgroundColor: theme.palette.primary.white
    }
  },
  backButtonPadding: {
    padding: theme.spacing(1, 0)
  },
  rightSideWrapper: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: 'auto',
    marginRight: theme.spacing(2.75)
  },
  rightSideWrapperMobile: {
    marginLeft: theme.spacing(3)
  },
  button: {
    borderRadius: 0,
    textTransform: 'none',
    fontWeight: 'bold',
    height: theme.spacing(5),
    [theme.breakpoints.down('xs')]: {
      width: theme.spacing(15.5),
      fontSize: 14,
      padding: theme.spacing(0.5)
    }
  },
  saveButton: {
    color: theme.palette.primary.white,
    width: theme.spacing(13.5),
    [theme.breakpoints.down('xs')]: {
      width: theme.spacing(9.5),
      fontSize: 14,
      padding: theme.spacing(0.5)
    }
  },
  styleOrderFromStore: {
    marginRight: theme.spacing(3),
    color: theme.palette.primary.main,
    cursor: 'pointer',
    [theme.breakpoints.down('xs')]: {
      fontSize: 14,
      marginRight: theme.spacing(0.5),
      width: theme.spacing(9.5)
    }
  },
  projectSummaryMainWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '40%',
    justifyContent: 'space-between'
  },
  projectSummaryMainWrapperMobile: {
    display: 'flex',
    flexWrap: 'wrap',
    padding: theme.spacing(1, 0),
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: theme.spacing(3),
    marginBottom: theme.spacing(-1)
  },
  projectSummaryParameter: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(2.5, 0)
  },
  projectSummaryParameterMobile: {
    marginRight: theme.spacing(2),
    display: 'flex',
    alignItems: 'center'
  },
  fontSize14: {
    fontSize: 14
  },
  styleTotalPrice: {
    marginLeft: theme.spacing(1),
    color: theme.palette.primary.main,
    fontWeight: 600
  },
  styleNetPrice: {
    marginLeft: theme.spacing(1),
    color: '#03A3E9',
    fontWeight: 600
  },
  style$PerWdc: {
    marginLeft: theme.spacing(1),
    color: '#62B15A',
    fontWeight: 600
  },
  styleProjectCost: {
    fontWeight: 600
  },
  disabled: {
    opacity: 0.5,
    backgroundColor: '#4b4b4ba3'
  },
  disableOrderFromStore: {
    opacity: 0.5,
    cursor: 'default'
  }
}));

const ActionButtons = props => {
  const classes = useStyles();
  const [ isHover, setIsHover ] = useState(false);
  const {
    isCalculateEnabled,
    handleClick,
    handleBack,
    handleSave,
    from = '',
    collapseProjectSummary,
    projectSummaryParameters,
    currentInterconnects,
    currentProjectBOMItems,
    projectSummary,
    isMobile,
    leadId,
    handleOrderfromStorePopup,
    isBomSaved
  } = props;

  const getProjectSummary = () => {
    const { netPrice = 0, totalDiscountSum = 0 } = projectSummaryParameters;
    return (
      <Box
        className={clsx(
          window.innerWidth > 1200 ? classes.projectSummaryMainWrapper : classes.projectSummaryMainWrapperMobile
        )}
      >
        <Divider orientation="vertical" />
        <Box
          className={clsx(
            window.innerWidth > 1200 ? classes.projectSummaryParameter : classes.projectSummaryParameterMobile
          )}
        >
          <Typography className={classes.styleProjectCost}>Project Cost</Typography>
        </Box>
        <Box
          className={clsx(
            window.innerWidth > 1200 ? classes.projectSummaryParameter : classes.projectSummaryParameterMobile
          )}
        >
          <Typography className={classes.fontSize14}>Gross cost:</Typography>
          <Typography className={classes.styleTotalPrice}>${netPrice.toLocaleString()}</Typography>
        </Box>
        <Box
          className={clsx(
            window.innerWidth > 1200 ? classes.projectSummaryParameter : classes.projectSummaryParameterMobile
          )}
        >
          <Typography className={classes.fontSize14}>Net cost:</Typography>
          <Typography className={classes.styleNetPrice}>${(netPrice - totalDiscountSum).toLocaleString()}</Typography>
        </Box>
        <Box
          className={clsx(
            window.innerWidth > 1200 ? classes.projectSummaryParameter : classes.projectSummaryParameterMobile
          )}
        >
          <Typography className={classes.fontSize14}>$/Wdc:</Typography>
          <Typography className={classes.style$PerWdc}>
            {get(projectSummaryParameters, '$perWdc', 0) === 'Infinity' ? (
              '-'
            ) : (
              get(projectSummaryParameters, '$perWdc', 0).toLocaleString() + '/Wdc'
            )}
          </Typography>
        </Box>
        <Divider orientation="vertical" />
      </Box>
    );
  };
  if (from === 'output') {
    return (
      <Fragment>
        <Box className={clsx(classes.buttonRowOutput)}>
          {collapseProjectSummary && isMobile && getProjectSummary()}
          <Box className={classes.displayFlex}>
            <Box
              className={clsx(
                isMobile && collapseProjectSummary && classes.backButtonPadding,
                classes.backButtonWrapper,
                classes.backButtonWrapperWidth
              )}
            >
              <Button
                variant="contained"
                color="primary"
                className={clsx(classes.backButton, classes.button)}
                disableElevation
                onClick={handleBack}
              >
                Edit Requirements
              </Button>
            </Box>
            {collapseProjectSummary && !isMobile && getProjectSummary()}
            <Box
              className={clsx(
                classes.rightSideWrapper,
                classes.buttonRowPaddingOutput,
                window.innerWidth < 1200 && collapseProjectSummary && classes.rightSideWrapperMobile
              )}
            >
              <LightTooltip open={!isBomSaved && isHover} title={saveBomInfo}>
                <Typography
                  className={clsx(classes.styleOrderFromStore, !isBomSaved && classes.disableOrderFromStore)}
                  onClick={
                    isBomSaved ? (
                      () => {
                        trackEvents('triggerBOM', { menuValue: 'Order_from_Store_button' });
                        handleOrderfromStorePopup(true);
                      }
                    ) : (
                      () => setIsHover(true)
                    )
                  }
                  onMouseEnter={e => setIsHover(true)}
                  onMouseOut={e => setIsHover(false)}
                >
                  Order from Store
                </Typography>
              </LightTooltip>
              <Button
                variant="contained"
                color="primary"
                className={clsx(classes.saveButton, classes.button)}
                disableElevation
                onClick={() =>
                  handleSave(currentInterconnects, currentProjectBOMItems, projectSummary, !isEmpty(leadId), 'save')}
              >
                Save
              </Button>
            </Box>
          </Box>
        </Box>
      </Fragment>
    );
  } else {
    return (
      <Grid container direction="row" className={classes.buttonRowInput}>
        <LightTooltip open={!isCalculateEnabled && isHover} title={mandatoryInfo}>
          <Box
            className={clsx(classes.calculateButton, classes.button, !isCalculateEnabled && classes.disabled)}
            onClick={isCalculateEnabled ? handleClick : () => setIsHover(true)}
            onMouseEnter={e => setIsHover(true)}
            onMouseOut={e => setIsHover(false)}
          >
            Calculate
          </Box>
        </LightTooltip>
      </Grid>
    );
  }
};

const mapStateToProps = state => ({
  isMobile: state.appReducer.isMobileView
});

export default connect(mapStateToProps)(ActionButtons);
