import React, { Fragment, useState } from 'react';
import { Dialog, DialogContent, DialogTitle, DialogActions, Button, Typography, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import SystemDetailInputField from '../CommonFields/systemDetailInputField';
import { cloneDeep, findIndex, get, isEmpty } from 'lodash';
import { LightTooltip } from '../CommonFields/infoTooltip';
import clsx from 'clsx';

const styles = makeStyles(theme => ({
  titleWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderBottom: '1px solid #E1E1E1'
  },
  button: {
    textTransform: 'none',
    width: theme.spacing(11),
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.white,
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.white
    }
  },
  cancelButton: {
    textTransform: 'none',
    width: theme.spacing(11),
    backgroundColor: theme.palette.primary.white,
    color: theme.palette.primary.dark,
    '&:hover': {
      backgroundColor: theme.palette.primary.darkGray,
      color: theme.palette.primary.dark
    }
  },
  buttonWrapper: {
    padding: theme.spacing(2, 3),
    borderTop: '1px solid #E1E1E1'
  },
  paddingDialogContent: {
    padding: theme.spacing(0, 3, 2)
  },
  label: {
    fontSize: 16,
    color: '#4A4A4A'
  },
  disableProceed: {
    opacity: 0.5,
    cursor: 'default'
  }
}));

const CustomValuePopup = props => {
  const {
    open = false,
    handleCustomPanelConfigurations,
    renderFields,
    interconnect,
    handleChange,
    errorData,
    projectType,
    systemDetails,
    interconnectId,
    source
  } = props;
  const classes = styles();
  const dialogTitle = source === 'moduleDetails' ? 'Enter Panel Details' : 'Enter Orientation Configuration';
  const [ isHover, setIsHover ] = useState(false);

  const getFieldProperties = (inputField, interconnect) => {
    const keyName = inputField[0];
    const inputFieldProperties = inputField[1];
    const { array = [], tooltip = '', unit = '', label = '', disabled = false } = inputFieldProperties;
    const showUnit = !isEmpty(unit);
    const value = get(interconnect, `${keyName}`) || '';
    return {
      keyName,
      array,
      tooltip,
      unit,
      label,
      disabled,
      showUnit,
      value,
      handleChange: handleChange
    };
  };

  const getErrorDetails = (interconnect, keyName, value) => {
    if (isEmpty(value)) return {};
    let interconnectArray = cloneDeep(get(systemDetails, `${projectType}`, []));
    const matchingInterconnectIndex = findIndex(interconnectArray, object => object.id === interconnect['id']);
    const errorObject = errorData && get(errorData, `systemDetails.${projectType}`, [])[matchingInterconnectIndex];
    const isError = get(errorObject, `${keyName}.error`, false);
    const helperObject = errorData && get(errorData, `systemDetails.${projectType}`, [])[matchingInterconnectIndex];
    const helperText = get(helperObject, `${keyName}.errorMessage`, []);
    return { isError, helperText };
  };

  const renderInputFields = (inputField, interconnect, type) => {
    const fieldProperties = getFieldProperties(inputField, interconnect);
    const { keyName, value } = fieldProperties;
    const errorDetails = getErrorDetails(interconnect, keyName, value);
    return <SystemDetailInputField type={type} interconnect={interconnect} {...fieldProperties} {...errorDetails} />;
  };

  const handleCancel = () => {
    handleCustomPanelConfigurations(interconnectId, false, {
      fieldName: source === 'moduleDetails' ? 'openDidntFindModulePopup' : 'openCustomOrientationPanelsPopup',
      source,
      resetValuesPanelOrientation: true
    });
  };

  const handleProceedModuleDetails = async () => {
    const e = { target: { value: '' } };
    const helperParams = { isProceedClicked: true };
    await handleChange(e, '', interconnect, helperParams);
    await handleCustomPanelConfigurations(interconnectId, false, { fieldName: 'openDidntFindModulePopup' });
    await handleCustomPanelConfigurations(interconnectId, true, { fieldName: 'isProceedClickedPopup' });
  };

  const handleProceedPanelOrientation = () => {
    handleCustomPanelConfigurations(interconnectId, false, {
      fieldName: 'openCustomOrientationPanelsPopup',
      source
    });
  };

  const isProceedDisabled = () => {
    const { portraitPanelCount, landscapePanelCount, pvPanelsCount } = interconnect;
    const isPanelSumMatching = Number(portraitPanelCount) + Number(landscapePanelCount) === Number(pvPanelsCount);
    return source === 'panelOrientation' && !isPanelSumMatching;
  };

  const proceedFunction = source === 'moduleDetails' ? handleProceedModuleDetails : handleProceedPanelOrientation;
  return (
    <Dialog
      open={open}
      aria-labelledby="findModule"
      aria-describedby="findModuleDescription"
      onClose={() =>
        handleCustomPanelConfigurations(interconnectId, false, {
          fieldName: source === 'moduleDetails' ? 'openDidntFindModulePopup' : 'openCustomOrientationPanelsPopup'
        })}
      fullWidth
    >
      <DialogTitle id="template" disableTypography className={classes.titleWrapper}>
        <Typography variant="h6">{dialogTitle}</Typography>
      </DialogTitle>
      <DialogContent className={classes.paddingDialogContent}>
        {Object.entries(renderFields).map((inputField, index) => {
          const inputFieldProperties = inputField[1];
          const { type = '' } = inputFieldProperties;
          const keyName = inputField[0];
          if (
            (source === 'moduleDetails' && (keyName === 'pvPanelType' || keyName === 'pvPanelWattage')) ||
            (source === 'panelOrientation' && (keyName === 'portraitPanelCount' || keyName === 'landscapePanelCount'))
          ) {
            return <Box key={inputField + index}>{renderInputFields(inputField, interconnect, type)}</Box>;
          }
          return <Fragment key={inputField + index} />;
        })}
      </DialogContent>
      <DialogActions className={classes.buttonWrapper}>
        <Button className={classes.cancelButton} onClick={() => handleCancel()} variant="outlined">
          Cancel
        </Button>
        <LightTooltip open={isProceedDisabled() && isHover} title={'Mismatch in PV panel count entered'}>
          <Button
            className={clsx(classes.button, isProceedDisabled() && classes.disableProceed)}
            onClick={isProceedDisabled() ? () => setIsHover(true) : () => proceedFunction()}
            variant="outlined"
            onMouseOver={e => {
              setIsHover(true);
            }}
            onMouseOut={e => setIsHover(false)}
          >
            Proceed
          </Button>
        </LightTooltip>
      </DialogActions>
    </Dialog>
  );
};

export default CustomValuePopup;
