import { fork } from 'redux-saga/effects';
import {
  getSiteSearchLeadsFlow,
  setBOMInputDetailsFlow,
  getAllTemplatesFlow,
  saveTemplateFlow,
  createTemplateFlow,
  deleteTemplateFlow,
  connectBOMToLeadFlow,
  getLeadBOMDetailsFlow,
  getPVManufacturersFlow,
  getPVModelsFlow,
  getExistingSystemDetailsFlow,
  calculateResidentialBOMOutputFlow,
  getCartUrlFlow,
  checkExistingEmailFlow,
  getRecentlyUsedPVManufacturersModulesFlow
} from './containers/BOMCalculator/saga';
import { triggerDownloadCSVFlow, getNpsShownFlow, setNpsFeedbackFlow } from './containers/BOMCalculatorOutput/saga';

export default function* root() {
  yield fork(getSiteSearchLeadsFlow);
  yield fork(setBOMInputDetailsFlow);
  yield fork(getAllTemplatesFlow);
  yield fork(saveTemplateFlow);
  yield fork(createTemplateFlow);
  yield fork(deleteTemplateFlow);
  yield fork(connectBOMToLeadFlow);
  yield fork(getLeadBOMDetailsFlow);
  yield fork(getPVManufacturersFlow);
  yield fork(getPVModelsFlow);
  yield fork(getExistingSystemDetailsFlow);
  yield fork(calculateResidentialBOMOutputFlow);
  yield fork(getCartUrlFlow);
  yield fork(checkExistingEmailFlow);
  yield fork(getRecentlyUsedPVManufacturersModulesFlow);

  yield fork(triggerDownloadCSVFlow);
  yield fork(getNpsShownFlow);
  yield fork(setNpsFeedbackFlow);
}
