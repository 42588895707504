import React from 'react';
import configureStore from '../lib/store';
import { Provider } from 'react-redux';
import { MuiThemeProvider } from '@material-ui/core/styles';
import theme from '../lib/theme';
import Routes from '../lib/routes';

const App = () => {
  return (
    <MuiThemeProvider theme={theme}>
      <Provider store={configureStore()}>
        <Routes />
      </Provider>
    </MuiThemeProvider>
  );
};

export default App;
