import { Button, makeStyles } from '@material-ui/core';
import React from 'react';
import { getEnlightenOrigin } from '../../../utils/helper';

const useStyles = makeStyles(theme => ({
  importDesign: {
    display: 'flex',
    margin: theme.spacing(2.5),
    textTransform: 'none'
  },
  downloadIcon: {
    width: theme.spacing(1.5),
    height: theme.spacing(1.5)
  }
}));

function BOMImportDesignButton(props) {
  const classes = useStyles(props);
  const { msgToSend } = props;
  return (
    <Button
      color="primary"
      variant="outlined"
      className={classes.importDesign}
      // Sends postmesssage to ENLM to open the Popup
      onClick={() => window.parent.postMessage({ message: msgToSend }, getEnlightenOrigin())}
    >
      <img src={require(`../Icons/download.svg`)} className={classes.downloadIcon} alt="downloadIcon" /> Import Design
    </Button>
  );
}

export default BOMImportDesignButton;
