import React from 'react';
import { LinearProgress, Box, withStyles } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const BorderLinearProgress = withStyles(theme => ({
  root: {
    height: 5,
    borderRadius: 5
  },
  colorPrimary: {
    backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700]
  },
  bar: {
    borderRadius: 5,
    backgroundColor: theme.palette.primary.main
  }
}))(LinearProgress);

const useStyles = makeStyles(theme => ({
  mainWrapper: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    [theme.breakpoints.down('xs')]: {
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(3.25)
    }
  }
}));

const CustomLinearProgress = props => {
  const classes = useStyles();
  const { progressValue } = props;
  return (
    <Box className={classes.mainWrapper}>
      <BorderLinearProgress variant="determinate" value={progressValue} />
    </Box>
  );
};

export default CustomLinearProgress;
