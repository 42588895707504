import React, { Fragment, useEffect, useState } from 'react';
import clsx from 'clsx';
import {
  TextField,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Button,
  Typography,
  IconButton
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { isEmpty, get } from 'lodash';

import useStyles from './styles';

let autocomplete = null;
const mapRef = { current: null };
const mapObjRef = { current: null };
let marker = null;
let prevAddress = '';
let prevAddressObj = {};
let prevLatLng = {};

const MapDialog = props => {
  const { open, handleClose, handleSave, defaultAddress, defaultAddressObj, isFromOutput } = props;
  const classes = useStyles();

  const getDefaultLatLng = () => {
    const latitude = get(defaultAddressObj, 'latitude', '');
    const longitude = get(defaultAddressObj, 'longitude', '');
    return latitude && longitude ? { lat: latitude, lng: longitude } : {};
  };

  const [ addressSelected, setAddressSelected ] = useState(false);
  const [ isSaved, setIsSaved ] = useState(false);
  const [ latLng, setLatLng ] = useState(getDefaultLatLng());
  const [ address, setAddress ] = useState(defaultAddress);
  const [ addressObj, setAddressObj ] = useState(defaultAddressObj);

  const geocoder = window.google && new window.google.maps.Geocoder();

  useEffect(
    () => {
      if (open) {
        prevAddress = address;
        prevAddressObj = addressObj;
        prevLatLng = latLng;
        getDefaultLatLngFromIp();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [ open ]
  );

  useEffect(
    () => {
      if (!isEmpty(defaultAddress) && !isEmpty(defaultAddressObj)) {
        const latLng = getDefaultLatLng();
        setLatLng(getDefaultLatLng());
        setAddress(defaultAddress);
        setAddressObj(defaultAddressObj);
        if (isFromOutput && !isEmpty(latLng)) {
          setMapPosition(latLng);
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [ defaultAddress, defaultAddressObj, isFromOutput ]
  );

  const getDefaultLatLngFromIp = async () => {
    await fetch('https://api-prod.secureprivacy.ai/api/adminsettings/displaypage/ipinfo?format=json')
      .then(res => res.json())
      .then(data => initializeMap(data.lat, data.lon))
      .catch(() => {
        console.log(`Couldn't find user ip`);
      });
  };

  const initializeMap = (lat, lng) => {
    if (mapRef.current && window.google && window.google.maps) {
      const location = new window.google.maps.LatLng(lat, lng);
      marker = new window.google.maps.Marker({ draggable: true, position: location });
      mapObjRef.current = new window.google.maps.Map(mapRef.current, {
        center: location,
        zoom: 18,
        fullscreenControl: false,
        streetViewControl: false,
        mapTypeId: 'satellite'
      });
      mapObjRef.current.setTilt(0);
      autocomplete = new window.google.maps.places.Autocomplete(document.getElementById('address'), {});
      autocomplete.addListener('place_changed', handlePlaceSelect);
      if (isSaved || !isEmpty(latLng)) {
        setMapPosition(latLng);
      }
      let clickEventRef = '';
      let dragEventRef = '';
      if (mapObjRef.current) {
        clickEventRef = window.google.maps.event.addListener(mapObjRef.current, 'click', event => {
          setMarker(event);
        });
        dragEventRef = window.google.maps.event.addListener(marker, 'dragend', event => {
          setMarker(event);
        });
      }
      return () => {
        window.google.maps.event.removeListener(clickEventRef);
        window.google.maps.event.removeListener(dragEventRef);
      };
    }
  };

  const setMapPosition = latLng => {
    marker.setPosition(latLng);
    mapObjRef.current.setCenter(latLng);
    marker.setMap(mapObjRef.current);
  };

  const getSegregatedAddress = addressObject => {
    const addresses = addressObject.address_components;
    let streetNumber = addresses.find(
      address => address.types.includes('street_number') || address.types.includes('premise')
    );
    let route = addresses.find(
      address => address.types.includes('route') || address.types.includes('sublocality_level_1')
    );
    streetNumber = streetNumber ? `${streetNumber.long_name}, ` : '';
    route = route ? route.long_name : '';
    const address1 = `${streetNumber}${route}`;
    const city = addresses.find(address => address.types.includes('locality'));
    const state = addresses.find(address => address.types.includes('administrative_area_level_1'));
    const zip = addresses.find(address => address.types.includes('postal_code'));
    const country = addresses.find(address => address.types.includes('country'));
    const latitude = addressObject.geometry.location.lat();
    const longitude = addressObject.geometry.location.lng();
    const addressObj = {
      address1: address1.split(',')[0],
      address2: address1.split(',')[1] ? address1.split(',')[1].trim() : '',
      city: city && (city.long_name || city.short_name),
      state: state && (state.short_name || state.long_name),
      zip: zip && (zip.short_name || zip.long_name),
      country: country && (country.short_name || country.long_name),
      latitude,
      longitude
    };
    const latLng = { lat: latitude, lng: longitude };
    setLatLng(latLng);
    return { latLng, addressObj };
  };

  const handlePlaceSelect = () => {
    let addressObject = autocomplete.getPlace();
    if (!isEmpty(get(addressObject, 'address_components', ''))) {
      const formattedAddress = addressObject.formatted_address;
      const { latLng, addressObj } = getSegregatedAddress(addressObject);
      setAddress(formattedAddress);
      setAddressObj(addressObj);
      setMapPosition(latLng);
      setAddressSelected(true);
    }
  };

  const setAddressDetails = location => {
    geocoder.geocode({ location }, results => {
      const { latLng, addressObj } = getSegregatedAddress(results[0]);
      setAddressObj(addressObj);
      setLatLng(latLng);
      setMapPosition(latLng);
    });
  };

  const setMarker = event => {
    if (isEmpty(address)) {
      setAddressDetails(event.latLng);
      const latitude = event.latLng.lat();
      const longitude = event.latLng.lng();
      const latLng = { lat: latitude, lng: longitude };
      setMapPosition(latLng);
    }
  };

  const preventEnterPress = event => {
    if (event.which === 13) {
      event.preventDefault();
    }
  };

  const onPopupClose = () => {
    if (!isSaved) {
      setAddressSelected(false);
      marker.setMap(null);
    }
    setAddress(prevAddress);
    setAddressObj(prevAddressObj);
    setLatLng(prevLatLng);
    handleClose();
  };

  const renderMapContent = () => (
    <DialogContent className={classes.mapWrapper}>
      <TextField
        id="address"
        className={clsx(classes.address, isFromOutput && classes.hide)}
        value={address}
        onKeyPress={e => preventEnterPress(e)}
        onChange={e => setAddress(e.target.value)}
        variant="outlined"
      />
      <div id="latlng" ref={mapRef} className={classes.mapArea} />
    </DialogContent>
  );

  if (isFromOutput) {
    return <Fragment>{renderMapContent()}</Fragment>;
  }

  return (
    <Dialog open={open} aria-labelledby="map" aria-describedby="map-description" fullWidth>
      <DialogTitle id="map" disableTypography className={classes.titleWrapper}>
        <Typography variant="h6">Select on Map</Typography>
        <IconButton aria-label="close" onClick={onPopupClose} className={classes.closeButton}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      {renderMapContent()}
      <DialogActions className={classes.buttonWrapper}>
        <Button className={classes.button} onClick={onPopupClose} variant="outlined">
          Cancel
        </Button>
        <Button
          className={classes.saveButton}
          onClick={() => {
            setIsSaved(true);
            handleSave(address, addressObj);
          }}
          color="primary"
          variant="contained"
          disableElevation
          disabled={isEmpty(address) || !addressSelected}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default MapDialog;
