import React, { Fragment, useState } from 'react';
import {
  Box,
  Typography,
  Grid,
  FormControl,
  TextField,
  FormHelperText,
  InputLabel,
  Select,
  MenuItem,
  Link,
  Divider
} from '@material-ui/core';
import clsx from 'clsx';
import { isEmpty, get } from 'lodash';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import useStyles from './styles';
import { Label } from '../../common/Label';
import { moduleCompatibilityLink } from '../../../config/constants';
import { trackEvents } from '../../../utils/helper';
import { Autocomplete } from '@material-ui/lab';
import InfoPopper from './infoPopper';
import PvSheddingPopup from './pvSheddingPopup';

const SelectDropdowns = props => {
  const {
    interconnect,
    keyName,
    array,
    value,
    handleChange,
    disabled = false,
    manufacturersOrModelsList,
    recentlyUsedPVList
  } = props;
  const classes = useStyles();

  if (keyName === 'pvManufacturer' || keyName === 'pvModel') {
    const preventEnterPress = event => {
      if (event.which === 13) {
        event.preventDefault();
      }
    };
    const onSelect = (e, v) => {
      const event = { target: { value: get(v, 'id') } };
      handleChange(event, keyName, interconnect);
    };

    const getOptionsList = () => {
      let result = [];
      let filteredRecentPVList = [],
        filteredRemainingPVList = [];
      manufacturersOrModelsList.forEach(manu => {
        if (isEmpty(recentlyUsedPVList) || !recentlyUsedPVList.find(value => value.id === manu.id)) {
          filteredRemainingPVList.push(manu);
        } else {
          filteredRecentPVList.push(manu);
        }
      });
      const isEmptyRecentList = isEmpty(filteredRecentPVList);
      result = [
        ...(get(interconnect, 'isProceedClickedPopup')
          ? [
              {
                id: 'Custom',
                name: 'Custom'
              }
            ]
          : []),
        ...(isEmptyRecentList ? [] : [ { id: 'recently_used', name: 'Recently Used' } ]),
        ...filteredRecentPVList,
        ...(isEmptyRecentList ? [] : [ { id: 'divider', name: 'Divider' } ]),
        ...filteredRemainingPVList
      ];
      return result;
    };

    const getValueForAutocomplete = () => {
      let result = '';
      if (value === 'Custom') return { id: 'Custom', name: 'Custom' };
      manufacturersOrModelsList.forEach(manu => {
        if (manu.id === Number(value)) {
          result = manu;
        }
      });
      return result;
    };

    return (
      <Autocomplete
        className={classes.autocomplete}
        onKeyPress={e => preventEnterPress(e)}
        value={getValueForAutocomplete()}
        onChange={(e, value) => onSelect(e, value)}
        options={getOptionsList()}
        getOptionLabel={option => option.name || ''}
        getOptionDisabled={option => option.id === 'recently_used' || option.id === 'divider'}
        closeIcon={null}
        renderOption={(option, state) => {
          if (option.id === 'divider') {
            return (
              <Box className={classes.styleRecentDivider}>
                <Divider variant="fullWidth" />
              </Box>
            );
          } else {
            return <Fragment>{option.name}</Fragment>;
          }
        }}
        renderInput={params => <TextField {...params} fullWidth />}
      />
    );
  }
  return (
    <Select
      IconComponent={props => <ExpandMoreIcon className="MuiSelect-icon" />}
      onChange={e => handleChange(e, keyName, interconnect)}
      value={value}
      disabled={disabled}
    >
      {array.map((list, index) => {
        if (get(list, 'hide', false)) {
          return null;
        }
        return (
          <MenuItem
            className={clsx(list.pvSheddingRequired && classes.colorGrey)}
            key={list.key + index}
            value={list.value}
            disabled={get(list, 'disabled', false)}
          >
            {list.value}
          </MenuItem>
        );
      })}
    </Select>
  );
};

const SystemDetailInputField = props => {
  const classes = useStyles();
  const {
    type,
    index,
    interconnect,
    keyName,
    tooltip,
    unit,
    label,
    showUnit,
    value,
    handleChange,
    isError = false,
    helperText = '',
    handleCustomPanelConfigurations
  } = props;
  const title = showUnit ? `${label} (${unit})` : label;
  const [ showPvSheddingPopup, setShowPvSheddingPopup ] = useState(false);
  const Template = templateProps => {
    const { leftContent = <Fragment />, rightContent = <Fragment />, isError = false } = templateProps;
    return (
      <Grid container direction="row" className={classes.systemDetailsWrapper}>
        <Grid item className={classes.leftHeading}>
          {leftContent}
        </Grid>
        <Grid item className={classes.rightSection}>
          <FormHelperText error={isError}>{rightContent}</FormHelperText>
        </Grid>
      </Grid>
    );
  };

  const handleClickNotFindModule = interconnect => {
    handleCustomPanelConfigurations(get(interconnect, 'id'), true, { fieldName: 'openDidntFindModulePopup' });
    const isEdit = get(interconnect, 'isProceedClickedPopup', false) ? true : false;
    if (!isEdit) {
      trackEvents('triggerBOM', { menuValue: 'PV_Module_not_found' });
    }
  };

  const getSubLabel = () => {
    if (keyName === 'microinverter') {
      // TODO - Country based link implementaion pending
      const body = (
        <FormHelperText>
          <Link href={moduleCompatibilityLink} target="_blank" className={classes.styleImage}>
            Check module compatibility
          </Link>
        </FormHelperText>
      );
      return <Template leftContent={body} />;
    } else if (keyName === 'pvModel') {
      const body = (
        <Typography onClick={() => handleClickNotFindModule(interconnect)} className={classes.styleModuleCompatibilty}>
          {get(interconnect, 'isProceedClickedPopup', false) ? 'Edit module details' : 'Don’t see your PV model?'}
        </Typography>
      );
      return <Template leftContent={body} />;
    } else if (keyName === 'storageSize' && get(interconnect, 'showPvSheddingLink', false)) {
      const body = (
        <Fragment>
          <Link className={classes.styleModuleCompatibilty} onClick={() => setShowPvSheddingPopup(true)}>
            PV Shedding Required
          </Link>
          <PvSheddingPopup open={showPvSheddingPopup} onClose={() => setShowPvSheddingPopup(false)} />
        </Fragment>
      );
      return <Template leftContent={body} />;
    }
    return null;
  };

  const preventDefault = (event, key) => {
    if (event.which !== 8 && event.which !== 0 && (event.which < 48 || event.which > 57)) {
      event.preventDefault();
    }
  };

  const renderTextField = () => (
    <FormControl id={label + 'FormControl'} className={clsx(classes.systemDetailsField, classes.customLabel)}>
      <TextField
        id={label + index}
        label={<Label className={classes.styleLabel} />}
        value={value}
        onChange={e => handleChange(e, keyName, interconnect)}
        error={isError}
        onKeyPress={e => preventDefault(e)}
      />
    </FormControl>
  );

  const renderSelectField = () => (
    <FormControl
      id={label + 'FormControl'}
      className={clsx(classes.systemDetailsField, classes.customLabel)}
      error={isError}
    >
      <InputLabel id={'requirementInputLabel'}>
        <Label className={classes.styleLabel} />
      </InputLabel>
      <SelectDropdowns {...props} />
    </FormControl>
  );

  return (
    <Box>
      <Grid container direction="row" className={classes.systemDetailsWrapper}>
        <Grid item className={classes.leftHeading}>
          <Typography style={{ display: 'inline-flex' }}>
            {title}
            {!isEmpty(tooltip) && <InfoPopper text={tooltip} />}
          </Typography>
        </Grid>
        <Grid item className={classes.rightSection}>
          {type === 'dropdown' ? renderSelectField() : renderTextField()}
        </Grid>
      </Grid>
      {isError && <Template rightContent={helperText} isError={true} />}
      {getSubLabel()}
    </Box>
  );
};

export default SystemDetailInputField;
