import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  content: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3)
    }
  }
}));

const NoMatch = ({ location }) => {
  const classes = useStyles();
  return (
    <div className={classes.content}>
      <Typography variant="h6" component="h3">
        No Match for <code> {location.pathname} </code>
      </Typography>
    </div>
  );
};

export default NoMatch;
