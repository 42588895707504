import { makeStyles } from '@material-ui/core/styles';
import { styles } from '../../BOMCalculatorInput/MapDialog/styles';

const useStyles = makeStyles(theme => ({
  tableContainer: {
    padding: theme.spacing(4, 2.5),
    width: 'auto',
    '& td, & th': {
      padding: theme.spacing(0.75, 2)
    }
  },
  bomPricingTable: {
    '& .MuiTableRow-root': {
      borderBottom: '1px solid #70707036',
      '&:last-child': {
        borderBottom: 'none',
        '& .MuiTableCell-root': {
          borderBottom: 'none'
        }
      }
    }
  },
  highlightedRow: {
    background: '#E8E8E89E 0% 0% no-repeat padding-box'
  },
  tableHeader: {
    fontSize: 14,
    fontWeight: 'bold',
    color: '#233643'
  },
  bomItemTableCell: {
    display: 'flex',
    alignItems: 'center',
    border: 0,
    '& button': {
      float: 'left'
    },
    '& div': {
      display: 'flow-root',
      marginTop: theme.spacing(0.125)
    }
  },
  bomItemName: {
    fontSize: 14,
    color: '#4D4F5C'
  },
  bomQuantity: {
    whiteSpace: 'nowrap'
  },
  addremoveIcon: {
    padding: 0,
    border: '1.5px solid #3B3B3B',
    borderRadius: theme.spacing(0.75),
    margin: 0,
    width: theme.spacing(2.75),
    height: theme.spacing(2.75),
    color: '#3B3B3B',
    '&.Mui-disabled': {
      opacity: 0.5
    }
  },
  removeIcon: {
    marginRight: theme.spacing(1)
  },
  addIcon: {
    marginLeft: theme.spacing(1)
  },
  addRemoveButton: {
    width: theme.spacing(2),
    height: theme.spacing(2),
    fill: '#3B3B3B'
  },
  notes: {
    fontSize: 14,
    color: '#4F89F4',
    padding: theme.spacing(3, 3, 0),
    fontWeight: 'bold'
  },
  bomInfoPopper: {
    maxWidth: 500,
    maxHeight: 200,
    zIndex: 1400,
    '& sup': {
      color: '#000000de'
    }
  },
  infoPrice: {
    color: theme.palette.primary.mainText
  },
  noPadding: {
    padding: 0
  },
  helpIcon: {
    padding: 0,
    fontSize: 18,
    paddingLeft: theme.spacing(0.25)
  },
  boldFont: {
    fontWeight: 'bold'
  },
  descPaper: {
    padding: theme.spacing(1.25)
  },
  descIcon: {
    float: 'right'
  },
  skuQuantityWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  ...styles(theme)
}));

export default useStyles;
