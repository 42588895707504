import React, { Component, Fragment } from 'react';
import {
  Box,
  IconButton,
  InputBase,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  withStyles
} from '@material-ui/core';
import clsx from 'clsx';
import { get, isEmpty, isEqual } from 'lodash';
import styles from './styles';
import { connect } from 'react-redux';
import {
  getFilteredArray,
  getHelperArrays,
  getNetPriceIncludingDiscountPerBOMItem,
  getNetPriceIncludingDiscountSubcategoryWise,
  getTotalQuantity
} from './helper';
import AddBoxIcon from '@material-ui/icons/AddBox';
import IndeterminateCheckBoxIcon from '@material-ui/icons/IndeterminateCheckBox';
import InfoTooltip from '../../BOMCalculatorInput/CommonFields/infoTooltip';
import { infoIconStoreText } from '../../../containers/BOMCalculator/constants';
import ToggleOffOutlined from '@material-ui/icons/ToggleOffOutlined';
import ToggleOnOutlined from '@material-ui/icons/ToggleOnOutlined';

class EnphaseEquipmentsTable extends Component {
  state = {
    currentEnphaseBOMItems: [],
    interconnectName: '',
    numberOfInterconnects: 0,
    showInfoEstoreItems: false
  };
  isInterconnectUpdated = false;

  componentDidUpdate(prevProps) {
    if (!this.isInterconnectUpdated && !isEmpty(this.props.currentInterconnects)) {
      this.isInterconnectUpdated = true;
      this.setState({
        currentEnphaseBOMItems: this.props.currentInterconnects,
        interconnectName:
          this.props.currentInterconnects.length > 1 ? 'project' : this.props.currentInterconnects[0]['name'],
        numberOfInterconnects: this.props.currentInterconnects.length
      });
      this.setShowInfoEstoreIcon();
    }
    if (!isEqual(prevProps.currentInterconnects, this.props.currentInterconnects)) {
      this.setState({ currentEnphaseBOMItems: this.props.currentInterconnects });
      this.setShowInfoEstoreIcon();
    }
  }

  setShowInfoEstoreIcon = () => {
    this.props.currentInterconnects.forEach(interconnect => {
      const bomItems = get(interconnect, 'bom_items');
      bomItems.forEach(bomItem => {
        if (
          get(bomItem, 'category', '') === 'ENPHASE_EQUIPMENT' &&
          !this.props.storeDistributorToggle &&
          (get(bomItem, 'store_price') === null || isEmpty(get(bomItem, 'store_price', '').toString()))
        ) {
          this.setState({ showInfoEstoreItems: true });
        }
      });
    });
  };

  handleInterconnectChange = e => {
    this.setState({ interconnectName: e.target.value });
  };

  handleMoreDetailsToggle = e => {
    const { updateStoreDistributerToggle, storeDistributorToggle } = this.props;
    updateStoreDistributerToggle(!storeDistributorToggle);
  };

  getFieldValue = (array, fieldName) => {
    const fieldValue = get(array, fieldName);
    return fieldValue === null ? '' : fieldValue;
  };

  render() {
    const {
      classes,
      handleInterconnectsInputChange,
      handleExpandCollapseTables,
      expandCollapseItems,
      storeDistributorToggle
    } = this.props;
    const { currentEnphaseBOMItems, numberOfInterconnects } = this.state;
    const showInterconnectDropdown = numberOfInterconnects > 1;
    const isCompleteProjectDropdown = this.state.interconnectName === 'project';
    const helperArray = getHelperArrays(this.state.currentEnphaseBOMItems, {
      category: 'ENPHASE_EQUIPMENT',
      isInterconnect: true
    });
    let totalPriceTable = 0;
    helperArray.forEach(helperObject => {
      const { key } = helperObject;
      totalPriceTable += getNetPriceIncludingDiscountSubcategoryWise(this.state.currentEnphaseBOMItems, {
        category: 'ENPHASE_EQUIPMENT',
        isInterconnect: true,
        subCategory: key
      });
    });
    totalPriceTable = Math.round(totalPriceTable) === -0 ? 0 : Math.round(totalPriceTable);

    return (
      <Fragment>
        <Paper square className={classes.mainWrapper}>
          <Box className={classes.mainTableWrapper}>
            <Box className={classes.headingBox}>Enphase Equipment</Box>
            {showInterconnectDropdown && (
              <Select
                value={this.state.interconnectName}
                className={classes.dropdownWrapper}
                onChange={e => {
                  this.handleInterconnectChange(e);
                }}
              >
                <MenuItem key={'enphaseEquipmentProject'} value={'project'}>
                  {'Project'}
                </MenuItem>
                {currentEnphaseBOMItems.map((interconnect, index) => {
                  return (
                    <MenuItem key={interconnect.name + index} value={interconnect.name}>
                      {interconnect.name}
                    </MenuItem>
                  );
                })}
              </Select>
            )}
          </Box>
          <TableContainer component={Paper} className={classes.tableWrapper}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead className={classes.tableHeader}>
                <TableRow>
                  <TableCell
                    className={clsx(classes.borderRight, classes.headerContainer)}
                    classes={{ root: clsx(classes.styleTableHeaderContent, classes.widthItems) }}
                  >
                    ITEMS
                  </TableCell>
                  <TableCell
                    className={classes.headerContainer}
                    classes={{ root: clsx(classes.styleTableHeaderContent, classes.widthQuantity) }}
                  >
                    QUANTITY
                  </TableCell>
                  <TableCell
                    className={classes.headerContainer}
                    classes={{ root: clsx(classes.styleTableHeaderContent, classes.widthPrice) }}
                  >
                    <Box className={classes.mainSwitchWrapper}>
                      <Typography className={classes.fontSize13}>COST ($/UNIT) :</Typography>
                      <Box className={classes.switchWrapper}>
                        <Typography className={classes.fontSize13}>&nbsp;Store</Typography>
                        <IconButton
                          onClick={() => this.handleMoreDetailsToggle()}
                          className={classes.styleDistributorToggleWrapper}
                        >
                          {storeDistributorToggle ? (
                            <ToggleOnOutlined color="primary" className={classes.styleDistributorToggle} />
                          ) : (
                            <ToggleOffOutlined color="primary" className={classes.styleDistributorToggle} />
                          )}
                        </IconButton>
                        <Typography className={classes.fontSize13}>Distributor</Typography>
                        {this.state.showInfoEstoreItems && (
                          <InfoTooltip text={infoIconStoreText} applyClassName="storePriceInfo" />
                        )}
                      </Box>
                    </Box>
                  </TableCell>
                  <TableCell
                    className={classes.headerContainer}
                    classes={{ root: clsx(classes.styleTableHeaderContent, classes.widthNetPrice) }}
                  >
                    NET COST
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {helperArray.map((row, index) => {
                  const { collapseName, subCategory } = row;
                  const collapseCategory = !get(expandCollapseItems, `${collapseName}`);
                  const subCategoryNetPrice = Math.round(
                    getNetPriceIncludingDiscountSubcategoryWise(this.state.currentEnphaseBOMItems, {
                      subCategory: subCategory.toUpperCase(),
                      category: 'ENPHASE_EQUIPMENT',
                      isInterconnect: true
                    })
                  ).toLocaleString();
                  return (
                    <Fragment key={'enphase-equipment-' + index}>
                      <TableRow>
                        <TableCell className={classes.borderRight} component="th" scope="row">
                          <Box className={classes.firstColumnWrapper}>
                            <IconButton
                              className={classes.styleCollapseIcon}
                              onClick={() => {
                                handleExpandCollapseTables(collapseName);
                              }}
                            >
                              {collapseCategory ? (
                                <IndeterminateCheckBoxIcon color="primary" />
                              ) : (
                                <AddBoxIcon color="primary" />
                              )}
                            </IconButton>
                            <Typography
                              className={clsx(
                                classes.firstColumnHeading,
                                collapseCategory && classes.firstColumnHeadingSelected
                              )}
                            >
                              {subCategory}
                            </Typography>
                          </Box>
                        </TableCell>
                        <TableCell>-</TableCell>
                        <TableCell>-</TableCell>
                        <TableCell>
                          <Typography className={clsx(classes.font14, classes.stylePriceText)}>
                            ${subCategoryNetPrice === -0 ? 0 : subCategoryNetPrice}
                          </Typography>
                        </TableCell>
                      </TableRow>
                      {collapseCategory &&
                        getFilteredArray(this.state.currentEnphaseBOMItems, {
                          subCategory: subCategory.toUpperCase(),
                          category: 'ENPHASE_EQUIPMENT',
                          interconnectName: this.state.interconnectName,
                          isInterconnect: true
                        }).map((subCategoricalValue, index) => {
                          const {
                            sku = '',
                            distributor_price = '',
                            quantity = '',
                            quantity_per_sku = '',
                            discount_amount = ''
                          } = subCategoricalValue;
                          const totalQuantity = getTotalQuantity(
                            this.state.currentEnphaseBOMItems,
                            'ENPHASE_EQUIPMENT',
                            subCategory.toUpperCase(),
                            subCategoricalValue.name
                          );

                          return (
                            <TableRow key={'enphase-equipment-table-' + index} className={classes.tableRowBackground}>
                              <TableCell className={classes.borderRight} component="th" scope="row">
                                <Box className={classes.subCategoryMainWrapper}>
                                  <Typography className={classes.font14}>{subCategoricalValue.name}</Typography>
                                  {sku && <Typography className={classes.subCategoryStyleSKU}>SKU : {sku}</Typography>}
                                  {quantity_per_sku && (
                                    <Typography className={classes.subCategoryStyleQTY}>
                                      Qty/SKU : {quantity_per_sku}
                                    </Typography>
                                  )}
                                </Box>
                              </TableCell>
                              <TableCell className={classes.font14}>
                                {subCategory.toUpperCase() !== 'ACCESSORIES' ? isCompleteProjectDropdown ? (
                                  totalQuantity
                                ) : (
                                  quantity
                                ) : (
                                  <Box
                                    className={clsx(
                                      classes.priceInputWrapper,
                                      isCompleteProjectDropdown && classes.borderBottomNone
                                    )}
                                  >
                                    {isCompleteProjectDropdown ? (
                                      <Typography className={classes.font14}>{totalQuantity}</Typography>
                                    ) : (
                                      <InputBase
                                        value={quantity === null ? '' : quantity}
                                        className={clsx(classes.inputBaseMarginLeftRight, classes.font14)}
                                        onChange={e => {
                                          handleInterconnectsInputChange(e, {
                                            category: 'ENPHASE_EQUIPMENT',
                                            subCategory: subCategory.toUpperCase(),
                                            subCategoryName: subCategoricalValue.name,
                                            interconnectName: this.state.interconnectName,
                                            fieldName: 'quantity',
                                            storeDistributorToggle: storeDistributorToggle
                                          });
                                        }}
                                      />
                                    )}
                                  </Box>
                                )}
                              </TableCell>
                              <TableCell>
                                {!storeDistributorToggle && get(subCategoricalValue, 'store_price', '') ? (
                                  <Typography className={classes.font14}>
                                    ${get(subCategoricalValue, 'store_price', '')}
                                  </Typography>
                                ) : (
                                  <Box className={classes.priceInputWrapper}>
                                    <Typography className={classes.font14}>$</Typography>
                                    <InputBase
                                      value={distributor_price === null ? '' : distributor_price}
                                      className={clsx(classes.inputBaseMarginLeftRight, classes.font14)}
                                      onChange={e => {
                                        handleInterconnectsInputChange(e, {
                                          category: 'ENPHASE_EQUIPMENT',
                                          subCategory: subCategory.toUpperCase(),
                                          subCategoryName: subCategoricalValue.name,
                                          interconnectName: this.state.interconnectName,
                                          fieldName: 'distributor_price',
                                          storeDistributorToggle: storeDistributorToggle
                                        });
                                      }}
                                    />
                                  </Box>
                                )}
                              </TableCell>
                              <TableCell>
                                <Box className={classes.displayFlexAlignCenter}>
                                  <Typography className={clsx(classes.stylePriceText, classes.font14)}>
                                    ${Math.round(
                                      getNetPriceIncludingDiscountPerBOMItem(this.state.currentEnphaseBOMItems, {
                                        category: 'ENPHASE_EQUIPMENT',
                                        subCategory: subCategory.toUpperCase(),
                                        name: subCategoricalValue.name,
                                        isInterconnect: true
                                      })
                                    ).toLocaleString()}
                                  </Typography>
                                  {subCategory === 'Inverters' && (
                                    <Box className={classes.discountMainWrapper}>
                                      <Typography className={classes.discountLabel}>Discount :</Typography>
                                      <Box className={classes.discountInputWrapper}>
                                        <Typography>$</Typography>
                                        <InputBase
                                          value={discount_amount === null ? '' : discount_amount}
                                          className={classes.inputBaseMarginLeftRight}
                                          onChange={e => {
                                            handleInterconnectsInputChange(e, {
                                              category: 'ENPHASE_EQUIPMENT',
                                              subCategory: subCategory.toUpperCase(),
                                              subCategoryName: subCategoricalValue.name,
                                              interconnectName: this.state.interconnectName,
                                              fieldName: 'discount_amount',
                                              storeDistributorToggle: storeDistributorToggle
                                            });
                                          }}
                                        />
                                      </Box>
                                    </Box>
                                  )}
                                </Box>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                    </Fragment>
                  );
                })}

                <TableRow>
                  <TableCell
                    className={clsx(classes.borderRight, classes.styleTotalRowText)}
                    component="th"
                    scope="row"
                  >
                    Total
                  </TableCell>
                  <TableCell />
                  <TableCell />
                  <TableCell className={classes.styleTotalRowText}>${totalPriceTable.toLocaleString()}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  isMobile: state.appReducer.isMobileView
});

export default connect(mapStateToProps)(withStyles(styles)(EnphaseEquipmentsTable));
