import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import {
  withStyles,
  Box,
  Typography,
  Paper,
  FormControl,
  InputLabel,
  Select,
  FormControlLabel,
  Switch,
  Grid,
  TextField,
  MenuItem,
  ClickAwayListener,
  FormHelperText,
  Checkbox,
  Link
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CloseIcon from '@material-ui/icons/Close';
import clsx from 'clsx';
import { get, isEmpty } from 'lodash';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

import styles from './styles';
import MapDialog from '../MapDialog';
import CustomerDetailsPopup from '../CustomerDetailsPopup';
import MapIcon from '../Icons/mapIcon';
import { Label } from '../../common/Label';
import { getConstantValues } from '../../../containers/BOMCalculator/helper';
import { getSiteSearchLeads } from '../../../containers/BOMCalculator/actions';
import {
  existingStorageUpgrade,
  defaultCountryPhoneCode,
  newSystem,
  backupStrings,
  commercial,
  fullEnergyIndependence
} from '../../../containers/BOMCalculator/constants';

class index extends Component {
  constructor(props) {
    super(props);
    this.autocomplete = null;
  }

  state = {
    isGoogleAddress: false,
    clickedOustideAddress: false,
    clickedOutsideZipCode: false,
    zipCodeAutosearch: [],
    addressFromMap: false
  };

  zipCodeTimeout = null;
  existingSystemTimeout = null;
  autocompleteSet = false;
  existingCustomerDetails = {};

  componentDidMount() {
    const { getSiteSearchLeads, isBOMCalculatorOutput = false } = this.props;
    !isBOMCalculatorOutput && getSiteSearchLeads({ searchValue: '' });
  }

  componentDidUpdate(prevProps) {
    const { addMoreToggle, systemRequirements, isBOMCalculatorOutput, showCustomerDetailsPopup } = this.props;
    const isCustomerDetailsPopupOpen = isBOMCalculatorOutput && showCustomerDetailsPopup;
    if (
      (!this.autocompleteSet && (addMoreToggle || isCustomerDetailsPopupOpen)) ||
      (addMoreToggle && prevProps.addMoreToggle !== addMoreToggle)
    ) {
      if (isBOMCalculatorOutput && showCustomerDetailsPopup) {
        setTimeout(() => {
          this.initialiseGoogleAddressField();
        }, 1000);
      } else {
        this.initialiseGoogleAddressField();
      }
    }
    if (
      get(systemRequirements, 'requirement', '') !== get(prevProps, 'systemRequirements.requirement', '') &&
      this.props.from !== 'lead'
    ) {
      this.onAutocompleteSelect('');
    }
  }

  initialiseGoogleAddressField = () => {
    this.autocomplete = new window.google.maps.places.Autocomplete(document.getElementById('bomaddress'), {
      componentRestrictions: { country: 'us' }
    });
    this.autocomplete.addListener('place_changed', this.handlePlaceSelect);
    this.autocompleteSet = true;
  };

  handlePlaceSelect = () => {
    const { handleOnChange } = this.props;
    let placeObject = this.autocomplete.getPlace();
    const addressObject = this.getFilteredAddressObject(placeObject, true);
    handleOnChange('', 'systemRequirements.customerDetails.address', {
      value: placeObject.formatted_address,
      from: 'systemRequirements'
    });
    handleOnChange('', 'systemRequirements.customerDetails.addressObject', {
      value: addressObject,
      from: 'systemRequirements'
    });
    this.setState({
      isGoogleAddress: true,
      clickedOustideAddress: false
    });
  };

  getFilteredAddressObject = (addressObject, fromGoogleSuggestions = false) => {
    const { systemRequirements } = this.props;
    if (isEmpty(addressObject)) {
      return {};
    }
    let addresses = addressObject.address_components;
    const streetNumber = addresses.find(
      address => address.types.includes('street_number') || address.types.includes('premise')
    );
    const route = addresses.find(
      address => address.types.includes('route') || address.types.includes('sublocality_level_1')
    );
    const address1 = streetNumber && route ? [ streetNumber.long_name, route.long_name ].join(', ') : '';
    const city = addresses.find(address => address.types.includes('locality'));
    const state = addresses.find(address => address.types.includes('administrative_area_level_1'));
    const zip = addresses.find(address => address.types.includes('postal_code'));
    const country = addresses.find(address => address.types.includes('country'));
    const latitude = addressObject.geometry.location.lat();
    const longitude = addressObject.geometry.location.lng();
    const addressObj = {
      address1: fromGoogleSuggestions ? address1.split(',')[0] : get(systemRequirements, 'customerDetails.address', ''),
      address2: fromGoogleSuggestions ? (address1.split(',')[1] ? address1.split(',')[1].trim() : '') : '',
      city: city && (city.long_name || city.short_name),
      state: state && (state.short_name || state.long_name),
      zip: zip && (zip.short_name || zip.long_name),
      country: country && (country.short_name || country.long_name),
      latitude,
      longitude
    };
    return addressObj;
  };

  handleChange = (e, key) => {
    const { handleOnChange, systemRequirements, validateSystemRequirementsInput } = this.props;
    const newValue = e.target.value;
    if (key === 'zipCode') {
      this.setState({ zipCodeAutosearch: [] });
      const isZipCodeValid = newValue && newValue.match(/^[0-9]{5}(?:-[0-9]{4})?$/);
      const zipCode = isZipCodeValid && isZipCodeValid[0] ? isZipCodeValid[0] : '';
      const search = new window.google.maps.places.AutocompleteService();
      clearTimeout(this.zipCodeTimeout);
      if (zipCode) {
        this.zipCodeTimeout = setTimeout(() => {
          search.getPlacePredictions(
            { input: newValue, types: [ '(regions)' ], componentRestrictions: { country: 'us' } },
            (result, status) => {
              if (status === 'OK' && result[0] && !this.state.isGoogleAddress) {
                const geocoderRef = new window.google.maps.Geocoder();
                geocoderRef.geocode({ placeId: result[0].place_id }, (res, geoStatus) => {
                  if (
                    geoStatus === 'OK' &&
                    res[0] &&
                    res[0].formatted_address !== this.addressString &&
                    !this.addressSelected
                  ) {
                    this.setState({ zipCodeAutosearch: res[0] });
                    validateSystemRequirementsInput({
                      key: 'systemRequirements.customerDetails.zipCode'
                    });
                  }
                });
              } else {
                validateSystemRequirementsInput({
                  key: 'systemRequirements.customerDetails.zipCode',
                  zipCodeError: true
                });
              }
            }
          );
        }, 500);
      } else {
        validateSystemRequirementsInput({
          key: 'systemRequirements.customerDetails.zipCode',
          zipCodeError: true
        });
      }
    }
    this.setState({
      isGoogleAddress: key === 'address' ? false : this.state.isGoogleAddress,
      clickedOustideAddress: key !== 'address',
      clickedOutsideZipCode: key !== 'zipCode'
    });
    handleOnChange(e, `systemRequirements.customerDetails.${key}`, {
      from: 'systemRequirements',
      checkZipCodeEmpty:
        !this.state.isGoogleAddress &&
        this.state.clickedOustideAddress &&
        get(systemRequirements, 'customerDetails.address')
    });
  };

  renderTextfield = (key, label) => {
    const { classes, systemRequirements, errorData } = this.props;
    const value = get(systemRequirements, `customerDetails.${key}`);

    return (
      <FormControl
        id={key + 'BomFormControl'}
        className={clsx(classes.textFieldAdditionalDetails, classes.customLabel)}
      >
        <TextField
          id={'bom' + key}
          label={<Label text={label} className={classes.styleLabel} />}
          value={value}
          onChange={e => this.handleChange(e, key)}
          error={
            errorData &&
            get(errorData, `systemRequirements.customerDetails.${key}`, '') &&
            get(errorData, `systemRequirements.customerDetails.${key}.error`)
          }
          helperText={
            errorData &&
            get(errorData, `systemRequirements.customerDetails.${key}`, '') &&
            get(errorData, `systemRequirements.customerDetails.${key}.errorMessage`)
          }
        />
      </FormControl>
    );
  };

  handleOnPhoneChange = (value, data, event, formattedValue) => {
    const { handleOnChange, systemRequirements } = this.props;
    handleOnChange('', 'systemRequirements.customerDetails.mobile', {
      value,
      from: 'systemRequirements',
      checkZipCodeEmpty:
        !this.state.isGoogleAddress &&
        this.state.clickedOustideAddress &&
        get(systemRequirements, 'customerDetails.address')
    });
  };

  renderPhoneField = key => {
    const { classes, systemRequirements, errorData } = this.props;
    const isError =
      errorData &&
      get(errorData, 'systemRequirements.customerDetails.mobile', '') &&
      get(errorData, 'systemRequirements.customerDetails.mobile.error');
    const helperText =
      errorData &&
      get(errorData, 'systemRequirements.customerDetails.mobile', '') &&
      get(errorData, 'systemRequirements.customerDetails.mobile.errorMessage');
    const value = get(systemRequirements, 'customerDetails.mobile');
    const countryCode = get(systemRequirements, 'customerDetails.existingSystem.country', '');
    const existingSystem = get(systemRequirements, 'customerDetails.existingSystem.name', '');

    return (
      <FormControl
        id={key + 'BomFormControl'}
        className={clsx(classes.textFieldAdditionalDetails, classes.customLabel)}
      >
        <Typography className={classes.styleMobileHeading}>Mobile</Typography>
        <PhoneInput
          buttonClass={classes.buttonClass}
          inputClass={clsx(
            classes.phoneField,
            classes.phoneInputField,
            isError && classes.errorClass,
            !isEmpty(existingSystem) && classes.hideInput
          )}
          country={countryCode ? countryCode.toLowerCase() : 'us' || 'us'}
          value={value || ''}
          onChange={this.handleOnPhoneChange}
          disableDropdown
          countryCodeEditable={false}
          disableCountryGuess
        />
        {!isEmpty(existingSystem) && (
          <input
            className={clsx(classes.phoneField, classes.additionalPhoneInputField)}
            value={value || ''}
            onChange={() => {}}
          />
        )}
        {isError && <FormHelperText className={classes.colorRed}>{helperText}</FormHelperText>}
      </FormControl>
    );
  };

  handleClickAway = key => {
    const { systemRequirements, handleOnChange } = this.props;
    if (key === 'clickedOutsideZipCode' && !isEmpty(get(systemRequirements, 'customerDetails.zipCode'))) {
      const { zipCodeAutosearch } = this.state;
      const { formatted_address = '' } = zipCodeAutosearch;
      const addressObject = this.getFilteredAddressObject(zipCodeAutosearch, false);
      if (!isEmpty(zipCodeAutosearch)) {
        handleOnChange('', 'systemRequirements.customerDetails.zipCode', {
          value: formatted_address,
          from: 'systemRequirements',
          checkZipCodeEmpty:
            !this.state.isGoogleAddress &&
            this.state.clickedOustideAddress &&
            get(systemRequirements, 'customerDetails.address')
        });
        handleOnChange('', 'systemRequirements.customerDetails.addressObject', {
          value: addressObject,
          from: 'systemRequirements',
          checkZipCodeEmpty:
            !this.state.isGoogleAddress &&
            this.state.clickedOustideAddress &&
            get(systemRequirements, 'customerDetails.address')
        });
      }
    }
    this.setState({ [key]: true });
  };

  renderAddressZipField = (key, label, stateVariable) => {
    return (
      <ClickAwayListener
        onClickAway={() => {
          this.handleClickAway(stateVariable);
        }}
      >
        {this.renderTextfield(key, label)}
      </ClickAwayListener>
    );
  };

  onTextChangeAutocomplete = event => {
    const { getSiteSearchLeads, handleCustomerDetails, systemRequirements } = this.props;
    const newValue = event.target.value;
    clearTimeout(this.existingSystemTimeout);
    if (newValue && newValue.length > 0) {
      this.existingSystemTimeout = setTimeout(() => {
        getSiteSearchLeads({ searchValue: newValue });
      }, 1000);
    } else {
      getSiteSearchLeads({ searchValue: '' });
    }
    handleCustomerDetails({
      ...get(systemRequirements, 'customerDetails'),
      existingSystem: {
        ...get(systemRequirements, 'customerDetails.existingSystem'),
        name: newValue
      }
    });
  };

  preventEnterPress = event => {
    if (event.which === 13) {
      event.preventDefault();
    }
  };

  getAddress = value => {
    const address = [];
    [ value.address, value.city, value.state, value.country ].forEach(value => {
      if (!isEmpty(value)) {
        address.push(value);
      }
    });
    return address.join(', ');
  };

  getAddress1And2 = value => {
    const splitAddressIndex = value.address.search(',');
    let address1 = '',
      address2 = '';
    if (splitAddressIndex === -1) {
      address1 = value.address;
    } else {
      address1 = value.address.substring(0, splitAddressIndex).trim();
      address2 =
        splitAddressIndex < value.address.length
          ? value.address.substring(splitAddressIndex + 1, value.address.length).trim()
          : '';
    }
    return [ address1, address2 ];
  };

  getValue = (value, key) => (!isEmpty(value) ? get(value, key, '') : '');

  onAutocompleteSelect = async value => {
    const { handleCustomerDetails, systemRequirements, getSiteSearchLeads, handleExistingSystemSelect } = this.props;
    const ownerInfo = get(value, 'owner_info', {});
    const email = this.getValue(ownerInfo, 'email');
    const zipCode = this.getValue(value, 'zip');
    if (isEmpty(value)) {
      !isEmpty(get(this.existingCustomerDetails, 'existingSystem', '')) && getSiteSearchLeads({ searchValue: '' });
    }
    this.existingCustomerDetails = {
      ...get(systemRequirements, 'customerDetails'),
      name: this.getValue(ownerInfo, 'name'),
      email: email,
      mobile: this.getValue(ownerInfo, 'phone') || defaultCountryPhoneCode,
      address: !isEmpty(value) ? this.getAddress(value) : '',
      zipCode: zipCode,
      existingSystem: value || {},
      addressObject: {
        address1: !isEmpty(value) ? this.getAddress1And2(value)[0] : '',
        address2: !isEmpty(value) ? this.getAddress1And2(value)[1] : '',
        city: this.getValue(value, 'city'),
        state: this.getValue(value, 'state'),
        zip: zipCode,
        country: this.getValue(value, 'country'),
        latitude: this.getValue(value, 'latitude'),
        longitude: this.getValue(value, 'longitude')
      }
    };
    await handleCustomerDetails(this.existingCustomerDetails);
    if (!isEmpty(value)) handleExistingSystemSelect(value);
  };

  handleAutocompleteBlur = () => {
    const { systemRequirements, handleCustomerDetails } = this.props;
    const existingSystemName = get(this.existingCustomerDetails, 'existingSystem.name', '');
    const systemName = get(systemRequirements, 'customerDetails.existingSystem.name', '');
    if (!isEmpty(existingSystemName) && systemName !== existingSystemName) {
      handleCustomerDetails(this.existingCustomerDetails);
    }
  };

  handleSaveAddressFromMap = (address, addressObj) => {
    const { handleCustomerDetails, systemRequirements } = this.props;
    this.existingCustomerDetails = {
      ...get(systemRequirements, 'customerDetails'),
      address: address,
      zipCode: this.getValue(addressObj, 'zip'),
      addressObject: { ...addressObj }
    };
    handleCustomerDetails(this.existingCustomerDetails);
    this.setState({ addressFromMap: false, isGoogleAddress: true });
  };

  getSystemName = option =>
    get(option, 'system_id') + ' - ' + get(option, 'name') + ' - ' + get(option, 'owner_info.email');

  renderMapView = (open, isFromOutput) => {
    const { systemRequirements } = this.props;
    return (
      <MapDialog
        open={open}
        defaultAddress={get(systemRequirements, 'customerDetails.address', '')}
        defaultAddressObj={get(systemRequirements, 'customerDetails.addressObject', '')}
        handleClose={() => this.setState({ addressFromMap: false })}
        handleSave={(address, addressObj) => this.handleSaveAddressFromMap(address, addressObj)}
        isFromOutput={isFromOutput}
      />
    );
  };

  renderCustomerDetails = (isFromOutput = false) => {
    const { classes, systemRequirements, siteSearchList, showCustomerDetailsPopup } = this.props;
    const { requirement } = systemRequirements;
    const address = get(systemRequirements, 'customerDetails.address', '');
    const existingSystem = get(systemRequirements, 'customerDetails.existingSystem.name', '');
    const isNotEmptyExistingSystem = !isEmpty(existingSystem);
    return (
      <Fragment>
        {isFromOutput && (
          <Typography className={classes.notes}>Please provide the below details to save BOM to a lead.</Typography>
        )}
        <Box className={classes.customerDetailsWrapper}>
          <Grid container direction="row" className={classes.additionalDetails}>
            {requirement === existingStorageUpgrade ? (
              <Box>
                <Autocomplete
                  onKeyPress={e => this.preventEnterPress(e)}
                  className={clsx(classes.textFieldAdditionalDetails, classes.customLabel)}
                  value={existingSystem}
                  inputValue={existingSystem}
                  onChange={(e, value) => this.onAutocompleteSelect(value)}
                  onBlur={() => this.handleAutocompleteBlur()}
                  noOptionsText="No options"
                  options={siteSearchList}
                  getOptionSelected={(option, value) =>
                    value === get(option, 'name') ||
                    value === '' ||
                    value === get(systemRequirements, 'customerDetails.existingSystem.name', '')}
                  getOptionLabel={option => this.getSystemName(option)}
                  popupIcon={<ExpandMoreIcon />}
                  closeIcon={isEmpty(existingSystem) ? <Fragment /> : <CloseIcon fontSize="small" />}
                  renderInput={params => (
                    <TextField
                      id="existingEnphaseSystem"
                      onChange={this.onTextChangeAutocomplete}
                      {...params}
                      label={<Label text={'Existing Enphase System'} className={classes.styleLabel} />}
                      fullWidth
                    />
                  )}
                />
              </Box>
            ) : null}
            <Box className={clsx(isNotEmptyExistingSystem && classes.disabledField)}>
              {this.renderTextfield('name', 'Name')}
            </Box>
            <Box className={clsx(isNotEmptyExistingSystem && classes.disabledField)}>
              {this.renderTextfield('email', 'Email')}
            </Box>
            <Box className={clsx(isNotEmptyExistingSystem && classes.disabledField)}>
              {this.renderPhoneField('mobile', 'Mobile')}
            </Box>
            <Box className={clsx(isNotEmptyExistingSystem && classes.disabledField)}>
              {this.renderAddressZipField('address', 'Address', 'clickedOustideAddress')}
              {!isFromOutput && (
                <Box className={classes.mapWrapper} onClick={() => this.setState({ addressFromMap: true })}>
                  <Typography className={classes.mapText}>Select on Map</Typography>
                  <MapIcon />
                </Box>
              )}
            </Box>
            {!this.state.isGoogleAddress &&
            this.state.clickedOustideAddress &&
            address && (
              <Box className={clsx(isNotEmptyExistingSystem && classes.disabledField)}>
                {this.renderAddressZipField('zipCode', 'Zip Code', 'clickedOutsideZipCode')}
              </Box>
            )}
          </Grid>
          {isFromOutput && (
            <Grid container direction="row" className={clsx(classes.additionalDetails, classes.additionalmapWrapper)}>
              {this.renderMapView(showCustomerDetailsPopup, true)}
            </Grid>
          )}
        </Box>
      </Fragment>
    );
  };

  getBackupSection = () => {
    const { classes, systemRequirements, handleOnChange, projectType } = this.props;
    const requirement = get(systemRequirements, 'requirement', '');
    if (projectType === commercial) return <Fragment />;
    if (requirement === 'Solar + Storage' || requirement === fullEnergyIndependence) {
      const backupKind = 'loadControllersRequired';
      return (
        <Box className={classes.backupWrapper}>
          <Checkbox
            color="primary"
            checked={get(systemRequirements, backupKind)}
            className={classes.styleCheckboxButton}
            onChange={e =>
              handleOnChange(e, `systemRequirements.${backupKind}`, {
                from: 'systemRequirements',
                value: e.target.checked
              })}
          />
          <Typography>{backupStrings()[backupKind]}</Typography>
          <Link
            className={classes.styleLearnMore}
            target="_blank"
            href="https://enphase.com/download/tech-brief/system-use-cases-iq8-microinverters"
          >
            Learn More
          </Link>
        </Box>
      );
    } else return <Fragment />;
  };

  render() {
    const {
      classes,
      handleMoreDetailsToggle,
      addMoreToggle,
      systemRequirements,
      projectType,
      handleOnChange,
      disabled,
      isBOMCalculatorOutput,
      showCustomerDetailsPopup = false,
      handleCloseCustomerDetailsPopup = () => {},
      handleSaveCustomerDetailsPopup = () => {}
    } = this.props;
    const { requirement } = systemRequirements;
    const requirementList = get(getConstantValues(projectType, '1'), 'requirementList.array', []);
    const { errorData } = this.props;

    if (isBOMCalculatorOutput) {
      return (
        <CustomerDetailsPopup
          open={showCustomerDetailsPopup}
          onPopupClose={handleCloseCustomerDetailsPopup}
          handleSave={handleSaveCustomerDetailsPopup}
        >
          {this.renderCustomerDetails(true)}
        </CustomerDetailsPopup>
      );
    }

    return (
      <Fragment>
        <Paper square className={clsx(classes.projectTypePaper, disabled && classes.disabledField)}>
          <Typography className={classes.chooseProjectTypeText}>Choose the system requirements</Typography>
          <Box className={classes.requirementRowWrapper}>
            <FormControl id={'requirementFormControl'} className={clsx(classes.inputField, classes.customLabel)}>
              <InputLabel id={'requirementInputLabel'}>
                <Label text="Select Requirement" className={classes.styleLabel} />
              </InputLabel>
              <Select
                IconComponent={props => <ExpandMoreIcon className="MuiSelect-icon" />}
                onChange={e => handleOnChange(e, 'systemRequirements.requirement', { from: 'systemRequirements' })}
                value={requirement}
              >
                {requirement === 'New System' && (
                  <MenuItem key={'newSystem'} value={newSystem}>
                    {newSystem}
                  </MenuItem>
                )}
                {requirementList.map((list, index) => {
                  if (get(list, 'hide', false)) {
                    return null;
                  }
                  return (
                    <MenuItem key={list.key + index} value={list.value} disabled={list.disabled}>
                      {list.value}
                    </MenuItem>
                  );
                })}
              </Select>
              {get(errorData, `systemRequirements.requirement.error`) && (
                <Typography className={classes.errorMessage}>
                  {get(errorData, `systemRequirements.requirement.errorMessage`)}
                </Typography>
              )}
            </FormControl>
            {this.getBackupSection()}
          </Box>
          <Box className={classes.addMoreDetailsWrapper}>
            <Typography>
              Add more details to save the BOM Calculation{' '}
              <span className={classes.optionalText}>&nbsp;(Optional)</span>
            </Typography>
            <FormControlLabel
              control={<Switch checked={addMoreToggle} onChange={() => handleMoreDetailsToggle()} color="primary" />}
              labelPlacement="end"
              className={classes.styleSwitch}
            />
          </Box>
          <Typography className={classes.noteText}>
            Note: A lead will be created and BOM details will be saved as part of the lead
          </Typography>
          {addMoreToggle && this.renderCustomerDetails(false)}
        </Paper>
        {this.renderMapView(this.state.addressFromMap, false)}
      </Fragment>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  getSiteSearchLeads: payload => dispatch(getSiteSearchLeads(payload))
});

const mapStateToProps = state => ({
  siteSearchList: state.bomInputReducer.siteSearchList
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(index));
