import I18n from 'e_i18n';
import en from './en';
import fr from './fr';
import de from './de';
import nl from './nl';
import it from './it';
import es from './es';
import en_AU from './en_AU';

I18n.translations = { en, fr, de, nl, it, es, 'en-AU': en_AU };

export default I18n;
