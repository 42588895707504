import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Route } from 'react-router-dom';
import queryString from 'query-string';
import { constructQueryString, forwardTo, setToken } from '../../utils/helper';
import { setMobileView, setEnlightenManagerToken, setUserDetails } from './actions';
import Footer from '../../components/Footer';
import { get } from 'lodash';

const Layout = ({ children, isMobileView, loading, matchProps, ...rest }) => {
  return (
    <Fragment>
      {children}
      <Footer />
    </Fragment>
  );
};

class DefaultLayout extends React.Component {
  constructor(props) {
    super(props);
    const { search, pathname } = props.location;
    const {
      token,
      origin,
      installer,
      page = '',
      leadId = '',
      journeyType = '',
      from = '',
      order_id = '',
      status = ''
    } = queryString.parse(search);

    const queryParams = constructQueryString(
      {
        origin,
        installer,
        page,
        leadId,
        journeyType,
        from,
        order_id,
        status
      },
      true
    );
    if (token && pathname) {
      setToken(token);
      forwardTo(pathname + queryParams);
    }
    if (token) {
      this.props.setEnlightenManagerToken(token);
      const userDetails = JSON.parse(Buffer.from(token.split('.')[1], 'base64'));
      this.props.setUserDetails(get(userDetails, 'data', {}) || {});
    }
  }

  UNSAFE_componentWillMount() {
    window.addEventListener('resize', this.handleWindowSizeChange);
  }

  handleWindowSizeChange = () => {
    const isMobileView = window.innerWidth <= 1200;
    this.props.setMobileView(isMobileView);
  };

  componentDidMount = () => {
    this.handleWindowSizeChange();
  };

  render() {
    const { component: Component, isMobileView, loading, ...rest } = this.props;
    return (
      <Route
        {...rest}
        render={matchProps => (
          <Layout isMobileView={isMobileView} loading={loading} matchProps={matchProps}>
            <Component {...matchProps} />
          </Layout>
        )}
      />
    );
  }
}

const mapDispatchToProps = dispatch => ({
  setMobileView: isMobileView => dispatch(setMobileView(isMobileView)),
  setEnlightenManagerToken: tokenVal => dispatch(setEnlightenManagerToken(tokenVal)),
  setUserDetails: userDetails => dispatch(setUserDetails(userDetails))
});

const mapStateToProps = state => ({
  isMobileView: state.appReducer.isMobileView,
  loading: state.appReducer.loading,
  enlightenManagerToken: state.appReducer.enlightenManagerToken
});

export default connect(mapStateToProps, mapDispatchToProps)(DefaultLayout);
