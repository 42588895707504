import React, { Component } from 'react';
import {
  Backdrop,
  Box,
  CircularProgress,
  Typography,
  withStyles,
  DialogTitle,
  DialogContent,
  Dialog,
  DialogActions,
  Button
} from '@material-ui/core';
import clsx from 'clsx';
import queryString from 'query-string';
import moment from 'moment';
import { connect } from 'react-redux';
import PageHeader from '../../components/common/PageHeader';
import ProjectSummary from '../../components/BOMCalculatorOutput/ProjectSummary';
import BillOfMaterial from '../../components/BOMCalculatorOutput/BillOfMaterial';
import ActionButtons from '../../components/BOMCalculatorInput/ActionButtons';
import Snackbar from '../../components/SnakBar';
import { cloneDeep, get, isEmpty } from 'lodash';
import {
  addInputDataToCSV,
  addInstallerInfoToCSV,
  addOutputDataToCSV,
  addProjectBOMDataToCSV,
  getInputData,
  getNetPriceAndDiscount
} from './helper';
import { getLeadBOMDetails, getCartUrl } from '../BOMCalculator/actions';
import BomSavePopup from '../../components/BOMCalculatorOutput/BomSavePopup';
import ConfirmationPopup from '../../components/BOMCalculatorOutput/ConfirmationPopup';
import { OrderFromStorePopup, ExportBOMPopup } from '../../components/BOMCalculatorOutput';
import { constructQueryString, forwardTo, trackEvents } from '../../utils/helper';
import TemplatePopupAllTemplates from '../../components/BOMCalculatorInput/TemplatePopupAllTemplates';
import BOMSavePopup from '../../components/common/BOMSavePopup';
import { triggerDownloadCSV } from './actions';
import { commercial } from '../BOMCalculator/constants';
import NPSFeedbackPopup from '../../components/BOMCalculatorOutput/NPSFeedbackPopup';
import { getValue } from '../../utils/localStorage';

const styles = theme => ({
  root: {
    backgroundColor: '#FBFBFB',
    padding: theme.spacing(2),
    paddingBottom: theme.spacing(0),
    [theme.breakpoints.up('sm')]: {
      paddingLeft: theme.spacing(0),
      paddingRight: theme.spacing(0)
    }
  },
  rootMobile: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(0)
  },
  headerWrapper: {
    display: 'flex',
    alignItems: 'center',
    backgrounColor: '#F2F2F2',
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(1, 2, 1, 1.75)
    }
  },
  fontSizeHeader: {
    fontSize: 35,
    paddingLeft: theme.spacing(3),
    [theme.breakpoints.down('xs')]: {
      fontSize: 20,
      paddingLeft: theme.spacing(0)
    }
  },
  downloadCsvButton: {
    display: 'flex',
    alignItems: 'center',
    border: '1px solid #F3731F',
    marginLeft: 'auto',
    marginRight: theme.spacing(3),
    padding: theme.spacing(0.5, 2, 0.5, 2),
    color: '#F3731F',
    cursor: 'pointer',
    [theme.breakpoints.down('xs')]: {
      marginRight: 0
    }
  },
  styleDownloadCSVText: {
    marginLeft: theme.spacing(1),
    fontSize: 14,
    marginTop: theme.spacing(0.25)
  },
  backdrop: {
    zIndex: 1400,
    color: theme.palette.primary.main
  },
  thankYouHeading: {
    padding: theme.spacing(3, 3, 1),
    '& h2': {
      fontSize: 20,
      color: '#4A4A4A',
      fontWeight: 'bold'
    }
  },
  successMessage: {
    fontSize: 16,
    color: '#4A4A4A'
  },
  actionWrapper: {
    justifyContent: 'flex-start',
    padding: theme.spacing(1.5, 3, 3)
  },
  successOrderId: {
    color: '#4F89F4',
    fontWeight: 'bold',
    fontSize: 16
  },
  acceptButton: {
    color: theme.palette.secondary.main,
    textTransform: 'none',
    minWidth: theme.spacing(12.5)
  }
});

class BOMCalculatorOutput extends Component {
  state = {
    currentInterconnects: [],
    currentProjectBOMItems: [],
    collapseProjectSummary: false,
    storeDistributorToggle: true,
    openConfirmationPopup: false,
    showOrderfromStorePopup: false,
    showOrderSuccessPopup: false,
    orderId: '',
    showTemplatePopupOutput: false,
    openExportBOMPopup: false,
    exportBOMPopup: {
      includeInput: true,
      includeOutput: true,
      includePrices: true
    },
    showExitIntentPopup: false
  };

  isInterconnectUpdated = false;

  componentDidMount() {
    const { page = '', ...remainingParams } = queryString.parse(this.props.history.location.search);
    // calling lead details API to fill the output page data
    if (page === 'output') {
      forwardTo(this.props.history.location.pathname + constructQueryString(remainingParams));
      const { search, pathname } = this.props.history.location;
      const { from = '', leadId = '', journeyType = '' } = queryString.parse(search);
      const companyId = get(this.props.userDetails, 'company_id', '');
      this.props.getLeadBOMDetails({
        companyId,
        leadId,
        journeyType,
        successCbk: response => {
          const inputData = getInputData(response.projectBOM, companyId, from);
          this.props.updateInputState(inputData);
          const { order_id = '', status = '', ...remainingSearchparams } = queryString.parse(search);
          if (!isEmpty(order_id) && status === 'success') {
            this.setState({ showOrderSuccessPopup: true, orderId: order_id });
            forwardTo(`${pathname}${constructQueryString(remainingSearchparams)}`);
          }
        }
      });
    }
    document.addEventListener('mouseleave', this.handleExitIntentPopupLogic);
    this.forceUpdate();
    window.scrollTo(0, 0);
  }
  handleExitIntentPopupLogic = event => {
    if (
      (event.clientY <= 0 ||
        event.clientX <= 0 ||
        (event.clientX >= window.innerWidth || event.clientY >= window.innerHeight)) &&
      this.props.isBOMChanged
    ) {
      this.setState({ showExitIntentPopup: true });
    }
  };

  componentWillUnmount() {
    document.removeEventListener('mouseleave', this.handleExitIntentPopupLogic);
  }

  componentDidUpdate() {
    if (!this.isInterconnectUpdated && !isEmpty(this.props.bomDetailsArray)) {
      this.isInterconnectUpdated = true;
      this.setState({
        currentInterconnects: get(this.props.bomDetailsArray, 'interconnects', []) || [],
        currentProjectBOMItems: get(this.props.bomDetailsArray, 'project_bomitems', []) || []
      });
    }
  }

  handleExpandCollapseProjectSummary = () => {
    this.setState({ collapseProjectSummary: !this.state.collapseProjectSummary });
  };

  setInputValues = (array = [], helperParams, value) => {
    let childBomItems = array;
    const {
      category = '',
      subCategory = '',
      subCategoryName = '',
      fieldName,
      storeDistributorToggle = false
    } = helperParams;
    const getNetPrice = childItem => {
      const { distributor_price, quantity, store_price } = childItem;
      let netPriceValue = 0;
      if (category === 'ENPHASE_EQUIPMENT') {
        if (fieldName === 'distributor_price') {
          netPriceValue =
            (!storeDistributorToggle && store_price ? Number(store_price) : Number(value)) * Number(quantity);
        } else if (fieldName === 'quantity') {
          netPriceValue =
            (!storeDistributorToggle && store_price ? Number(store_price) : Number(distributor_price)) * Number(value);
        } else {
          netPriceValue =
            (!storeDistributorToggle && store_price ? Number(store_price) : Number(distributor_price)) *
            Number(quantity);
        }
      } else if (category === 'NON_ENPHASE_EQUIPMENT_ELECTRICAL') {
        if (fieldName === 'distributor_price') {
          netPriceValue = Number(value) * Number(quantity);
        } else {
          netPriceValue = Number(distributor_price) * Number(value);
        }
      } else if (category === 'NON_ENPHASE_EQUIPMENT_STRUCTURAL') {
        const { project_summary } = this.props.bomDetailsArray;
        const systemSizeDc = get(project_summary, 'system_size_dc');
        netPriceValue = Number(value) * systemSizeDc * 1000;
      } else {
        netPriceValue = Number(value);
      }
      return netPriceValue;
    };
    childBomItems.forEach((childItem, index) => {
      if (
        childItem.category === category &&
        childItem.sub_category === subCategory &&
        childItem.name === subCategoryName
      ) {
        childItem['net_price'] = getNetPrice(childItem);
        childItem[fieldName] = value;
      }
    });
    return childBomItems;
  };

  isValueWithinRange = (helperParams, value) => {
    const { fieldName, category } = helperParams;
    if (isNaN(value)) return false;
    let result = true;
    if (fieldName === 'distributor_price') {
      if (value.includes('.')) {
        const splitOnDot = value.split('.');
        if (category === 'NON_ENPHASE_EQUIPMENT_STRUCTURAL' && splitOnDot[1].length > 3) result = false;
        if (category !== 'NON_ENPHASE_EQUIPMENT_STRUCTURAL' && splitOnDot[1].length > 2) result = false;
        else if (Number(value) < 0 || Number(value) > 25000) result = false;
      }
      result = result && !(value !== '' && (Number(value) < 0 || Number(value) > 25000));
    } else if (fieldName === 'quantity' || fieldName === 'discount_amount') {
      if ((value !== '' && (Number(value) < 1 || Number(value) > 10000)) || value.includes('.')) result = false;
    }
    return result;
  };

  handleInterconnectsInputChange = (e, helperParams = {}) => {
    const { interconnectName, fieldName } = helperParams;
    if (!this.isValueWithinRange(helperParams, e.target.value)) return;
    let tempStateBomItems = cloneDeep(this.state.currentInterconnects);
    tempStateBomItems.forEach((interconnect, index) => {
      if (
        (fieldName === 'quantity' && interconnect.name === interconnectName) ||
        fieldName === 'distributor_price' ||
        fieldName === 'discount_amount'
      ) {
        interconnect['bom_items'] = this.setInputValues(
          get(interconnect, 'bom_items', []),
          helperParams,
          e.target.value
        );
      }
    });
    this.setState({ currentInterconnects: tempStateBomItems });
    this.props.onBOMChange();
  };

  handleProjectBomItemsInputChange = (e, helperParams = {}) => {
    if (!this.isValueWithinRange(helperParams, e.target.value)) return;
    const currentProjectBOMItems = this.setInputValues(
      cloneDeep(this.state.currentProjectBOMItems),
      helperParams,
      e.target.value
    );
    this.setState({
      currentProjectBOMItems
    });
    this.props.onBOMChange();
  };

  downloadCSV = () => {
    // variables
    const {
      inputState,
      userDetails,
      history,
      leadDetails,
      leadJourneyObject,
      triggerDownloadCSV,
      isNpsShownLead,
      handleNpsPopupOpenClose
    } = this.props;
    const { exportBOMPopup } = this.state;
    const user = get(userDetails, 'first_name', '') + ' ' + get(userDetails, 'last_name', '');
    const { installer = '' } = queryString.parse(history.location.search);
    const generatedOn = moment().format('MM/DD/YYYY');
    const projectType = get(inputState, 'projectType', '');
    const leadId = !isEmpty(leadDetails) ? get(leadDetails, 'lead_id') : get(leadJourneyObject, 'lead_id');

    // csv
    let CSV = 'Enphase BOM Calculator\r\n';
    CSV += addInstallerInfoToCSV(inputState, projectType, { installer, user, generatedOn, leadId });

    if (get(exportBOMPopup, 'includeInput')) {
      CSV += addInputDataToCSV(inputState, projectType);
      CSV += '\r\n';
    }
    if (get(exportBOMPopup, 'includeOutput')) {
      CSV += addOutputDataToCSV(this.state.currentInterconnects, projectType);
      CSV += '\r\n';
    }
    CSV += addProjectBOMDataToCSV(this.state, projectType, this.state.storeDistributorToggle);
    CSV += '\r\n';
    let uri = 'data:text/csv;charset=utf-8,%EF%BB%BF' + encodeURI(CSV);
    let link = document.createElement('a');
    link.href = uri;
    link.style = 'visibility:hidden';
    link.download = `Bill of Materials_${generatedOn}.csv`;

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    if (projectType === commercial) {
      triggerDownloadCSV({ csvObject: { lead_id: leadId, csv: CSV } });
    }
    this.setState({ openExportBOMPopup: false });
    if (!isNpsShownLead) {
      handleNpsPopupOpenClose(true, 'CSV_DOWNLOAD');
    }
  };

  handleCheckboxChangeCSV = (e, fieldName) => {
    this.setState({ exportBOMPopup: { ...this.state.exportBOMPopup, [fieldName]: e.target.checked } });
  };

  updateStoreDistributerToggle = (value = false) => {
    let tempState = cloneDeep(this.state.currentInterconnects);
    tempState.forEach(interconnect => {
      const bomItems = get(interconnect, 'bom_items', []);
      bomItems.forEach(bomItem => {
        if (bomItem.category === 'ENPHASE_EQUIPMENT') {
          bomItem['net_price'] =
            (!value && bomItem.store_price ? Number(bomItem.store_price) : Number(bomItem.distributor_price)) *
            Number(bomItem.quantity);
        }
      });
    });
    if (value) {
      trackEvents('triggerBOM', { menuValue: 'Distributor_toggle' });
    }
    this.setState({ storeDistributorToggle: value, currentInterconnects: tempState });
  };

  toggleOrderfromStorePopup = (showPopup = false) => {
    this.setState({ showOrderfromStorePopup: showPopup });
  };

  handleChooseOutput = templateID => {
    const { templatesList } = this.props;
    const { storeDistributorToggle } = this.state;
    let tempCurrentInterconnects = cloneDeep(this.state.currentInterconnects);
    let tempProjectBomItems = cloneDeep(this.state.currentProjectBOMItems);
    let matchingTemplateObject;
    templatesList.forEach(template => {
      if (template.id === templateID) {
        matchingTemplateObject = template;
      }
    });
    const itemList = get(matchingTemplateObject, 'item_list');
    itemList.forEach(item => {
      const { category, name, sub_category } = item;
      tempCurrentInterconnects.forEach(interconnect => {
        const bomItems = get(interconnect, 'bom_items');
        bomItems.forEach(bomItem => {
          if (bomItem.category === category && bomItem.sub_category === sub_category && bomItem.name === name) {
            bomItem['distributor_price'] = item['price'];
            const { quantity, store_price } = bomItem;
            let netPriceValue = 0;
            if (category === 'ENPHASE_EQUIPMENT') {
              netPriceValue =
                (!storeDistributorToggle && store_price ? Number(store_price) : Number(item['price'])) *
                Number(quantity);
            } else if (category === 'NON_ENPHASE_EQUIPMENT_ELECTRICAL') {
              netPriceValue = Number(item['price']) * Number(quantity);
            }
            bomItem['net_price'] = netPriceValue;
          }
        });
      });
      tempProjectBomItems.forEach(bomItem => {
        if (bomItem.category === category && bomItem.sub_category === sub_category && bomItem.name === name) {
          bomItem['distributor_price'] = item['price'];
          let netPriceValue = 0;
          if (category === 'NON_ENPHASE_EQUIPMENT_STRUCTURAL') {
            const { project_summary } = this.props.bomDetailsArray;
            const systemSizeDc = get(project_summary, 'system_size_dc');
            netPriceValue = Number(item['price']) * systemSizeDc * 1000;
          } else {
            netPriceValue = Number(item['price']);
          }
          bomItem['net_price'] = netPriceValue;
        }
      });
    });
    this.setState({
      currentInterconnects: tempCurrentInterconnects,
      currentProjectBOMItems: tempProjectBomItems,
      showTemplatePopupOutput: false
    });
  };

  handleOpenTemplate = () => {
    this.setState({ showTemplatePopupOutput: true });
  };

  render() {
    const {
      classes,
      isMobile,
      handleBack,
      bomDetailsArray,
      handleSave,
      currentlySending,
      currentlySendingEmailValidation,
      openBomSavePopup,
      handlePopupOpenClose,
      leadDetails,
      userDetails,
      leadJourneyObject,
      getCartUrl,
      inputState,
      existingEmailCheckError,
      existingEmailCheckErrorMessage,
      handleNpsPopupOpenClose,
      openNPSPopup,
      onSubmitNpsFeedback
    } = this.props;
    const { project_summary } = bomDetailsArray;
    const systemSizeDc = get(project_summary, 'system_size_dc', 0);
    const { netPrice, totalDiscountSum } = getNetPriceAndDiscount(this.state, systemSizeDc);
    const $perWdc = systemSizeDc !== 0 ? (netPrice / (systemSizeDc * 1000)).toFixed(2) : 'Infinity';
    const leadId = !isEmpty(leadDetails) ? get(leadDetails, 'lead_id') : get(leadJourneyObject, 'lead_id');
    const journeyType = !isEmpty(leadDetails)
      ? get(leadDetails, 'journey_type')
      : get(leadJourneyObject, 'journey_type');
    const journeyId = !isEmpty(leadDetails)
      ? get(leadDetails, 'journey_id')
      : get(leadJourneyObject, 'installer_lead_journey_id');
    const projectSummaryParameters = {
      netPrice,
      totalDiscountSum,
      $perWdc
    };
    const {
      currentInterconnects,
      currentProjectBOMItems,
      collapseProjectSummary,
      showOrderfromStorePopup,
      showOrderSuccessPopup,
      showTemplatePopupOutput,
      openExportBOMPopup,
      showExitIntentPopup
    } = this.state;
    return (
      <Box className={clsx(isMobile ? classes.rootMobile : classes.root)}>
        <Box className={classes.headerWrapper}>
          <PageHeader className={classes.fontSizeHeader} text="Bill of Material" />
          <Box className={classes.downloadCsvButton} onClick={() => this.setState({ openExportBOMPopup: true })}>
            <Typography className={classes.styleDownloadCSVText}>Export BOM</Typography>
          </Box>
        </Box>
        <ProjectSummary
          projectSummaryParameters={projectSummaryParameters}
          handleExpandCollapseProjectSummary={this.handleExpandCollapseProjectSummary}
          collapseProjectSummary={this.state.collapseProjectSummary}
        />
        <BillOfMaterial
          currentInterconnects={currentInterconnects}
          currentProjectBOMItems={currentProjectBOMItems}
          handleInterconnectsInputChange={this.handleInterconnectsInputChange}
          handleProjectBomItemsInputChange={this.handleProjectBomItemsInputChange}
          systemSizeDc={systemSizeDc}
          updateStoreDistributerToggle={this.updateStoreDistributerToggle}
          storeDistributorToggle={this.state.storeDistributorToggle}
          handleOpenTemplate={this.handleOpenTemplate}
        />
        <ActionButtons
          from="output"
          handleSave={handleSave}
          collapseProjectSummary={collapseProjectSummary}
          handleBack={() => this.setState({ openConfirmationPopup: true })}
          projectSummaryParameters={projectSummaryParameters}
          currentInterconnects={currentInterconnects}
          currentProjectBOMItems={currentProjectBOMItems}
          projectSummary={get(this.props.bomDetailsArray, 'project_summary') || {}}
          handleOrderfromStorePopup={this.toggleOrderfromStorePopup}
          isBomSaved={!isEmpty(leadId)}
          leadId={leadId}
        />
        <ConfirmationPopup
          open={this.state.openConfirmationPopup}
          handleClose={() => this.setState({ openConfirmationPopup: false })}
          handleConfirm={() => handleBack()}
          leadId={leadId}
          journeyType={journeyType}
          userDetails={userDetails}
        />
        <BomSavePopup open={openBomSavePopup} handlePopupOpenClose={handlePopupOpenClose} />
        <OrderFromStorePopup
          open={showOrderfromStorePopup}
          currentInterconnects={currentInterconnects}
          leadId={leadId}
          journeyId={journeyId}
          journeyType={journeyType}
          getCartUrl={getCartUrl}
          handleOrderfromStorePopup={this.toggleOrderfromStorePopup}
          userDetails={userDetails}
        />
        <TemplatePopupAllTemplates
          open={showTemplatePopupOutput}
          handleClose={() => this.setState({ showTemplatePopupOutput: false })}
          handleContinue={this.handleChooseOutput}
          source="output"
          stateValues={inputState}
          bomDetailsArray={this.props.bomDetailsArray}
        />
        <ExportBOMPopup
          open={openExportBOMPopup}
          itemList={this.state.exportBOMPopup}
          handleClose={() => this.setState({ openExportBOMPopup: false })}
          handleCheckboxChangeCSV={this.handleCheckboxChangeCSV}
          triggerCSVDownload={this.downloadCSV}
        />
        <Dialog
          onClose={() => this.setState({ showOrderSuccessPopup: false })}
          aria-labelledby="customized-dialog-title"
          open={showOrderSuccessPopup}
          maxWidth={'sm'}
          id="orderSuccess"
        >
          <DialogTitle
            id="customized-dialog-title"
            onClose={() => this.setState({ showOrderSuccessPopup: false })}
            className={classes.thankYouHeading}
          >
            Thank you for your order
          </DialogTitle>
          <DialogContent>
            <Typography className={classes.successMessage}>
              Your order is successfully placed. Order ID:{' '}
              <span className={classes.successOrderId}>{this.state.orderId}</span>
            </Typography>
          </DialogContent>
          <DialogActions className={classes.actionWrapper}>
            <Button
              onClick={() => this.setState({ showOrderSuccessPopup: false })}
              color="primary"
              variant="contained"
              className={classes.acceptButton}
              disableElevation
            >
              Okay
            </Button>
          </DialogActions>
        </Dialog>
        {(currentlySending || currentlySendingEmailValidation) && (
          <Backdrop className={classes.backdrop} open>
            <CircularProgress color="inherit" />
          </Backdrop>
        )}
        {showExitIntentPopup && (
          <BOMSavePopup
            showExitIntentPopup={showExitIntentPopup}
            handleClose={() => {
              this.setState({ showExitIntentPopup: false });
            }}
            handleSave={() => {
              handleSave(
                currentInterconnects,
                currentProjectBOMItems,
                get(this.props.bomDetailsArray, 'project_summary') || {},
                !isEmpty(leadId),
                'none'
              );
            }}
          />
        )}

        <NPSFeedbackPopup
          openNPSPopup={openNPSPopup}
          handleClose={handleNpsPopupOpenClose}
          onSubmit={onSubmitNpsFeedback}
        />

        {existingEmailCheckError &&
        existingEmailCheckErrorMessage && <Snackbar severity={'error'} message={existingEmailCheckErrorMessage} />}
      </Box>
    );
  }
}

const mapStateToProps = state => ({
  isMobile: state.appReducer.isMobileView,
  userDetails: state.appReducer.userDetails,
  currentlySending: state.bomInputReducer.currentlySending,
  currentlySendingEmailValidation: state.bomInputReducer.currentlySendingEmailValidation,
  leadDetails: state.bomInputReducer.leadDetails,
  leadJourneyObject: state.bomInputReducer.leadJourneyObject,
  templatesList: state.bomInputReducer.templatesList
});

const mapDispatchToProps = dispatch => ({
  getLeadBOMDetails: payload => dispatch(getLeadBOMDetails(payload)),
  getCartUrl: payload => dispatch(getCartUrl(payload)),
  triggerDownloadCSV: payload => dispatch(triggerDownloadCSV(payload))
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(BOMCalculatorOutput));
