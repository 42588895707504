import { isEmpty } from 'lodash';
import getConfig from '../config/env';

class Settings {
  constructor(config) {
    this.config = config;
  }

  getConfig() {
    return this.config;
  }

  getApiUrl(action) {
    return `${this.config.api}/${action}`;
  }

  bomApiUrl(url) {
    return `${this.config.bom_api_url}/api/v1/${url}`;
  }

  getEnlightenProxyApiUrl(options) {
    let url = this.drsApiUrl('proxyApi/request');
    if (isEmpty(options)) return url;
    const queryParams = [];
    for (const [key, value] of Object.entries(options)) {
      queryParams.push(encodeURIComponent(key) + '=' + encodeURIComponent(value));
    }
    return url + '?' + queryParams.join('&')
  }

  enlightenUrl(url) {
    return `${this.config.enlighten_url}/${url}`;
  }

  digiCoreUrl(url) {
    return `${this.config.digital_core_url}/api/v1/${url}`;
  }

//TODO: remove this and make api in BOM API
  drsApiUrl(url) {
    return `${this.config.drs_api_url}/api/v1/${url}`
  }

}

export default new Settings(getConfig(process.env.NODE_ENV));
