import React, { Component, Fragment } from 'react';
import { Box, Divider, Grid, IconButton, Paper, Typography, withStyles } from '@material-ui/core';
import clsx from 'clsx';
import ProjectSummaryPopup from './popup';
import { connect } from 'react-redux';
import { cloneDeep, get, isEmpty } from 'lodash';
import AddBoxIcon from '@material-ui/icons/AddBox';
import IndeterminateCheckBoxIcon from '@material-ui/icons/IndeterminateCheckBox';
import { trackEvents } from '../../../utils/helper';
import InfoTooltip from '../../BOMCalculatorInput/CommonFields/infoTooltip';

const styles = theme => ({
  projectSummaryPaper: {
    padding: theme.spacing(0.75),
    marginTop: theme.spacing(2),
    boxShadow: '0px 0px 8px #00000029'
  },
  projectSummaryHeaderWrapper: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: theme.spacing(1),
    padding: theme.spacing(1, 0, 1, 0),
    [theme.breakpoints.down('xs')]: {
      marginLeft: theme.spacing(1)
    }
  },
  expandCollapseIconWrapper: {
    padding: 0,
    marginLeft: theme.spacing(0.75)
  },
  expandCollapseIconSize: {
    fontSize: 28
  },
  projectSummaryText: {
    fontWeight: '1000',
    fontSize: 20,
    marginLeft: theme.spacing(1.5),
    [theme.breakpoints.down('xs')]: {
      fontSize: 16,
      marginTop: theme.spacing(0.5)
    }
  },
  viewAllButton: {
    color: '#4F89F4',
    marginLeft: 'auto',
    marginRight: theme.spacing(2.375),
    [theme.breakpoints.down('xs')]: {
      marginRight: theme.spacing(1.75),
      marginTop: theme.spacing(0.5)
    },
    cursor: 'pointer',
    fontWeight: 600
  },
  projectSummaryContentWrapper: {
    borderRadius: theme.spacing(1),
    border: '1px solid #F2F2F2',
    backgroundColor: '#F2F2F2',
    marginLeft: theme.spacing(2.25),
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(3),
    marginBottom: theme.spacing(4),
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-evenly',
    padding: theme.spacing(1.25, 0.5, 1.25, 0.5),
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(0.5),
      marginLeft: theme.spacing(1.375),
      marginRight: theme.spacing(1.75)
    }
  },
  projectParametersMainWrapper: {
    width: '67%',
    [theme.breakpoints.down('md')]: {
      width: '100%'
    }
  },
  projectParametersGridWrapper: {
    height: '100%',
    width: '100%',
    backgroundColor: '#FFF',
    borderRadius: theme.spacing(1)
  },
  styleHeadingParameters: {
    marginLeft: theme.spacing(3),
    [theme.breakpoints.down('md')]: {
      marginLeft: theme.spacing(2),
      fontSize: 14
    },
    marginTop: theme.spacing(3),
    fontWeight: 1000
  },
  parametersInfoWrapper: {
    display: 'flex',
    marginLeft: theme.spacing(3),
    [theme.breakpoints.down('md')]: {
      marginLeft: theme.spacing(2)
    },
    marginTop: theme.spacing(2.25),
    alignItems: 'center'
  },
  projectParameterGridSubWrapper: {
    width: '100%',
    height: '152px',
    [theme.breakpoints.up('md')]: {
      '&:nth-child(-n+3)': {
        borderRight: '3px solid #F2F2F2'
      }
    },
    [theme.breakpoints.down('1281')]: {
      '&:nth-child(odd)': {
        borderRight: '3px solid #F2F2F2'
      },
      '&:nth-child(1)': {
        borderBottom: '3px solid #F2F2F2'
      },
      '&:nth-child(2)': {
        borderBottom: '3px solid #F2F2F2'
      }
    }
  },
  projectCostMainWrapper: {
    display: 'flex',
    width: '32%',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      marginLeft: theme.spacing(0),
      marginTop: theme.spacing(0.375)
    },
    borderRadius: '8px',
    backgroundColor: '#FFF',
    height: '152px'
  },
  styleValues: {
    fontSize: 30,
    marginLeft: theme.spacing(0.5),
    [theme.breakpoints.down('md')]: {
      marginLeft: theme.spacing(1)
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 18
    },
    fontWeight: 1000
  },
  system_size_dcStyle: {
    color: '#03A3E9'
  },
  system_size_acStyle: {
    color: '#62B15A'
  },
  inverters_countStyle: {
    color: '#F3731F'
  },
  branch_circuits_countStyle: {
    color: '#03A3E9'
  },
  styleUnit: {
    marginLeft: theme.spacing(0.75),
    [theme.breakpoints.down('md')]: {
      marginLeft: theme.spacing(0.5)
    },
    [theme.breakpoints.down('xs')]: {
      marginTop: 0,
      fontSize: 18
    },
    marginTop: theme.spacing(1),
    fontSize: 20
  },
  projectCostSubWrapper: {
    width: '50%',
    marginRight: theme.spacing(-0.375)
  },
  styleNetPriceValue: {
    fontSize: 32,
    fontWeight: 600,
    color: theme.palette.primary.main,
    marginLeft: theme.spacing(3),
    [theme.breakpoints.down('md')]: {
      marginLeft: theme.spacing(2)
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 25
    },
    marginTop: theme.spacing(3)
  },
  dividerWrapper: {
    marginTop: theme.spacing(1.5),
    marginBottom: theme.spacing(1.5)
  },
  projectCostRightWrapper: {
    padding: theme.spacing(3.25, 2.5, 2.5, 4),
    [theme.breakpoints.down('md')]: {
      paddingLeft: theme.spacing(0.5)
    },
    [theme.breakpoints.down('xs')]: {
      paddingRight: 0
    }
  },
  styleProjectCost: {
    display: 'flex',
    padding: theme.spacing(0.5),
    width: '100%'
  },
  projectCostSubHeading: {
    minWidth: '75%',
    [theme.breakpoints.down('md')]: {
      minWidth: '57%'
    },
    fontSize: 15,
    [theme.breakpoints.down('xs')]: {
      fontSize: 14
    }
  },
  totalPriceColor: {
    color: '#03A3E9',
    fontWeight: 600,
    [theme.breakpoints.down('xs')]: {
      fontSize: 15
    }
  },
  discountColor: {
    color: '#62B15A',
    fontWeight: 600,
    [theme.breakpoints.down('xs')]: {
      fontSize: 15
    }
  },
  wdcColor: {
    color: '#8777D9',
    fontWeight: 600,
    [theme.breakpoints.down('xs')]: {
      fontSize: 15
    }
  },
  verticalDivider: {
    width: '3px',
    backgroundColor: '#F2F2F2'
  },
  summaryIcon: {
    [theme.breakpoints.down('xs')]: {
      width: '30%'
    }
  }
});

class ProjectSummary extends Component {
  state = {
    openPopup: false,
    projectParametersArray: [
      {
        key: 'system_size_dc',
        label: 'System Size DC',
        value: '',
        unit: 'kW',
        iconName: 'systemSizeDCIcon'
      },
      {
        key: 'system_size_ac',
        label: 'System Size AC',
        value: '',
        unit: 'kW',
        iconName: 'systemSizeACIcon',
        tooltip: 'Module power output is less than microinverter’s nominal power output'
      },
      { key: 'inverters_count', label: 'Inverter Count', value: '', unit: '', iconName: 'inverterCountIcon' },
      {
        key: 'branch_circuits_count',
        label: 'Branch Circuit Count',
        value: '',
        unit: '',
        iconName: 'branchCircuitCountIcon'
      }
    ]
  };
  isBomDetailsUpdated = false;

  componentDidUpdate(prevProps) {
    const { bomDetailsArray } = this.props;
    const { project_summary } = bomDetailsArray;

    if (!this.isBomDetailsUpdated && !isEmpty(project_summary)) {
      this.isBomDetailsUpdated = true;
      let tempProjectParametersArray = cloneDeep(this.state.projectParametersArray);
      tempProjectParametersArray.forEach(projectSummaryParameter => {
        projectSummaryParameter['value'] = project_summary[projectSummaryParameter.key];
      });
      this.setState({ projectParametersArray: tempProjectParametersArray });
    }
  }

  handlePopupOpenClose = value => {
    this.setState({ openPopup: value });
  };

  handleMinimizeProjectSummary = () => {
    const { handleExpandCollapseProjectSummary, collapseProjectSummary } = this.props;
    if (!collapseProjectSummary) {
      trackEvents('triggerBOM', { menuValue: 'Minimize_Project_summary' });
    }
    handleExpandCollapseProjectSummary();
  };

  render() {
    const { classes, collapseProjectSummary, projectSummaryParameters } = this.props;
    const { netPrice = 0, totalDiscountSum = 0, $perWdc } = projectSummaryParameters;
    const { system_size_ac = 0, system_size_dc = 0 } = get(this.props.bomDetailsArray, 'project_summary', {});
    const showTooltip = Number(system_size_ac) - Number(system_size_dc) > 0;
    return (
      <Fragment>
        <Paper square className={classes.projectSummaryPaper}>
          <Box className={classes.projectSummaryHeaderWrapper}>
            <IconButton
              onClick={() => this.handleMinimizeProjectSummary()}
              className={classes.expandCollapseIconWrapper}
            >
              {!collapseProjectSummary ? (
                <IndeterminateCheckBoxIcon color="primary" className={classes.expandCollapseIconSize} />
              ) : (
                <AddBoxIcon color="primary" className={classes.expandCollapseIconSize} />
              )}
            </IconButton>
            <Typography className={classes.projectSummaryText}>Project Summary</Typography>
            <Typography
              onClick={() => {
                trackEvents('triggerBOM', { menuValue: 'View_all_output' });
                this.handlePopupOpenClose(true);
              }}
              className={classes.viewAllButton}
            >
              View Detailed Summary
            </Typography>
          </Box>
          {!collapseProjectSummary && (
            <Box className={classes.projectSummaryContentWrapper}>
              <Box className={classes.projectParametersMainWrapper}>
                <Grid container className={classes.projectParametersGridWrapper}>
                  {this.state.projectParametersArray.map((parameter, index) => {
                    const { key, label, value, unit, iconName, tooltip } = parameter;
                    return (
                      <Grid
                        item
                        md={3}
                        xs={6}
                        key={'project-summary-' + index}
                        className={clsx(
                          classes.projectParameterGridSubWrapper,
                          key === 'systemSizeDc' || key === 'invertersCount'
                        )}
                      >
                        <Typography className={classes.styleHeadingParameters}>
                          {label} {key === 'system_size_ac' && showTooltip && <InfoTooltip text={tooltip} />}
                        </Typography>
                        <Box className={classes.parametersInfoWrapper}>
                          <img
                            className={classes.summaryIcon}
                            alt={iconName}
                            src={require(`../Icons/${iconName}.svg`)}
                          />
                          <Typography className={clsx(classes.styleValues, classes[`${key}Style`])}>
                            {value.toString().includes('.') ? Number(value).toFixed(2) : value}
                          </Typography>
                          {unit && (
                            <Typography className={clsx(classes.styleUnit, classes[`${key}Style`])}>{unit}</Typography>
                          )}
                        </Box>
                      </Grid>
                    );
                  })}
                </Grid>
              </Box>
              <Box className={clsx(classes.projectCostMainWrapper, classes.parametersSubWrapper)}>
                <Box className={classes.projectCostSubWrapper}>
                  <Typography className={classes.styleHeadingParameters}>Net cost</Typography>
                  <Typography className={classes.styleNetPriceValue}>
                    ${(netPrice - totalDiscountSum).toLocaleString()}
                  </Typography>
                </Box>
                <Box className={classes.dividerWrapper}>
                  <Divider orientation="vertical" classes={{ vertical: classes.verticalDivider }} />
                </Box>
                <Box className={classes.projectCostRightWrapper}>
                  <Box className={classes.styleProjectCost}>
                    <Typography className={classes.projectCostSubHeading}>Gross cost</Typography>
                    <Typography className={classes.totalPriceColor}>${netPrice.toLocaleString()}</Typography>
                  </Box>
                  <Box className={classes.styleProjectCost}>
                    <Typography className={classes.projectCostSubHeading}>Discount</Typography>
                    <Typography className={classes.discountColor}>${totalDiscountSum.toLocaleString()}</Typography>
                  </Box>
                  <Box className={classes.styleProjectCost}>
                    <Typography className={classes.projectCostSubHeading}>$/Wdc</Typography>
                    <Typography className={classes.wdcColor}>
                      {$perWdc === 'Infinity' ? '-' : $perWdc.toLocaleString() + '/Wdc'}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          )}
        </Paper>
        <ProjectSummaryPopup open={this.state.openPopup} handleClose={this.handlePopupOpenClose} />
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  isMobile: state.appReducer.isMobileView,
  bomDetailsArray: state.bomInputReducer.bomDetailsArray
});

export default connect(mapStateToProps)(withStyles(styles)(ProjectSummary));
