import * as React from 'react';
import { SvgIcon } from '@material-ui/core';

const Residential = props => {
  return (
    <SvgIcon {...props} width="50.719" height="50.656" viewBox="0 0 50.719 50.656">
      <g opacity={props.isselected === 'true' ? 1 : 0.64} transform="translate(-525.531 -438.593)">
        <path
          fill={props.isselected === 'true' ? '#f37320' : '#4b4b4b'}
          stroke="#fff"
          strokeWidth={2.5}
          d="M49.193,21.806l-21.6-19.2a2.4,2.4,0,0,0-3.187,0l-21.6,19.2a2.4,2.4,0,1,0,3.187,3.588l.806-.717V47.6A2.4,2.4,0,0,0,9.2,50H42.8a2.4,2.4,0,0,0,2.4-2.4V24.678l.806.717a2.4,2.4,0,1,0,3.187-3.588ZM40.4,45.2H11.6V20.411L26,7.611l14.4,12.8Z"
          transform="translate(525 438)"
        />
        <rect
          strokeWidth={2.5}
          fill="none"
          stroke={props.isselected === 'true' ? '#f37320' : '#4b4b4b'}
          width="12"
          height="18"
          rx="2"
          transform="translate(545 467.5)"
        />
      </g>
    </SvgIcon>
  );
};

export default Residential;
