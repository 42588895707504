import React, { Component, Fragment } from 'react';
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  withStyles
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import clsx from 'clsx';
import { capitalize, cloneDeep, findLastIndex, get, isEmpty } from 'lodash';
import { connect } from 'react-redux';
import { getSubCategoryLabels } from './TemplateTables/helper';
import CommonTemplatesTables from './TemplateTables/CommonTemplatesTables';
import {
  saveTemplate,
  getAllTemplates,
  createTemplate,
  deleteTemplate
} from '../../../containers/BOMCalculator/actions';
import { Label } from '../../common/Label';
import scrollIntoView from 'scroll-into-view';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import { trackEvents } from '../../../utils/helper';
import {
  fullEnergyIndependence,
  homeEssentialBackup,
  solarOnly,
  sunlightBackup
} from '../../../containers/BOMCalculator/constants';

const styles = theme => ({
  dialogTitleWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  paper: {
    borderRadius: theme.spacing(0),
    width: theme.spacing(150)
  },
  dialogTitle: {
    fontSize: 20,
    color: '#4A4A4A',
    fontWeight: 1000
  },
  dialogContentWrapper: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(3)
  },
  close: {
    opacity: 0.5,
    cursor: 'pointer',
    fontSize: theme.spacing(3),
    marginRight: theme.spacing(-0.75)
  },
  addButtonRowWrapper: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    marginBottom: theme.spacing(2)
  },
  infoTextStyle: {
    color: '#4D4F5C',
    fontSize: 14,
    marginLeft: theme.spacing(0.375)
  },
  styleAddNewButton: {
    border: '1px solid #FF5959',
    marginLeft: 'auto',
    color: '#FF5959',
    [theme.breakpoints.down('xs')]: {
      marginLeft: 0,
      marginTop: theme.spacing(1)
    }
  },
  styleDisabled: {
    border: '1px solid rgba(0, 0, 0, 0.26)'
  },
  styleDisabledCreate: {
    border: '1px solid rgba(0, 0, 0, 0.26)',
    color: 'rgba(0, 0, 0, 0.26) !important',
    backgroundColor: `${theme.palette.primary.white} !important`
  },
  templateMainWrapper: {
    border: '0.5px solid #E5E5E5',
    marginBottom: theme.spacing(1)
  },
  templateMainWrapperExpanded: {
    border: '0.5px solid #3B3B3B'
  },
  templateMainWrapperPadding: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    padding: theme.spacing(1.5)
  },
  displayFlex: {
    display: 'flex'
  },
  styleExpandIcon: {
    cursor: 'pointer'
  },
  templateSubWrapper: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  templateNameWrapper: {
    marginLeft: theme.spacing(1),
    display: 'flex',
    minWidth: theme.spacing(18.75),
    [theme.breakpoints.down('xs')]: {
      minWidth: theme.spacing(26.25)
    }
  },
  templateText: {
    color: '#4D4F5C'
  },
  templateNameText: {
    color: '#4D4F5C',
    fontWeight: 600,
    marginLeft: theme.spacing(0.5),
    marginTop: theme.spacing(0.125)
  },
  defaultTextBox: {
    borderRadius: theme.spacing(2.625),
    width: theme.spacing(9.625),
    height: theme.spacing(3.375),
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    marginLeft: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      marginLeft: theme.spacing(1.375),
      marginTop: theme.spacing(0.75)
    }
  },
  backgroundColorTagResidential: {
    backgroundColor: '#909090'
  },
  backgroundColorTagCommercial: {
    backgroundColor: theme.palette.primary.main
  },
  defaultText: {
    color: '#FFF',
    fontSize: 13
  },
  buttonsEditDeleteMainWrapper: {
    display: 'flex',
    marginLeft: 'auto',
    [theme.breakpoints.down('xs')]: {
      marginLeft: theme.spacing(2.5)
    },
    flexWrap: 'wrap',
    padding: theme.spacing(0.5, 0)
  },
  buttonsEditDeleteSubWrapper: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: theme.spacing(1),
    [theme.breakpoints.down('xs')]: {
      paddingLeft: theme.spacing(0)
    },
    paddingRight: theme.spacing(1),
    cursor: 'pointer'
  },
  cursorNormal: {
    cursor: 'default'
  },
  editText: {
    color: '#01B5DE',
    fontWeight: 600
  },
  editIcon: {
    height: theme.spacing(2.75),
    marginLeft: '16px'
  },
  deleteIcon: {
    height: theme.spacing(2.75),
    marginLeft: '8px'
  },
  deleteText: {
    color: '#FF5959',
    fontWeight: 600
  },
  disabledDelete: {
    color: 'rgba(0, 0, 0, 0.26)'
  },
  buttonsCancelSaveMainWrapper: {
    display: 'flex',
    marginLeft: 'auto',
    [theme.breakpoints.down('xs')]: {
      marginLeft: theme.spacing(4),
      marginTop: theme.spacing(1)
    },
    flexWrap: 'wrap',
    padding: theme.spacing(0.5, 0)
  },
  styleCancelButton: {
    border: '1px solid #9F9F9F',
    height: theme.spacing(4.5),
    color: '#4A4A4A',
    width: theme.spacing(9.5),
    textTransform: 'none'
  },
  styleSaveButton: {
    marginLeft: theme.spacing(2.25),
    marginRight: theme.spacing(1.5),
    height: theme.spacing(4.5),
    backgroundColor: theme.palette.primary.main,
    color: '#FFF',
    width: theme.spacing(9.75),
    textTransform: 'none',
    '&:hover': {
      color: theme.palette.primary.main,
      backgroundColor: '#FFF',
      border: '1px solid #F3731F'
    }
  },
  dividerWrapperExpanded: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1)
  },
  backdrop: {
    zIndex: 11,
    color: theme.palette.primary.main
  },
  styleChooseButton: {
    border: '1px solid #F3731F',
    color: '#F3731F',
    textTransform: 'none',
    width: theme.spacing(10),
    height: theme.spacing(4),
    marginRight: theme.spacing(1)
  },
  disabledChoose: {
    border: '1px solid rgba(0, 0, 0, 0.26)'
  },
  styleCreateButton: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.white,
    textTransform: 'none',
    width: theme.spacing(10),
    height: theme.spacing(4),
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.white
    }
  },
  addNewTemplateBoxWrapper: {
    border: '0.5px solid #3B3B3B',
    padding: theme.spacing(3, 1.5)
  },
  addNewTemplateBody: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  addNewInputFieldsWrapper: {
    marginLeft: theme.spacing(4)
  },
  addNewDropdownWrapper: {
    width: theme.spacing(19.25)
  },
  inputField: {
    width: theme.spacing(21.5),
    marginTop: theme.spacing(-1),
    [theme.breakpoints.down('xs')]: {
      width: '86%'
    }
  },
  textFieldAdditionalDetails: {
    marginLeft: theme.spacing(5),
    [theme.breakpoints.down('xs')]: {
      marginLeft: theme.spacing(0),
      marginTop: theme.spacing(2)
    }
  },
  customLabel: {
    '& .Mui-focused': {
      '& label': {
        alignItems: 'center'
      }
    },
    '& .MuiInputLabel-shrink': {
      '& label': {
        display: 'none'
      }
    },
    '& .MuiInput-underline.Mui-disabled:before': {
      borderBottomStyle: 'solid'
    }
  },
  styleLabel: {
    color: '#999999'
  },
  styleAddNewTemplateHeading: {
    color: '#4D4F5C',
    marginLeft: theme.spacing(4),
    fontWeight: 600
  },
  addNewTemplateButtonsWrapper: {
    marginTop: theme.spacing(1.5),
    [theme.breakpoints.down('xs')]: {
      marginLeft: theme.spacing(4),
      marginTop: theme.spacing(4)
    }
  },
  styleCloneText: {
    color: 'rgba(0, 0, 0, 0.54)',
    fontWeight: 1000
  },
  styleCopyIcon: {
    paddingTop: 0,
    paddingBottom: 0,
    '&:hover': {
      background: 'none'
    }
  },
  gridWrapperActionButtons: {
    marginLeft: 'auto',
    marginTop: theme.spacing(0.5),
    [theme.breakpoints.down('769')]: {
      marginLeft: theme.spacing(4.5)
    }
  },
  actionButtonsTemplate: {
    [theme.breakpoints.down('769')]: {
      marginTop: theme.spacing(1)
    }
  },
  dividerWrapper: {
    height: theme.spacing(3.75),
    marginLeft: theme.spacing(1.5)
  },
  cloneButtonWrapper: {
    marginLeft: theme.spacing(0.5),
    marginTop: theme.spacing(0.25)
  }
});

class TemplatePopup extends Component {
  state = {
    isExpanded: false,
    expandedTemplate: '',
    isDisabledAddNew: false,
    templatesArray: [],
    collapseItems: {},
    isEditClicked: null,
    addNewTemplateSelectedTemplate: '',
    addNewTemplateName: 'New Template',
    selectedTemplate: ''
  };
  isTemplateUpdated = false;
  isOutputTemplateSet = false;

  componentDidMount() {
    const labelsObject = getSubCategoryLabels();
    let collapseItems = cloneDeep(this.state.collapseItems);
    Object.entries(labelsObject).forEach(([ key, value ]) => {
      collapseItems[key] = true;
    });
    this.setState({ collapseItems });
  }

  componentDidUpdate(prevProps) {
    const { templatesList, bomDetailsArray = {} } = this.props;
    if (!this.isTemplateUpdated && !isEmpty(templatesList)) {
      this.isTemplateUpdated = true;
      this.setState({ templatesArray: this.changeTemplateList(templatesList) });
    }
    if (prevProps !== this.props) {
      this.setState({ templatesArray: this.changeTemplateList(templatesList) });
    }
    if (!this.isOutputTemplateSet && !isEmpty(bomDetailsArray)) {
      const { source } = this.props;
      this.isOutputTemplateSet = true;
      if (source === 'output') {
        const { pricing_template_id } = bomDetailsArray;
        this.setState({ selectedTemplate: pricing_template_id });
      }
    }
  }

  // change order of template list using requirement
  changeTemplateList = templatesList => {
    const { projectType, systemDetails, systemRequirements } = this.props.stateValues;
    let { requirement } = systemRequirements;
    if (projectType === 'commercial') {
      let sumToDecideCommercial = 0;
      const interconnects = get(systemDetails, projectType, []);
      interconnects.forEach(interconnect => {
        sumToDecideCommercial +=
          Number(get(interconnect, 'pvPanelWattage')) * Number(get(interconnect, 'pvPanelsCount'));
      });
      if (sumToDecideCommercial > 100000) {
        requirement = 'Large System';
      } else {
        requirement = 'Small System';
      }
    } else {
      if (requirement === sunlightBackup) {
        requirement = solarOnly;
      } else if (requirement === homeEssentialBackup || requirement === fullEnergyIndependence) {
        requirement = 'Solar + Storage';
      } else if (requirement === 'Storage Upgrade (Existing customer)') {
        requirement = 'Storage only';
      }
    }

    let newTemplatesList = [];
    for (let i = 0; i < templatesList.length; i++) {
      if (templatesList && templatesList[i].template_name.toLowerCase() === requirement.toLowerCase()) {
        newTemplatesList.unshift(templatesList[i]);
      } else {
        newTemplatesList.push(templatesList[i]);
      }
    }
    return newTemplatesList;
  };

  getMatchingTemplate = (tempDefaultTemplatesList, templateName) => {
    let result = '';
    tempDefaultTemplatesList.forEach(template => {
      if (template.template_name === templateName) {
        result = template.id;
      }
    });
    return result;
  };

  handleExpandCollapseTableRow = fieldName => {
    let collapseItems = cloneDeep(this.state.collapseItems);
    collapseItems[fieldName] = !collapseItems[fieldName];
    this.setState({ collapseItems });
  };

  handleAddNewTemplate = source => {
    trackEvents('triggerBOM', {
      menuValue: source === 'output' ? 'Add_New_Template_ouputpage' : 'Add_New_Template_inputpage'
    });
    this.setState({ isDisabledAddNew: true }, () => {
      scrollIntoView(document.getElementById('addNewTemplateSection'));
    });
  };

  handleDeleteTemplate = templateID => {
    const { getAllTemplates, deleteTemplate } = this.props;
    deleteTemplate({
      templateID,
      successCbk: () => {
        getAllTemplates();
        this.setState({
          expandedTemplate: '',
          isExpanded: false
        });
      }
    });
  };

  handleClone = template => {
    const { createTemplate, getAllTemplates } = this.props;
    const { template_name } = template;
    let createTemplateBody = {};
    createTemplateBody['template_name'] = template_name + ' - clone';
    createTemplateBody['item_list'] = template['item_list'];
    createTemplateBody['type'] = template['type'];
    createTemplate({
      createTemplateBody,
      successCbk: () => {
        getAllTemplates();
      }
    });
  };

  renderEditDeleteButtons = (text, iconName, templateID, is_default = false) => {
    const { classes } = this.props;
    const isEdit = text.toLowerCase() === 'edit';
    const handleClick = isEdit ? this.handleExpandCollapse : is_default ? () => {} : this.handleDeleteTemplate;
    return (
      <Box
        className={clsx(classes.buttonsEditDeleteSubWrapper, is_default && classes.cursorNormal)}
        onClick={() => handleClick(templateID, true)}
      >
        <Typography
          className={clsx(isEdit ? classes.editText : classes.deleteText, is_default && classes.disabledDelete)}
        >
          {text}
        </Typography>
        <img
          alt="editIcon"
          src={require(`./Icons/${iconName}.svg`)}
          className={clsx(isEdit ? classes.editIcon : classes.deleteIcon)}
        />
        {(!isEdit || (isEdit && window.innerWidth > 767)) && (
          <Box className={classes.dividerWrapper}>
            <Divider orientation="vertical" />
          </Box>
        )}
      </Box>
    );
  };

  handleExpandCollapse = (templateID, isEditClicked = false) => {
    if (templateID === this.state.expandedTemplate) {
      this.setState({ expandedTemplate: '', isExpanded: false, isEditClicked });
    } else {
      this.setState({ isExpanded: true, expandedTemplate: templateID, isEditClicked });
    }
    if (isEditClicked && this.props.source === 'input') {
      trackEvents('triggerBOM', { menuValue: 'Edit_template_inputpage' });
    }
  };

  getTemplateDetails = templateId => {
    const { templatesArray } = this.state;
    let result = '';
    templatesArray.forEach(template => {
      const { id, template_name } = template;
      if (id === templateId) {
        result = template_name;
      }
    });
    switch (result) {
      case 'Solar Only':
        result = 'Residential_Solar_template';
        break;
      case 'Small System':
        result = 'Small_commercial_template';
        break;
      case 'Large System':
        result = 'Large_commercial_template';
        break;
      case 'Solar + Storage':
        result = 'Residential_Solar_Storage_template';
        break;
      case 'Storage Only':
        result = 'Residential_Storage_template';
        break;
      default:
        result = '';
    }
    return result;
  };

  handleChooseTemplate = (id, company_id) => {
    const { handleContinue } = this.props;
    const templateGAEvent = this.getTemplateDetails(id);
    if (!isEmpty(templateGAEvent)) {
      trackEvents('triggerBOM', { menuValue: templateGAEvent });
    }
    this.setState({ selectedTemplate: id });
    handleContinue(id, company_id);
  };

  handleAddNewTemplateCancelButton = () => {
    this.setState({
      isDisabledAddNew: false,
      addNewTemplateSelectedTemplate: '',
      addNewTemplateName: 'New Template'
    });
  };

  handleCreateNewTemplate = () => {
    const { addNewTemplateName, addNewTemplateSelectedTemplate } = this.state;
    const { templatesList, createTemplate, getAllTemplates } = this.props;
    let createTemplateBody = {};
    templatesList.forEach(template => {
      if (template.id === addNewTemplateSelectedTemplate) {
        createTemplateBody['template_name'] = addNewTemplateName;
        createTemplateBody['item_list'] = template['item_list'];
        createTemplateBody['type'] = template['type'];
      }
    });
    createTemplate({
      createTemplateBody,
      successCbk: () => {
        getAllTemplates();
        this.setState({
          expandedTemplate: '',
          isExpanded: false,
          isDisabledAddNew: false,
          addNewTemplateName: 'New Template',
          addNewTemplateSelectedTemplate: ''
        });
      }
    });
  };

  handleCancelButtonTemplate = () => {
    this.setState({
      isExpanded: false,
      expandedTemplate: ''
    });
  };

  handleAddNewTemplateFieldsChange = (event, key) => {
    this.setState({ [key]: event.target.value });
  };

  getNewTemplateDropdown = () => {
    const { templatesList } = this.props;
    let templateArray = [];
    templatesList.forEach(template => {
      const { template_name, type, is_default } = template;
      if (is_default && (template_name === 'Solar + Storage' || template_name === 'Small System')) {
        templateArray.push({ templateType: type, template });
      }
    });
    return templateArray;
  };

  handleOnSaveTemplate = () => {
    const { templatesArray, expandedTemplate } = this.state;
    const { saveTemplate, getAllTemplates } = this.props;
    let templateSpecificArray = templatesArray.filter(template => {
      return template.id === expandedTemplate;
    });
    const sendTemplateBody = {
      template_name: get(templateSpecificArray[0], 'template_name'),
      item_list: get(templateSpecificArray[0], 'item_list'),
      is_default: get(templateSpecificArray[0], 'is_default', false)
    };
    saveTemplate({
      id: expandedTemplate,
      sendTemplateBody,
      successCbk: () => {
        getAllTemplates();
        this.setState({ expandedTemplate: '', isExpanded: false });
      }
    });
  };

  getTemplateSpecificObject = helperParams => {
    const { category, subCategory, filteredItemListCategoryWise } = helperParams;
    const subCategoryList = filteredItemListCategoryWise.filter(item => {
      return item.category === category && item.sub_category === subCategory;
    });
    return {
      key: subCategory,
      label: get(getSubCategoryLabels(), subCategory),
      subCategoryList
    };
  };

  getTemplatesArrayToPassAsProp = category => {
    const { templatesArray, expandedTemplate } = this.state;
    let templateSpecificArray = templatesArray.filter(template => {
      return template.id === expandedTemplate;
    });
    if (templateSpecificArray.length > 0) {
      templateSpecificArray = templateSpecificArray[0];
    }
    const itemList = get(templateSpecificArray, 'item_list', []);
    const filteredItemListCategoryWise = itemList.filter(item => {
      return item.category === category;
    });
    let subCategories = [];
    filteredItemListCategoryWise.forEach(item => {
      const { sub_category } = item;
      if (!subCategories.find(value => value === sub_category)) {
        subCategories.push(sub_category);
      }
    });
    let result = [];
    subCategories.forEach(item => {
      result.push(this.getTemplateSpecificObject({ category, subCategory: item, filteredItemListCategoryWise }));
    });
    return result;
  };

  isValueWithinRange = (helperParams, value) => {
    const { category } = helperParams;
    if (isNaN(value)) return false;
    let result = true;
    if (value.includes('.')) {
      const splitOnDot = value.split('.');
      if (category === 'NON_ENPHASE_EQUIPMENT_STRUCTURAL' && splitOnDot[1].length > 3) result = false;
      if (category !== 'NON_ENPHASE_EQUIPMENT_STRUCTURAL' && splitOnDot[1].length > 2) result = false;
      else if (Number(value) < 0 || Number(value) > 25000) result = false;
    }
    result = result && !(value !== '' && (Number(value) < 0 || Number(value) > 25000));
    return result;
  };

  handleTemplateNameChange = (value, index) => {
    const templatesArray = cloneDeep(this.state.templatesArray);
    templatesArray[index].template_name = value;
    this.setState({ templatesArray });
  };

  handleInputPriceChanges = (event, helperParams) => {
    const { category, subCategory, name } = helperParams;
    if (!this.isValueWithinRange(helperParams, event.target.value)) return;
    let templatesArray = cloneDeep(this.state.templatesArray);
    const { expandedTemplate } = this.state;
    const matchingTemplateIndex = findLastIndex(templatesArray, template => template.id === expandedTemplate);
    let templateSpecificArray = templatesArray[matchingTemplateIndex];
    let itemList = get(templateSpecificArray, 'item_list', []);
    itemList.forEach(item => {
      if (item.category === category && item.sub_category === subCategory && item.name === name) {
        item['price'] = event.target.value;
      }
    });
    templateSpecificArray['item_list'] = itemList;
    templatesArray[matchingTemplateIndex] = templateSpecificArray;
    this.setState({ templatesArray });
  };

  getTemplateTables = category => {
    return (
      <CommonTemplatesTables
        bomItemsArray={this.getTemplatesArrayToPassAsProp(category)}
        handleExpandCollapseTableRow={this.handleExpandCollapseTableRow}
        handleInputPriceChanges={this.handleInputPriceChanges}
        collapseItems={this.state.collapseItems}
        category={category}
        isEditClicked={this.state.isEditClicked}
      />
    );
  };

  render() {
    const {
      classes,
      open = false,
      handleClose = () => {},
      currentlySending,
      source = 'input',
      stateValues
    } = this.props;
    const { addNewTemplateSelectedTemplate, addNewTemplateName, templatesArray, isEditClicked } = this.state;
    const { projectType = '' } = stateValues;
    const isCreateDisabled = isEmpty(addNewTemplateName) || isEmpty(addNewTemplateSelectedTemplate);

    return (
      <Dialog
        open={open}
        maxWidth={'lg'}
        aria-labelledby="template-popup-bom"
        fullWidth
        id="templateAllPopup"
        classes={{ paper: classes.paper }}
      >
        <DialogTitle disableTypography className={classes.dialogTitleWrapper}>
          <Typography className={classes.dialogTitle}>{'Pricing Template'}</Typography>
          <CloseIcon
            className={classes.close}
            onClick={() => {
              this.setState({
                expandedTemplate: '',
                isExpanded: false,
                isDisabledAddNew: false,
                addNewTemplateName: 'New Template',
                addNewTemplateSelectedTemplate: ''
              });
              handleClose(false);
            }}
          />
        </DialogTitle>
        <Divider />
        <DialogContent className={classes.dialogContentWrapper}>
          <Box className={classes.addButtonRowWrapper}>
            <Typography className={classes.infoTextStyle}>
              Please select a template to be used for BOM price calculations
            </Typography>
            <Button
              className={clsx(classes.styleAddNewButton, this.state.isDisabledAddNew && classes.styleDisabled)}
              onClick={() => {
                this.handleAddNewTemplate(source);
              }}
              disabled={this.state.isDisabledAddNew}
            >
              + Add New Template
            </Button>
          </Box>
          <Box>
            {templatesArray.map((template, index) => {
              const { template_name, id, is_default, company_id, type } = template;
              const isTemplateExpanded = id === this.state.expandedTemplate;
              const isDisabledChoose = source === 'output' && id === this.state.selectedTemplate;
              if (!type || !projectType || type.toLowerCase() !== projectType.toLowerCase()) return null;
              return (
                <Box
                  key={'template:' + index}
                  className={clsx(
                    isTemplateExpanded && classes.templateMainWrapperExpanded,
                    classes.templateMainWrapper
                  )}
                >
                  <Box className={classes.templateMainWrapperPadding}>
                    <Box className={classes.displayFlex}>
                      <Box
                        onClick={() => {
                          this.handleExpandCollapse(id, false);
                        }}
                        className={classes.styleExpandIcon}
                      >
                        {isTemplateExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                      </Box>
                      <Box className={classes.templateSubWrapper}>
                        {!is_default && isEditClicked && this.state.expandedTemplate === id ? (
                          <Box className={classes.templateNameWrapper}>
                            <TextField
                              className={classes.templateNameText}
                              value={template_name}
                              onChange={event => this.handleTemplateNameChange(event.target.value, index)}
                            />
                          </Box>
                        ) : (
                          <Box className={classes.templateNameWrapper}>
                            <Typography className={classes.templateNameText}>{template_name}</Typography>
                          </Box>
                        )}
                        <Box className={clsx(classes.defaultTextBox, classes[`backgroundColorTag${capitalize(type)}`])}>
                          <Typography className={classes.defaultText}>{capitalize(type)}</Typography>
                        </Box>
                      </Box>
                    </Box>
                    {!isTemplateExpanded ? (
                      <Box className={classes.gridWrapperActionButtons}>
                        <Grid container>
                          <Grid item sm={3} xs={6} className={classes.actionButtonsTemplate}>
                            <Button
                              className={clsx(classes.styleChooseButton, isDisabledChoose && classes.disabledChoose)}
                              onClick={() => this.handleChooseTemplate(id, company_id)}
                              disabled={isDisabledChoose}
                            >
                              Choose
                            </Button>
                          </Grid>

                          <Grid item sm={3} xs={6} className={classes.actionButtonsTemplate}>
                            {this.renderEditDeleteButtons('Edit', 'editTemplateIcon', id)}
                          </Grid>

                          <Grid item sm={3} xs={6} className={classes.actionButtonsTemplate}>
                            {this.renderEditDeleteButtons(
                              'Delete',
                              is_default ? 'deleteTemplateIconDisabled' : 'deleteTemplateIcon',
                              id,
                              is_default
                            )}
                          </Grid>

                          <Grid item sm={3} xs={6} className={classes.actionButtonsTemplate}>
                            <Box
                              className={clsx(classes.buttonsEditDeleteSubWrapper, classes.cloneButtonWrapper)}
                              onClick={() => this.handleClone(template)}
                            >
                              <Typography className={classes.styleCloneText}>Clone</Typography>
                              <IconButton className={classes.styleCopyIcon}>
                                <FileCopyOutlinedIcon />
                              </IconButton>
                            </Box>
                          </Grid>
                        </Grid>
                      </Box>
                    ) : (
                      isEditClicked && (
                        <Box className={classes.buttonsCancelSaveMainWrapper}>
                          <Button
                            className={classes.styleCancelButton}
                            onClick={() => this.handleCancelButtonTemplate()}
                          >
                            Cancel
                          </Button>
                          <Button className={classes.styleSaveButton} onClick={() => this.handleOnSaveTemplate()}>
                            Save
                          </Button>
                        </Box>
                      )
                    )}
                  </Box>
                  {isTemplateExpanded && (
                    <Box className={classes.dividerWrapperExpanded}>
                      <Divider />
                    </Box>
                  )}
                  {isTemplateExpanded && (
                    <Fragment>
                      {this.getTemplateTables('ENPHASE_EQUIPMENT')}
                      {this.getTemplateTables('NON_ENPHASE_EQUIPMENT_ELECTRICAL')}
                      {this.getTemplateTables('NON_ENPHASE_EQUIPMENT_STRUCTURAL')}
                      {this.getTemplateTables('SOFT_COST')}
                    </Fragment>
                  )}
                </Box>
              );
            })}
          </Box>
          {this.state.isDisabledAddNew && (
            <Box className={classes.addNewTemplateBoxWrapper} id="addNewTemplateSection">
              <Typography className={classes.styleAddNewTemplateHeading}>Create Custom Template</Typography>
              <Box className={classes.addNewTemplateBody}>
                <Box className={classes.addNewInputFieldsWrapper}>
                  <FormControl
                    id={'addNewTemplateFormControlDropdown'}
                    className={clsx(classes.inputField, classes.customLabel)}
                  >
                    <InputLabel id={'addNewTemplateInputLabel'}>
                      <Label text="Select Default Template" className={classes.styleLabel} />
                    </InputLabel>
                    <Select
                      value={addNewTemplateSelectedTemplate}
                      onChange={e => this.handleAddNewTemplateFieldsChange(e, 'addNewTemplateSelectedTemplate')}
                    >
                      {this.getNewTemplateDropdown().map((item, index) => {
                        const { templateType, template } = item;
                        const { id } = template;
                        return (
                          <MenuItem value={id} key={'addNewTemplate' + id}>
                            {templateType}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                  <FormControl
                    id={'addNewTemplateFormControlTextField'}
                    className={clsx(classes.inputField, classes.textFieldAdditionalDetails, classes.customLabel)}
                  >
                    <TextField
                      label={<Label text={'Template Name'} className={classes.styleLabel} />}
                      value={addNewTemplateName}
                      onChange={e => this.handleAddNewTemplateFieldsChange(e, 'addNewTemplateName')}
                    />
                  </FormControl>
                </Box>
                <Box className={classes.addNewTemplateButtonsWrapper}>
                  <Button className={classes.styleChooseButton} onClick={() => this.handleAddNewTemplateCancelButton()}>
                    Cancel
                  </Button>
                  <Button
                    className={clsx(classes.styleCreateButton, isCreateDisabled && classes.styleDisabledCreate)}
                    disabled={isCreateDisabled}
                    onClick={() => this.handleCreateNewTemplate()}
                  >
                    Create
                  </Button>
                </Box>
              </Box>
            </Box>
          )}
        </DialogContent>
        {currentlySending && (
          <Backdrop className={classes.backdrop} open>
            <CircularProgress color="inherit" />
          </Backdrop>
        )}
      </Dialog>
    );
  }
}

const mapStateToProps = state => ({
  bomDetailsArray: state.bomInputReducer.bomDetailsArray,
  templatesList: state.bomInputReducer.templatesList,
  currentlySending: state.bomInputReducer.currentlySending
});

const mapDispatchToProps = dispatch => ({
  saveTemplate: payload => dispatch(saveTemplate(payload)),
  getAllTemplates: payload => dispatch(getAllTemplates(payload)),
  createTemplate: payload => dispatch(createTemplate(payload)),
  deleteTemplate: payload => dispatch(deleteTemplate(payload))
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(TemplatePopup));
