import React from 'react';
import { Dialog, DialogContent, DialogTitle, DialogActions, Button, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const styles = makeStyles(theme => ({
  titleWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderBottom: '1px solid #E1E1E1'
  },
  button: {
    textTransform: 'none',
    width: theme.spacing(11),
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.white,
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.white
    }
  },
  cancelButton: {
    textTransform: 'none',
    width: theme.spacing(11),
    backgroundColor: theme.palette.primary.white,
    color: theme.palette.primary.dark,
    '&:hover': {
      backgroundColor: theme.palette.primary.darkGray,
      color: theme.palette.primary.dark
    }
  },
  buttonWrapper: {
    padding: theme.spacing(2, 3),
    borderTop: '1px solid #E1E1E1'
  },
  paddingDialogContent: {
    padding: theme.spacing(2, 3)
  },
  label: {
    fontSize: 16,
    color: '#4A4A4A'
  },
  note: {
    fontSize: 16
  },
  colorLeadId: {
    color: '#4F89F4'
  },
  displayFlex: {
    display: 'flex',
    paddingTop: theme.spacing(3)
  },
  problemIcon: {
    fontSize: theme.spacing(4),
    padding: theme.spacing(0.75, 1.25)
  },
  blueColor: {
    color: theme.palette.primary.blue
  }
}));

const ConfirmationPopup = props => {
  const { open, handleClose, handleConfirm } = props;
  const classes = styles();
  return (
    <Dialog
      open={open}
      aria-labelledby="template"
      aria-describedby="template-description"
      onClose={handleClose}
      fullWidth
    >
      <DialogTitle id="template" disableTypography className={classes.titleWrapper}>
        <Typography variant="h6">Edit Requirements</Typography>
      </DialogTitle>
      <DialogContent className={classes.paddingDialogContent}>
        <Typography className={classes.label}>
          Are you sure you want to edit the requirements? This will reset any changes made on this page
        </Typography>
      </DialogContent>
      <DialogActions className={classes.buttonWrapper}>
        <Button className={classes.cancelButton} onClick={() => handleClose()} variant="outlined">
          Cancel
        </Button>
        <Button className={classes.button} onClick={() => handleConfirm()} variant="outlined">
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationPopup;
