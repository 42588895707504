import React, { useState, useRef, useEffect } from 'react';
import { Typography, IconButton, makeStyles, Popper, Paper, ClickAwayListener, Grow } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import clsx from 'clsx';
import renderHTML from 'react-render-html';

const useStyles = makeStyles(theme => ({
  infoIconWrapper: {
    padding: '0 !important',
    marginLeft: theme.spacing(0.25)
  },
  infoIconDimension: {
    fontSize: 20,
    marginLeft: theme.spacing(0.25)
  },
  systemDetailsInfo: {
    marginBottom: theme.spacing(0.25)
  },
  popper: {
    zIndex: 1100
  },
  text: {
    fontSize: theme.spacing(1.5),
    width: theme.spacing(40),
    padding: theme.spacing(1)
  }
}));

const InfoPopper = props => {
  const classes = useStyles();
  const { text, applyClassName = 'systemDetailsInfo' } = props;

  const [ open, setOpen ] = useState(false);
  const anchorRef = useRef(null);

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen);
  };

  const handleClose = event => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = useRef(open);
  useEffect(
    () => {
      if (prevOpen.current === true && open === false) {
        anchorRef.current.focus();
      }

      prevOpen.current = open;
    },
    [ open ]
  );

  return (
    <div>
      <IconButton ref={anchorRef} className={classes.infoIconWrapper} onClick={handleToggle}>
        <InfoIcon className={clsx(classes.infoIconDimension, classes[applyClassName])} />
      </IconButton>

      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        placement="bottom"
        transition
        disablePortal
        className={classes.popper}
      >
        {({ TransitionProps, placement }) => (
          <Grow {...TransitionProps}>
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <Typography autoFocusItem={open} className={classes.text}>
                  {renderHTML(text)}
                </Typography>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  );
};

export default InfoPopper;
