import React, { Fragment } from 'react';
import clsx from 'clsx';
import { Box, Typography, Paper, Checkbox } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';

import useStyles from './styles';
import { Commercial, Residential } from '../Icons';

const ProjectType = props => {
  const classes = useStyles();
  const { handleRadioChange, projectType } = props;
  const isCommercial = projectType === 'commercial';
  const isResidential = projectType === 'residential';

  const options = [
    {
      key: 'commercial',
      title: 'Commercial',
      isSelected: isCommercial,
      Icon: Commercial
    },
    {
      key: 'residential',
      title: 'Residential',
      isSelected: isResidential,
      Icon: Residential
    }
  ];

  const renderOptions = option => {
    const { key, title, isSelected, Icon } = option;
    return (
      <Box
        key={key}
        className={clsx(classes.styleTypeWrapper, isSelected ? classes.typeWrapperOutlined : classes.typeWrapper)}
        onClick={() => handleRadioChange(key)}
      >
        <Box className={classes.styleTypeSubWrapper}>
          <Icon className={classes.iconSize} isselected={isSelected.toString()} />
          <Typography className={clsx(classes.typeText, isSelected && classes.fontColorMain)}>{title}</Typography>
          <Checkbox
            color="primary"
            checked={isSelected}
            className={classes.styleCheckboxButton}
            icon={<RadioButtonUncheckedIcon />}
            checkedIcon={<CheckCircleIcon />}
          />
        </Box>
      </Box>
    );
  };

  return (
    <Fragment>
      <Paper square className={clsx(classes.projectTypePaper, props.disabled && classes.disabledField)}>
        <Typography className={classes.chooseProjectTypeText}>
          Choose the type of project you are looking for
        </Typography>
        <Box className={classes.mainTypeWrapper}>{options.map(option => renderOptions(option))}</Box>
      </Paper>
    </Fragment>
  );
};

export default ProjectType;
