import { createTheme, responsiveFontSizes } from '@material-ui/core/styles';
import { orange, grey, blue } from '@material-ui/core/colors';

let theme = createTheme({
  typography: {
    useNextVariants: true,
    fontFamily: 'Roboto'
  },
  palette: {
    primary: {
      main: '#F3731F',
      dark: '#335977',
      yellow: '#FFE785',
      faded: '#C9C9C9',
      lightBlack: '#5C5C5C',
      dataBlack: '#999999',
      white: '#FFFFFF',
      mainText: '#FF7141',
      contentBg: '#F4F4F4',
      border: '#E5E5E5',
      sectionBg: '#284358',
      darkGray: '#DBDBDB',
      blue: '#0D83F5',
      quoteDivideColor: '#F37320',
      lightGray: '#A3A3A3',
      required: '#f44336',
      ...orange
    },
    secondary: {
      main: '#FFFFFF',
      light: '#FAFAFA',
      dark: '#7E7E7E',
      lighter: grey[100],
      heading: '#44524F',
      ...grey
    },
    tertiary: {
      main: '#2679f6',
      ...blue
    }
  },
  overrides: {
    MuiStepIcon: {
      text: {
        fontWeight: 'bold',
        fill: '#FFFFFF'
      },
      root: {
        '&$active': {
          color: ''
        }
      }
    }
  }
});

theme = responsiveFontSizes(theme);

export default theme;
