import React, { Component } from 'react';
import { Router, Switch } from 'react-router-dom';
import history from './history';
import { DefaultLayout } from '../containers/Layouts';
import NoMatch from '../components/NoMatch';
import BOMCalculatorInput from '../containers/BOMCalculator';

export default class Routes extends Component {
  render() {
    return (
      <Router history={history}>
        <Switch>
          <DefaultLayout exact path="/manager/dashboard/bom" component={BOMCalculatorInput} />
          <DefaultLayout component={NoMatch} />
        </Switch>
      </Router>
    );
  }
}
