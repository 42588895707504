const styles = (theme, fromTemplate = false) => ({
  mainWrapper: {
    boxShadow: '0px 0px 8px #00000029',
    backgroundColor: '#FFF',
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(4),
    borderRadius: theme.spacing(1)
  },
  mainWrapperTemplates: {
    boxShadow: 'none',
    backgroundColor: '#FFF',
    marginTop: fromTemplate ? 0 : theme.spacing(3),
    marginBottom: theme.spacing(4),
    borderRadius: 0
  },
  mainTableWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    padding: theme.spacing(1.5),
    marginLeft: fromTemplate ? theme.spacing(5) : 0
  },
  headingBox: {
    width: fromTemplate ? '100%' : '30%',
    fontSize: 16,
    fontWeight: 1000,
    [theme.breakpoints.down('xs')]: {
      width: '100%'
    }
  },
  dropdownWrapper: {
    width: theme.spacing(20),
    [theme.breakpoints.down('xs')]: {
      marginTop: theme.spacing(2)
    }
  },
  tableWrapper: {
    marginTop: theme.spacing(1),
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0
  },
  tableWrapperTemplates: {
    marginTop: theme.spacing(1),
    borderRadius: 0,
    boxShadow: 'none'
  },
  tableHeader: {
    border: 'none',
    padding: theme.spacing(2)
  },
  headerContainer: {
    color: '#FFF',
    backgroundColor: theme.palette.primary.dark
  },
  borderRight: {
    borderRight: '2px solid #E5E5E5'
  },
  borderRightAndWidth: {
    borderRight: '2px solid #E5E5E5',
    width: '45%'
  },
  mainSwitchWrapper: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap'
  },
  switchWrapper: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      marginLeft: theme.spacing(-0.5)
    }
  },
  fontSize13: {
    fontSize: 13
  },
  styleDistributorToggle: {
    fontSize: 32
  },
  styleDistributorToggleWrapper: {
    paddingTop: 0,
    paddingBottom: 0
  },
  styleTableHeaderContent: {
    padding: theme.spacing(0.5, 0.5, 0.5, 2),
    fontSize: 13
  },
  widthItems: {
    width: '30%',
    minWidth: theme.spacing(24.5)
  },
  widthQuantity: {
    width: '25%',
    [theme.breakpoints.down('md')]: {
      minWidth: '16%'
    },
    [theme.breakpoints.down('xs')]: {
      minWidth: '180px'
    }
  },
  widthPrice: {
    width: '25%',
    [theme.breakpoints.down('md')]: {
      minWidth: '16%'
    },
    [theme.breakpoints.down('xs')]: {
      minWidth: '180px'
    }
  },
  widthNetPrice: {
    width: '25%',
    [theme.breakpoints.down('xs')]: {
      minWidth: '240px'
    }
  },
  styleTableHeaderContentTemplate: {
    padding: theme.spacing(0.5, 0.5, 0.5, 6.5),
    fontSize: 12
  },
  styleCollapseIcon: {
    height: theme.spacing(2.25),
    width: theme.spacing(2.25),
    padding: 0,
    cursor: 'pointer'
  },
  firstColumnWrapper: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: fromTemplate ? theme.spacing(4.5) : 0
  },
  firstColumnHeading: {
    marginLeft: theme.spacing(1.5),
    marginTop: theme.spacing(0.25),
    fontSize: 14,
    fontWeight: 600
  },
  firstColumnHeadingSelected: {
    color: theme.palette.primary.main
  },
  firstColumnHeadingOtherSoftCosts: {
    fontSize: 14,
    fontWeight: 600,
    marginLeft: fromTemplate ? theme.spacing(4.5) : 0
  },
  tableRowBackground: {
    backgroundColor: '#F2F2F2'
  },
  subCategoryMainWrapper: {
    marginLeft: fromTemplate ? theme.spacing(8.25) : theme.spacing(3.75)
  },
  font13: {
    fontSize: 13
  },
  subCategoryStyleSKU: {
    color: '#545456',
    fontSize: 12,
    paddingBottom: theme.spacing(0.5),
    paddingTop: theme.spacing(0.5)
  },
  subCategoryStyleQTY: {
    color: '#545456',
    fontSize: 12
  },
  templatesSecondColumnPaddingLeft: {
    paddingLeft: theme.spacing(6.5)
  },
  priceInputWrapper: {
    display: 'flex',
    borderBottom: '1px solid rgba(0,0,0,0.42)',
    alignItems: 'center',
    width: '36%',
    [theme.breakpoints.down('md')]: {
      width: '50%'
    }
  },
  priceInputWrapperStructural: {
    display: 'flex',
    borderBottom: '1px solid rgba(0,0,0,0.42)',
    alignItems: 'center',
    width: '28%',
    [theme.breakpoints.down('md')]: {
      width: '50%'
    },
    [theme.breakpoints.down('xs')]: {
      width: '68%'
    }
  },
  borderBottomNone: {
    borderBottom: 'none !important'
  },
  inputBaseMarginLeftRight: {
    marginLeft: theme.spacing(0.5),
    marginRight: theme.spacing(0.5)
  },
  displayFlexAlignCenter: {
    display: 'flex',
    alignItems: 'center'
  },
  font14: {
    fontSize: 14
  },
  stylePriceText: {
    fontWeight: 600,
    minWidth: theme.spacing(7)
  },
  discountMainWrapper: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: 'auto',
    marginRight: 'auto ',
    paddingLeft: theme.spacing(1.5),
    minWidth: theme.spacing(14.5)
  },
  discountLabel: {
    [theme.breakpoints.down('md')]: {
      fontSize: 14
    }
  },
  discountInputWrapper: {
    display: 'flex',
    borderBottom: '1px solid rgba(0,0,0,0.42)',
    alignItems: 'center',
    width: '36%',
    [theme.breakpoints.down('md')]: {
      width: '48%'
    },
    marginLeft: theme.spacing(1)
  },
  styleTotalRowText: {
    color: theme.palette.primary.main,
    fontWeight: 1000,
    fontSize: 16
  }
});

export default styles;
