export const sysSizeDC = 'system_size_dc';
export const sysSizeAC = 'system_size_ac';

export const inputFields = {
  commercial: [
    {
      key: 'name',
      apiKey: 'name',
      name: 'Name',
      nonCsvColumn: true
    },
    {
      key: 'pvManufacturer',
      apiKey: 'maker_id',
      name: 'PV Manufacturer',
      nonCsvColumn: true
    },
    {
      key: 'pvModel',
      apiKey: 'module_id',
      name: 'PV Model',
      nonCsvColumn: true
    },
    {
      key: 'pvPanelType',
      apiKey: 'panel_type',
      name: 'PV Panel Type'
    },
    {
      key: 'pvPanelWattage',
      apiKey: 'panel_wdc',
      name: 'PV Panel Wattage (DC)'
    },
    {
      key: 'pvPanelsCount',
      apiKey: 'panel_count',
      name: 'PV Panels Count'
    },
    {
      key: 'panelOrientation',
      apiKey: 'panel_orientation',
      name: 'Panel Orientation'
    },
    {
      key: 'servicePanelAmperage',
      apiKey: 'service_panel_amperage',
      name: 'Service panel amperage',
      unit: 'A'
    },
    {
      key: 'servicePanelVoltage',
      apiKey: 'service_panel_voltage',
      name: 'Service panel Voltage',
      unit: 'V'
    },
    {
      key: 'microinverter',
      apiKey: 'inverter_type',
      name: 'Microinverter'
    },
    {
      key: 'centerTapping',
      apiKey: 'is_center_tapping',
      name: 'Center Feeding',
      boolean: true
    },
    {
      key: 'unusedCableDrops',
      apiKey: 'percentage_of_unused_cable_drops',
      name: '% of unused cable drops',
      unit: '%'
    },
    {
      key: 'highElectricNoiseEnvironment',
      apiKey: 'is_high_electric_noise',
      name: 'High Electric noise Environment',
      nonCsvColumn: true,
      boolean: true
    },
    {
      key: 'name',
      apiKey: 'name',
      name: 'Name',
      nonCsvColumn: true
    },
    {
      key: 'nprRequired',
      apiKey: 'is_npr_required',
      name: 'NPR Required?',
      nonCsvColumn: true,
      boolean: true
    },
    {
      key: 'advGridFunctionRequired',
      apiKey: 'is_adv_grid_function_required',
      name: 'Advanced Grid Function Required?',
      nonCsvColumn: true,
      boolean: true
    }
  ],
  residential: [
    {
      key: 'name',
      apiKey: 'name',
      name: 'Name',
      nonCsvColumn: true
    },
    {
      key: 'pvManufacturer',
      apiKey: 'maker_id',
      name: 'PV Manufacturer',
      nonCsvColumn: true
    },
    {
      key: 'pvModel',
      apiKey: 'module_id',
      name: 'PV Model',
      nonCsvColumn: true
    },
    {
      key: 'storageSize',
      apiKey: 'storage_size',
      name: 'Storage Size',
      nonCsvColumn: true
    },
    {
      key: 'acModule',
      apiKey: 'module_name',
      name: 'AC Module',
      nonCsvColumn: true
    },
    {
      key: 'pvPanelType',
      apiKey: 'panel_type',
      name: 'PV Panel Type'
    },
    {
      key: 'pvPanelWattage',
      apiKey: 'panel_wdc',
      name: 'PV Panel Wattage (DC)'
    },
    {
      key: 'pvPanelsCount',
      apiKey: 'panel_count',
      name: 'PV Panels Count'
    },
    {
      key: 'servicePanelVoltage',
      apiKey: 'service_panel_voltage',
      name: 'Service panel Voltage',
      unit: 'V'
    },
    {
      key: 'microinverter',
      apiKey: 'inverter_type',
      name: 'Microinverter'
    },
    {
      key: 'existingMicroinverter',
      apiKey: 'existing_micros',
      name: 'Existing Microinverter',
      nonCsvColumn: true
    },
    {
      key: 'existingCellModem',
      apiKey: 'cell_modem_required',
      name: 'Existing Cell modem',
      nonCsvColumn: true,
      boolean: true
    },
    {
      key: 'panelOrientation',
      apiKey: 'panel_orientation',
      name: 'Panel Orientation',
      nonCsvColumn: true
    },
    {
      key: 'portraitPanelCount',
      apiKey: 'portrait_panel_count',
      name: 'Portrait Panel Count',
      nonCsvColumn: true
    },
    {
      key: 'landscapePanelCount',
      apiKey: 'landscape_panel_count',
      name: 'Landscape Panel Count',
      nonCsvColumn: true
    }
  ]
};

export const csvOutputFields = {
  commercial: [
    {
      key: sysSizeAC,
      name: 'System Size (AC)',
      canAdd: true,
      unit: 'kW'
    },
    {
      key: sysSizeDC,
      name: 'System Size (DC)',
      canAdd: true,
      unit: 'kW'
    },
    {
      key: 'service_panel_voltage',
      name: 'Service Panel Voltage',
      unit: 'V'
    },
    {
      key: 'inverters_per_phase',
      name: 'Inverters per Phase',
      canAdd: true
    },
    {
      key: 'inverters_count',
      name: 'Inverter Count',
      canAdd: true
    },
    {
      key: 'inverter_type',
      name: 'Inverter'
    },
    {
      key: 'dc_ac_ratio',
      name: 'DC/AC ratio',
      canAdd: true
    },
    {
      key: 'branch_circuits_count',
      name: 'Branch circuit Count',
      canAdd: true
    },
    {
      key: 'branch_circuits_per_phase',
      name: 'Branch circuits per Phase',
      canAdd: true
    }
  ],
  residential: [
    {
      key: sysSizeAC,
      name: 'System Size (AC)',
      canAdd: true,
      unit: 'kW'
    },
    {
      key: sysSizeDC,
      name: 'System Size (DC)',
      canAdd: true,
      unit: 'kW'
    },
    {
      key: 'service_panel_voltage',
      name: 'Service Panel Voltage',
      unit: 'V'
    },
    {
      key: 'inverters_per_phase',
      name: 'Inverters per Phase',
      canAdd: true
    },
    {
      key: 'inverters_count',
      name: 'Inverter Count',
      canAdd: true
    },
    {
      key: 'inverter_type',
      name: 'Inverter'
    },
    {
      key: 'dc_ac_ratio',
      name: 'DC/AC ratio',
      canAdd: true
    },
    {
      key: 'branch_circuits_count',
      name: 'Branch circuit Count',
      canAdd: true
    },
    {
      key: 'branch_circuits_per_phase',
      name: 'Branch circuits per Phase',
      canAdd: true
    }
  ]
};

export const categories = {
  ENPHASE_EQUIPMENT: {
    name: 'Enphase Equipment',
    subCategories: {
      INVERTERS: 'Inverters',
      STORAGE: 'Storage',
      COMMUNICATIONS: 'Communications',
      ACCESSORIES: 'Accessories'
    },
    fields: {
      commercial: [
        {
          key: 'quantity',
          name: 'Project quantity',
          canAdd: true
        },
        {
          key: 'store_price',
          alternateKey: 'distributor_price',
          name: 'Unit Price ($)'
        },
        {
          key: 'net_price',
          name: 'Gross Cost ($)',
          categorySum: true,
          canAdd: true
        },
        {
          key: 'discount_amount',
          name: 'Discount',
          categorySum: true
        },
        {
          key: 'total_price',
          name: 'Net Cost ($)',
          calculatedByOtherField: true,
          categorySum: true
        },
        {
          key: 'price_per_wdc',
          name: '$/Wdc',
          calculatedByOtherField: true,
          categorySum: true
        },
        {
          key: 'sku',
          name: 'SKU'
        },
        {
          key: 'quantity_per_sku',
          name: 'Qty/SKU'
        }
      ],
      residential: [
        {
          key: 'quantity',
          name: 'Project quantity',
          canAdd: true
        },
        {
          key: 'store_price',
          alternateKey: 'distributor_price',
          name: 'Unit Price ($)'
        },
        {
          key: 'net_price',
          name: 'Gross Cost ($)',
          categorySum: true,
          canAdd: true
        },
        {
          key: 'discount_amount',
          name: 'Discount',
          categorySum: true
        },
        {
          key: 'total_price',
          name: 'Net Cost ($)',
          calculatedByOtherField: true,
          categorySum: true
        },
        {
          key: 'price_per_wdc',
          name: '$/Wdc',
          calculatedByOtherField: true,
          categorySum: true
        },
        {
          key: 'sku',
          name: 'SKU'
        },
        {
          key: 'quantity_per_sku',
          name: 'Qty/SKU'
        }
      ]
    }
  },
  NON_ENPHASE_EQUIPMENT_ELECTRICAL: {
    name: 'Non-Enphase Equipment (Electrical)',
    subCategories: {
      ELECTRICAL: 'Electrical',
      PV_PANEL: 'PV panels',
      AC_PANEL: 'AC panels'
    },
    fields: {
      commercial: [
        {
          key: 'quantity',
          name: 'Project quantity',
          canAdd: true
        },
        {
          key: 'distributor_price',
          name: 'Unit Price ($)'
        },
        {
          key: 'net_price',
          name: 'Gross Cost ($)',
          canAdd: true,
          categorySum: true
        },
        {
          key: 'discount_amount',
          name: 'Discount',
          categorySum: true
        },
        {
          key: 'total_price',
          name: 'Net Cost ($)',
          calculatedByOtherField: true,
          categorySum: true
        },
        {
          key: 'price_per_wdc',
          name: '$/Wdc',
          calculatedByOtherField: true,
          categorySum: true
        },
        {
          key: 'sku',
          name: 'SKU'
        },
        {
          key: 'quantity_per_sku',
          name: 'Qty/SKU'
        }
      ],
      residential: [
        {
          key: 'quantity',
          name: 'Project quantity',
          canAdd: true
        },
        {
          key: 'distributor_price',
          name: 'Unit Price ($)'
        },
        {
          key: 'net_price',
          name: 'Gross Cost ($)',
          canAdd: true,
          categorySum: true
        },
        {
          key: 'discount_amount',
          name: 'Discount',
          categorySum: true
        },
        {
          key: 'total_price',
          name: 'Net Cost ($)',
          calculatedByOtherField: true,
          categorySum: true
        },
        {
          key: 'price_per_wdc',
          name: '$/Wdc',
          calculatedByOtherField: true,
          categorySum: true
        },
        {
          key: 'sku',
          name: 'SKU'
        },
        {
          key: 'quantity_per_sku',
          name: 'Qty/SKU'
        }
      ]
    }
  },
  NON_ENPHASE_EQUIPMENT_STRUCTURAL: {
    name: 'Non-Enphase Equipment (Structural)',
    subCategories: {
      RACKING: 'Racking',
      OTHER_EBOS: 'Other EBOS',
      LABOR_PROJECT_MANAGEMENT: 'Labor and Project Mgmt',
      CONTINGENCY: 'Contingency'
    },
    fields: {
      commercial: [
        {
          key: 'quantity',
          name: 'Project quantity'
        },
        {
          key: 'distributor_price',
          name: 'Unit price ($/Wdc)'
        },
        {
          key: 'net_price',
          name: 'Gross Cost ($)',
          categorySum: true
        },
        {
          key: 'discount_amount',
          name: 'Discount'
        },
        {
          key: 'total_price',
          name: 'Net Cost ($)',
          categorySum: true,
          calculatedByOtherField: true
        },
        {
          key: 'price_per_wdc',
          name: '$/Wdc',
          calculatedByOtherField: true,
          categorySum: true
        }
      ],
      residential: [
        {
          key: 'quantity',
          name: 'Project quantity'
        },
        {
          key: 'distributor_price',
          name: 'Unit price ($/Wdc)'
        },
        {
          key: 'net_price',
          name: 'Gross Cost ($)',
          categorySum: true
        },
        {
          key: 'discount_amount',
          name: 'Discount'
        },
        {
          key: 'total_price',
          name: 'Net Cost ($)',
          categorySum: true,
          calculatedByOtherField: true
        },
        {
          key: 'price_per_wdc',
          name: '$/Wdc',
          calculatedByOtherField: true,
          categorySum: true
        }
      ]
    }
  },
  SOFT_COST: {
    name: '',
    subCategories: {
      SOFT_COST: 'Other Soft costs'
    },
    fields: {
      commercial: [
        {
          key: 'quantity',
          name: 'Project quantity'
        },
        {
          key: 'distributor_price',
          name: 'Unit Price ($)'
        },
        {
          key: 'net_price',
          name: 'Gross Cost ($)',
          categorySum: true
        },
        {
          key: 'discount_amount',
          name: 'Discount'
        },
        {
          key: 'total_price',
          name: 'Net Cost ($)',
          categorySum: true,
          calculatedByOtherField: true
        },
        {
          key: 'price_per_wdc',
          name: '$/Wdc',
          calculatedByOtherField: true,
          categorySum: true
        }
      ],
      residential: [
        {
          key: 'quantity',
          name: 'Project quantity'
        },
        {
          key: 'distributor_price',
          name: 'Unit Price ($)'
        },
        {
          key: 'net_price',
          name: 'Gross Cost ($)',
          categorySum: true
        },
        {
          key: 'discount_amount',
          name: 'Discount'
        },
        {
          key: 'total_price',
          name: 'Net Cost ($)',
          categorySum: true,
          calculatedByOtherField: true
        },
        {
          key: 'price_per_wdc',
          name: '$/Wdc',
          calculatedByOtherField: true,
          categorySum: true
        }
      ]
    }
  }
};

export const priceKeys = [ 'net_price', 'total_price', 'discount_amount' ];

// Encharge 3, Encharge 10
export const excludeSKUsFromStoreOrder = [ 'ENCHARGE-3-1P-NA', 'ENCHARGE-10-1P-NA' ];
export const TRIGGER_DOWNLOAD_CSV_REQUEST = 'TRIGGER_DOWNLOAD_CSV_REQUEST';

export const GET_NPS_SHOWN_REQUEST = 'GET_NPS_SHOWN_REQUEST';

export const SET_NPS_FEEDBACK_REQUEST = 'SET_NPS_FEEDBACK_REQUEST';
