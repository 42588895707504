import React, { Component, Fragment } from 'react';
import {
  Box,
  IconButton,
  InputBase,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  withStyles
} from '@material-ui/core';
import clsx from 'clsx';
import { get, isEmpty, isEqual } from 'lodash';
import styles from './styles';
import {
  getFilteredArray,
  getHelperArrays,
  getNetPriceIncludingDiscountPerBOMItem,
  getNetPriceIncludingDiscountSubcategoryWise,
  getTotalQuantity
} from './helper';
import AddBoxIcon from '@material-ui/icons/AddBox';
import IndeterminateCheckBoxIcon from '@material-ui/icons/IndeterminateCheckBox';

class NonEnphaseElectricalTable extends Component {
  state = {
    currentEnphaseBOMItems: [],
    interconnectName: '',
    numberOfInterconnects: 0
  };

  isInterconnectUpdated = false;

  componentDidUpdate(prevProps) {
    if (!this.isInterconnectUpdated && !isEmpty(this.props.currentInterconnects)) {
      this.isInterconnectUpdated = true;
      this.setState({
        currentEnphaseBOMItems: this.props.currentInterconnects,
        interconnectName:
          this.props.currentInterconnects.length > 1 ? 'project' : this.props.currentInterconnects[0]['name'],
        numberOfInterconnects: this.props.currentInterconnects.length
      });
    }
    if (!isEqual(prevProps.currentInterconnects, this.props.currentInterconnects)) {
      this.setState({ currentEnphaseBOMItems: this.props.currentInterconnects });
    }
  }

  handleInterconnectChange = e => {
    this.setState({ interconnectName: e.target.value });
  };

  render() {
    const { classes, handleInterconnectsInputChange, handleExpandCollapseTables, expandCollapseItems } = this.props;
    const { currentEnphaseBOMItems, numberOfInterconnects } = this.state;
    const showInterconnectDropdown = numberOfInterconnects > 1;
    const isCompleteProjectDropdown = this.state.interconnectName === 'project';
    const helperArray = getHelperArrays(this.state.currentEnphaseBOMItems, {
      category: 'NON_ENPHASE_EQUIPMENT_ELECTRICAL',
      isInterconnect: true
    });
    let totalPriceTable = 0;
    helperArray.forEach(helperObject => {
      const { key } = helperObject;
      totalPriceTable += getNetPriceIncludingDiscountSubcategoryWise(this.state.currentEnphaseBOMItems, {
        category: 'NON_ENPHASE_EQUIPMENT_ELECTRICAL',
        isInterconnect: true,
        subCategory: key
      });
    });

    return (
      <Fragment>
        <Paper square className={classes.mainWrapper}>
          <Box className={classes.mainTableWrapper}>
            <Box className={classes.headingBox}>Non-Enphase Equipment (Electrical)</Box>
            {showInterconnectDropdown && (
              <Select
                value={this.state.interconnectName}
                className={classes.dropdownWrapper}
                onChange={e => {
                  this.handleInterconnectChange(e);
                }}
              >
                <MenuItem key={'nonEnphaseEquipmentProject'} value={'project'}>
                  {'Project'}
                </MenuItem>
                {currentEnphaseBOMItems.map((interconnect, index) => {
                  return (
                    <MenuItem key={interconnect.name + index} value={interconnect.name}>
                      {interconnect.name}
                    </MenuItem>
                  );
                })}
              </Select>
            )}
          </Box>
          <TableContainer component={Paper} className={classes.tableWrapper}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead className={classes.tableHeader}>
                <TableRow>
                  <TableCell
                    className={clsx(classes.borderRight, classes.headerContainer)}
                    classes={{ root: clsx(classes.styleTableHeaderContent, classes.widthItems) }}
                  >
                    ITEMS
                  </TableCell>
                  <TableCell
                    className={classes.headerContainer}
                    classes={{ root: clsx(classes.styleTableHeaderContent, classes.widthQuantity) }}
                  >
                    QUANTITY
                  </TableCell>
                  <TableCell
                    className={classes.headerContainer}
                    classes={{ root: clsx(classes.styleTableHeaderContent, classes.widthPrice) }}
                  >
                    COST ($/UNIT)
                  </TableCell>
                  <TableCell
                    className={classes.headerContainer}
                    classes={{ root: clsx(classes.styleTableHeaderContent, classes.widthNetPrice) }}
                  >
                    NET COST
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {helperArray.map((row, index) => {
                  const { collapseName, subCategory, key } = row;
                  const collapseCategory = !get(expandCollapseItems, `${collapseName}`);
                  return (
                    <Fragment key={'non-enphase-equipment-' + index}>
                      <TableRow>
                        <TableCell className={classes.borderRight} component="th" scope="row">
                          <Box className={classes.firstColumnWrapper}>
                            <IconButton
                              className={classes.styleCollapseIcon}
                              onClick={() => {
                                handleExpandCollapseTables(collapseName);
                              }}
                            >
                              {collapseCategory ? (
                                <IndeterminateCheckBoxIcon color="primary" />
                              ) : (
                                <AddBoxIcon color="primary" />
                              )}
                            </IconButton>
                            <Typography
                              className={clsx(
                                classes.firstColumnHeading,
                                collapseCategory && classes.firstColumnHeadingSelected
                              )}
                            >
                              {subCategory}
                            </Typography>
                          </Box>
                        </TableCell>
                        <TableCell>-</TableCell>
                        <TableCell>-</TableCell>
                        <TableCell>
                          <Typography className={clsx(classes.font14, classes.stylePriceText)}>
                            ${Math.round(
                              getNetPriceIncludingDiscountSubcategoryWise(this.state.currentEnphaseBOMItems, {
                                subCategory: key,
                                category: 'NON_ENPHASE_EQUIPMENT_ELECTRICAL',
                                isInterconnect: true
                              })
                            ).toLocaleString()}
                          </Typography>
                        </TableCell>
                      </TableRow>
                      {collapseCategory &&
                        getFilteredArray(this.state.currentEnphaseBOMItems, {
                          subCategory: key,
                          category: 'NON_ENPHASE_EQUIPMENT_ELECTRICAL',
                          interconnectName: this.state.interconnectName,
                          isInterconnect: true
                        }).map((subCategoricalValue, index) => {
                          const { distributor_price = '', quantity = '' } = subCategoricalValue;
                          const totalQuantity = getTotalQuantity(
                            this.state.currentEnphaseBOMItems,
                            'NON_ENPHASE_EQUIPMENT_ELECTRICAL',
                            key,
                            subCategoricalValue.name
                          );
                          return (
                            <TableRow
                              key={'non-enphase-equipment-table-' + index}
                              className={classes.tableRowBackground}
                            >
                              <TableCell className={classes.borderRight} component="th" scope="row">
                                <Box className={classes.subCategoryMainWrapper}>
                                  <Typography className={classes.font14}>{subCategoricalValue.name}</Typography>
                                </Box>
                              </TableCell>
                              <TableCell>
                                <Box
                                  className={clsx(
                                    classes.priceInputWrapper,
                                    isCompleteProjectDropdown && classes.borderBottomNone
                                  )}
                                >
                                  {isCompleteProjectDropdown ? (
                                    <Typography className={classes.font14}>{totalQuantity}</Typography>
                                  ) : (
                                    <InputBase
                                      value={quantity === null ? '' : quantity}
                                      className={clsx(classes.inputBaseMarginLeftRight, classes.font14)}
                                      onChange={e => {
                                        handleInterconnectsInputChange(e, {
                                          category: 'NON_ENPHASE_EQUIPMENT_ELECTRICAL',
                                          subCategory: key,
                                          subCategoryName: subCategoricalValue.name,
                                          interconnectName: this.state.interconnectName,
                                          fieldName: 'quantity'
                                        });
                                      }}
                                    />
                                  )}
                                </Box>
                              </TableCell>
                              <TableCell>
                                <Box className={classes.priceInputWrapper}>
                                  <Typography>$</Typography>
                                  <InputBase
                                    value={distributor_price === null ? '' : distributor_price}
                                    className={clsx(classes.font14, classes.inputBaseMarginLeftRight)}
                                    onChange={e => {
                                      handleInterconnectsInputChange(e, {
                                        category: 'NON_ENPHASE_EQUIPMENT_ELECTRICAL',
                                        subCategory: key,
                                        subCategoryName: subCategoricalValue.name,
                                        interconnectName: this.state.interconnectName,
                                        fieldName: 'distributor_price'
                                      });
                                    }}
                                  />
                                </Box>
                              </TableCell>
                              <TableCell>
                                <Typography className={clsx(classes.stylePriceText, classes.font14)}>
                                  ${Math.round(
                                    getNetPriceIncludingDiscountPerBOMItem(this.state.currentEnphaseBOMItems, {
                                      category: 'NON_ENPHASE_EQUIPMENT_ELECTRICAL',
                                      subCategory: key,
                                      name: subCategoricalValue.name,
                                      isInterconnect: true
                                    })
                                  ).toLocaleString()}
                                </Typography>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                    </Fragment>
                  );
                })}

                <TableRow>
                  <TableCell
                    className={clsx(classes.borderRight, classes.styleTotalRowText)}
                    component="th"
                    scope="row"
                  >
                    Total
                  </TableCell>
                  <TableCell />
                  <TableCell />
                  <TableCell className={classes.styleTotalRowText}>
                    ${Math.round(totalPriceTable).toLocaleString()}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Fragment>
    );
  }
}

export default withStyles(styles)(NonEnphaseElectricalTable);
