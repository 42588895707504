import { findIndex, get } from 'lodash';

export const getTotalQuantity = (currentEnphaseBOMItems, category, subCategory, name) => {
  let totalQuantity = 0;
  currentEnphaseBOMItems.forEach(interconnect => {
    const projectBomItems = get(interconnect, 'bom_items', []);
    projectBomItems.forEach(bomItem => {
      if (bomItem.category === category && bomItem.sub_category === subCategory && bomItem.name === name) {
        totalQuantity += bomItem.quantity;
      }
    });
  });
  return totalQuantity;
};

const isBomExistingAlready = (array, findObject) => {
  const matchingIndex = findIndex(array, value => value.name === findObject.name);
  return matchingIndex !== -1;
};

export const getFilteredArray = (tempBomItems, helperParams) => {
  const { category, subCategory, interconnectName, isInterconnect = false } = helperParams;

  let subCategoricalBomItems = [];
  if (isInterconnect) {
    if (interconnectName === 'project') {
      tempBomItems.forEach(interconnect => {
        const bomItemsProject = get(interconnect, 'bom_items', []);
        bomItemsProject.forEach(bomItem => {
          if (
            bomItem.category === category &&
            bomItem.sub_category === subCategory &&
            !isBomExistingAlready(subCategoricalBomItems, bomItem)
          ) {
            subCategoricalBomItems.push(bomItem);
          }
        });
      });
    } else {
      let interconnectSpecificBomItems = tempBomItems.filter(bomItem => {
        return bomItem.name === interconnectName;
      });
      if (interconnectSpecificBomItems.length > 0) {
        interconnectSpecificBomItems = get(interconnectSpecificBomItems[0], 'bom_items', []);
      }
      subCategoricalBomItems = interconnectSpecificBomItems.filter(bomItem => {
        return bomItem.category === category && bomItem.sub_category === subCategory;
      });
    }
  } else {
    subCategoricalBomItems = tempBomItems.filter(bomItem => {
      return bomItem.category === category && bomItem.sub_category === subCategory;
    });
  }
  return subCategoricalBomItems;
};

export const getSubCategoryLabelObject = subcategory => {
  switch (subcategory) {
    case 'INVERTERS':
      return {
        key: 'INVERTERS',
        collapseName: 'collapseEnphaseInverters',
        subCategory: 'Inverters'
      };

    case 'COMMUNICATIONS':
      return {
        key: 'COMMUNICATIONS',
        collapseName: 'collapseEnphaseCommunications',
        subCategory: 'Communications'
      };
    case 'ACCESSORIES':
      return {
        key: 'ACCESSORIES',
        collapseName: 'collapseEnphaseAccessories',
        subCategory: 'Accessories'
      };
    case 'STORAGE':
      return {
        key: 'STORAGE',
        collapseName: 'collapseEnphaseStorage',
        subCategory: 'Storage'
      };
    case 'AC_PANEL':
      return {
        key: 'AC_PANEL',
        collapseName: 'collapseACPanels',
        subCategory: 'AC Panel'
      };
    case 'PV_PANEL':
      return {
        key: 'PV_PANEL',
        collapseName: 'collapsePVPanels',
        subCategory: 'PV Panel'
      };
    case 'ELECTRICAL':
      return {
        key: 'ELECTRICAL',
        collapseName: 'collapseElectrical',
        subCategory: 'Electrical'
      };
    case 'RACKING':
      return {
        key: 'RACKING',
        collapseName: 'collapseRacking',
        subCategory: 'Racking'
      };

    case 'OTHER_EBOS':
      return {
        key: 'OTHER_EBOS',
        collapseName: 'collaseOtherEBOS',
        subCategory: 'Other EBOS'
      };
    case 'LABOR_PROJECT_MANAGEMENT':
      return {
        key: 'LABOR_PROJECT_MANAGEMENT',
        collapseName: 'collapseLabor',
        subCategory: 'Labour and Project Management'
      };
    case 'CONTINGENCY':
      return {
        key: 'CONTINGENCY',
        collapseName: 'collapseContigency',
        subCategory: 'Contingency'
      };

    default:
      return {};
  }
};

export const getHelperArrays = (bomItemsArray = [], helperParams = {}) => {
  const { isInterconnect = false, category } = helperParams;
  let result = [];
  if (isInterconnect) {
    bomItemsArray.forEach((bomItem, index) => {
      const modifiedBomItems = get(bomItem, 'bom_items', []);
      modifiedBomItems.forEach(modifiedBomItem => {
        const matchingIndex = findIndex(result, value => value.key === modifiedBomItem.sub_category);
        if (category === modifiedBomItem.category && matchingIndex === -1) {
          const { key, collapseName, subCategory } = getSubCategoryLabelObject(modifiedBomItem.sub_category);
          result.push({ key, collapseName, subCategory });
        }
      });
    });
  } else {
    if (category === 'SOFT_COST') {
      result = [
        {
          key: 'SOFT_COST',
          subCategory: 'Design & Engineering'
        },
        {
          key: 'SOFT_COST',
          subCategory: 'Interconnect & Permitting'
        },
        {
          key: 'SOFT_COST',
          subCategory: 'Additional Warranty'
        }
      ];
    } else {
      bomItemsArray.forEach(modifiedBomItem => {
        const matchingIndex = findIndex(result, value => value.key === modifiedBomItem.sub_category);
        if (category === modifiedBomItem.category && matchingIndex === -1) {
          const { key, collapseName, subCategory } = getSubCategoryLabelObject(modifiedBomItem.sub_category);
          result.push({ key, collapseName, subCategory });
        }
      });
    }
  }
  return result;
};

export const getNetPriceAndDiscountPerBomItem = (bomItems, helperParams) => {
  const { category, subCategory, name } = helperParams;
  let discountAmount = 0,
    totalNetPrice = 0;
  bomItems.forEach(bomItem => {
    if (bomItem.category === category && bomItem.sub_category === subCategory && bomItem.name === name) {
      if (discountAmount === 0) {
        discountAmount = bomItem.discount_amount;
      }
      totalNetPrice += bomItem.net_price;
    }
  });
  return { discountAmount, totalNetPrice };
};

export const getNetPriceIncludingDiscountPerBOMItem = (array, helperParams = {}) => {
  const { isInterconnect = false } = helperParams;
  let discount = 0;
  let netPrice = 0;
  if (isInterconnect) {
    array.forEach(interconnect => {
      const bomItems = get(interconnect, 'bom_items', []);
      const { discountAmount, totalNetPrice } = getNetPriceAndDiscountPerBomItem(bomItems, helperParams);
      if (discount === 0) {
        discount = discountAmount;
      }
      netPrice += totalNetPrice;
    });
  } else {
    const { discountAmount, totalNetPrice } = getNetPriceAndDiscountPerBomItem(array, helperParams);
    discount = discountAmount;
    netPrice = totalNetPrice;
  }
  return netPrice - discount;
};

export const getAllUniqueBOMItemsSubcategoryWise = (array = [], helperParams = {}) => {
  const { category, subCategory, isInterconnect } = helperParams;
  let result = [];
  if (isInterconnect) {
    array.forEach(interconnect => {
      const bomItems = get(interconnect, 'bom_items', []);
      bomItems.forEach(bomItem => {
        if (bomItem.category === category && bomItem.sub_category === subCategory) {
          if (!result.includes(bomItem.name)) {
            result.push(bomItem.name);
          }
        }
      });
    });
  } else {
    array.forEach(bomItem => {
      if (bomItem.category === category && bomItem.sub_category === subCategory) {
        if (!result.includes(bomItem.name)) {
          result.push(bomItem.name);
        }
      }
    });
  }
  return result;
};

export const getNetPriceIncludingDiscountSubcategoryWise = (array = [], helperParams = {}) => {
  const uniqueBOMItemsSubcategoryWise = getAllUniqueBOMItemsSubcategoryWise(array, helperParams);
  let sum = 0;
  uniqueBOMItemsSubcategoryWise.forEach(item => {
    let newHelperParams = helperParams;
    newHelperParams['name'] = item;
    sum += getNetPriceIncludingDiscountPerBOMItem(array, newHelperParams);
  });
  return sum;
};
