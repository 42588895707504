import { get, isEmpty } from 'lodash';

export const SITE_SEARCH_LEAD_REQUEST = 'SITE_SEARCH_LEAD_REQUEST';
export const SITE_SEARCH_LEAD_SUCCESS = 'SITE_SEARCH_LEAD_SUCCESS';
export const SITE_SEARCH_LEAD_FAILURE = 'SITE_SEARCH_LEAD_FAILURE';

export const SET_BOM_INPUT_DETAILS_REQUEST = 'SET_BOM_INPUT_DETAILS_REQUEST';
export const SET_BOM_INPUT_DETAILS_SUCCESS = 'SET_BOM_INPUT_DETAILS_SUCCESS';
export const SET_BOM_INPUT_DETAILS_FAILURE = 'SET_BOM_INPUT_DETAILS_FAILURE';

export const GET_LEAD_BOM_DETAILS_REQUEST = 'GET_LEAD_BOM_DETAILS_REQUEST';
export const GET_LEAD_BOM_DETAILS_SUCCESS = 'GET_LEAD_BOM_DETAILS_SUCCESS';
export const GET_LEAD_BOM_DETAILS_FAILURE = 'GET_LEAD_BOM_DETAILS_FAILURE';

export const GET_EXISTING_SYSTEM_DETAILS_REQUEST = 'GET_EXISTING_SYSTEM_DETAILS_REQUEST';
export const GET_EXISTING_SYSTEM_DETAILS_SUCCESS = 'GET_EXISTING_SYSTEM_DETAILS_SUCCESS';
export const GET_EXISTING_SYSTEM_DETAILS_FAILURE = 'GET_EXISTING_SYSTEM_DETAILS_FAILURE';

export const CALCULATE_RESIDENTIAL_BOM_OUTPUT_REQUEST = 'CALCULATE_RESIDENTIAL_BOM_OUTPUT_REQUEST';
export const CALCULATE_RESIDENTIAL_BOM_OUTPUT_SUCCESS = 'CALCULATE_RESIDENTIAL_BOM_OUTPUT_SUCCESS';
export const CALCULATE_RESIDENTIAL_BOM_OUTPUT_FAILURE = 'CALCULATE_RESIDENTIAL_BOM_OUTPUT_FAILURE';

export const GET_CART_URL_REQUEST = 'GET_CART_URL_REQUEST';
export const GET_CART_URL_SUCCESS = 'GET_CART_URL_SUCCESS';
export const GET_CART_URL_FAILURE = 'GET_CART_URL_FAILURE';

// TODO - Need to replace values with keys (Line 11 - 18)
export const portrait = 'Portrait';
export const landscape = 'Landscape';
export const landscapeBallastEW = 'Landscape Ballast, E/W';
export const solarOnly = 'Solar only';
export const sunlightBackup = 'Sunlight Backup';
export const homeEssentialBackup = 'Home Essential Backup';
export const fullEnergyIndependence = 'Full Energy Independence';
export const storageOnly = 'Storage only';
export const existingStorageUpgrade = 'Storage Upgrade (Existing customer)';
export const yes = 'Yes';
export const no = 'No';
export const cell60 = '60 - cell';
export const cell66 = '66 - cell';
export const cell72 = '72 - cell';
export const cell96 = '96 - cell';
export const halfCell120 = '120 half - cell';
export const halfCell144 = '144 half - cell';
export const acModule = 'AC Module';
export const lessThanOrEqual200 = '<= 200';
export const servicePanelVoltage120_208 = '120/208 (3 Phase)';
export const servicePanelVoltage120_240 = '120/240 (1 Phase)';
export const IQ7 = 'IQ7';
export const IQ7Plus = 'IQ7+';
export const IQ6Plus = 'IQ6+';
export const storage3_4 = '3.4';
export const M175 = 'M175';
export const commercial = 'commercial';
export const residential = 'residential';
export const newSystem = 'New System';

export const mandatoryInfo = 'Please fill all mandatory details';
export const saveBomInfo = 'Please save current Bill of Material before purchase from Enphase Store';
export const skuQuantityInfo =
  'SKU Qty refers to the number of item SKUs to order. BOM calculator provides unit quantities which are adjusted based on SKU requirements on the Enphase store';
export const defaultCountryPhoneCode = '+1';
export const infoIconStoreText =
  "Few BOM items are not available on Enphase Store. Please click on 'Order from Store' to see the list of BOM available.";
export const infoIconContingencyText =
  'Contingency refers to costs that will probably occur based on past experience but with some uncertainty regarding the amount';

export const iq3BatteryId = 'a2a58461-53eb-4a5b-8bf5-4a73b4f1a73c';
export const iq10BatteryId = '5f47d35f-3908-4011-afc5-6d5df35d5e71';

export const microCompatibilityList = {
  IQ7: [ cell60, halfCell120 ],
  'IQ7+': [ cell60, cell72, halfCell120, halfCell144 ],
  IQ7A: [ cell60, cell66, halfCell120 ],
  IQ7X: [ cell96 ],
  IQ8: [ cell60, halfCell120 ],
  'IQ8+': [ cell60, cell66, cell72, halfCell120, halfCell144, acModule ],
  IQ8M: [ cell60, cell66, cell72, halfCell120, halfCell144, acModule ],
  IQ8A: [ cell60, cell66, cell72, halfCell120, halfCell144, acModule ],
  IQ8H: [ cell60, cell66, cell72, halfCell120, halfCell144, acModule ]
};

export const yesNoArrayList = () => [
  {
    key: yes,
    value: 'Yes'
  },
  {
    key: no,
    value: 'No'
  }
];

export const panelOrientationArrayList = () => [
  {
    key: portrait,
    value: 'Portrait'
  },
  {
    key: landscape,
    value: 'Landscape'
  },
  {
    key: landscapeBallastEW,
    value: 'Landscape Ballast, E/W'
  }
];

export const servicePanelAmperageArrayList = () => [
  {
    key: lessThanOrEqual200,
    value: '<= 200'
  },
  {
    key: 'between201And400',
    value: '201 - 400'
  },
  {
    key: 'between401And600',
    value: '401 - 600'
  },
  {
    key: 'greaterThan600',
    value: '> 600'
  }
];

export const acModuleArrayList = () => [
  {
    key: 'hanwaQCells',
    value: 'Hanwa Q cells'
  },
  {
    key: 'solaria',
    value: 'Solaria',
    disabled: true
  },
  {
    key: 'longi',
    value: 'Longi',
    disabled: true
  }
];

export const storageSizeArrayList = (isCommercial = true, interconnect = {}, systemRequirements) => {
  const pvPanelCount = get(interconnect, 'pvPanelsCount', '') || '';
  const microinverter = get(interconnect, 'microinverter', '') || '';
  const contMicroPowerObject = { IQ7: 0.295, 'IQ7+': 0.295, IQ7A: 0.295, IQ7X: 0.295 };
  const requirement = get(systemRequirements, 'requirement', '');
  const minEncharge3Required =
    requirement === existingStorageUpgrade || requirement === storageOnly
      ? 0
      : Math.ceil(Number(pvPanelCount) * Number(contMicroPowerObject[microinverter]) / 1.92);

  return [
    {
      key: storage3_4,
      value: '1 x IQ Battery 3 (3.4 kWh)',
      pvSheddingRequired: minEncharge3Required > 1,
      e3Count: 1,
      e10Count: 0
    },
    {
      key: '6.7',
      value: '2 x IQ Battery 3 (6.7 kWh)',
      pvSheddingRequired: minEncharge3Required > 2,
      e3Count: 2,
      e10Count: 0
    },
    {
      key: '10.1',
      value: '1 x IQ Battery 10 (10.1 kWh)',
      pvSheddingRequired: minEncharge3Required > 3,
      e3Count: 0,
      e10Count: 1
    },
    {
      key: '13.4',
      value: '1 x IQ Battery 10 + 1 x IQ Battery 3 (13.4 kWh)',
      pvSheddingRequired: minEncharge3Required > 4,
      e3Count: 1,
      e10Count: 1
    },
    {
      key: '16.8',
      value: '1 x IQ Battery 10 + 2 x IQ Battery 3 (16.8 kWh)',
      pvSheddingRequired: minEncharge3Required > 5,
      e3Count: 2,
      e10Count: 1
    },
    {
      key: '20.2',
      value: '2 x IQ Battery 10 (20.2 kWh)',
      pvSheddingRequired: minEncharge3Required > 6,
      e3Count: 0,
      e10Count: 2
    },
    {
      key: '23.5',
      value: '2 x IQ Battery 10 + 1 x IQ Battery 3 (23.5 kWh)',
      pvSheddingRequired: minEncharge3Required > 7,
      e3Count: 1,
      e10Count: 2
    },
    {
      key: '26.9',
      value: '2 x IQ Battery 10 + 2 x IQ Battery 3 (26.9 kWh)',
      pvSheddingRequired: minEncharge3Required > 8,
      e3Count: 2,
      e10Count: 2
    },
    {
      key: '30.2',
      value: '3 x IQ Battery 10 (30.2 kWh)',
      pvSheddingRequired: minEncharge3Required > 9,
      e3Count: 0,
      e10Count: 3
    },
    {
      key: '33.6',
      value: '3 x IQ Battery 10 + 1 x IQ Battery 3 (33.6 kWh)',
      pvSheddingRequired: minEncharge3Required > 10,
      e3Count: 1,
      e10Count: 3
    },
    {
      key: '37',
      value: '3 x IQ Battery 10 + 2 x IQ Battery 3 (37 kWh)',
      pvSheddingRequired: minEncharge3Required > 11,
      e3Count: 2,
      e10Count: 3
    },
    {
      key: '40.3',
      value: '4 x IQ Battery 10 (40.3 kWh)',
      pvSheddingRequired: minEncharge3Required > 12,
      e3Count: 0,
      e10Count: 4
    }
  ];
};

export const existingMicroinverterArrayList = () => [
  {
    key: M175,
    value: 'M175'
  },
  {
    key: 'M190',
    value: 'M190'
  },
  {
    key: 'M210',
    value: 'M210'
  },
  {
    key: 'M215',
    value: 'M215'
  },
  {
    key: 'M250',
    value: 'M250'
  },
  {
    key: 'D380',
    value: 'D380'
  },
  {
    key: 'IQ6',
    value: 'IQ6'
  },
  {
    key: IQ6Plus,
    value: IQ6Plus
  }
];

export const requirementArrayList = isCommercial => [
  {
    key: solarOnly,
    value: 'Solar only'
  },
  {
    key: sunlightBackup,
    value: sunlightBackup,
    hide: isCommercial
  },
  {
    key: homeEssentialBackup,
    value: homeEssentialBackup,
    hide: isCommercial
  },
  {
    key: fullEnergyIndependence,
    value: fullEnergyIndependence,
    hide: isCommercial
  },
  {
    key: storageOnly,
    value: 'Storage only',
    hide: isCommercial
  },
  {
    key: existingStorageUpgrade,
    value: 'Storage Upgrade (Existing customer)',
    hide: isCommercial
  }
];

export const requirementMap = {
  solar: 'Solar only',
  storage: 'Storage only',
  solarstorage: 'Solar + Storage',
  newsystem: 'New System'
};

export const servicePanelVoltageArrayList = systemRequirements => {
  const loadControllersRequired = get(systemRequirements, 'loadControllersRequired', false);
  const sunlightBackupRequired = get(systemRequirements, 'sunlightBackupRequired', false);
  return [
    {
      key: servicePanelVoltage120_208,
      value: '120/208 (3 Phase)',
      disabled: loadControllersRequired || sunlightBackupRequired
    },
    {
      key: servicePanelVoltage120_240,
      value: '120/240 (1 Phase)'
    },
    {
      key: '277/480 (3 Phase)',
      value: '277/480 (3 Phase)',
      disabled: loadControllersRequired || sunlightBackupRequired
    }
  ];
};

export const panelOrientationList = () => [
  { key: 'portrait', value: 'Portrait' },
  { key: 'landscape', value: 'Landscape' },
  { key: 'custom', value: 'Custom' }
];

export const pvPanelTypeArrayList = isCommercial => [
  {
    key: cell60,
    value: '60 - cell'
  },
  {
    key: cell66,
    value: '66 - cell',
    hide: isCommercial
  },
  {
    key: cell72,
    value: '72 - cell'
  },
  {
    key: cell96,
    value: '96 - cell',
    hide: isCommercial
  },
  {
    key: halfCell120,
    value: '120 half - cell'
  },
  {
    key: halfCell144,
    value: '144 half - cell'
  },
  {
    key: acModule,
    value: 'AC Module',
    hide: isCommercial
  }
];

const availablePvPanelType = [ cell60, cell66, cell72, cell96, halfCell120, halfCell144, acModule ];

export const microInverterArrayList = (isCommercial, interconnect, systemRequirements = {}) => {
  const requirement = get(systemRequirements, 'requirement', '');
  const showOnlyIQ8 = requirement === sunlightBackup;

  const pvPanelType = get(interconnect, 'pvPanelType', '') || '';
  const servicePanelVoltage = get(interconnect, 'servicePanelVoltage', '') || '';
  const shouldHide = inverterType =>
    !microCompatibilityList[inverterType].includes(pvPanelType) && availablePvPanelType.includes(pvPanelType);

  return [
    {
      key: IQ7,
      value: 'IQ7',
      disabled: (!isEmpty(pvPanelType) && shouldHide('IQ7')) || showOnlyIQ8
    },
    {
      key: IQ7Plus,
      value: 'IQ7+',
      disabled: (!isCommercial && shouldHide(IQ7Plus)) || showOnlyIQ8
    },
    {
      key: 'IQ8D',
      value: 'IQ8D',
      disabled: servicePanelVoltage === '120/240 (1 Phase)',
      hide: !isCommercial
    },
    {
      key: 'IQ7A',
      value: 'IQ7A',
      disabled: (!isEmpty(pvPanelType) && shouldHide('IQ7A')) || showOnlyIQ8
    },
    {
      key: 'IQ7X',
      value: 'IQ7X',
      disabled: (!isEmpty(pvPanelType) && shouldHide('IQ7X')) || showOnlyIQ8
    },
    {
      key: 'IQ8',
      value: 'IQ8',
      disabled:
        (!isEmpty(pvPanelType) && shouldHide('IQ8')) ||
        servicePanelVoltage === '120/208 (3 Phase)' ||
        servicePanelVoltage === '277/480 (3 Phase)',
      hide: isCommercial
    },
    {
      key: 'IQ8+',
      value: 'IQ8+',
      disabled:
        (!isEmpty(pvPanelType) && shouldHide('IQ8+')) ||
        servicePanelVoltage === '120/208 (3 Phase)' ||
        servicePanelVoltage === '277/480 (3 Phase)',
      hide: isCommercial
    },
    {
      key: 'IQ8M',
      value: 'IQ8M',
      disabled:
        (!isEmpty(pvPanelType) && shouldHide('IQ8M')) ||
        servicePanelVoltage === '120/208 (3 Phase)' ||
        servicePanelVoltage === '277/480 (3 Phase)',
      hide: isCommercial
    },
    {
      key: 'IQ8A',
      value: 'IQ8A',
      disabled:
        (!isEmpty(pvPanelType) && shouldHide('IQ8A')) ||
        servicePanelVoltage === '120/208 (3 Phase)' ||
        servicePanelVoltage === '277/480 (3 Phase)',
      hide: isCommercial
    },
    {
      key: 'IQ8H',
      value: 'IQ8H',
      disabled: (!isEmpty(pvPanelType) && shouldHide('IQ8H')) || servicePanelVoltage === '277/480 (3 Phase)',
      hide: isCommercial
    }
  ];
};

export const panelMakers = {
  'Hanwa Q cells': 'QCELL',
  Solaria: 'SOL',
  Longi: 'LON'
};

export const getCSVCheckboxLabels = () => {
  return {
    includeInput: 'Include input system details',
    includeOutput: 'Include Project Summary',
    includePrices: 'Include prices'
  };
};

export const backupStrings = () => {
  return {
    sunlightBackupRequired: 'Require Sunlight Backup',
    loadControllersRequired: 'Require Load Controllers'
  };
};

export const GET_TEMPLATES_REQUEST = 'GET_TEMPLATES_REQUEST';
export const GET_TEMPLATES_FAILURE = 'GET_TEMPLATES_FAILURE';
export const GET_TEMPLATES_SUCCESS = 'GET_TEMPLATES_SUCCESS';

export const SAVE_TEMPLATE_REQUEST = 'SAVE_TEMPLATE_REQUEST';
export const SAVE_TEMPLATE_FAILURE = 'SAVE_TEMPLATE_FAILURE';
export const SAVE_TEMPLATE_SUCCESS = 'SAVE_TEMPLATE_SUCCESS';

export const CREATE_TEMPLATE_REQUEST = 'CREATE_TEMPLATE_REQUEST';
export const CREATE_TEMPLATE_FAILURE = 'CREATE_TEMPLATE_FAILURE';
export const CREATE_TEMPLATE_SUCCESS = 'CREATE_TEMPLATE_SUCCESS';

export const DELETE_TEMPLATE_REQUEST = 'DELETE_TEMPLATE_REQUEST';
export const DELETE_TEMPLATE_FAILURE = 'DELETE_TEMPLATE_FAILURE';
export const DELETE_TEMPLATE_SUCCESS = 'DELETE_TEMPLATE_SUCCESS';

export const CONNECT_BOM_TO_LEAD_REQUEST = 'CONNECT_BOM_TO_LEAD_REQUEST';
export const CONNECT_BOM_TO_LEAD_SUCCESS = 'CONNECT_BOM_TO_LEAD_SUCCESS';
export const CONNECT_BOM_TO_LEAD_FAILURE = 'CONNECT_BOM_TO_LEAD_FAILURE';

export const GET_PV_MANUFACTURERS_REQUEST = 'GET_PV_MANUFACTURERS_REQUEST';
export const GET_PV_MANUFACTURERS_SUCCESS = 'GET_PV_MANUFACTURERS_SUCCESS';
export const GET_PV_MANUFACTURERS_FAILURE = 'GET_PV_MANUFACTURERS_FAILURE';

export const GET_RECENTLY_USED_PV_REQUEST = 'GET_RECENTLY_USED_PV_REQUEST';
export const GET_RECENTLY_USED_PV_SUCCESS = 'GET_RECENTLY_USED_PV_SUCCESS';
export const GET_RECENTLY_USED_PV_FAILURE = 'GET_RECENTLY_USED_PV_FAILURE';

export const GET_PV_MODELS_REQUEST = 'GET_PV_MODELS_REQUEST';
export const GET_PV_MODELS_SUCCESS = 'GET_PV_MODELS_SUCCESS';
export const GET_PV_MODELS_FAILURE = 'GET_PV_MODELS_FAILURE';

export const SET_CURRENTLY_SENDING_FALSE = 'SET_CURRENTLY_SENDING_FALSE';

export const CHECK_EXISTING_EMAIL_REQUEST = 'CHECK_EXISTING_EMAIL_REQUEST';
export const CHECK_EXISTING_EMAIL_SUCCESS = 'CHECK_EXISTING_EMAIL_SUCCESS';
export const CHECK_EXISTING_EMAIL_FAILURE = 'CHECK_EXISTING_EMAIL_FAILURE';
