import {
  SITE_SEARCH_LEAD_REQUEST,
  SITE_SEARCH_LEAD_SUCCESS,
  SITE_SEARCH_LEAD_FAILURE,
  SET_BOM_INPUT_DETAILS_REQUEST,
  SET_BOM_INPUT_DETAILS_FAILURE,
  SET_BOM_INPUT_DETAILS_SUCCESS,
  GET_TEMPLATES_FAILURE,
  GET_TEMPLATES_REQUEST,
  GET_TEMPLATES_SUCCESS,
  SAVE_TEMPLATE_REQUEST,
  SAVE_TEMPLATE_SUCCESS,
  SAVE_TEMPLATE_FAILURE,
  CONNECT_BOM_TO_LEAD_REQUEST,
  CONNECT_BOM_TO_LEAD_SUCCESS,
  CONNECT_BOM_TO_LEAD_FAILURE,
  GET_LEAD_BOM_DETAILS_REQUEST,
  GET_LEAD_BOM_DETAILS_SUCCESS,
  GET_LEAD_BOM_DETAILS_FAILURE,
  GET_PV_MANUFACTURERS_REQUEST,
  GET_PV_MANUFACTURERS_SUCCESS,
  GET_PV_MANUFACTURERS_FAILURE,
  GET_EXISTING_SYSTEM_DETAILS_REQUEST,
  GET_EXISTING_SYSTEM_DETAILS_SUCCESS,
  GET_EXISTING_SYSTEM_DETAILS_FAILURE,
  CALCULATE_RESIDENTIAL_BOM_OUTPUT_REQUEST,
  CALCULATE_RESIDENTIAL_BOM_OUTPUT_SUCCESS,
  CALCULATE_RESIDENTIAL_BOM_OUTPUT_FAILURE,
  GET_CART_URL_REQUEST,
  GET_CART_URL_SUCCESS,
  GET_CART_URL_FAILURE,
  SET_CURRENTLY_SENDING_FALSE,
  GET_PV_MODELS_REQUEST,
  GET_PV_MODELS_SUCCESS,
  GET_PV_MODELS_FAILURE,
  CREATE_TEMPLATE_REQUEST,
  CREATE_TEMPLATE_SUCCESS,
  CREATE_TEMPLATE_FAILURE,
  DELETE_TEMPLATE_REQUEST,
  DELETE_TEMPLATE_SUCCESS,
  DELETE_TEMPLATE_FAILURE,
  CHECK_EXISTING_EMAIL_REQUEST,
  CHECK_EXISTING_EMAIL_SUCCESS,
  CHECK_EXISTING_EMAIL_FAILURE,
  GET_RECENTLY_USED_PV_REQUEST,
  GET_RECENTLY_USED_PV_SUCCESS,
  GET_RECENTLY_USED_PV_FAILURE
} from './constants';

let initialState = {
  currentlySending: false,
  currentlySendingEmailValidation: false,
  currentlySendingRecentPVDetails: false,
  siteSearchList: [],
  messageType: '',
  message: '',
  bomDetailsArray: [],
  templatesList: [],
  leadJourneyObject: {},
  leadId: '',
  leadDetails: {},
  pvManufacturersList: [],
  existingSystemDetails: [],
  recentlyUsedPVManufacturersList: []
};

function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_CURRENTLY_SENDING_FALSE:
      return {
        ...state,
        [action.keyName]: false
      };
    case SITE_SEARCH_LEAD_REQUEST:
      return {
        ...state
      };
    case SITE_SEARCH_LEAD_SUCCESS:
      return {
        ...state,
        siteSearchList: action.siteSearchList
      };
    case SITE_SEARCH_LEAD_FAILURE:
      return {
        ...state,
        messageType: 'error',
        message: action.message
      };
    case GET_LEAD_BOM_DETAILS_REQUEST:
    case SET_BOM_INPUT_DETAILS_REQUEST:
    case CALCULATE_RESIDENTIAL_BOM_OUTPUT_REQUEST:
      return {
        ...state,
        currentlySending: true
      };
    case SET_BOM_INPUT_DETAILS_SUCCESS:
    case CALCULATE_RESIDENTIAL_BOM_OUTPUT_SUCCESS:
      return {
        ...state,
        currentlySending: false,
        bomDetailsArray: action.bomDetailsArray
      };
    case GET_LEAD_BOM_DETAILS_SUCCESS:
      return {
        ...state,
        currentlySending: false,
        leadDetails: action.leadDetails,
        bomDetailsArray: action.bomDetailsArray,
        leadId: action.leadDetails.lead_id
      };
    case GET_LEAD_BOM_DETAILS_FAILURE:
    case SET_BOM_INPUT_DETAILS_FAILURE:
    case CALCULATE_RESIDENTIAL_BOM_OUTPUT_FAILURE:
      return {
        ...state,
        currentlySending: false,
        messageType: 'error',
        message: action.message
      };
    case GET_TEMPLATES_REQUEST:
      return {
        ...state,
        currentlySending: true
      };
    case GET_TEMPLATES_SUCCESS:
      return {
        ...state,
        currentlySending: false,
        templatesList: action.templatesList
      };
    case GET_TEMPLATES_FAILURE:
      return {
        ...state,
        currentlySending: false,
        messageType: 'error',
        message: action.message
      };
    case SAVE_TEMPLATE_REQUEST:
      return {
        ...state,
        currentlySending: true
      };
    case SAVE_TEMPLATE_SUCCESS:
      return {
        ...state,
        currentlySending: false
      };
    case SAVE_TEMPLATE_FAILURE:
      return {
        ...state,
        currentlySending: false,
        messageType: 'error',
        message: action.message
      };
    case CREATE_TEMPLATE_REQUEST:
      return {
        ...state,
        currentlySending: true
      };
    case CREATE_TEMPLATE_SUCCESS:
      return {
        ...state,
        currentlySending: false
      };
    case CREATE_TEMPLATE_FAILURE:
      return {
        ...state,
        currentlySending: false,
        messageType: 'error',
        message: action.message
      };
    case DELETE_TEMPLATE_REQUEST:
      return {
        ...state,
        currentlySending: true
      };
    case DELETE_TEMPLATE_SUCCESS:
      return {
        ...state,
        currentlySending: false
      };
    case DELETE_TEMPLATE_FAILURE:
      return {
        ...state,
        currentlySending: false,
        messageType: 'error',
        message: action.message
      };
    case CONNECT_BOM_TO_LEAD_REQUEST:
      return {
        ...state,
        currentlySending: true
      };
    case CONNECT_BOM_TO_LEAD_SUCCESS:
      return {
        ...state,
        currentlySending: false,
        leadJourneyObject: action.leadJourneyObject,
        leadId: action.leadJourneyObject.lead_id
      };
    case CONNECT_BOM_TO_LEAD_FAILURE:
      return {
        ...state,
        currentlySending: false,
        messageType: 'error',
        message: action.message
      };
    case GET_PV_MANUFACTURERS_REQUEST:
      return {
        ...state,
        messageType: '',
        message: ''
      };
    case GET_PV_MANUFACTURERS_SUCCESS:
      return {
        ...state,
        pvManufacturersList: action.pvManufacturersList
      };
    case GET_PV_MANUFACTURERS_FAILURE:
      return {
        ...state,
        messageType: 'error',
        message: action.message
      };
    case GET_RECENTLY_USED_PV_REQUEST:
      return {
        ...state,
        currentlySendingRecentPVDetails: true,
        messageType: '',
        message: ''
      };
    case GET_RECENTLY_USED_PV_SUCCESS:
      return {
        ...state,
        currentlySendingRecentPVDetails: false,
        [action.listName]: action.recentlyUsedList
      };
    case GET_RECENTLY_USED_PV_FAILURE:
      return {
        ...state,
        currentlySendingRecentPVDetails: false,
        messageType: 'error',
        message: action.message
      };
    case GET_PV_MODELS_REQUEST:
      return {
        ...state,
        currentlySending: true,
        messageType: '',
        message: ''
      };
    case GET_PV_MODELS_SUCCESS:
      return {
        ...state,
        currentlySending: false
      };
    case GET_PV_MODELS_FAILURE:
      return {
        ...state,
        currentlySending: false,
        messageType: 'error',
        message: action.message
      };
    case GET_EXISTING_SYSTEM_DETAILS_REQUEST:
      return {
        ...state,
        messageType: '',
        message: '',
        currentlySending: true
      };
    case GET_EXISTING_SYSTEM_DETAILS_SUCCESS:
      return {
        ...state,
        currentlySending: false,
        existingSystemDetails: action.existingSystemDetails
      };
    case GET_EXISTING_SYSTEM_DETAILS_FAILURE:
      return {
        ...state,
        currentlySending: false,
        messageType: 'error',
        message: action.message
      };
    case GET_CART_URL_REQUEST:
      return {
        ...state,
        currentlySending: true
      };
    case GET_CART_URL_SUCCESS:
      return {
        ...state,
        currentlySending: false
      };
    case GET_CART_URL_FAILURE:
      return {
        ...state,
        currentlySending: false,
        messageType: 'error',
        message: action.message
      };
    case CHECK_EXISTING_EMAIL_REQUEST:
      return {
        ...state,
        currentlySendingEmailValidation: true
      };
    case CHECK_EXISTING_EMAIL_SUCCESS:
      return {
        ...state,
        currentlySendingEmailValidation: false
      };
    case CHECK_EXISTING_EMAIL_FAILURE:
      return {
        ...state,
        currentlySendingEmailValidation: false
      };
    default:
      return state;
  }
}

export default reducer;
